import React, { FC, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaginateList } from 'src/app/common/types/common-types';
import { ChannelCampaignItem } from 'src/app/modules/PulseLeads/types/channel-campaign-types';
import { deleteChannelCampaign } from 'src/app/modules/PulseLeads/network/channelCampaignCrud';
import { channelCampaignPath } from '../../ChannelCampaignRoutes';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type ChannelCampaignListProps = {
  isLoading: boolean;
  channelCampaignList?: PaginateList<ChannelCampaignItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const ChannelCampaignList: FC<ChannelCampaignListProps> = ({
  isLoading,
  channelCampaignList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteChannelCampaign(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Channel Campaign deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.straightThrough.channelCampaignList')}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.straightThrough.createChannelCampaign'),
            onClick: () => history.push(`${channelCampaignPath}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Channel Code',
            onClick: (row) => history.push(`${channelCampaignPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Channel Code',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'channelCode',
            displayName: Translation('pulseleads.straightThrough.channelCode'),
            renderData: (row) => row.channelCode || '-',
          },
          {
            keyIndex: 'campaignName',
            displayName: Translation('pulseleads.straightThrough.campaignName'),
            renderData: (row) => row.campaignName || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={channelCampaignList?.docs}
        totalPages={channelCampaignList?.totalPages}
        totalRecords={channelCampaignList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default ChannelCampaignList;
