import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import {
  ApprovalListParam,
  fetchApprovalList,
  fetchUserRole,
} from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import { ApprovalCampaignItem, AgencyCampaignStatusEnum } from 'src/app/modules/AgencyCampaign/types/approval-types';
import ApprovalList from './components/ApprovalList';
import { CampaignTypeItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { fetchCampaignTypeList } from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';

type ApprovalListingPageProps = {} & ParamsProps;

const initialState: ApprovalListParam = {
  roles: [''],
  status: AgencyCampaignStatusEnum.PENDING,
  campaignName: '',
  campaignType: '',
  campaignId: '',
  applicantName: '',
  page: 1,
  limit: 5,
};

export const approvalFilterKeys = ['status', 'campaignName', 'campaignType', 'campaignId', 'applicantName'];

const paramsInitiator = (initialParams?: Record<string, string>): ApprovalListParam => {
  return initialParams
    ? {
        roles: [''],
        status: initialParams.status || AgencyCampaignStatusEnum.PENDING,
        campaignName: initialParams.campaignName || '',
        campaignType: initialParams.campaignType || '',
        campaignId: initialParams.campaignId || '',
        applicantName: initialParams.applicantName || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ApprovalListingPage: FC<ApprovalListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [isLoadingUserRole, setIsLoadingUserRole] = useState<boolean>(true);
  const [filterState, setFilterState] = useState<ApprovalListParam>(paramsInitiator(initialParams));
  const [approvalList, setApprovalList] = useState<PaginateList<ApprovalCampaignItem>>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const [userRoleList, setUserRoleList] = useState<string[]>([]);
  const loadUserRoleList = async () => {
    const result = await fetchUserRole({ type: 'STAFF', user: user?.sub || '' });
    setUserRoleList(result);
    setFilterState({ ...filterState, roles: result });
    setIsLoadingUserRole(false);
    refreshData();
  };

  useEffect(() => {
    loadUserRoleList();
  }, []);

  const { isLoading, refreshData } = useDataProvider<PaginateList<ApprovalCampaignItem>>(
    async () => {
      if (!isLoadingUserRole) {
        try {
          if (onChangeQueryParams) {
            onChangeQueryParams(filterState);
          }
          return await fetchApprovalList(filterState, sortKey, dispatch);
        } catch (err) {
          setApprovalList(undefined);
        }
      }
    },
    setApprovalList,
    false,
  );

  useEffect(() => {
    refreshData();
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const [campaignTypeList, setCampaignTypeList] = useState<PaginateList<CampaignTypeItem>>();
  useDataProvider<PaginateList<CampaignTypeItem>>(
    async () => {
      try {
        return await fetchCampaignTypeList({ page: 1 }, [], dispatch);
      } catch (err) {}
    },
    setCampaignTypeList,
    false,
  );

  const handleFilterStatusChange = (_: any, newStatus: AgencyCampaignStatusEnum) => {
    setFilterState({
      ...filterState,
      status: newStatus,
    });
    refreshData();
  };

  const renderTabTitle = (status: AgencyCampaignStatusEnum) => (
    <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
      {Translation(`agencyCampaign.common.status.${status}`)}
    </span>
  );

  return (
    <>
      <Tabs
        indicatorColor="secondary"
        textColor="inherit"
        value={filterState.status}
        onChange={handleFilterStatusChange}
      >
        <Tab label={renderTabTitle(AgencyCampaignStatusEnum.PENDING)} value={AgencyCampaignStatusEnum.PENDING} />
        <Tab label={renderTabTitle(AgencyCampaignStatusEnum.COMPLETED)} value={AgencyCampaignStatusEnum.COMPLETED} />
      </Tabs>
      <PruFilter
        title={Translation('agencyCampaign.approval.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            initialValue: filterState.campaignName,
            displayName: Translation('agencyCampaign.common.campaignName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'campaignType',
            initialValue: filterState.campaignType,
            displayName: Translation('agencyCampaign.common.campaignType'),
            choices: [
              { displayName: '', value: '' },
              ...(campaignTypeList?.docs?.map((typeItem) => {
                return {
                  displayName: typeItem.name,
                  value: typeItem._id,
                };
              }) || []),
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignId',
            initialValue: filterState.campaignId,
            displayName: Translation('agencyCampaign.common.campaignId'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'applicantName',
            initialValue: filterState.applicantName,
            displayName: Translation('agencyCampaign.common.applicant'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            campaignName: data.campaignName,
            campaignType: data.campaignType,
            campaignId: data.campaignId,
            applicantName: data.applicantName,
          })
        }
        fetchData={refreshData}
      />
      <ApprovalList
        isLoading={isLoading}
        filterStatus={filterState.status}
        approvalList={approvalList}
        user={user}
        userRoleList={userRoleList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={(dataKey) => updateSortingKey(dataKey)}
      />
    </>
  );
};

export default ApprovalListingPage;
