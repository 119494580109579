import React, { useState, useEffect } from 'react';

import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { useIntl } from 'react-intl';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { useStyles } from '../styles/SettingStyles';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { settingViewAgentTask, fetchTodoSettingStatus } from '../../../network/todolistCrud';

const SettingViewManagerPage = ({}) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [canShowed, SetCanShowed] = useState(false);
  const [canHidden, SetCanHidden] = useState(false);
  const [keyStatus, SetKeyStatus] = useState('');
  const [valueStatus, SetValueStatus] = useState('');

  const onGetStatusManagerView = async () => {
    return await fetchTodoSettingStatus()
      .then((res) => {
        SetKeyStatus(res.key);
        SetValueStatus(res.value);
        if (res.isVisble) {
          SetCanShowed(true);
        } else {
          SetCanHidden(true);
        }
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: Translation('todolist.messageResponse.failedGet'),
            },
          ]),
        );
      });
  };

  const onActionRequestService = async (isVisibleParam: boolean) => {
    const payload = {
      key: keyStatus,
      value: valueStatus,
      isVisble: isVisibleParam,
    };

    try {
      await settingViewAgentTask(payload, dispatch)
        .then((res) => {
          if (res.isVisble) {
            SetCanShowed(true);
            SetCanHidden(false);
          } else {
            SetCanShowed(false);
            SetCanHidden(true);
          }
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('todolist.messageResponse.success'),
              },
            ]),
          );
        })
        .catch((err) => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Success',
                content: Translation('todolist.messageResponse.failed'),
              },
            ]),
          );
        });
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onSaveChanges = async (target: any) => {
    if (target.name === 'canShowed') {
      if (target.checked) {
        ModalService.confirm({
          title: Translation('todolist.modal.title'),
          description: Translation('todolist.modal.body'),
          onConfirm: () => {
            onActionRequestService(true);
          },
          confirmText: Translation('todolist.modal.button.save'),
          cancelText: Translation('todolist.modal.button.cancel'),
        });
      }
    } else {
      if (target.checked) {
        ModalService.confirm({
          title: Translation('todolist.modal.title'),
          description: Translation('todolist.modal.body'),
          onConfirm: () => {
            onActionRequestService(false);
          },
          confirmText: Translation('todolist.modal.button.save'),
          cancelText: Translation('todolist.modal.button.cancel'),
        });
      }
    }
  };

  useEffect(() => {
    onGetStatusManagerView();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('todolist.setting.title')}</div>
        </div>
      </div>
      <div className={classes.subHeader}>{Translation('todolist.setting.subtitle')}</div>
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}></div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{Translation('todolist.setting.field.canview')}</span>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              disabled={false}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={canShowed}
              name="canShowed"
              onChange={(e) => onSaveChanges(e.target)}
            />
          }
          label={Translation('todolist.setting.label.show')}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={false}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={canHidden}
              name="canHidden"
              onChange={(e) => onSaveChanges(e.target)}
            />
          }
          label={Translation('todolist.setting.label.hide')}
          labelPlacement="end"
        />
      </div>
    </div>
  );
};

export default SettingViewManagerPage;
