import { FC, useState } from 'react';
import { useStyles } from '../../style';
import { RadioGroup, FormControlLabel, Radio, LinearProgress, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload, formatBytes } from 'src/app/common/utils';
import { MTmode } from 'src/app/modules/MandatoryTraining/constants';
import { MICROSITE_RESOURCE } from 'src/app/modules/lead-microsite/constants';
import {
  FormSectionProps,
  LeadMicrositeContentTypeEnum,
  bannerStatus,
} from 'src/app/modules/lead-microsite/types/microsite.type';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import Form from 'src/app/common/components/Form';
import CloseIcon from '@mui/icons-material/Close';

const BannerSetting: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [bannerData, setBannerData] = useState(form.getFieldValue('bannerData'));
  const [status, setStatus] = useState(form.getFieldValue('bannerType') ?? LeadMicrositeContentTypeEnum.DEFAULT);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const isView = formMode === MTmode.VIEW;

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const TranslationWithVariable = (key: string) => intl.formatMessage({ id: key });
      const errMsg = TranslationWithVariable('global.max.file.size.allow');
      const size = formatBytes(file.size);

      const overSize = (size.type === 1 && size.value > 2048) || (size.type === 2 && size.value > 2);
      if (overSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }

      try {
        const createBlobRes = await createBlob(
          {
            mimeType: file.type,
            accessLevel: 'public',
            module: MICROSITE_RESOURCE,
            originalFilename: file.name,
          },
          dispatch,
        );

        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

        const result = blobDetail[0];

        if (result) {
          const bannerData = {
            id: result.blobId,
            fileName: result.blobName,
            fileUrl: result.url,
          };
          const newContent = {
            ...form.getFieldsValue(true),
            bannerData,
          };
          setBannerData(bannerData);
          form.setFieldsValue(newContent);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  return (
    <>
      <div className={classes.fieldMargin}>
        <Form.Item name="bannerType" label={Translation('agent_referral_link_banner')} rules={[DEFAULT_REQUIRED_RULES]}>
          <RadioGroup
            aria-label="isDefaultBanner"
            name="isDefaultBanner"
            defaultValue={status}
            className={classes.flexRow}
            onChange={(e) => setStatus(e.target.value)}
          >
            {Object.keys(bannerStatus(intl)).map((key: string) => (
              <FormControlLabel
                key={bannerStatus(intl)[key].text}
                value={bannerStatus(intl)[key].value}
                control={<Radio />}
                label={bannerStatus(intl)[key].text}
                disabled={isView}
              />
            ))}
          </RadioGroup>
        </Form.Item>
      </div>
      {/*banner*/}

      {
        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isCustom = getFieldValue('bannerType') === LeadMicrositeContentTypeEnum.CUSTOM;
            return (
              isCustom && (
                <div className="tw-flex tw-flex-row tw-mb-6 tw-mt-6 tw-ml-56">
                  <div>
                    {bannerData?.fileUrl && (
                      <div className="tw-flex tw-flex-row">
                        <div className={classes.imageContainer}>
                          <img className={classes.imageDisplayArea} src={bannerData?.fileUrl} alt="App Icon" />
                        </div>
                        <span>
                          <CloseIcon
                            className="tw-ml-8"
                            onClick={() => {
                              if (!isView) {
                                const bannerData = {
                                  id: '',
                                  fileName: '',
                                  fileUrl: '',
                                };
                                setBannerData(bannerData);
                                const newData = {
                                  ...form.getFieldsValue(true),
                                  bannerData,
                                };
                                form.setFieldsValue(newData);
                              }
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <div className={classes.rowContainer}>
                      <div className="tw-relative tw-w-full">
                        <div className="tw-flex tw-items-center">
                          <>
                            {!bannerData?.fileUrl && (
                              <div>
                                <input
                                  id="upload-campaign-image"
                                  hidden
                                  type="file"
                                  accept="image/jpeg, image/png, image/jpg"
                                  onClick={(e) => {
                                    const element = e.target as HTMLInputElement;
                                    element.value = '';
                                  }}
                                  onChange={handleFile}
                                />
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  size="small"
                                  disabled={isView}
                                  onClick={() => document.getElementById('upload-campaign-image')!.click()}
                                >
                                  {Translation('section.common.operation.upload')}
                                </Button>
                              </div>
                            )}
                            {!bannerData?.fileUrl && (
                              <div className="tw-ml-3">{Translation('agent_referral_link_banner_tips')}</div>
                            )}

                            {!!imageUploadProgress && (
                              <LinearProgress
                                className={classes.mt10}
                                variant="determinate"
                                color="secondary"
                                value={imageUploadProgress}
                              />
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          }}
        </Form.Item>
      }
    </>
  );
};
export default BannerSetting;
