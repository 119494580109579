import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { deleteCampaignFeedbackGroup } from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';
import { campaignFeedbackPath } from '../../CampaignFeedbackRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  CampaignFeedbackGroupItem,
  CampaignFeedbackItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';

type CampaignFeedbackGroupListProps = {
  isLoading: boolean;
  campaignFeedbackGroupList?: PaginateList<CampaignFeedbackGroupItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const CampaignFeedbackGroupList: FC<CampaignFeedbackGroupListProps> = ({
  isLoading,
  campaignFeedbackGroupList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteCampaignFeedbackGroup(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `FeedbackGroup deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.campaignFeedbackGroup.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.campaignFeedbackGroup.newFeedbackGroup'),
            onClick: () => history.push(`${campaignFeedbackPath}/group/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Feedback Group',
            onClick: (row) => history.push(`${campaignFeedbackPath}/group/edit/${row._id}`),
          },
          // {
          //   title: Translation("section.common.operation.delete"),
          //   tooltipText: "Delete Feedback Group",
          //   onClick: (row) => setDialogState({ open: true, id: row._id })
          // }
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('pulseleads.campaignFeedback.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'feedbacks',
            displayName: Translation('pulseleads.campaignFeedback.common.feedback'),
            renderData: (row) => (row.feedbacks as CampaignFeedbackItem[]).map((item) => item.name).join(', ') || '-',
          },
          {
            keyIndex: 'defaultFeedback',
            displayName: Translation('pulseleads.campaignFeedback.common.defaultFeedback'),
            renderData: (row) => (row.feedbacks as CampaignFeedbackItem[])[0].name || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={campaignFeedbackGroupList?.docs}
        totalPages={campaignFeedbackGroupList?.totalPages}
        totalRecords={campaignFeedbackGroupList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default CampaignFeedbackGroupList;
