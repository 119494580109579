import React, { useState } from 'react';
import { IconButton, Chip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TODOLIST_BASE_PATH, TODOLIST_TASKSECTION_PATH } from 'src/app/modules/ToDoList/constants';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { onDisplayDateFormat } from 'src/app/common/utils';
import { useStyles } from '../../styles/TaskSectionStyles';
import { moveTaskSectionUpward, moveTaskSectionDownward } from 'src/app/modules/ToDoList/network/todolistCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';

type TaskSectionProposalProps = {
  isLoading: boolean;
  tableName: string;
  data: any[];
  isShow: boolean;
  onRefresh: () => void;
};

const TaskSectionProposal = ({ isLoading, tableName, data, isShow, onRefresh }: TaskSectionProposalProps) => {
  const history = useHistory();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const taskSectionData = data.sort((a, b) => a.order - b.order);
  const dispatch = useDispatch();

  const [showHideSave, setShowHideSave] = useState(false);

  const onEditPositionData = async () => {
    return setShowHideSave(true);
  };

  const onCancelEditPositionData = async () => {
    return setShowHideSave(false);
  };

  const onUpwardData = async (id: string) => {
    try {
      await moveTaskSectionUpward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('todolist.messageResponse.success'),
          },
        ]),
      );

      onRefresh();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onDownwardData = async (id: string) => {
    try {
      await moveTaskSectionDownward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('todolist.messageResponse.success'),
          },
        ]),
      );

      onRefresh();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onEditTaskGroup = async (id: string, idChild: string, taskSectionStatus: boolean) => {
    history.push({
      pathname: `${TODOLIST_BASE_PATH}${TODOLIST_TASKSECTION_PATH}/edit/${id}/${idChild}`,
      state: { taskSectionStatus: taskSectionStatus },
    });
  };

  return (
    <>
      <PruTable
        title={Translation(`todolist.taskSection.${tableName}.title`)}
        subTitle={isShow ? 'Showed' : 'Hidden'}
        disableBulkSelect
        disablePagination
        headerBtnDef={[
          {
            color: 'secondary',
            title: Translation('todolist.common.editPosition'),
            onClick: () => {
              onEditPositionData();
            },
            condition: () => {
              return !showHideSave;
            },
          },
          {
            color: 'primary',
            title: Translation('todolist.button.done'),
            onClick: () => {
              onCancelEditPositionData();
            },
            condition: () => {
              return showHideSave;
            },
          },
        ]}
        operationDef={[
          {
            title: Translation('todolist.common.operation.edit'),
            tooltipText: Translation('todolist.common.tooltip.edit'),
            onClick: (row) => onEditTaskGroup(tableName, row.key, row.isShow),
            condition: () => {
              return !showHideSave;
            },
          },
          {
            title: (
              <IconButton size={'small'}>
                <ArrowUpwardIcon />
              </IconButton>
            ),
            tooltipText: Translation('todolist.action.move.up'),
            onClick: (row) => onUpwardData(row.key),
            condition: (row) => {
              return showHideSave && row.isShow;
            },
          },
          {
            title: (
              <IconButton size={'small'}>
                <ArrowDownwardIcon />
              </IconButton>
            ),
            tooltipText: Translation('todolist.action.move.down'),
            onClick: (row) => onDownwardData(row.key),
            condition: (row) => {
              return showHideSave && row.isShow;
            },
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'row.key',
            align: 'center',
            displayName: Translation('todolist.taskSection.titleTable.name'),
            renderData: (row) => (
              <span className={classes.tableFieldName}>
                {row.key ? Translation(`todolist.taskSection.title.${row.key}`) : '-'}
              </span>
            ),
          },
          {
            keyIndex: 'row.lastUpdate',
            align: 'center',
            displayName: Translation('todolist.taskSection.titleTable.lastUpdate'),
            renderData: (row) => onDisplayDateFormat(row.updatedAt) || '-',
          },
          {
            keyIndex: 'row.isShow',
            align: 'center',
            displayName: Translation('todolist.taskSection.titleTable.status'),
            renderData: (row) =>
              row.isShow ? (
                <Chip color="primary" label="Showed" variant="outlined" />
              ) : (
                <Chip className={classes.chipHidden} label="Hidden" variant="outlined" />
              ),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={taskSectionData}
      />
    </>
  );
};

export default TaskSectionProposal;
