import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import AlarmIcon from '@mui/icons-material/AddAlarm';
import { ReceiverType, PublishStatus, PublishTimeOptions } from '../../../../constants';
import { styles as useDetailClass } from '../style';
import TargetApplicationSection from 'src/app/common/components/TargetApplicantSection';
import FileUploader, { FileObj } from 'src/app/common/components/FileUploader';
import { fileUpload } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { getEventTemplate } from '../../../../network/collectionDetailCrud';
import moment from 'moment';
import { ENABLE_DESIGNATION } from 'src/app/common/components/target-applicant-section-v2';

const PublishSettings: React.FC<any> = (props) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: detailClass } = useDetailClass();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [publishItems, SetPublishItems] = useState<any[]>([]);
  const { form, disabled, isCreate, formStatus, designationSource } = props;
  const initialValues = form.getFieldsValue(true);

  if (initialValues.publishStatus !== undefined) {
    initialValues.publishStatus = initialValues.publishStatus.toString();
  }

  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };

  useEffect(() => {
    getDisplayStatus();
    form.setFieldsValue({ publishOption: formStatus || '0' });

    if (initialValues.includeFile && initialValues?.receiverType.toString() === ReceiverType.TargetReceiver) {
      const targetIncludeFile = { ...initialValues.includeFile };
      initialValues.includeFile = null;
      form.setFieldsValue({ targetIncludeFile });
      form.setFieldsValue({ includeFile: null });
    }

    if (initialValues.publishImmediately) {
      form.setFieldsValue({ publishTime: '' });
    }

    if (initialValues.publishImmediately !== undefined) {
      form.setFieldsValue({ PublishTimeOptions: initialValues.publishImmediately.toString() });
    }
    // eslint-disable-next-line
  }, [formStatus]);

  const onToggleDesignation = (values: any) => {
    form.setFieldsValue({ designationSet: values });
    initialValues.designationSet = values;
  };

  const uploadFileMockStatus = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob(
      { mimeType: file.type, accessLevel: 'public', module: 'training' },
      dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];

    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  const setData = (key: string, value: any) => {
    form.setFieldsValue({ [`${key}`]: value });
  };

  const onDownloadTemplate = () => {
    getEventTemplate(dispatch);
  };

  const getDisplayStatus = () => {
    const currentStatus = formStatus.toString();
    const isPublishedItem = currentStatus === PublishStatus.Publish.key;
    const isWaittingPublished = currentStatus === PublishStatus.WaitingPublish.key;
    const isDraft = currentStatus === PublishStatus.Draft.key;
    const isUnpublished = currentStatus === PublishStatus.UnPublish.key;

    let displayStatus: any = [];

    if (isCreate || isDraft) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish]);
    } else if (isWaittingPublished) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish, PublishStatus.UnPublish]);
    } else if (isPublishedItem) {
      displayStatus = displayStatus.concat([PublishStatus.Publish, PublishStatus.UnPublish]);
    } else if (isUnpublished) {
      displayStatus = displayStatus.concat([PublishStatus.Publish, PublishStatus.UnPublish]);
    } else {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish, PublishStatus.UnPublish]);
    }

    SetPublishItems(displayStatus);
  };

  // const PUBLISH_OPTIONS_VALIDATE = {
  //     validator(_: any, data: any) {

  //         if (data && data.toString() === '3') {
  //             return Promise.reject(new Error(Translation('component.pulseleads.prospect.form-required-text')));
  //         }

  //         return Promise.resolve();
  //     }
  // };

  return (
    <>
      <div className={detailClass.divideMargin}>
        <span className={commonClasses.sessionHeader}>{Translation('component.formTitle.publishment-settings')}</span>
      </div>

      <div className={detailClass.container}>
        {ENABLE_DESIGNATION && (
          <Form.Item label={Translation('mandatory.training.category.publish.to')} name="receiverType">
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              {Object.entries(ReceiverType).map(([label, value]) => {
                if (!isNaN(Number(label))) {
                  return;
                }

                return (
                  <FormControlLabel
                    disabled={disabled}
                    key={`key_${label}`}
                    value={value}
                    control={<Radio />}
                    label={Translation(`mandatory.training.component.publish.setting.pushlish.type.${label}`)}
                    checked={initialValues?.receiverType.toString() === value.toString()}
                  />
                );
              })}
            </RadioGroup>
          </Form.Item>
        )}

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isBySegment = getFieldValue('receiverType').toString() === ReceiverType.Segment.toString();
            return (
              isBySegment && (
                <Form.Item label={Translation('component.formLabel.publish-to-agent')}>
                  <>
                    <TargetApplicationSection
                      isDisabled={disabled}
                      eligibleDesignations={designationSource || []}
                      eligibleZones={[]}
                      onToggleDesignation={onToggleDesignation}
                      enableZones={false}
                      designationsName="designationSet"
                      isNew={isCreate}
                    />
                  </>
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isBySegment = getFieldValue('receiverType').toString() === ReceiverType.Segment.toString();

            return (
              isBySegment && (
                <Form.Item label={Translation('receiver.include.text')} required={false}>
                  {(_, meta, { getFieldValue }) => {
                    return (
                      <div className="tw-my-6">
                        <FileUploader
                          maxFileSize={10}
                          disabled={disabled}
                          upload={uploadFileMockStatus}
                          download={() => {}}
                          value={initialValues.includeFile}
                          allowedFileTypes={['xlsx']}
                          onChange={(data) => {
                            setData('includeFile', data);
                          }}
                        />
                        {!initialValues.includeFile && (
                          <label>
                            (The Agents in this list will be also assigned to the courses even they are not in the
                            selected segments){' '}
                          </label>
                        )}
                      </div>
                    );
                  }}
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isBySegment = getFieldValue('receiverType').toString() === ReceiverType.Segment.toString();

            return (
              isBySegment && (
                <Form.Item label={Translation('receiver.exclude.text')} required={false}>
                  {(_, meta, { getFieldValue }) => {
                    return (
                      <div className="tw-my-6">
                        <FileUploader
                          maxFileSize={10}
                          disabled={disabled}
                          upload={uploadFileMockStatus}
                          download={() => {}}
                          value={initialValues.excludeFile}
                          allowedFileTypes={['xlsx']}
                          onChange={(data) => {
                            setData('excludeFile', data);
                          }}
                        />
                        {!initialValues.excludeFile && (
                          <label>
                            (The Agents in this list will not be assigned to the courses even they are in the selected
                            segments){' '}
                          </label>
                        )}
                      </div>
                    );
                  }}
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const TargetReceiver = getFieldValue('receiverType').toString() === ReceiverType.TargetReceiver.toString();

            return TargetReceiver ? (
              <Form.Item
                label={Translation('component.formLabel.customized-receiver')}
                name="targetIncludeFile"
                rules={[DEFAULT_REQUIRED_RULES]}
              >
                {(_, meta, { getFieldValue }) => {
                  return (
                    <div>
                      <FileUploader
                        maxFileSize={10}
                        disabled={disabled}
                        upload={uploadFileMockStatus}
                        download={() => {}}
                        value={initialValues.targetIncludeFile}
                        allowedFileTypes={['xlsx']}
                        onChange={(data) => {
                          setData('targetIncludeFile', data);
                        }}
                      />
                    </div>
                  );
                }}
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        {!disabled && (
          <Form.Item label={' '} hideDot={true}>
            <Button color="secondary" onClick={onDownloadTemplate}>
              {Translation('component.notification.downloadTemplate')}
            </Button>
          </Form.Item>
        )}

        <Form.Item
          label={Translation('mandatory.training.category.publish.option')}
          name="publishOption"
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            {publishItems &&
              publishItems.map((item: any) => {
                return (
                  item && (
                    <FormControlLabel
                      key={item.key}
                      value={item.key}
                      control={<Radio disabled={disabled} />}
                      label={Translation(item.label)}
                      checked={
                        initialValues.publishOption === item.key.toString() ||
                        (initialValues?.publishOption.toString() === '3' && item.key.toString() === '1')
                      }
                    />
                  )
                );
              })}
          </RadioGroup>
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isPublish = getFieldValue('publishOption') === PublishStatus.Publish.key;
            const isWaittingPublished = getFieldValue('publishOption') === PublishStatus.WaitingPublish.key;

            return (
              (isPublish || isWaittingPublished) && (
                <Form.Item
                  label={Translation('event.list.detail.publish_time')}
                  name="PublishTimeOptions"
                  rules={[DEFAULT_REQUIRED_RULES]}
                >
                  <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
                    {Object.entries(PublishTimeOptions).map(([label, value]) => {
                      return (
                        <FormControlLabel
                          disabled={disabled}
                          key={`key_${label}`}
                          value={JSON.parse(value)}
                          checked={initialValues.publishImmediately === JSON.parse(value)}
                          control={
                            <Radio
                              onChange={(e) => {
                                const publishImmediately = e.target.value === PublishTimeOptions.Immediately.toString();

                                setData('publishImmediately', publishImmediately);
                              }}
                            />
                          }
                          label={Translation(`mandatory.training.component.publish.time.${label}`)}
                        />
                      );
                    })}
                  </RadioGroup>
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isSpecificTime = getFieldValue('publishImmediately') === false;
            const isPublish = getFieldValue('publishOption') === PublishStatus.Publish.key;
            const isWaittingPublished = getFieldValue('publishOption') === PublishStatus.WaitingPublish.key;

            return (
              isSpecificTime &&
              (isPublish || isWaittingPublished) && (
                <Form.Item
                  label={Translation('component.formLabel.modifyTime')}
                  rules={[DEFAULT_REQUIRED_RULES]}
                  name="publishedAt"
                >
                  <>
                    <PruDateTimePicker
                      minutesStep={5}
                      ampm={false}
                      disabled={disabled}
                      format="DD/MM/YYYY HH:mm"
                      value={initialValues.publishedAt ? initialValues.publishedAt : null}
                      onChange={(value) => {
                        const val = moment.utc(value).local().utc().format();
                        setData('publishedAt', val);
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <AlarmIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        },
                      }}
                    />
                  </>
                </Form.Item>
              )
            );
          }}
        </Form.Item>
      </div>
    </>
  );
};

export default PublishSettings;
