import { IntlShape } from 'react-intl';
import { getConfigurations, isPermitted } from 'src/app/common/utils';
import { ModuleHierachy } from '../../common/module/types';
import {
  RECRUITMENT_TITLE,
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE,
  AdminRecruitmentPermissionType,
  RECRUITMENT_APPLICATION_FORM_PATH,
  RECRUITMENT_APPLICATION_FORM_SETTING_PATH,
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH,
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_NAMESPACE,
  FORM_TEMPLATE_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_NAMESPACE,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_NAMESPACE,
  RECRUITMENT_LICENSE_EXAM_RESULTS_NAMESPACE,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH,
  RECRUITMENT_LICENSE_EXAM_RESULTS_PATH,
  RECRUITMENT_APPLICATION_COURSES,
  RECRUITMENT_APPLICATION_TRAINING_COURSES_NAMESPACE,
  RECRUITMENT_ONBOARDING_TRAINING,
  RECRUITMENT_QUIZ_REPORT_PATH,
  RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_NAMESPACE,
  PDF_TEMPLATE_PATH,
  RECRUITMENT_LICENSE_VERIFICATION_PATH,
  RECRUITMENT_LICENSE_VERIFICATION_NAMESPACE,
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_NAMESPACE,
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION,
  RECRUITMENT_ONBOARDING_APPLICATION_PATH,
  RECRUITMENT_APPROVAL_NAMESPACE,
} from './constants';
import EditHistoryRoutes from './pages/edit-history/edit-history-routes';
import LicenseExamScheduleRoutes from './pages/license-exam/schedule/schedule-routes';
import OnboardingCourseRoutes from './pages/onboarding-training/courses/routes';
import LicenseExamRegistrationRoutes from './pages/license-exam/registration/registration-routes';
import LicenseExamResultsRoutes from './pages/license-exam/results/results-routes';
import QuizReportRoutes from './pages/onboarding-training/quiz-report/quiz-report-route';
import PDFTemplateRoutes from './pages/onboarding-application/pdf-template/pdf-template-routes';
import FormTemplateRoutes from './pages/onboarding-application/form-template/form-template-routes';
import LicenseVerificationRoutes from './pages/license-verification/license-verification-routes';
import TrainingFeeVerificationRoutes from './pages/onboarding-training/training-fee-verification/training-fee-verification-routes';
import ApprovalRoutes, { approvalBasePath } from './pages/onboarding-application/approval/approval-routes';

export const recruitmentModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(RECRUITMENT_TITLE),
    path: RECRUITMENT_BASE_PATH,
    icon: '/media/svg/icons/General/Clipboard.svg',
    enableRead: isPermitted(
      [
        AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_CREATE,
        AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_READ,
        AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_UPDATE,
        AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_APPROVE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation('onboarding.application.menu.title'),
        path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}`,
        enableRead: isPermitted(
          [
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_UPDATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_APPROVE,
          ],
          permissions,
        ),
        child: [
          {
            title: 'Application Form',
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}`,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE,
              ],
              permissions,
            ),
            child: [
              // {
              //   title: 'Setting',
              //   path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${RECRUITMENT_APPLICATION_FORM_SETTING_PATH}`,
              //   namespace: RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE,
              //   component: OnboardingApplicationRoutes,
              //   enableRead: isPermitted(
              //     [
              //       AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
              //       AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
              //       AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
              //     ],
              //     permissions,
              //   ),
              //   enableCreate: isPermitted(
              //     [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE],
              //     permissions,
              //   ),
              //   enableUpdate: isPermitted(
              //     [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE],
              //     permissions,
              //   ),
              // },
              {
                title: 'Setting',
                path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${FORM_TEMPLATE_PATH}`,
                namespace: RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE,
                component: FormTemplateRoutes,
                enableRead: isPermitted(
                  [
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE],
                  permissions,
                ),
                enableUpdate: isPermitted(
                  [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE],
                  permissions,
                ),
              },
              {
                title: 'PDF Template',
                path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${PDF_TEMPLATE_PATH}`,
                namespace: RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE,
                component: PDFTemplateRoutes,
                enableRead: isPermitted(
                  [
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE,
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_READ,
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_CREATE],
                  permissions,
                ),
                enableUpdate: isPermitted(
                  [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE],
                  permissions,
                ),
              },
              {
                title: 'Edit History',
                path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH}`,
                namespace: RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_NAMESPACE,
                component: EditHistoryRoutes,
                enableRead: isPermitted(
                  [
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ,
                    AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE],
                  permissions,
                ),
              },
            ],
          },
          {
            title: Translation('onboarding.application.approve.menu.title'),
            path: approvalBasePath,
            component: ApprovalRoutes,
            namespace: RECRUITMENT_APPROVAL_NAMESPACE,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_UPDATE,
                AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_APPROVE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_READ], permissions),
            enableUpdate: isPermitted([AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_UPDATE], permissions),
            enableApprove: isPermitted([AdminRecruitmentPermissionType.RECRUITMENT_APPROVAL_APPROVE], permissions),
            options: {
              disableCreate: true,
              enableApprove: true,
            },
          },
        ],
      },
      {
        title: 'Onboarding Training',
        path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}`,
        enableRead: isPermitted(
          [
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_COURSES_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_COURSES_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation('onboarding.training.fee.verification.title'),
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION}`,
            namespace: RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_NAMESPACE,
            component: TrainingFeeVerificationRoutes,
            enableModule: getConfigurations()?.Recruitment?.enableTrainingFeeApprovalFunction,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_CREATE,
                AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_UPDATE,
              ],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION_UPDATE],
              permissions,
            ),
          },
          {
            title: 'Course',
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_APPLICATION_COURSES}`,
            namespace: RECRUITMENT_APPLICATION_TRAINING_COURSES_NAMESPACE,
            component: OnboardingCourseRoutes,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_COURSES_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_COURSES_CREATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_COURSES_CREATE],
              permissions,
            ),
          },
          {
            title: 'Quiz Report',
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_QUIZ_REPORT_PATH}`,
            namespace: RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_NAMESPACE,
            component: QuizReportRoutes,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_CREATE,
                AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_READ,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_CREATE],
              permissions,
            ),
          },
        ],
      },
      {
        title: Translation('recruitment.exam.menu.title.license_exam'),
        path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}`,
        enableRead: isPermitted(
          [
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation('recruitment.exam.menu.title.schedule'),
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH}`,
            namespace: RECRUITMENT_LICENSE_EXAM_SCHEDULE_NAMESPACE,
            component: LicenseExamScheduleRoutes,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE,
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation('recruitment.exam.menu.title.registration'),
            path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH}`,
            namespace: RECRUITMENT_LICENSE_EXAM_REGISTRATION_NAMESPACE,
            component: LicenseExamRegistrationRoutes,
            enableRead: isPermitted(
              [
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_READ,
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE,
                AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE],
              permissions,
            ),
          },
          // {
          //   title: 'Results',
          //   path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_RESULTS_PATH}`,
          //   namespace: RECRUITMENT_LICENSE_EXAM_RESULTS_NAMESPACE,
          //   component: LicenseExamResultsRoutes,
          //   enableRead: isPermitted(
          //     [
          //       AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_READ,
          //       AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE,
          //       AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE,
          //     ],
          //     permissions,
          //   ),
          //   enableCreate: isPermitted(
          //     [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE],
          //     permissions,
          //   ),
          //   enableUpdate: isPermitted(
          //     [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE],
          //     permissions,
          //   ),
          // },
        ],
      },
      {
        title: Translation('recruitment.license.verification'),
        path: `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_VERIFICATION_PATH}`,
        namespace: RECRUITMENT_LICENSE_VERIFICATION_NAMESPACE,
        component: LicenseVerificationRoutes,
        enableRead: isPermitted(
          [
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_READ,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_CREATE,
            AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted(
          [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_CREATE],
          permissions,
        ),
        enableUpdate: isPermitted(
          [AdminRecruitmentPermissionType.RECRUITMENT_LICENSE_VERIFICATION_UPDATE],
          permissions,
        ),
      },
    ],
  };
};
