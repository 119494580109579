import React, { FC, useState, useReducer, HTMLAttributes } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  RuleSetItem,
  RuleItem as LeadsRuleItem,
  RuleType,
  ruleTitleMap,
  ruleOperatorTitleMap,
} from '../../../../types/rule-types';
import { makeStyles } from 'tss-react/mui';
import { Tooltip, IconButton, Button, TextField, FormControl, Select } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { map, find } from 'lodash';
import { useErrorHandler, ErrorFieldType, csvHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { ALL_FIELDS_ERROR_TEXT, NO_ROW_ERROR_TEXT } from '../../../../constants';
import {
  RuleSetBody,
  modifyLeadsRuleItem,
  createLeadsRuleItem,
  deleteLeadsRuleItem,
  downloadLeadsRuleCsv,
} from '../../../../network/customerDedupCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { RuleOperator } from 'src/app/modules/PulseLeads/enum/rule-enum';

type RuleItemProps = {
  ruleSetItem?: RuleSetItem;
  onRefresh: () => void;
  onFinish?: () => void;
} & HTMLAttributes<HTMLDivElement>;

type RuleSet = {
  name: string;
  items: LeadsRuleItem[];
};

type RuleSetState = RuleSet | undefined;

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 5,
    padding: '10px 15px 0 15px',
    backgroundColor: '#F5F5F5',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #EEEEEE',
  },
  viewRowContainer: {
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #EEEEEE',
  },
  contentRow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  viewContentText: {
    fontWeight: 400,
    lineHeight: 2,
  },
  footerContainer: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #EEEEEE',
  },
  textField: {
    backgroundColor: '#FFFFFF',
  },
  errorText: {
    fontSize: 10,
    color: '#F018A6',
  },
}));

const stateInitiator = (ruleSetItem?: RuleSetItem) => {
  return ruleSetItem
    ? {
        name: ruleSetItem.name,
        items: [...ruleSetItem.items],
      }
    : {
        name: '',
        items: [
          {
            type: '' as '',
            operator: RuleOperator.EQUAL,
            value: '',
          },
        ],
      };
};

type InitStateAction = {
  type: 'INIT_STATE';
  payload: {
    data: RuleSet;
  };
};

type ClearStateAction = {
  type: 'CLEAR_STATE';
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof Pick<RuleSet, 'name'>;
    value: any;
  };
};

type AddRuleIncludeItem = {
  type: 'ADD_RULE_INCLUDE_ITEM';
  payload: {
    rowIndex: number;
  };
};

type ModifyRuleIncludeItem = {
  type: 'MODIFY_RULE_INCLUDE_ITEM';
  payload: {
    rowIndex: number;
    itemIndex: number;
    value: string;
  };
};

type RemoveRuleIncludeItem = {
  type: 'REMOVE_RULE_INCLUDE_ITEM';
  payload: {
    rowIndex: number;
    itemIndex: number;
  };
};

type CsvRuleAction = {
  type: 'UPLOAD_CSV_RULE';
  payload: {
    rules: LeadsRuleItem[];
  };
};

type AddRuleAction = {
  type: 'ADD_RULE';
};

type ModifyRuleAction = {
  type: 'MODIFY_RULE';
  payload: {
    index: number;
    field: keyof LeadsRuleItem;
    value: any;
  };
};

type RemoveRuleAction = {
  type: 'REMOVE_RULE';
  payload: {
    index: number;
  };
};

type RuleSetAction =
  | InitStateAction
  | ClearStateAction
  | ModifyFieldAction
  | AddRuleAction
  | ModifyRuleAction
  | RemoveRuleAction
  | CsvRuleAction
  | AddRuleIncludeItem
  | ModifyRuleIncludeItem
  | RemoveRuleIncludeItem;

const ruleReducer = (state: RuleSetState, action: RuleSetAction): RuleSetState => {
  switch (action.type) {
    case 'INIT_STATE':
      return action.payload.data;
    case 'CLEAR_STATE':
      return undefined;
    case 'MODIFY_FIELD':
      return state
        ? {
            ...state,
            [action.payload.field]: action.payload.value,
          }
        : undefined;
    case 'UPLOAD_CSV_RULE':
      return state
        ? {
            ...state,
            items: action.payload.rules,
          }
        : undefined;
    case 'ADD_RULE':
      return state
        ? {
            ...state,
            items: [
              ...state.items,
              {
                type: '',
                operator: RuleOperator.EQUAL,
                value: '',
              },
            ],
          }
        : undefined;
    case 'MODIFY_RULE':
      if (state) {
        let foundItem = state.items[action.payload.index];
        if (foundItem) {
          foundItem[action.payload.field] = action.payload.value;
          if (action.payload.field === 'operator') {
            if (action.payload.value === RuleOperator.EQUAL) {
              foundItem.value = '';
            } else if (action.payload.value === RuleOperator.INCLUDE) {
              foundItem.value = [''];
            }
          }
        }
      }
      return state ? { ...state } : undefined;
    case 'REMOVE_RULE':
      if (state) {
        state.items.splice(action.payload.index, 1);
      }
      return state ? { ...state } : undefined;
    case 'ADD_RULE_INCLUDE_ITEM':
      if (state) {
        let row = state.items[action.payload.rowIndex];
        if (row.value instanceof Array) {
          row.value.push('');
        }
      }
      return state ? { ...state } : undefined;
    case 'MODIFY_RULE_INCLUDE_ITEM':
      if (state) {
        let row = state.items[action.payload.rowIndex];
        if (row.value instanceof Array) {
          row.value[action.payload.itemIndex] = action.payload.value;
        }
      }
      return state ? { ...state } : undefined;
    case 'REMOVE_RULE_INCLUDE_ITEM':
      if (state) {
        let row = state.items[action.payload.rowIndex];
        if (row.value instanceof Array) {
          row.value.splice(action.payload.itemIndex, 1);
        }
      }
      return state ? { ...state } : undefined;
    default:
      return state;
  }
};

type ErrorState = {
  mandatory: {
    name: boolean;
    noRules: boolean;
    missingFields: boolean;
  };
  immediate: {};
};

type DialogState = {
  open: boolean;
  id: string;
};

const RuleItem: FC<RuleItemProps> = ({ ruleSetItem, onRefresh, onFinish, ...rest }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [editState, editDispatch] = useReducer(ruleReducer, ruleSetItem ? undefined : stateInitiator());

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler<ErrorState>(editState || {}, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'noRules',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (editState) {
          let items = editState.items;
          if (items.length < 1) {
            return true;
          }
        }
        return false;
      },
    },
    {
      name: 'missingFields',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (editState) {
          let items = editState.items;
          let flag = false;
          items.forEach((item) => {
            if (item.type === '') {
              flag = true;
            }
            if (item.operator === RuleOperator.EQUAL && !item.value) {
              flag = true;
            }
            if (
              item.operator === RuleOperator.INCLUDE &&
              item.value instanceof Array &&
              (item.value.length <= 0 || item.value.find((str) => !!!str) === '')
            ) {
              flag = true;
            }
          });
          return flag;
        }
        return false;
      },
    },
  ]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      let data = await csvHandler(e.target.files[0]);
      const headerRow = data[0];
      const operatorRow = data[1];
      const dataRows: string[][] = data.filter((_, index) => index >= 2);

      let csvRuleItems: LeadsRuleItem[] = [];

      headerRow.forEach((atr, index) => {
        const ruleItem = find(RuleType, (type) => type.toLowerCase() === atr.trim().toLowerCase());
        if (!!ruleItem) {
          const operatorItem = find(
            RuleOperator,
            (type) => type.toLowerCase() === operatorRow[index].trim().toLowerCase(),
          );
          if (!!operatorItem) {
            csvRuleItems.push({
              type: ruleItem,
              operator: operatorItem,
              value: dataRows.map((row) => row[index]).filter((str) => !!str),
            });
          }
        }
      });
      editDispatch({ type: 'UPLOAD_CSV_RULE', payload: { rules: csvRuleItems } });
    }
  };

  const onSave = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (editState && !hasError) {
      const body: RuleSetBody = {
        name: editState.name,
        items: editState.items,
      };
      if (ruleSetItem) {
        //  Edit
        await modifyLeadsRuleItem(ruleSetItem._id, body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Rule Set Item updated successfully - ${ruleSetItem._id}`,
            },
          ]),
        );
        editDispatch({ type: 'CLEAR_STATE' });
      } else {
        //  Create
        await createLeadsRuleItem(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Rule Set Item created successfully`,
            },
          ]),
        );
      }
      if (onFinish) {
        onFinish();
      }
    }
  };

  const onDelete = async (id: string) => {
    try {
      await deleteLeadsRuleItem(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Rule deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-rule-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <div className={classes.container} {...rest}>
        <div className={classes.headerContainer}>
          {editState !== undefined ? (
            <div className={commonClasses.header}>
              <TextField
                error={errorState.mandatory.name}
                helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
                style={{ width: 300, margin: 5 }}
                InputProps={{
                  classes: {
                    input: classes.textField,
                  },
                }}
                margin="dense"
                variant="outlined"
                value={editState.name}
                onChange={(e) => {
                  onDismissErrorHandler('name', e.target.value);
                  editDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
                }}
              />
            </div>
          ) : (
            <div className={commonClasses.header} style={{ fontSize: 14, fontWeight: 600 }}>
              {ruleSetItem?.name}
            </div>
          )}
          {editState === undefined ? (
            <div>
              {ruleSetItem && ruleSetItem._id && (
                <AsyncCsvLink
                  style={{ display: 'inline' }}
                  isDisabled={false}
                  filename={`${ruleSetItem.name}.csv`}
                  dataParser={(str) => str}
                  asyncCall={() => downloadLeadsRuleCsv(ruleSetItem._id, dispatch)}
                >
                  <Tooltip title={'Download Rule CSV'}>
                    <IconButton style={{ padding: 5 }}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                </AsyncCsvLink>
              )}
              <Tooltip title={'Edit Rule'}>
                <IconButton
                  style={{ padding: 5 }}
                  onClick={() => editDispatch({ type: 'INIT_STATE', payload: { data: stateInitiator(ruleSetItem) } })}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {ruleSetItem && ruleSetItem._id && (
                <Tooltip title={'Delete Rule'}>
                  <IconButton
                    style={{ padding: 5 }}
                    onClick={() => setDialogState({ open: true, id: ruleSetItem._id })}
                  >
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ) : (
            <div className={classes.btnContainer}>
              <input
                id="upload-rule-csv"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <Button
                style={{ marginRight: 20 }}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-rule-csv')!.click()}
              >
                {Translation('pulseleads.rule.uploadCsv')}
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  onDismissErrorHandler('noRules', true);
                  editDispatch({ type: 'ADD_RULE' });
                }}
              >
                {Translation('pulseleads.rule.newRule')}
              </Button>
            </div>
          )}
        </div>
        {editState !== undefined ? (
          <>
            {editState.items.map((item, rowIndex) => (
              <div key={`rule-item-${rowIndex}`} className={classes.rowContainer}>
                <div className={classes.contentRow}>
                  <div style={{ flexBasis: '30%' }}>
                    <FormControl
                      error={errorState.mandatory.missingFields}
                      style={{ backgroundColor: '#FFFFFF', margin: 0 }}
                      margin="dense"
                      variant="outlined"
                    >
                      <Select
                        native
                        value={item.type}
                        onChange={(e) => {
                          onDismissErrorHandler('missingFields', e.target.value);
                          editDispatch({
                            type: 'MODIFY_RULE',
                            payload: { index: rowIndex, field: 'type', value: e.target.value as string },
                          });
                        }}
                      >
                        <option aria-label="None" value="" />
                        {map(RuleType, (key) => (
                          <option key={`dropdown-choice-${key}`} value={key}>
                            {Translation(ruleTitleMap[key])}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ flexBasis: '30%' }}>
                    <FormControl style={{ backgroundColor: '#FFFFFF', margin: 0 }} margin="dense" variant="outlined">
                      <Select
                        native
                        value={item.operator}
                        onChange={(e) =>
                          editDispatch({
                            type: 'MODIFY_RULE',
                            payload: { index: rowIndex, field: 'operator', value: e.target.value as string },
                          })
                        }
                      >
                        <option value={RuleOperator.EQUAL}>
                          {Translation(ruleOperatorTitleMap[RuleOperator.EQUAL])}
                        </option>
                        <option value={RuleOperator.INCLUDE}>
                          {Translation(ruleOperatorTitleMap[RuleOperator.INCLUDE])}
                        </option>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ flexBasis: '40%', display: 'flex', alignItems: 'center' }}>
                    <div>
                      {item.value instanceof Array ? (
                        item.value.map((value, itemIndex) => (
                          <div
                            key={`rule-${rowIndex}-include-item-${itemIndex}`}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <TextField
                              error={errorState.mandatory.missingFields}
                              style={{
                                width: 200,
                                backgroundColor: '#FFFFFF',
                                margin: `${itemIndex !== 0 ? 5 : 0}px 0 0 0`,
                              }}
                              margin="dense"
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                onDismissErrorHandler('missingFields', e.target.value);
                                editDispatch({
                                  type: 'MODIFY_RULE_INCLUDE_ITEM',
                                  payload: { rowIndex, itemIndex, value: e.target.value },
                                });
                              }}
                            />
                            {item.value[itemIndex + 1] === undefined && (
                              <IconButton
                                style={{ padding: 3, marginLeft: 8 }}
                                onClick={() => {
                                  onDismissErrorHandler('missingFields', true);
                                  editDispatch({ type: 'ADD_RULE_INCLUDE_ITEM', payload: { rowIndex } });
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                            <IconButton
                              disabled={item.value.length <= 1}
                              style={{ padding: 3, marginLeft: 8 }}
                              onClick={() => {
                                onDismissErrorHandler('missingFields', true);
                                editDispatch({ type: 'REMOVE_RULE_INCLUDE_ITEM', payload: { rowIndex, itemIndex } });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))
                      ) : (
                        <TextField
                          error={errorState.mandatory.missingFields}
                          style={{ width: 200, backgroundColor: '#FFFFFF', margin: 0 }}
                          margin="dense"
                          variant="outlined"
                          value={item.value}
                          onChange={(e) => {
                            onDismissErrorHandler('missingFields', e.target.value);
                            editDispatch({
                              type: 'MODIFY_RULE',
                              payload: { index: rowIndex, field: 'value', value: e.target.value },
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <IconButton
                    style={{ padding: 3 }}
                    onClick={() => {
                      onDismissErrorHandler('missingFields', true);
                      editDispatch({ type: 'REMOVE_RULE', payload: { index: rowIndex } });
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              </div>
            ))}
            {errorState.mandatory.noRules && <div className={classes.errorText}>{NO_ROW_ERROR_TEXT}</div>}
            {errorState.mandatory.missingFields && <div className={classes.errorText}>{ALL_FIELDS_ERROR_TEXT}</div>}
          </>
        ) : (
          ruleSetItem?.items.map((item, rowIndex) => {
            const ruleTitleKey = ruleTitleMap[item.type as RuleType];
            return (
              <div key={`rule-item-${ruleSetItem._id}-${rowIndex}`} className={classes.viewRowContainer}>
                <div className={classes.viewContentText} style={{ flexBasis: '30%' }}>
                  {ruleTitleKey ? Translation(ruleTitleKey) : item.type}
                </div>
                <div className={classes.viewContentText} style={{ flexBasis: '30%' }}>
                  {item.operator === RuleOperator.EQUAL
                    ? Translation('common.operator.equal')
                    : item.operator === RuleOperator.INCLUDE
                    ? Translation('common.operator.include')
                    : item.operator}
                </div>
                <div className={classes.viewContentText} style={{ flexBasis: '40%' }}>
                  {item.value instanceof Array
                    ? item.value.map((val, valIndex) => <div key={valIndex}>{val}</div>)
                    : item.value}
                </div>
              </div>
            );
          })
        )}
        {editState !== undefined && (
          <div className={classes.footerContainer}>
            <Button
              style={{ marginRight: 20 }}
              size="small"
              variant="contained"
              onClick={() => {
                editDispatch({ type: 'CLEAR_STATE' });
                onFinish && onFinish();
              }}
            >
              {Translation('global.text.cancel')}
            </Button>
            <Button size="small" color="secondary" variant="contained" onClick={() => onSave()}>
              {Translation('global.text.confirm')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default RuleItem;
