import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { UserManagementLogItem } from '../types/user-management-log-types';

const userManagementLogEndpoint = 'audit/logs';

export type UserManagementLogListParam = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
  activity?: string;
  activityStatus?: string;
  service?: string;
  lastUpdatedBy?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
};

export type CreateUpdateUserManagementLogBody = {};

export const fetchUserManagementLogList = async (
  params: UserManagementLogListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<UserManagementLogItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${userManagementLogEndpoint}?`, params);
  return apiClient
    .get<PaginateList<UserManagementLogItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLogFilterOptions = async (dispatch?: Dispatch<any>): Promise<any> => {
  // const queryPath = `http://localhost:3008/${userManagementLogEndpoint}/filter`;
  const queryPath = `${ADMIN_URL}/${userManagementLogEndpoint}/filters`;
  return apiClient
    .get<PaginateList<UserManagementLogItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// export const fetchUserManagementLogItem = async (
//   id: string,
//   dispatch?: Dispatch<any>,
// ): Promise<UserManagementLogItem> => {
//   return apiClient
//     .get<UserManagementLogItem>(`${ADMIN_URL}/${userManagementLogEndpoint}/${id}`)
//     .then((response) => response.data)
//     .catch((err) => {
//       throw apiErrorHandler(err, dispatch);
//     });
// };

// export const createUserManagementLogItem = async (
//   body: CreateUpdateUserManagementLogBody,
//   dispatch?: Dispatch<any>,
// ): Promise<void> => {
//   return apiClient
//     .post<void>(`${ADMIN_URL}/${userManagementLogEndpoint}`, body)
//     .then((response) => response.data)
//     .catch((err) => {
//       throw apiErrorHandler(err, dispatch);
//     });
// };

// export const modifyUserManagementLogItem = async (
//   id: string,
//   body: CreateUpdateUserManagementLogBody,
//   dispatch?: Dispatch<any>,
// ): Promise<void> => {
//   return apiClient
//     .patch<void>(`${ADMIN_URL}/${userManagementLogEndpoint}/${id}`, body)
//     .then((response) => response.data)
//     .catch((err) => {
//       throw apiErrorHandler(err, dispatch);
//     });
// };
