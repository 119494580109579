import React, { FC, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress } from '@mui/material';

// import { awardListPath } from '../AwardListRoutes';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useProfileStyles } from 'src/app/modules/Profile/profileStyle';
import {
  createAwardTypeListByMassUpload,
  CreateAwardTypeListByMassUploadBody,
  downloadMassUploadCsv,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import { awardTypeListPath } from '../AwardTypeListRoute';
import { csvUploadType } from '../../../types/agent-profile-types';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';

type UploadFormState = {
  blobId?: string;
  filename?: string;
};
type ManualUpdateFormState = {
  awardType: UploadFormState;
};
type DialogState = {
  open: boolean;
  id: string;
  method: string;
};
enum ToolTip {
  SUBMIT = 'agentProfile.ad_set_default_message',
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
}
const initialState: ManualUpdateFormState = {
  awardType: {
    blobId: undefined,
    filename: undefined,
  },
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};
type ResetAction = {
  type: 'RESET_STATE';
};

type ManualUpdateFormAction = ModifyFieldAction | ResetAction;

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

export const AddAwardTypeListPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useProfileStyles();

  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [toolTip, setToolTip] = useState<string>();
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
    method: '',
  });
  const cancel = () => {
    setToolTip(Translation(ToolTip.CANCEL));
    setDialogState({ open: true, id: '', method: 'cancel' });
  };
  const onClose = () => {
    setDialogState({ open: false, id: '', method: '' });
    setToolTip('');
  };
  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>, fileType: keyof ManualUpdateFormState) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', originalFilename: file.name },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: fileType,
              value: {
                blobId: result.blobId,
                filename: file.name,
              },
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };
  const onSubmit = async () => {
    const body: CreateAwardTypeListByMassUploadBody = {
      awardType: formState.awardType,
    };

    try {
      await createAwardTypeListByMassUpload(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `${Translation('agentProfile.ad_uploaded_successfully_label')}`,
          },
        ]),
      );
      formDispatch({ type: 'RESET_STATE' });
      history.push(`${awardTypeListPath}`);
    } catch (err) {}
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(`agentProfile.ad_add_award_list_header_title`)}</div>
          </div>
          <ConfirmDialog
            key={`delete-settings-rule-dialog-${dialogState.open}`}
            open={dialogState.open}
            onClose={() => onClose()}
            onRefresh={() => ''}
            onConfirm={async () => history.push(`${awardTypeListPath}`)}
            title={Translation('agentProfile.ad_reminder_title')}
            content={toolTip}
          />
        </div>
        <div className={classes.rowContainer} style={{}}>
          <div className={classes.fieldContainer} style={{ width: 150 }}>
            <span className={classes.field}>{Translation('agentProfile.ad_awards_list_header_title')}:</span>
          </div>
          <input
            id="upload-file"
            hidden
            type="file"
            accept=".csv"
            onClick={(e) => {
              const element = e.target as HTMLInputElement;
              element.value = '';
            }}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const fileType = e.target.files[0].type;
                if (csvUploadType.has(fileType)) {
                  handleFile(e, 'awardType');
                } else {
                  dispatch(
                    appendAlertItem([
                      {
                        severity: AlertType.WARNING,
                        title: Translation('fail_title'),
                        content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                      },
                    ]),
                  );
                }
              }
            }}
          />
          <div className={classes.rowContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-file')!.click()}
              className={classes.chooseFileBtn}
            >
              {Translation('app.button.chooseFile')}
            </Button>
            {formState.awardType?.filename && <div style={{ marginLeft: 10 }}>{formState.awardType.filename}</div>}
            <AsyncCsvLink
              isDisabled={false}
              filename={`award_type_template.csv`}
              dataParser={(str) => str}
              asyncCall={() => downloadMassUploadCsv('award_type_template')}
            >
              <span className={classes.downloadTemplateBtn}>
                {Translation(`agentProfile.ad_download_template_button`)}
              </span>
            </AsyncCsvLink>
          </div>
          {!!uploadFileProgress && (
            <LinearProgress
              style={{ marginTop: 10 }}
              variant="determinate"
              color="secondary"
              value={uploadFileProgress}
            />
          )}
        </div>

        <div className={classes.centerFooterContainer}>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              minWidth: 350,
              justifyContent: 'space-around',
            }}
          >
            <Button variant="contained" color="secondary" onClick={() => cancel()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
              {Translation('app.button.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
