import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from 'src/app/layout';
import CourseFormList from './course-main-form';
import {
  createOnboardingTrainingCourse,
  deleteOnboardingTrainingCourse,
  fetchOnboardingCourseDetail,
  updateOnboardingTrainingCourse,
} from '../../../network/onboarding-course-crud';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useIntl } from 'react-intl';

const OnboardingCourseDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [formListData, setFormListData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const courseId = (location.state as any)?.courseId || id;
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  useEffect(() => {
    if (courseId) {
      refreshData();
    }
  }, [courseId]);

  const refreshData = useCallback(async () => {
    if (courseId) {
      try {
        setIsLoading(true);
        const res = await fetchOnboardingCourseDetail(courseId);
        res.systemReferral = res.systemReferral.toString();
        setFormListData(res);
        setIsLoading(false);
      } catch (e) {}
    }
  }, [courseId, location.state]);

  const pageType = useMemo(() => {
    if (location.pathname.includes('create')) {
      return 'create';
    } else if (location.pathname.includes('edit')) {
      return 'edit';
    } else {
      return 'view';
    }
  }, [location.pathname]);

  const onSave = useCallback(
    async (formData: any, type: string) => {
      formData.status = (formData.status as string).toLocaleUpperCase();
      try {
        if (!!courseId) type = 'edit';
        if ((type === 'create' || type === 'publish') && !id) {
          const id = await createOnboardingTrainingCourse(formData, dispatch);
          history.replace(location.pathname, { courseId: id });
        } else if ((type === 'edit' || type === 'publish') && courseId) {
          await updateOnboardingTrainingCourse(formData, dispatch);
          history.goBack();
        }
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: ``,
            },
          ]),
        );
      } catch (e) {
        throw new Error('Function not implemented.');
      }
    },
    [courseId],
  );

  const onDelete = useCallback(async () => {
    const reqId = courseId;
    await deleteOnboardingTrainingCourse(reqId);
    history.goBack();
  }, [courseId]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <CourseFormList
          id={courseId}
          disabled={pageType === 'view'}
          formListData={formListData}
          onSave={onSave}
          isEdit={false}
          history={history}
          type={pageType}
          refreshData={refreshData}
          location={location}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default OnboardingCourseDetailPage;
