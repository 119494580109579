import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'src/app/i18n/I18nConfig';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { ConfigBannerTierPaginateList } from 'src/app/modules/Profile/types/agent-profile-types';

import { configBannerTierPath } from '../ConfigBannerTierRoutes';
import { deleteBannerTier } from 'src/app/modules/Profile/network/agentProfileCrud';
import { getDefaultDisplayDate } from 'src/app/common/utils';
type ConfigPromotionsListProps = {
  isLoading: boolean;
  list?: ConfigBannerTierPaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const ConfigBannerTierList: FC<ConfigPromotionsListProps> = ({ isLoading, list, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      //delete
      await deleteBannerTier(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Tier deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
        content={Translation('agentProfile.ad_delete_message')}
      />
      <PruTable
        title={Translation('agentProfile.ad_agent_tier_list_header_title')}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'primary',
            title: 'Add Tier',
            onClick: () => history.push(`${configBannerTierPath}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('agentProfile.ad_edit_label'),
            tooltipText: 'Edit Banner tier',
            onClick: (row) => history.push(`${configBannerTierPath}/edit/${row.tierCode}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Banner tier',
            onClick: (row) => setDialogState({ open: true, id: row.tierCode }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            align: 'left',
            displayName: Translation('agentProfile.ad_tier_name_label'),
            renderData: (row) => row.name['enUs'] || '-',
          },

          {
            keyIndex: 'tierCode',
            align: 'left',
            displayName: Translation('agentProfile.ad_tier_code_label'),
            renderData: (row) => row.tierCode || '-',
          },
          {
            keyIndex: 'default',
            align: 'left',
            displayName: Translation('agentProfile.ad_default_label'),
            renderData: (row) => (row.isDefault ? 'Yes' : 'No' || '-'),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('agentProfile.ad_created_time_label'),
            renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('agentProfile.ad_last_updated_time_label'),
            renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={list?.docs}
        totalPages={list?.totalPages}
        totalRecords={list?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default ConfigBannerTierList;
