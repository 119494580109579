import React, { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { initI18nGenericData, regionLocale, useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import {
  ErrorFieldDef,
  ErrorFieldType,
  fileUpload,
  formatBytes,
  getConfigurations,
  useErrorHandler,
} from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import { get } from 'lodash';
import { DialogueDetails } from '../../../types/talkbot-dialogue-type';
import {
  createDialogue,
  fetchDialogueDetail,
  fetchDialogueList,
  updateDialogue,
} from '../../../network/talkbot-dialogue-crud';
import { FormMode } from 'src/app/common/types';
import { numberRegex } from 'src/app/common/constants';

interface HookProps {
  id?: string;
  formMode?: FormMode;
  history: any;
  // other params
}

export const useDetail = ({ id, history, formMode }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [formState, setFormState] = useState<Record<string, any>>({});

  // redux
  const dispatch = useDispatch();

  // get config
  const flowIdList = useMemo(() => {
    const config = getConfigurations();
    return get(config, 'Sales.talkbot.dialogues') || [];
  }, []);

  const { data, error, loading, run } = useRequest((id) => fetchDialogueDetail(id), { manual: true });

  const {
    data: dialogueList,
    loading: dialogueLoading,
    run: runDialogue,
  } = useRequest(() => fetchDialogueList({}, dispatch));

  useEffect(() => {
    if (id) {
      // manual call api request
      run(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const { flowId, name, description, image, isActive, weight } = data;
      setFormState({ flowId, name, description, image, isActive, weight });
    }
  }, [data]);

  const { existWeights, existFlowIds } = useMemo(() => {
    const weights = dialogueList?.filter((item) => item.weight !== data?.weight)?.map((item) => item.weight);
    const flowIds = dialogueList?.filter((item) => item.flowId !== data?.flowId)?.map((item) => item.flowId);
    return { existWeights: weights, existFlowIds: flowIds };
  }, [dialogueList, data]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'flowId',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'weight',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'weightFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => typeof formState.weight === 'number' && checkWeight(formState.weight, existWeights),
      },
      {
        name: 'isActive',
        fieldType: ErrorFieldType.MANDATORY,
      },
      ...(() => {
        let errorFieldDef: ErrorFieldDef[] = [];
        regionLocale?.forEach((locale) => {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: `name-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.name?.[locale],
            },
          ];
        });
        return errorFieldDef;
      })(),
      ...(() => {
        let errorFieldDef: ErrorFieldDef[] = [];
        regionLocale?.forEach((locale) => {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: `image-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.image?.[locale],
            },
          ];
        });
        return errorFieldDef;
      })(),
    ],
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.weight]);

  const handleUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const errMsg = Translation('global.max.file.size.allow');
        const size = formatBytes(file.size);
        const overSize = (size.type === 1 && size.value > 2048) || (size.type === 2 && size.value > 2);
        if (overSize) return dispatch(appendAlertItem([{ severity: AlertType.ERROR, title: '', content: errMsg }]));
        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', module: 'talkbot-dialogue' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file);
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            setFormState((prev: any) => ({
              ...prev,
              image: {
                ...prev.image,
                [locale]: { blobId: result.blobId, filename: file.name, url: result.url, type: result.mimeType },
              },
            }));
          }
        } catch (err) {}
      }
    },
    [dispatch, Translation],
  );

  const onSubmit = async (formState: Record<string, any>) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (!id) {
        await createDialogue(formState as DialogueDetails);
      } else {
        await updateDialogue(id, formState, dispatch);
      }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Submit successfully',
          },
        ]),
      );
      history.goBack();
    }
  };

  return {
    data,
    loading,
    dialogueLoading,
    flowIdList,
    existWeights,
    existFlowIds,
    formState,
    errorState,
    setFormState,
    onDismissErrorHandler,
    handleUpload,
    onSubmit,
  };
};

export const checkWeight = (value: number, existWeights?: number[]) => {
  if (!numberRegex.test(String(value))) {
    return true;
  }
  if (existWeights?.includes(Number(value))) {
    return true;
  }
  return false;
};
