import React, { FC, useState, useReducer, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  FormControl,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { map } from 'lodash';
import { DATE_ERROR_TEXT, MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { createBlob, getBlob } from 'src/app/common/network';
import { csvHandler, ErrorFieldType, useErrorHandler, fileUpload } from 'src/app/common/utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { fetchConfig, ConfigParam } from 'src/app/modules/PulseLeads/network/campaignCrud';
import {
  isBulkUploadFileExist,
  checkInvalidCampaign,
  createBulkUploadFile,
  downloadBulkUploadCsv,
  CheckInvalidCampaignParam,
  CreateBulkUploadFileBody,
} from 'src/app/modules/PulseLeads/network/bulkUploadCrud';
import { BulkUploadTypeEnum } from '../../../../types/bulk-upload-types';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { CampaignTypeEnum } from '../../../../types/campaign-types';

const FIELD_CONTAINER_WIDTH = 220;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  template: {
    color: 'grey',
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

type UploadFormState = {
  uploadType: string;
  blobId?: string;
  filename?: string;
  enableScheduleUpload?: boolean;
  scheduledUploadTime?: Date | null;
};

const initialState: UploadFormState = {
  uploadType: '',
  blobId: undefined,
  filename: undefined,
  enableScheduleUpload: false,
  scheduledUploadTime: null,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof UploadFormState;
    value: any;
  };
};

type UploadFileAction = {
  type: 'UPLOAD_FILE';
  payload: {
    blobId: string;
    filename: string;
  };
};

type ResetAction = {
  type: 'RESET_STATE';
};

type UploadFormAction = ModifyFieldAction | UploadFileAction | ResetAction;

const formReducer = (state: UploadFormState, action: UploadFormAction): UploadFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_FILE':
      return {
        ...state,
        blobId: action.payload.blobId,
        filename: action.payload.filename,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

const BulkUploadUploadForm: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();
  const [config, setConfig] = useState<any>();

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'uploadType',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'file',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.blobId && formState.filename);
      },
    },
  ]);

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  const getCampaignIdList = async (file: File) => {
    const campaignIdList: string[] = [];
    const data = await csvHandler(file);
    const headerRow = data[0];
    const dataRows = data.filter((_, index) => index > 0);
    const campaignIdIndex = headerRow.findIndex((header) => header === 'campaignId');
    dataRows.forEach((row) => {
      const campaignId = row[campaignIdIndex];
      if (campaignId && !campaignIdList.includes(campaignId)) {
        campaignIdList.push(campaignId);
      }
    });
    return campaignIdList;
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const campaignStatusForBulkUpload = config?.content?.Sales?.campaign?.statusForBulkUpload;
      if (campaignStatusForBulkUpload && campaignStatusForBulkUpload.length !== 0) {
        const campaignIdList: string[] = await getCampaignIdList(file);
        const params: CheckInvalidCampaignParam = { campaignIdList, validStatusList: campaignStatusForBulkUpload };
        const invalidCampaignList = await checkInvalidCampaign(params, dispatch);
        if (invalidCampaignList.length > 0) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: `Campaigns are not ${campaignStatusForBulkUpload.join('/')}`,
              },
            ]),
          );
          return;
        }
      }
      if (await isBulkUploadFileExist(file.name, dispatch)) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `${file.name} has already been uploaded`,
            },
          ]),
        );
      } else {
        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'pulseLeads' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, setUploadFileProgress);
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            formDispatch({
              type: 'UPLOAD_FILE',
              payload: {
                blobId: result.blobId,
                filename: file.name,
              },
            });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Upload file successfully - ${file.name}`,
                },
              ]),
            );
            onDismissErrorHandler('file', file);
          }
        } finally {
          setUploadFileProgress(undefined);
        }
      }
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateBulkUploadFileBody = {
        resourceId: formState.blobId,
        type: formState.uploadType,
        filename: formState.filename,
        scheduledUploadTime: formState.enableScheduleUpload ? formState.scheduledUploadTime : null,
      };
      try {
        await createBulkUploadFile(body, dispatch);
        dispatch(
          appendAlertItem([
            formState.enableScheduleUpload && formState.scheduledUploadTime
              ? {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Scheduled Bulk Upload successfully with time ${moment(formState.scheduledUploadTime).format(
                    'DD/MM/YYYY HH:mm',
                  )}`,
                }
              : {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Bulk Upload successfully',
                },
          ]),
        );
        formDispatch({ type: 'RESET_STATE' });
      } catch (err) {}
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(`title.pulseleads.bulkUpload`)}</div>
          </div>
        </div>
        <div>
          <div className={classes.rowContainer} style={{ marginBottom: 10 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.bulkUpload.upload.selectBulkUploadType')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div>
              <FormControl
                error={errorState.mandatory.uploadType}
                style={{ margin: 0 }}
                margin="dense"
                variant="outlined"
              >
                <Select
                  style={{ minWidth: 250 }}
                  value={formState.uploadType}
                  onChange={(e) => {
                    onDismissErrorHandler('uploadType', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'uploadType', value: e.target.value } });
                  }}
                >
                  {map(BulkUploadTypeEnum, (option) => (
                    <MenuItem key={option} value={option}>
                      {Translation(`pulseleads.bulkUpload.common.${option}`)}
                    </MenuItem>
                  ))}
                  {config?.content?.Sales?.campaign?.enableServicingCampaign && (
                    <MenuItem key={'servicingLead'} value={'servicingLead'}>
                      {Translation(`pulseleads.bulkUpload.common.servicingLead`)}
                    </MenuItem>
                  )}
                </Select>
                {errorState.mandatory.uploadType && (
                  <FormHelperText className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col">
              <span>{Translation('pulseleads.bulkUpload.upload.bulkUploadTemplateCaption')}</span>
              <div>
                <span className={classes.template}>{Translation('pulseleads.bulkUpload.common.template')}</span>
              </div>
              {formState.uploadType && (
                <AsyncCsvLink
                  isDisabled={false}
                  filename={`${formState.uploadType}.csv`}
                  dataParser={(str) => str}
                  asyncCall={() => downloadBulkUploadCsv(formState.uploadType)}
                >
                  <Button style={{ padding: 1, fontSize: '14px' }}>
                    {Translation(`pulseleads.bulkUpload.common.${formState.uploadType}`)}
                  </Button>
                </AsyncCsvLink>
              )}
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.bulkUpload.upload.bulkUploadWithTemplate')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <input
              id="upload-file"
              hidden
              type="file"
              accept=".csv"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => handleFile(e)}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-file')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              {formState.filename && <div style={{ marginLeft: 10 }}>{formState.filename}</div>}
              {errorState.mandatory.file && (
                <div style={{ marginLeft: 10 }} className={classes.errorText}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </div>
              )}
            </div>
            {!!uploadFileProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                variant="determinate"
                color="secondary"
                value={uploadFileProgress}
              />
            )}
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.bulkUpload.upload.scheduleUploadDayTime')} :
              </span>
            </div>
            <FormControlLabel
              style={{ margin: '2px 12px 2px 0' }}
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.enableScheduleUpload === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableScheduleUpload',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.yes')}
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ margin: '2px 0' }}
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.enableScheduleUpload === false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableScheduleUpload',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.no')}
              labelPlacement="end"
            />
          </div>
          {formState.enableScheduleUpload && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}></span>
              </div>
              <PruDateTimePicker
                slotProps={{
                  textField: {
                    error: errorState.mandatory.scheduledUploadTime,
                    helperText: errorState.mandatory.scheduledUploadTime && MANDATORY_FIELD_ERROR_TEXT,
                  },
                }}
                disablePast
                ampm={false}
                format="DD/MM/YYYY HH:mm"
                value={formState.scheduledUploadTime || null}
                onChange={(date) => {
                  if (date) {
                    const now = new Date();
                    if (moment(date).isBefore(now)) {
                      date = now;
                    }
                    onDismissErrorHandler('scheduleUploadtime', date);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'scheduledUploadTime', value: date },
                    });
                  }
                }}
              />
              <div className={classes.dateDivider}></div>
            </div>
          )}

          <div className={classes.footerContainer}>
            <Button variant="contained" color="secondary" onClick={onSubmit}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkUploadUploadForm;
