import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, FormControlLabel, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { RegionLocale } from 'src/app/i18n';
import Form from 'src/app/common/components/Form';
import { weightValidation } from '../../api';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
}));

const PopUp: FC<any> = (props) => {
  // eslint-disable-next-line
  const {
    disabled,
    visible,
    isView,
    categoryId,
    categoryIntialValues,
    onCancel,
    onSave,
    i18ConfigData,
    confirmLoading,
  } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id, defaultMessage: id });
  const { classes } = useStyles();
  const [form] = Form.useForm();

  categoryIntialValues.status = categoryIntialValues?.status?.toString();

  const placeEnter = Translation('app.input.placeholder.please-enter');
  const emptyValue = Translation('component.hint.empty-value-not-allowed');

  const onFinish = (values: any) => {
    onSave(values);
  };

  const cancel = async () => {
    onCancel();
  };

  const validateWeight = async () => {
    const status = form.getFieldValue('status');
    const weight = form.getFieldValue('weight');

    if (status === 'active') {
      const data: any = {
        status,
        weight,
      };
      if (categoryId) {
        data.categoryId = categoryId;
      }

      const result = await weightValidation(data);
      const isValid = result.data;

      if (!isValid) {
        return Promise.reject(new Error(Translation('component.event-weight-diff-text')));
      }
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} initialValues={categoryIntialValues} className={classes.container}>
        {i18ConfigData.map((locale: RegionLocale) => (
          <Form.Item
            key={locale}
            name={`name-${locale}`}
            label={`${Translation('component.formLabel.name')} (${Translation(`navBar.lang.${locale}`)})`}
            rules={[
              {
                required: true,
                message: emptyValue,
              },
            ]}
          >
            <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
          </Form.Item>
        ))}

        <Form.Item
          name="description"
          label={Translation('title.event.category.table.header.description')}
          required={false}
        >
          <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
        </Form.Item>

        <Form.Item
          name="weight"
          label={Translation('title.event.category.table.header.weight')}
          rules={[
            {
              required: true,
              message: emptyValue,
            },
            {
              validator(_: any, value: string) {
                return validateWeight();
              },
            },
          ]}
        >
          <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
        </Form.Item>
        <Form.Item
          name="status"
          label={Translation('component.formLabel.status')}
          rules={[{ required: true, message: emptyValue }]}
        >
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              value="active"
              control={<Radio disabled={disabled} />}
              label={Translation('component.formSelectItem.active')}
            />
            <FormControlLabel
              value="inactive"
              control={<Radio disabled={disabled} />}
              label={Translation('component.formSelectItem.inactive')}
            />
          </RadioGroup>
        </Form.Item>
        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              {/* todo: cancel warling */}
              <Button variant="contained" color="inherit" onClick={cancel}>
                {Translation('app.button.cancel')}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {}}
              >
                {Translation('app.button.submit')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default PopUp;
