import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dialog, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
import Form from 'src/app/common/components/Form';
import { Field } from 'rc-field-form';
import { MTmode } from 'src/app/modules/MandatoryTraining/constants';
import { useStyles } from '../../style';
import PreviewPage from './preview-page';
import ContentSection from './sections/content-section';
import LeadDistributionSection from './sections/lead-distribution-section';
import PublishSection from './sections/publish-section';
import SubmissonSection from './sections/submission-section';
import { getARLConfigs } from '../../network/api';
import { activeType } from '../../type/types';

type PageOneDetailProps = {
  configs: any[];
  setConfigs: (value: any[]) => void;
  index: number;
  onNextPage: () => void;
};

const PageOneDetail: FC<PageOneDetailProps> = ({ configs, setConfigs, index, onNextPage }) => {
  const [form] = Form.useForm();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [initialValues] = useState<any>(configs[index] ?? {});
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<string>(configs[index].formMode ?? MTmode.VIEW);
  const langIndex = regionLocale.findIndex((item) => item === useLang());

  useEffect(() => {
    // save edit value when component unmount
    return () => {
      const allValue = form.getFieldsValue(true);
      const newValue = [...configs];
      newValue[index] = allValue;
      setConfigs(newValue);
    };
  }, []);

  const onEdit = () => {
    form.setFieldsValue({ formMode: MTmode.EDIT });
    setFormMode(MTmode.EDIT);
  };

  const onNext = async () => {
    await form.validateFields();
    const tempData = configs;
    tempData[index] = form.getFieldsValue(true);
    setConfigs(tempData);
    onNextPage();
  };

  const onCancel = async () => {
    // reset form, back to read view
    let rawData: any[] = [];
    const configs = await getARLConfigs(dispatch);
    if (configs && configs.length > 0) {
      rawData = configs.map((config: any) => {
        return {
          ...config,
          formMode: MTmode.VIEW,
        };
      });
    }
    await ModalService.confirm({
      title: Translation('component.hint.cancel-warning'),
      onConfirm: () => {
        form.setFieldsValue(rawData[index]);
        setConfigs(rawData);
        setFormMode(MTmode.VIEW);
      },
    });
  };

  return (
    <div>
      <Form form={form} initialValues={{ ...initialValues, agentType: initialValues.agentType ?? 'all' }}>
        <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
          <div className="tw-flex">
            <div className={classes.subHeader}>{Translation('agent_referral_link_setting')}</div>
          </div>

          <div className={classes.fieldMargin}>
            <Form.Item name="status" label={Translation('agent_referral_link_status')} rules={[DEFAULT_REQUIRED_RULES]}>
              <RadioGroup
                aria-label="status"
                name="isActive"
                style={{ ...getConvertedClasses('flex flex-row items-center'), flexDirection: 'row' }}
              >
                {Object.keys(activeType(intl)).map((key: string) => (
                  <FormControlLabel
                    key={activeType(intl)[key].text}
                    value={activeType(intl)[key].value}
                    control={<Radio />}
                    label={activeType(intl)[key].text}
                    disabled={formMode === MTmode.VIEW}
                  />
                ))}
              </RadioGroup>
            </Form.Item>
          </div>
        </div>

        <ContentSection form={form} formMode={formMode} />

        <SubmissonSection form={form} formMode={formMode} />

        <LeadDistributionSection form={form} formMode={formMode} />

        <PublishSection form={form} formMode={formMode} initialValues={initialValues} />

        {formMode === MTmode.VIEW && (
          <div className="tw-flex tw-bg-white tw-pb-6 tw-items-center tw-justify-center">
            <Button variant="contained" disabled={loading} color="secondary" onClick={onEdit}>
              {Translation('app.button.edit')}
            </Button>
            <span className={classes.ml_24}>
              <Button
                variant="contained"
                disabled={loading}
                color="secondary"
                onClick={() => {
                  setPopUpVisible(true);
                }}
              >
                {Translation('global.text.preview')}
              </Button>{' '}
            </span>
          </div>
        )}

        {formMode === MTmode.EDIT && (
          <div className="tw-flex tw-bg-white tw-pb-6 tw-items-center tw-justify-center">
            <Button variant="contained" disabled={loading} color="inherit" onClick={onCancel}>
              {Translation('app.button.cancel')}
            </Button>

            <span className={classes.ml_24}>
              <Button variant="contained" disabled={loading} color="secondary" onClick={onNext}>
                {Translation('app.button.next')}
              </Button>
            </span>
          </div>
        )}

        <Dialog
          open={popUpVisible}
          onClose={() => setPopUpVisible(false)}
          PaperProps={{ className: classes.previewPaper }}
        >
          <PreviewPage
            banner={
              form.getFieldValue(['content', 'banner']) === '1' ? form.getFieldValue(['content', 'bannerUrl']) : ''
            }
            title={form.getFieldValue(['content', 'title', langIndex, 'value']) ?? ''}
            subTitle={form.getFieldValue(['content', 'subTitle', langIndex, 'value']) ?? ''}
            inputFields={form.getFieldValue('inputFields') ?? {}}
            body={form.getFieldValue(['content', 'body', langIndex, 'value']) ?? ''}
            tncList={form.getFieldValue(['content', 'tncList', langIndex, 'value']) ?? []}
            callToAction={form.getFieldValue(['content', 'callToAction', langIndex, 'value']) ?? ''}
            onClose={setPopUpVisible}
          />
        </Dialog>
      </Form>
    </div>
  );
};

export default PageOneDetail;
