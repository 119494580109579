import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  AUDIT_LOG_TITLE,
  USER_MANAGEMENT_LOG_TITLE,
  AUDIT_LOG_BASE_PATH,
  USER_MANAGEMENT_LOG_PATH,
  USER_MANAGEMENT_LOG_NAMESPACE,
  AdminAuditLogPermissionType,
} from './constants';
import UserManagementLogRoutes from './pages/user-management-log/user-management-log-routes';

export const auditLogModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(AUDIT_LOG_TITLE),
    path: AUDIT_LOG_BASE_PATH,
    icon: '',
    // enableRead: isPermitted(
    //   [
    //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_READ,
    //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_CREATE,
    //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_UPDATE,
    //   ],
    //   permissions,
    // )
    enableRead: true,
    child: [
      {
        title: Translation(USER_MANAGEMENT_LOG_TITLE),
        path: `${AUDIT_LOG_BASE_PATH}${USER_MANAGEMENT_LOG_PATH}`,
        namespace: USER_MANAGEMENT_LOG_NAMESPACE,
        component: UserManagementLogRoutes,
        // enableRead: isPermitted(
        //   [
        //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_READ,
        //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_CREATE,
        //     AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_UPDATE,
        //   ],
        //   permissions,
        // ),
        // enableCreate: isPermitted([AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_CREATE], permissions),
        // enableUpdate: isPermitted([AdminAuditLogPermissionType.USER_MANAGEMENT_LOG_UPDATE], permissions),
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
      },
    ],
  };
};
