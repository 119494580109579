import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { UpdateProspectCategoryBody, getCategoryById, updateCategory, checkColor } from '../../api';
import { regionLocale } from 'src/app/i18n';
import ColorLabelRadio from '../components/ColorLabelRadio';
import { colors, activeType, categoryType } from '../../util';
import { leadProspectPath } from '../../ProspectCategoryRoutes';

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
}));

const Detail: React.FC<any> = (props) => {
  const { history } = props;
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    isActive: 'false',
    isDefault: false,
    color: 'white',
  });

  const { id } = useParams<{ id: string }>();
  const isNoneType = initialValues.type === categoryType.None;

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        setLoading(true);
        await getCategory();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const getCategory = async () => {
    const result = await getCategoryById(id);

    const { colorLabel, backgroundColorLabel, isActive } = result.data;

    var color = JSON.stringify({
      fontColor: colorLabel,
      bgColor: backgroundColorLabel,
    });

    const values = {
      ...result.data,
      ...{
        color,
        isActive: isActive ? isActive.toString() : 'false',
      },
    };

    setInitialValues(values);

    form.setFieldsValue(values);
  };

  const onFinish = async () => {
    const allValues = form.getFieldsValue(true);
    const color = JSON.parse(allValues.color);
    const saveData: UpdateProspectCategoryBody = {
      ...allValues,
      ...{
        isActive: JSON.parse(allValues.isActive),
        backgroundColorLabel: color.bgColor,
        colorLabel: color.fontColor,
      },
    };
    saveData.targetNumber = Number(saveData.targetNumber);
    saveData.targetFrequency = Number(saveData.targetFrequency);

    delete saveData.color;

    if (isNoneType) {
      delete saveData.targetNumber;
      delete saveData.targetFrequency;
      saveData.isActive = true;
    }

    setLoading(true);
    await updateCategory(id, saveData, dispatch);
    setLoading(false);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Prospect Category saved successfully',
        },
      ]),
    );

    goBackToList();
  };

  const goBackToList = () => {
    history.push(leadProspectPath);
  };

  const renderMutiLang = (name: string) => {
    return regionLocale.map((code, index) => {
      return (
        <>
          <Form.Item label={Translation(`navBar.lang.${code}`)} name={[name, code]} rules={[DEFAULT_REQUIRED_RULES]}>
            <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
          </Form.Item>
        </>
      );
    });
  };

  const updateDefaultStatus = () => {
    const isCheck = form.getFieldValue('isDefault');
    form.setFieldsValue({ isDefault: !isCheck });
    setPopUpVisible(false);
  };

  const getColorLabelValidate = () => {
    return {
      validator: async (_: any, value: string) => {
        const colors = JSON.parse(value);
        const res = await checkColor({
          id,
          colorLabel: colors.fontColor,
        });

        const isNotDuplicate = res.data;

        if (!isNotDuplicate) {
          return Promise.reject(new Error(Translation('form.pulseleads.prospect.catefory.duplication.color.message')));
        }

        return Promise.resolve();
      },
    };
  };

  const TARGET_FREQUENCT_VALIDATE = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (parseFloat(value) < 1 || parseFloat(value) > 52) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.prospect.catefory.target.frequency.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <div className={commonClasses.container}>
      <div className={commonClasses.header} style={{ marginBottom: 20 }}>
        {`${Translation('component.formTitle.edit')} ${Translation('title.pulseleads.prospect.category')}`}
      </div>

      <Form
        form={form}
        className={`add-form-wraper form-wraper`}
        onFinish={onFinish}
        initialValues={{ ...initialValues }}
      >
        <Form.Item name="isDefault">
          {(_, meta, { getFieldValue }) => {
            const isCheck = getFieldValue('isDefault');
            const isDefaultOrigin = initialValues.isDefault;
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDefaultOrigin}
                    checked={isCheck}
                    color="secondary"
                    onChange={(e: any) => {
                      if (!isCheck) {
                        setPopUpVisible(true);
                      } else {
                        form.setFieldsValue({ isDefault: !isCheck });
                      }
                    }}
                  />
                }
                label={Translation('form.pulseleads.prospect.catefory.setAsDerault.label')}
              />
            );
          }}
        </Form.Item>

        <div className={commonClasses.subHeader} style={{ marginBottom: 10 }}>
          <span className={commonClasses.subHeader}>{Translation('component.formLabel.name')}</span>
        </div>
        <Form.Item noStyle name="name">
          {(_, meta, { getFieldValue }) => {
            return renderMutiLang('name');
          }}
        </Form.Item>

        <div className={commonClasses.subHeader} style={{ marginBottom: 10 }}>
          <span className={commonClasses.subHeader}>{Translation('component.formLabel.description')}</span>
        </div>
        <Form.Item noStyle name="description">
          {(_, meta, { getFieldValue }) => {
            return renderMutiLang('description');
          }}
        </Form.Item>

        <Form.Item
          label={Translation(`form.pulseleads.prospect.catefory.color.label`)}
          rules={[getColorLabelValidate()]}
          name="color"
          required={false}
        >
          <RadioGroup aria-label="gender" name="customized-radios" style={{ display: 'flex', flexDirection: 'row' }}>
            {colors.map((item, index) => {
              return (
                <FormControlLabel
                  value={JSON.stringify(item)}
                  key={`color_radio_${index}`}
                  control={<ColorLabelRadio bgColor={item.fontColor} />}
                  label=""
                />
              );
            })}
          </RadioGroup>
        </Form.Item>

        {!isNoneType && (
          <Form.Item
            label={`${Translation('form.pulseleads.prospect.catefory.target.number')}`}
            name="targetNumber"
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <TextField type="number" margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
          </Form.Item>
        )}

        {!isNoneType && (
          <Form.Item label={Translation('form.pulseleads.prospect.catefory.target.target.frequency')}>
            {(_, meta, { getFieldValue }) => {
              return (
                <>
                  <Form.Item name="targetFrequency" rules={TARGET_FREQUENCT_VALIDATE}>
                    <TextField type="number" margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
                  </Form.Item>
                  <label style={{ width: 150, marginLeft: 15, marginBottom: 0 }}>
                    {Translation('form.pulseleads.prospect.catefory.target.numbe.range.text')}
                  </label>
                </>
              );
            }}
          </Form.Item>
        )}

        {!isNoneType && (
          <Form.Item
            name="isActive"
            label={Translation('form.prospect.master.is.active')}
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <RadioGroup aria-label="isActive？" name="isActive" style={{ display: 'flex', flexDirection: 'row' }}>
              {Object.keys(activeType).map((key: string) => (
                <FormControlLabel
                  key={activeType[key].text}
                  value={activeType[key].value}
                  control={
                    <Radio
                      onChange={(e: any) => {
                        form.setFieldsValue({ isActive: e.target.value.toString() });
                      }}
                    />
                  }
                  label={activeType[key].text}
                />
              ))}
            </RadioGroup>
          </Form.Item>
        )}
        <Form.Item noStyle>
          <>
            <Button type="submit" style={{ float: 'right' }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>

            <Button
              variant="contained"
              color="inherit"
              style={{ float: 'right', marginRight: 15 }}
              onClick={goBackToList}
            >
              {Translation('app.button.cancel')}
            </Button>
          </>
        </Form.Item>
      </Form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Dialog open={popUpVisible} fullWidth>
        <DialogTitle style={{ textAlign: 'center' }}>Reminder</DialogTitle>
        <div style={{ padding: 20, textAlign: 'center' }}>
          {Translation('component.pulseleads.prospect.category.update.default.message')}
        </div>
        <div className={commonClasses.footerContainer}>
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setPopUpVisible(false);
              }}
            >
              {Translation('app.button.cancel')}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="secondary"
              onClick={updateDefaultStatus}
            >
              {Translation('global.text.confirm')}
            </Button>
          </>
        </div>
      </Dialog>
    </div>
  );
};

export default Detail;
