import React, { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { AUDIT_LOG_BASE_PATH, USER_MANAGEMENT_LOG_PATH } from '../../constants';
import { userManagementLogFilterKeys } from './list/user-management-log-listing-page.hook';
import { UserManagementLogListingPage } from './list/user-management-log-listing-page.screen';
import { UserManagementLogDetailPage } from '../user-management-log-detail/user-management-log-detail.screen';

export const userManagementLogPath = `${AUDIT_LOG_BASE_PATH}${USER_MANAGEMENT_LOG_PATH}`;

const UserManagementLogRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={userManagementLogPath} to={`${userManagementLogPath}/list`} />
      <PruRoute
        exact
        path={`${userManagementLogPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={userManagementLogFilterKeys}>
            <UserManagementLogListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${userManagementLogPath}/detail`}
        render={(props) => (
          <UserManagementLogDetailPage {...props} />
          // <ParamsProvider {...props} acceptKeys={userManagementLogFilterKeys}>

          // </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default UserManagementLogRoutes;
