import React, { FC, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { SLAType, SLAConfigItem } from 'src/app/modules/PulseLeads/types/sla-types';
import { deleteSLAConfigItem } from 'src/app/modules/PulseLeads/network/slaCrud';
import { getSLARootPath, getSLAType } from '../../SLARoutes';
import { PaginateList } from 'src/app/common/types/common-types';

type SLAListProps = {
  isLoading: boolean;
  slaConfigList?: PaginateList<SLAConfigItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const SLAList: FC<SLAListProps> = ({ isLoading, slaConfigList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const slaType = useMemo(() => getSLAType(location.pathname) || SLAType.ACCEPT, []);

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteSLAConfigItem(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `SLA Config Item deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-sla-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation(`pulseleads.sla.${slaType}.list`)}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.sla.newConfig'),
            onClick: () => history.push(`${getSLARootPath(location.pathname)}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit SLA Config',
            onClick: (row) => history.push(`${getSLARootPath(location.pathname)}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete SLA Config',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('pulseleads.sla.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'duration',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.duration'),
            renderData: (row) => String(row.durationInMinutes) || '-',
          },
          {
            keyIndex: 'startTime',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.startTime'),
            renderData: (row) => row.startTime || '-',
          },
          {
            keyIndex: 'endTime',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.endTime'),
            renderData: (row) => row.endTime || '-',
          },
          {
            keyIndex: 'isWeekendIncluded',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.isWeekendIncluded'),
            renderData: (row) =>
              row.isWeekendIncluded ? Translation('app.checkbox.yes') : Translation('app.checkbox.no'),
          },
          {
            keyIndex: 'isPullBackLeadEnabled',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.isPullBackLeadEnabled'),
            renderData: (row) =>
              row.isPullBackLeadEnabled ? Translation('app.checkbox.yes') : Translation('app.checkbox.no'),
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.updatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.sla.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={slaConfigList?.docs}
        totalPages={slaConfigList?.totalPages}
        totalRecords={slaConfigList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default SLAList;
