enum IssuedByEnum {
  PAMB = 'pamb',
  PBTB = 'pbtb',
  PHKL = 'phkl',
}

enum CpdReminderEventEnum {
  CPD_DUE_DATE_REMINDER = 'cpd_due_date_reminder',
  CPD_COMPLETION = 'cpd_completion',
}

enum CpdDueDateTriggerTypeEnum {
  LIAM_1ST_YEAR_20_HOURS_REQUIREMENT_DUE = 'LIAM_Y1_20',
  LIAM_1ST_YEAR_10_HOURS_REQUIREMENT_DUE_BEFORE_2024 = 'LIAM_Y1_10',
  LIAM_2ND_YEAR_20_HOURS_REQUIREMENT_DUE_BEFORE_2024 = 'LIAM_Y2',
  LIAM_YEARLY_CPD_REQUIREMENT_DUE = 'LIAM_CPD',
  MTA_1ST_YEAR_20_HOURS_REQUIREMENT_DUE = 'MTA_Y1_20',
  MTA_YEARLY_CPD_REQUIREMENT_DUE = 'MTA_CPD',
  PITA_M2_EXAM_REQUIREMENT_DUE = 'RFP_M2_EXAM_LIAM',
  PITA_M2_SHARIAH_EXAM_REQUIREMENT_DUE = 'RFP_M2_EXAM_MTA',
}

type CertificationCustomPresets = {
  issuedByList: string[];
};

type CpdReminderPresets = {
  triggerTypeList: string[];
  variableReminderText: string;
};

type CpdReminderCustomPresets = Record<CpdReminderEventEnum, Partial<CpdReminderPresets>>;

type TrainingCustomPresets = {
  certificationCode: CertificationCustomPresets;
  cpdReminder: CpdReminderCustomPresets;
};

export const trainingConfigPresets: TrainingCustomPresets = {
  certificationCode: {
    issuedByList: Object.values(IssuedByEnum),
  },
  cpdReminder: {
    [CpdReminderEventEnum.CPD_DUE_DATE_REMINDER]: {
      triggerTypeList: Object.values(CpdDueDateTriggerTypeEnum),
      variableReminderText:
        'Available variables: {{requirementEnd}}, {{cpdAchieved}}, {{cpdShortfall}}. Example: LIAM 1st Year 20 Hours requirement is approaching deadline {{requirementEnd}}.',
    },
    [CpdReminderEventEnum.CPD_COMPLETION]: {},
  },
};
