import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress } from '@mui/material';
import React, { CSSProperties, ChangeEvent, FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { read, utils, writeFile } from 'xlsx';
import { flatten } from 'lodash';

type AgentListUploaderProps = {
  disabled?: boolean;
  onChange?: (value?: string[] | null) => void;
  list?: string[];
  btnTxt?: string;
  type?: string;
  maxFileSize?: number;
  recordsLimit?: number;
  linkStyle?: CSSProperties;
  color?: 'inherit' | 'primary' | 'secondary';
  tips?: string;
};

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },

  mr20: {
    marginRight: 20,
  },
}));

const AgentListUploader: FC<AgentListUploaderProps> = (props) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { classes: styles } = useStyles();
  const {
    disabled,
    list,
    onChange,
    maxFileSize,
    recordsLimit = 30000,
    linkStyle,
    color = 'primary',
    btnTxt = Translation('app.button.upload'),
    type,
    tips,
  } = props;

  const [loadPercentage, setLoadPercentage] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [value, setValue] = useState<any>(list ?? undefined);
  const dispatch = useDispatch();
  const agentCodeLength = Number(window.envConfig['REACT_APP_AGENT_CODE_LENGTH']) ?? 8;

  let flag: any = null;
  const inputRef = React.createRef<HTMLInputElement>();

  const deleteFile = () => {
    triggerChange(null);
    setValue(null);
    setLoadPercentage(0);
  };

  const onSelect = () => {
    inputRef.current!.click();
  };

  const simulatorPercentage = () => {
    flag = setInterval(() => {
      setLoadPercentage((v) => {
        if (v < 90) {
          return v + 10;
        }
        clearInterval(flag);
        return v;
      });
    }, 1000);
  };

  const transformAgentCode = (list: string[]) => {
    return list.map((agentCode) => {
      if (agentCode.length < agentCodeLength) {
        return agentCode.padStart(agentCodeLength, '0');
      } else {
        return agentCode;
      }
    });
  };

  const convertXlsxToArray = (data: any[]) => {
    let tempData = data;
    tempData.shift();
    tempData = flatten(tempData);
    tempData = tempData.map((item: any) => String(item));
    tempData = transformAgentCode(tempData);
    return tempData;
  };

  const fileReader = async (e: React.ChangeEvent<HTMLInputElement>, file: any) => {
    return new Promise<string[]>((resolve) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const workBook = read(e.target?.result);
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        const data = utils.sheet_to_json(workSheet, { header: 1 });
        const newArray = convertXlsxToArray(data);
        resolve(newArray);
      };
      if (e.target.files) {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    inputRef.current!.value = '';
    simulatorPercentage();
    setIsUploading(true);

    const newArray = await fileReader(e, file);
    if (!checkFile(file, maxFileSize, newArray.length)) {
      setIsUploading(false);
      setLoadPercentage(0);
      clearInterval(flag);
      return;
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: ``,
          },
        ]),
      );
      setValue(newArray);
      triggerChange(newArray);
      setLoadPercentage(100);
      setIsUploading(false);
      clearInterval(flag);
    }
  };

  const checkFile = (file: any, maxSize?: number, recordsCount?: number) => {
    if (!file) return false;
    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: TranslationWithVariable('global.max.file.size.allow', maxSize),
          },
        ]),
      );
      return false;
    }
    if (recordsCount && recordsCount > recordsLimit) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('global.max.file.records.allow'),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const triggerChange = (changedValue: string[] | null) => {
    if (onChange) {
      if (changedValue) {
        onChange(changedValue);
      } else {
        onChange(null);
      }
    }
  };
  const convertArray = (value: string[]) => {
    // convert agentCode array to  2-d array
    return value.map((item) => [item]);
  };

  const downloadXlsx = () => {
    const newFile = convertArray(value);
    const worksheet = utils.json_to_sheet(newFile);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'agentCode');
    utils.sheet_add_aoa(worksheet, [['agentCode']], { origin: 'A1' });
    writeFile(workbook, 'template.xlsx');
  };

  return (
    <>
      {!isUploading && !(value?.length > 0) && (
        <>
          <Button
            disabled={disabled}
            onClick={onSelect}
            variant="contained"
            component="span"
            color={color}
            className={styles.mr20}
          >
            <AddIcon />
            {btnTxt}
          </Button>
        </>
      )}
      {isUploading && (
        <div className="tw-w-full">
          <LinearProgress variant="determinate" value={loadPercentage} />
        </div>
      )}
      {value?.length > 0 && !isUploading && (
        <div>
          <span className="tw-text-lg tw-mr-1">{Translation('component.template.rule-apply')}</span>
          <a target="_blank" onClick={downloadXlsx} className="tw-text-lg tw-underline hover:tw-text-blue-500 ">
            {Translation('component.template.agent-list')}
          </a>
          <span className="tw-text-lg tw-ml-1">{type}</span>
          {!disabled && <CloseIcon className={styles.closeIcon} onClick={deleteFile} />}
        </div>
      )}
      <input
        className="tw-hidden"
        type="file"
        id="input"
        ref={inputRef}
        multiple
        onChange={onUploadFile}
        accept=".xlsx, .csv"
      />
      {tips && !(value?.length > 0) && <span>{tips}</span>}
    </>
  );
};

export default AgentListUploader;
