import { TextField, Button, RadioGroup, Radio, FormControlLabel, LinearProgress } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { styles, useStyles } from '../../../style';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { fileUpload, formatBytes } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import Form from 'src/app/common/components/Form';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { regionLocale } from 'src/app/i18n';
import { MTmode } from 'src/app/common/constants';
import { FormSectionProps, bannerStatus } from '../../../type/types';
import { AGENTLEADFORM_RESOURCE } from 'src/app/modules/sales-kit/constants';

const ContentSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const isView = formMode === MTmode.VIEW;
  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [bannerUrl, setBannerUrl] = useState(form.getFieldValue(['content', 'bannerUrl']));
  const [data, setData] = useState(form.getFieldValue('content'));

  useEffect(() => {
    // set default value for call To Action
    if (!data?.callToAction?.[0]?.value) {
      const newData = { ...data };
      newData.callToAction[0].value = "I'm Ready";
      setData(newData);
    }

    // set default value for Tnc
    if (!data?.tncList?.[0]?.value?.[0]) {
      const newData = { ...data };
      newData.tncList[0].value = [''];
      setData(newData);
    }
  }, []);

  useEffect(() => {
    if (form.getFieldValue('content') !== data) {
      setData(form.getFieldValue('content'));
    }
  }, [form.getFieldValue('content')]);

  const handleChange = (value: string, key: string, index: number) => {
    const { ...body } = form.getFieldValue('content');
    body[key][index].value = value;
    form.setFieldsValue({ content: body });
    setData(body);
  };

  const addTnc = () => {
    const { ...content } = data;
    regionLocale?.map((language, index) => {
      if (content.tncList?.[index]?.value?.length > 0) {
        content.tncList[index].value.push('');
      } else {
        content.tncList[index].value = [];
        content.tncList[index].value.push('');
      }
    });
    setData(content);
    form.setFieldsValue({ content: content });
  };

  const TncForm = (index: number, regionLocale: string[]) => {
    const validatedTnc = (data: any) => {
      const matchs = data?.match(/{(.*?)}/g);
      if (matchs?.length > 0) {
        for (const res of matchs) {
          if (res !== '{displayName_en}' && res !== '{displayName_local}' && res !== '{agentCode}') {
            return true;
          }
        }
      }
      return false;
    };

    const tncMatchRule = [
      {
        validator(_: any, data: any) {
          if (!data) {
            return Promise.resolve();
          }
          if (validatedTnc(data)) {
            return Promise.reject(new Error(Translation('agent_referral_link_tnc_rule')));
          }
          return Promise.resolve();
        },
      },
    ];
    const handleDelete = () => {
      if (!isView) {
        const { ...content } = form.getFieldValue('content');
        content.tncList?.forEach((item: any, lIndex: number) => {
          const newTnCTexts = [...form.getFieldValue(['content', 'tncList', lIndex, 'value'])];
          newTnCTexts.splice(index, 1);
          content.tncList[lIndex].value = newTnCTexts;
        });
        setData(content);
        form.setFieldsValue({ content: content });
      }
    };

    const handleTncChange = (value: string, index: number, lIndex: number) => {
      const { ...body } = form.getFieldValue('content');
      body.tncList[lIndex].value[index] = value;
      form.setFieldsValue({ content: body });
      setData(body);
    };

    return regionLocale?.map((language, lIndex) => {
      return (
        <div className={classes.rowContainer} key={language}>
          <div className={classes.titleArea}>
            <span>
              {Translation('survey.form.tnc')}
              {index + 1} :{`(${language.toLocaleUpperCase()})`}
            </span>
          </div>

          <div className={classes.flexRowFull}>
            <Form.Item name={['content', 'tncList', lIndex, 'value', index]} rules={tncMatchRule}>
              <TextField
                disabled={isView}
                variant="outlined"
                fullWidth
                margin="dense"
                value={data?.tncList?.[lIndex]?.value?.[index] ?? ''}
                onChange={(e) => {
                  handleTncChange(e.target.value, index, lIndex);
                }}
              />
            </Form.Item>

            <CloseIcon className={classes.formCloseIcon} onClick={handleDelete} />

            {lIndex !== 0 && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  if (!isView) {
                    handleTncChange(form.getFieldValue(['content', 'tncList', 0, 'value', index]), index, lIndex);
                  }
                }}
                style={styles.FieldCopyText}
              >
                {Translation('agent_referral_link_copy')}
              </Button>
            )}
          </div>
        </div>
      );
    });
  };
  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const TranslationWithVariable = (key: string) => intl.formatMessage({ id: key });
      const errMsg = TranslationWithVariable('global.max.file.size.allow');
      const size = formatBytes(file.size);

      const overSize = (size.type === 1 && size.value > 2048) || (size.type === 2 && size.value > 2);
      if (overSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }

      try {
        const createBlobRes = await createBlob(
          {
            mimeType: file.type,
            accessLevel: 'public',
            module: AGENTLEADFORM_RESOURCE,
            originalFilename: file.name,
          },
          dispatch,
        );

        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        console.log('blobDetail', blobDetail);

        const result = blobDetail[0];

        if (result) {
          setBannerUrl(result.url);
          const content = form.getFieldValue('content');
          const newContent = {
            ...content,
            bannerBlobId: result.blobId,
            bannerName: result.blobName,
            bannerUrl: result.url,
          };
          form.setFieldsValue({ content: newContent });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  const TncReminder = () => {
    return (
      <>
        <div className={classes.TNC}>
          <li> {'Please input [ ] to define file link name and ( ) to define file URL.'}</li>
          <div className="tw-ml-6">
            Example: We use cookies to provide necessary website functionality, improve your experience and analyze our
            traffic.By using our website, you agree to our [privacy policy](http://www.google.com.hk) and our cookies
            usage.
          </div>
          <li>
            {
              'Please input {variable} in your TnC and relevant data will then be extracted. Below are list of variable fields which you may use'
            }
          </li>
          <li className="tw-ml-6">{'{displayName_en}: full display name in English'}</li>
          <li className="tw-ml-6">{'{displayName_local}: full display name in local language'}</li>
          <li className="tw-ml-6">{'{agentCode}: agent code'}</li>
          <div className="tw-ml-6">{'Example: Referenced by Prudential Marketing Officer {displayName_en}'}</div>
        </div>
      </>
    );
  };

  const BannerSetting = () => {
    return (
      <>
        <div className={classes.fieldMargin}>
          <Form.Item
            name={['content', 'banner']}
            label={Translation('agent_referral_link_banner')}
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <RadioGroup
              aria-label="isDefaultBanner"
              name="isDefaultBanner"
              defaultValue={form.getFieldValue(['content', 'banner'])}
              className={classes.flexRow}
            >
              {Object.keys(bannerStatus(intl)).map((key: string) => (
                <FormControlLabel
                  key={bannerStatus(intl)[key].text}
                  value={bannerStatus(intl)[key].value}
                  control={<Radio />}
                  label={bannerStatus(intl)[key].text}
                  disabled={isView}
                />
              ))}
            </RadioGroup>
          </Form.Item>
        </div>
        {/*banner*/}

        {
          <Form.Item>
            {(_, meta, { getFieldValue }) => {
              const isCustom = getFieldValue(['content', 'banner']) === '0' ? false : true;
              return (
                isCustom && (
                  <div className="tw-flex tw-flex-row tw-mb-6 tw-mt-6 tw-ml-56">
                    <div>
                      {bannerUrl && (
                        <div className="tw-flex tw-flex-row">
                          <div className={classes.imageContainer}>
                            <img className={classes.imageDisplayArea} src={bannerUrl} alt="App Icon" />
                          </div>
                          <span>
                            <CloseIcon
                              className="tw-ml-8"
                              onClick={() => {
                                if (!isView) {
                                  setBannerUrl('');
                                  const content = {
                                    ...form.getFieldValue('content'),
                                    bannerBlobId: '',
                                    bannerName: '',
                                  };
                                  form.setFieldsValue({ content: content });
                                }
                              }}
                            />
                          </span>
                        </div>
                      )}
                      <div className={classes.rowContainer}>
                        <div className="tw-relative tw-w-full">
                          <div className="tw-flex tw-items-center">
                            <>
                              {!bannerUrl && (
                                <div>
                                  <input
                                    id="upload-campaign-image"
                                    hidden
                                    type="file"
                                    accept="image/jpeg, image/png, image/jpg"
                                    onClick={(e) => {
                                      const element = e.target as HTMLInputElement;
                                      element.value = '';
                                    }}
                                    onChange={handleFile}
                                  />
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    size="small"
                                    disabled={isView}
                                    onClick={() => document.getElementById('upload-campaign-image')!.click()}
                                  >
                                    {Translation('section.common.operation.upload')}
                                  </Button>
                                </div>
                              )}
                              {!bannerUrl && (
                                <div className="tw-ml-3">{Translation('agent_referral_link_banner_tips')}</div>
                              )}

                              {!!imageUploadProgress && (
                                <LinearProgress
                                  className={classes.mt10}
                                  variant="determinate"
                                  color="secondary"
                                  value={imageUploadProgress}
                                />
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            }}
          </Form.Item>
        }
      </>
    );
  };

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('agent_referral_link_content_setting')}</div>
      </div>

      <BannerSetting />

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['content', 'title', index, 'value']}
              label={`${Translation('agent_referral_link_title')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                    disabled={isView}
                    value={data?.title?.[index]?.value ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'title', index);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(form.getFieldValue(['content', 'title', 0, 'value']), 'title', index);
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`80 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['content', 'subTitle', index, 'value']}
              label={`${Translation('agent_referral_link_subTitle')}(${language.toLocaleUpperCase()})`}
              required={false}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                    disabled={isView}
                    value={data?.subTitle?.[index]?.value ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'subTitle', index);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(form.getFieldValue(['content', 'subTitle', 0, 'value']), 'subTitle', index);
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`80 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['content', 'body', index, 'value']}
              label={`${Translation('agent_referral_link_body')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
              style={index !== 0 ? styles.mt24 : {}}
            >
              <div className="tw-flex tw-flex-col tw-flex-1">
                <Tinymce
                  disabled={isView}
                  value={data?.body?.[index]?.value ?? ''}
                  onChange={(e) => {
                    handleChange(e, 'body', index);
                  }}
                  module="agent-referral-link"
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(form.getFieldValue(['content', 'body', 0, 'value']), 'body', index);
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}

      {/*Tncs*/}
      <div className={classes.flexRow}>
        <div className={classes.marginVertical_24}>
          <span className={classes.titleArea}>{Translation('agent_referral_link_customer_disclaimer')}</span>

          <span className={classes.ml_24}>
            <Button
              variant="contained"
              color="secondary"
              disabled={isView}
              className={classes.button}
              size="small"
              onClick={addTnc}
            >
              {Translation('app.button.addNew')}
            </Button>
          </span>
        </div>
      </div>
      <div className={classes.tncContainer}>
        <TncReminder />
      </div>

      <div className="tw-mb-3">
        {data?.tncList?.[0]?.value?.map((tnc: string, index: number) => {
          return TncForm(index, regionLocale);
        })}
      </div>

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.CTAtext} key={language}>
            <Form.Item
              name={['content', 'callToAction', index, 'value']}
              label={`${Translation('agent_referral_link_call_to_action')}(${language.toLocaleUpperCase()}):`}
              rules={[DEFAULT_REQUIRED_RULES]}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    disabled={isView}
                    value={data?.callToAction?.[index]?.value ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'callToAction', index);
                    }}
                    placeholder="I'm Ready"
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(
                            form.getFieldValue(['content', 'callToAction', 0, 'value']),
                            'callToAction',
                            index,
                          );
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`30 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}
    </div>
  );
};
export default ContentSection;
