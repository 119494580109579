import React, { useState, useEffect, useRef, HTMLAttributes, ReactElement } from 'react';
import { ApiErrorResponse } from '../network/apiErrorHandler';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { CSVLink } from 'react-csv';

type HeaderType = {
  label: string;
  key: string;
};

type ApiResponse<T> = {
  data: T[];
  columns: Record<string, string>;
  page: number;
  hasNextPage: boolean;
};

type AsyncCsvLinkPaginateProps<T> = {
  isDisabled: boolean;
  filename: string;
  asyncCall: (page: number) => Promise<ApiResponse<T>>;
  renderButton: (isLoading: boolean, onClick: () => void) => ReactElement;
} & HTMLAttributes<HTMLDivElement>;

function AsyncCsvLinkPaginate<T extends Record<string, unknown>>({
  isDisabled,
  filename,
  asyncCall,
  renderButton,
  children,
  ...rest
}: AsyncCsvLinkPaginateProps<T>) {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<T[] | undefined>();
  const [headers, setHeaders] = useState<HeaderType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const csvInstance = useRef<any>(null);

  const onClickDownload = async () => {
    if (isDisabled) return;
    setIsLoading(true);
    await getData(1);
  };

  const transformColumns = (columns: Record<string, string>): HeaderType[] =>
    Object.entries(columns).map(([key, value]) => ({ key, label: value }));

  const getData = async (page: number, data: T[] = []) => {
    try {
      const res = await asyncCall(page);
      const newData = [...data, ...res.data];
      if (res.hasNextPage) {
        await getData(page + 1, newData);
      } else {
        setHeaders(transformColumns(res.columns));
        setCsvData(newData);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(undefined);
      });
    }
  }, [csvData]);

  return (
    <>
      <div {...rest}>
        {renderButton(isLoading, onClickDownload)}
        {children}
      </div>
      {csvData && <CSVLink ref={csvInstance} headers={headers} data={csvData} filename={filename} target="_blank" />}
    </>
  );
}

export default AsyncCsvLinkPaginate;
