import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { convertDataToJSON } from 'src/app/common/components/event-survey-creator/util/creator.util';
import { StepStatusEnum, ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';
import { useState } from 'react';

type HookProps = ComponentProps<EventFormCommonProps>;

export const useRegistrationForm = ({ formCommonProps, onStepChange }: HookProps) => {
  const dispatch = useDispatch();
  const { eventItem, walkInAllowed, reloadData } = formCommonProps;

  const [surveyData, setSurveyData] = useState<any>(
    () => eventItem?.regFormBody ?? (eventItem ? convertDataToJSON(eventItem) : undefined),
  );

  const onSubmit = async (data: any) => {
    setSurveyData(data);
    const body: CreateUpdateEventBody = {
      regFormBody: data.content,
    };
    try {
      if (eventItem) {
        await modifyEventItem(eventItem._id, body, dispatch);
        await reloadData();
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Data saved successfully',
            },
          ]),
        );
        // Go to Publish Setting
        let newStepIndex = 5;
        if (walkInAllowed) {
          // Go to Walk-in Form
          newStepIndex = 4;
        }
        onStepChange({
          newActiveStep: { stepIndex: 0, child: { stepIndex: newStepIndex } },
          currentStepStatus: StepStatusEnum.FINISHED,
          forceNavigation: true,
          saveChanges: true,
        });
      }
    } catch (err) {}
  };

  return {
    surveyData,
    onSubmit,
  };
};
