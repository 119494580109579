import React, { useState } from 'react';
import { IconButton, Chip, Container } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TODOLIST_BASE_PATH, TODOLIST_TASKGROUP_PATH } from 'src/app/modules/ToDoList/constants';
import { onDisplayDateFormat, useDataProvider } from 'src/app/common/utils';
import { useStyles } from '../../styles/TaskGroupStyles';
import { fecthTodoListTask, moveTaskGroupUpward, moveTaskGroupDownward } from '../../../../network/todolistCrud';
import { useDispatch } from 'react-redux';
import { TaskItemList } from 'src/app/modules/ToDoList/types/todolist-type';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

const TaskGroupTable = ({}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [taskData, setTaskData] = useState<TaskItemList>();
  const [showHideSave, setShowHideSave] = useState(false);

  const { isLoading, refreshData } = useDataProvider<TaskItemList>(
    async () => {
      try {
        return await fecthTodoListTask(dispatch);
      } catch (err) {}
    },
    setTaskData,
    false,
  );
  const taskValue = taskData?.groups.sort((a, b) => a.order - b.order);

  const onEditPositionData = async () => {
    return setShowHideSave(true);
  };

  const onCancelEditPositionData = async () => {
    return setShowHideSave(false);
  };

  const onUpwardData = async (id: string) => {
    try {
      await moveTaskGroupUpward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('todolist.messageResponse.success'),
          },
        ]),
      );

      refreshData();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onDownwardData = async (id: string) => {
    try {
      await moveTaskGroupDownward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('todolist.messageResponse.success'),
          },
        ]),
      );

      refreshData();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onEditTaskGroup = async (id: string, taskStatus: boolean) => {
    history.push({
      pathname: `${TODOLIST_BASE_PATH}${TODOLIST_TASKGROUP_PATH}/edit/${id}`,
      state: { taskGroupStatus: taskStatus },
    });
  };

  return (
    <>
      <div className={classes.marginBottom}></div>
      {showHideSave ? (
        <Container component="span" className={classes.boxNotify}>
          <IconButton size={'small'}>
            <InfoOutlinedIcon />
            <p className={classes.fontBox}>{Translation('todolist.editPosition.notif')}</p>
          </IconButton>
        </Container>
      ) : (
        <></>
      )}
      <div className={classes.marginBottom}></div>

      <PruTable
        title={Translation('todolist.table.taskGroup')}
        disableBulkSelect
        disablePagination
        headerBtnDef={[
          {
            color: 'secondary',
            title: Translation('todolist.common.editPosition'),
            onClick: () => {
              onEditPositionData();
            },
            condition: () => {
              return !showHideSave;
            },
          },
          {
            color: 'primary',
            title: Translation('todolist.button.done'),
            onClick: () => {
              onCancelEditPositionData();
            },
            condition: () => {
              return showHideSave;
            },
          },
        ]}
        operationDef={[
          {
            title: Translation('todolist.common.operation.edit'),
            tooltipText: Translation('todolist.common.tooltip.edit'),
            onClick: (row) => onEditTaskGroup(row.key, row.isShow),
            condition: () => {
              return !showHideSave;
            },
          },
          {
            title: (
              <IconButton size={'small'}>
                <ArrowUpwardIcon />
              </IconButton>
            ),
            tooltipText: Translation('todolist.action.move.up'),
            onClick: (row) => onUpwardData(row.key),
            condition: (row) => {
              return showHideSave && row.isShow;
            },
          },
          {
            title: (
              <IconButton size={'small'}>
                <ArrowDownwardIcon />
              </IconButton>
            ),
            tooltipText: Translation('todolist.action.move.down'),
            onClick: (row) => onDownwardData(row.key),
            condition: (row) => {
              return showHideSave && row.isShow;
            },
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'row.key',
            align: 'center',
            displayName: Translation('todolist.taskGroup.titleTable.name'),
            renderData: (row) => (
              <span className={classes.tableFieldName}>
                {Translation(`todolist.taskSection.${row.key}.title`) || '-'}
              </span>
            ),
          },
          {
            keyIndex: 'row.updatedAt',
            align: 'center',
            displayName: Translation('todolist.taskGroup.titleTable.lastUpdate'),
            renderData: (row) => onDisplayDateFormat(row.updatedAt ?? '') || '-',
          },
          {
            keyIndex: 'row.isShow',
            align: 'center',
            displayName: Translation('todolist.taskGroup.titleTable.status'),
            renderData: (row) =>
              row.isShow ? (
                <Chip color="primary" label="Showed" variant="outlined" />
              ) : (
                <Chip className={classes.chipHidden} label="Hidden" variant="outlined" />
              ),
          },
        ]}
        isLoading={isLoading}
        onRefresh={() => refreshData()}
        dataSource={taskValue}
      />
    </>
  );
};

export default TaskGroupTable;
