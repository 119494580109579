import React, { FC, useReducer, useState } from 'react';
import { SurveyFormMode, SurveyDetail } from '../../../../types/survey-types';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { useHistory } from 'react-router-dom';
import { SURVEY_BASE_PATH, SURVEY_MANAGEMENT_PATH } from '../../../../constants';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import SurveyConfigSection from './SurveyConfigSection';
import { useErrorHandler, ErrorFieldType, getDayStart, getDayEnd, preFillZeroList } from 'src/app/common/utils';
import moment from 'moment';
import { designationSequence } from '../../../../../../common/components/DesignationSelectSection';
import SurveyCreatorSection from './SurveyCreatorSection';
import { useIntl } from 'react-intl';
import { SurveyItemBody, createSurvey, modifySurvey } from '../../../../network/surveyCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

type SurveyDetailFormProps = {
  formMode: SurveyFormMode;
  surveyDetail?: SurveyDetail;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
}));

export type SurveyFormState = {
  startDate: Date | null;
  endDate: Date | null;
  designations: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  body?: Record<string, unknown>;
};

const initialState: SurveyFormState = {
  startDate: null,
  endDate: null,
  designations: designationSequence,
  eligibleAgents: [],
  excludedAgents: [],
  body: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof SurveyFormState;
    value: any;
  };
};

type SurveyFormAction = ModifyFieldAction;

const surveyReducer = (state: SurveyFormState, action: SurveyFormAction) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return initialState;
  }
};

const detailToStateConvertor = (surveyDetail?: SurveyDetail): SurveyFormState => {
  return surveyDetail
    ? {
        startDate: surveyDetail.startDate,
        endDate: surveyDetail.endDate,
        designations: surveyDetail.designations,
        eligibleAgents: surveyDetail.eligibleAgents,
        excludedAgents: surveyDetail.excludedAgents,
        body: surveyDetail.body,
      }
    : initialState;
};

export type SurveyErrorState = {
  mandatory: {
    startDate: boolean;
    endDate: boolean;
    body: boolean;
  };
  immediate: {
    endDateBeforeStartDate: boolean;
  };
};

const SurveyDetailForm: FC<SurveyDetailFormProps> = ({ formMode, surveyDetail }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(surveyReducer, detailToStateConvertor(surveyDetail));
  const [tabState, setTabState] = useState<'CONFIG' | 'SURVEY_BODY'>('CONFIG');

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } =
    useErrorHandler<SurveyErrorState>(formState, [
      {
        name: 'startDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'body',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startDate && formState.endDate) {
            return !!moment(new Date(formState.startDate)).isAfter(moment(new Date(formState.endDate)));
          } else {
            return false;
          }
        },
      },
    ]);

  const onNextPage = () => {
    const { currentErrorState } = onSubmitErrorValidator();
    if (
      !currentErrorState.mandatory.startDate &&
      !currentErrorState.mandatory.endDate &&
      !currentErrorState.immediate.endDateBeforeStartDate
    ) {
      setTabState('SURVEY_BODY');
    }
  };

  const onSubmitSurvey = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const surveyBody: SurveyItemBody = {
        startDate: formState.startDate ? getDayStart(formState.startDate) : null,
        endDate: formState.endDate ? getDayEnd(formState.endDate) : null,
        designations: formState.designations,
        eligibleAgents: preFillZeroList(formState.eligibleAgents),
        excludedAgents: preFillZeroList(formState.excludedAgents),
        body: formState.body || {},
      };

      if (formMode === SurveyFormMode.CREATE) {
        await createSurvey(surveyBody, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Survey saved successfully`,
            },
          ]),
        );
      } else if (surveyDetail && formMode === SurveyFormMode.EDIT) {
        await modifySurvey(surveyDetail._id, surveyBody, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Survey updated and published successfully - ${surveyDetail._id}`,
            },
          ]),
        );
      }
      history.push(`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`survey.form.${formMode === SurveyFormMode.CREATE ? 'add' : 'edit'}`)}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => history.push(`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`)}
        >
          {Translation('app.button.back')}
        </Button>
      </div>

      {tabState === 'CONFIG' && (
        <SurveyConfigSection
          formState={formState}
          errorState={errorState}
          onChangeStartDate={(startDate) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'startDate', value: startDate },
            })
          }
          onChangeEndDate={(endDate) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'endDate', value: endDate },
            })
          }
          onChangeDesignations={(designations) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'designations', value: designations },
            })
          }
          onChangeEligibleAgents={(eligibleAgents) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'eligibleAgents', value: eligibleAgents },
            })
          }
          onChangeExcludedAgents={(excludedAgents) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'excludedAgents', value: excludedAgents },
            })
          }
          onDismissErrorHandler={onDismissErrorHandler}
          immediateErrorValidator={immediateErrorValidator}
          onNextPage={onNextPage}
        />
      )}

      {tabState === 'SURVEY_BODY' && (
        <SurveyCreatorSection
          surveyContent={formState.body}
          onChangeSurveyContent={(content) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'body', value: content },
            })
          }
          onPreviousPage={() => setTabState('CONFIG')}
          onSubmit={onSubmitSurvey}
        />
      )}
    </div>
  );
};

export default SurveyDetailForm;
