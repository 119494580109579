import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from '../../../../../../../app/common/utils';
import { Translation } from '../../../../../../../app/i18n/utils/i18n-utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import {
  deleteTemplateById,
  fetchFormTemplateList,
  publishTemplate,
  unpublishTemplate,
} from '../../../../network/form-template-crud';
import { FormTemplateItem, FormTemplateListParam, PaginateList } from '../../../../types/form-template-types';
import moment from 'moment';
import { popupGapReminder } from './utils';

const initialState: FormTemplateListParam = {
  page: 1,
  limit: 20,
  formType: '',
  publishStatus: '',
  formStatus: '',
  version: '',
};

export const formTemplateFilterKeys = [
  'type',
  'validateStartDate',
  'validateEndDate',
  'formStatus',
  'publishStatus',
  'version',
];

const paramsInitiator = (initialParams?: Record<string, string>): FormTemplateListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useFormTemplateListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<FormTemplateListParam>(paramsInitiator(initialParams));
  const [formTemplateList, setFormTemplateList] = useState<PaginateList<FormTemplateItem>>();
  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);

  const { isLoading, refreshData } = useDataProvider<PaginateList<FormTemplateItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchFormTemplateList(filterState, dispatch);
      } catch (err) {}
    },
    setFormTemplateList,
    false,
  );

  const isCheckedGapRef = useRef(false);

  useEffect(() => {
    if (!isCheckedGapRef.current && formTemplateList?.data) {
      isCheckedGapRef.current = true;
      popupGapReminder(formTemplateList.extraData);
    }
  }, [formTemplateList]);

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      const sortBy = value.toUpperCase();
      setFilterState({ ...filterState, sortBy: key, orderBy: sortBy });
    } else {
      setFilterState({ ...filterState, sortBy: undefined, orderBy: undefined });
    }
    refreshData();
  };

  const publish = async (templateId: string) => {
    try {
      await publishTemplate(templateId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('onboarding.common.publishSuccess'),
          },
        ]),
      );
      refreshData();
    } catch (err) {
      const msg = get(err, 'errors.message') || '';
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('menu.account.trigger'),
            content: msg,
          },
        ]),
      );
    }
  };

  const unpublish = async (templateId: string) => {
    try {
      await unpublishTemplate(templateId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('onboarding.common.unpublishSuccess'),
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const deleteTemplate = async (templateId: string) => {
    try {
      await deleteTemplateById(templateId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('onboarding.common.deleteSuccess'),
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };
  const downloadValidityPeriodLog = async (operationHistories: string, version: string) => {
    const blob = new Blob([JSON.stringify(operationHistories)], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `validityLog_v${version}_d${moment(new Date()).format('MMDDYYYY')}.json`;
    link.click();
  };

  return {
    filterState,
    formTemplateList,
    isLoading,
    formTypeOptions,
    deleteTemplate,
    setFilterState,
    refreshData,
    onSort,
    publish,
    unpublish,
    downloadValidityPeriodLog,
  };
};
