import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { deleteAgentLeadSource } from 'src/app/modules/PulseLeads/network/agentCrud';
import { agentLeadSourcePath } from '../../AgentLeadSourceRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { AgentLeadSource } from 'src/app/modules/PulseLeads/types/agent-types';

type AgentLeadSourceListProps = {
  isLoading: boolean;
  agentLeadSourceList?: PaginateList<AgentLeadSource>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AgentLeadSourceList: FC<AgentLeadSourceListProps> = ({
  isLoading,
  agentLeadSourceList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAgentLeadSource(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Lead Source deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  const renderI18nColumn = (regionLocale: RegionLocale[]) =>
    regionLocale.map((locale, index) => ({
      keyIndex: `name-${locale}`,
      displayName: `${Translation('pulseleads.agent.leadSource.name')} (${Translation(`navBar.lang.${locale}`)})`,
      renderData: (row: AgentLeadSource) => {
        if (row.i18nData) {
          return row.i18nData[locale]?.name || '-';
        } else if (
          (regionLocale.includes(RegionLocale.ENGLISH) && locale === RegionLocale.ENGLISH) ||
          (!regionLocale.includes(RegionLocale.ENGLISH) && index === 0)
        ) {
          return row.sourceName || '-';
        }
        return '-';
      },
    }));

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.agent.leadSource.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.agent.leadSource.newSource'),
            onClick: () => history.push(`${agentLeadSourcePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Lead Source',
            onClick: (row) => history.push(`${agentLeadSourcePath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Lead Source',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'image',
            displayName: '',
            renderData: (row) => (
              <div style={{ width: 60, height: 60, overflow: 'hidden', borderRadius: 12 }}>
                <img style={{ width: '100%', height: '100%' }} src={row.image?.url} alt="App Icon" />
              </div>
            ),
          },
          {
            keyIndex: 'parent',
            displayName: Translation('pulseleads.agent.leadSource.parentName'),
            renderData: (row) => (row.parent ? (row.parent as AgentLeadSource).sourceName : '-'),
          },
          ...renderI18nColumn(regionLocale),
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.agent.common.updatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('pulseleads.agent.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.agent.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={agentLeadSourceList?.docs}
        totalPages={agentLeadSourceList?.totalPages}
        totalRecords={agentLeadSourceList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AgentLeadSourceList;
