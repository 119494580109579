import React, { FC, useState, useEffect } from 'react';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';

import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import ScheduleList from './schedule-list';
import { fetchLicenseExamExamScheduleList, fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import { examScheduleParam } from '../../../types/license-exam-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { Box } from '@mui/material';
type ExamScheduleListPageProps = ParamsProps & ModulePermissionProps;

const initialState: examScheduleParam = {
  examType: '',
  examDateFrom: undefined,
  examDateTo: undefined,
  lastRegistrationDateFrom: undefined,
  lastRegistrationDateTo: undefined,
  examCode: '',
  examCentre: '',
  examName: '',
  status: '',
  limit: 5,
  page: 1,
};

export const examScheduleFilterKeys = [
  'examType',
  'examDateTo',
  'examDateFrom',
  'examCode',
  'examCentre',
  'examName',
  'lastRegistrationDateFrom',
  'lastRegistrationDateTo',
];

const paramsInitiator = (initialParams?: Record<string, any>): examScheduleParam => {
  return initialParams
    ? {
        examType: initialParams.examType,
        examDateFrom: initialParams.examDateFrom,
        examDateTo: initialParams.examDateTo,
        examCode: initialParams.examCode || '',
        examCentre: initialParams.examCentre || '',
        examName: initialParams.examName || '',
        status: initialParams.status || '',
        lastRegistrationDateFrom: initialParams.lastRegistrationDateFrom,
        lastRegistrationDateTo: initialParams.lastRegistrationDateTo,
        limit: 5,
        page: 1,
      }
    : initialState;
};

const ScheduleListPage: FC<ExamScheduleListPageProps> = ({
  initialParams,
  onChangeQueryParams,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialParams));
  const [timeSlotsList, setTimeSlotsList] = useState<any>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }>({ key: '', value: '' });

  const locale = useLang();

  useEffect(() => {
    refreshData();
  }, [sortKey]);

  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState as any);
        }
        let { examDateFrom, examDateTo, lastRegistrationDateFrom, lastRegistrationDateTo, status, ...rest } =
          filterState;

        if (examDateFrom) {
          examDateFrom = moment(examDateFrom).format('YYYY-MM-DD');
        }

        if (examDateTo) {
          examDateTo = moment(examDateTo).format('YYYY-MM-DD');
        }

        if (lastRegistrationDateFrom) {
          lastRegistrationDateFrom = moment(lastRegistrationDateFrom).format('YYYY-MM-DD');
        }

        if (lastRegistrationDateTo) {
          lastRegistrationDateTo = moment(lastRegistrationDateTo).format('YYYY-MM-DD');
        }

        if (!status) {
          status = undefined;
        }

        // new add sort
        const reqParams = {
          ...rest,
          status,
          examDateFrom,
          examDateTo,
          lastRegistrationDateFrom,
          lastRegistrationDateTo,
          sortBy: sortKey.key,
          orderBy: sortKey.value,
          lang: locale,
        };
        const res = await fetchLicenseExamExamScheduleList(reqParams, dispatch);
        if (res?.status) {
          const resWithMessage = res as any;
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: resWithMessage.status,
                content: resWithMessage.message,
              },
            ]),
          );
        } else {
          return res;
        }
      } catch (err) {}
    },
    setTimeSlotsList,
    false,
  );

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    if (!sortingKey?.value) {
      sortingKey.key = '';
    }
    setSortKey(sortingKey);
  };

  return (
    <Box className="auto-table-height">
      <PruFilter
        title={Translation('app.button.search')}
        itemDef={[
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'examType',
            initialValue: filterState.examType,
            displayName: Translation('recruitment.exam.examType'),
            fetchList: async () => {
              const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
              return res.map((item) => ({
                displayName: item || '-',
                value: item || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'examDateFrom',
            fieldTo: 'examDateTo',
            initialDateFrom: filterState.examDateFrom,
            initialDateTo: filterState.examDateTo,
            displayName: Translation('recruitment.exam.examDate'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'examCode',
            initialValue: filterState.examCode,
            displayName: Translation('recruitment.exam.examCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'examCentre',
            initialValue: filterState.examCentre,
            displayName: Translation('recruitment.exam.examCentre'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'examName',
            initialValue: filterState?.examName || '',
            displayName: Translation('recruitment.exam.examName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState?.status || '',
            displayName: Translation('recruitment.exam.status'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('recruitment.exam.status.ACTIVATE'), value: 'ACTIVATE' },
              { displayName: Translation('recruitment.exam.status.CANCELLED'), value: 'CANCELLED' },
              { displayName: Translation('recruitment.exam.status.COMPLETED'), value: 'COMPLETED' },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastRegistrationDateFrom',
            fieldTo: 'lastRegistrationDateTo',
            initialDateFrom: filterState.lastRegistrationDateFrom,
            initialDateTo: filterState.lastRegistrationDateTo,
            displayName: Translation('recruitment.exam.last_register_time'),
          },
        ]}
        onChangeFilter={(filter) => {
          setFilterState({
            ...filterState,
            examType: filter.examType,
            examDateFrom: filter.examDateFrom,
            examDateTo: filter.examDateTo,
            examCode: filter.examCode || '',
            examCentre: filter.examCentre || '',
            examName: filter.examName || '',
            status: filter.status || '',
            lastRegistrationDateFrom: filter.lastRegistrationDateFrom,
            lastRegistrationDateTo: filter.lastRegistrationDateTo,
          });
        }}
        fetchData={refreshData}
      />
      <ScheduleList
        isLoading={isLoading}
        timeSlotsList={timeSlotsList}
        enableCreate={enableCreate}
        enableUpdate={enableUpdate}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onRefresh={refreshData}
        onSort={(dataKey: any) => updateSortingKey(dataKey)}
      />
    </Box>
  );
};
export default ScheduleListPage;
