import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Dialog } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef } from 'src/app/common/types';
import { toAbsoluteUrl, fileUpload } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import { useStyles } from './upload-area.style';

type UploadAreaProps = {
  id: string;
  module: string;
  accessLevel: 'public' | 'private' | 'anonymous';
  disabled?: boolean;
  multiple?: boolean;
  error?: boolean;
  enableImageRatioReminder?: boolean;
  accept: string;
  sources: AttachmentDef[];
  maxFileNum?: number;
  maxFileSize: number;
  maxFileSizeUnit: 'KB' | 'MB';
  onChange: (file: AttachmentDef) => void;
  onRemove: (sourceIndex: number) => void;
};

const acceptErrMsgMapping: Record<string, string> = {
  'image/jpeg': 'jpg/jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'video/mp4': 'mp4',
};

const getUploadAreaConfig = (isMultiple: boolean, Translation: (id: string) => string) => ({
  uploadIconUrl: toAbsoluteUrl(isMultiple ? '/media/icon/upload_more.png' : '/media/icon/upload_attachment.png'),
  uploadText: Translation(isMultiple ? 'common.upload_more' : 'common.click_to_upload'),
});

export const UploadArea: FC<UploadAreaProps> = ({
  id,
  module,
  accessLevel,
  disabled,
  sources,
  multiple,
  error,
  enableImageRatioReminder,
  accept,
  maxFileNum,
  maxFileSize,
  maxFileSizeUnit,
  onChange,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showImageRatioReminder, setShowImageRatioReminder] = useState(false);
  const { classes } = useStyles({ isUploading });

  const showUpload = !disabled && (multiple || sources.length === 0) && sources.length !== maxFileNum;
  const { uploadIconUrl, uploadText } = getUploadAreaConfig(!!(multiple && sources.length > 0), Translation);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let errMsg = '';
      const acceptFileTypes = accept.split(',');
      if (!acceptFileTypes.includes(file.type)) {
        errMsg = Translation('global.max.file.type.allow', {
          num: acceptFileTypes.map((fileType) => acceptErrMsgMapping[fileType] || fileType.replace('.', '')).join('/'),
        });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }
      const fileSize = maxFileSizeUnit === 'KB' ? file.size / 1024 : file.size / 1024 / 1024;
      if (fileSize > maxFileSize) {
        errMsg = Translation('global.max.file.size.allow');
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }
      try {
        setIsUploading(true);
        const createBlobRes = await createBlob({ mimeType: file.type, accessLevel, module }, dispatch);
        await fileUpload(createBlobRes.url, file);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onChange({ blobId: result.blobId, filename: file.name, url: result.url, type: file.type });
          if (enableImageRatioReminder && file.type.startsWith('image/')) {
            const img = new Image();
            img.onload = () => {
              const aspectRatio = img.naturalWidth / img.naturalHeight;
              if (aspectRatio !== 2) {
                setShowImageRatioReminder(true);
              }
            };
            img.src = result.url;
          }
        }
      } catch (err) {
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} open={showImageRatioReminder}>
        <div className={classes.reminderContent}>
          <div className={classes.reminderTitle}>{Translation('reminder_title')}</div>
          <div>{Translation('common.upload_area.img_ratio_reminder')}</div>
        </div>
        <div className={classes.reminderImg}>
          <img src={'/media/icon/ratio_reminder_example.png'} alt="example" />
        </div>
        <div className={classes.reminderFooter}>
          <Button className={classes.blueText} onClick={() => setShowImageRatioReminder(false)}>
            {Translation('common.got_it')}
          </Button>
        </div>
      </Dialog>
      <div>
        <div className={classes.container}>
          {sources.map((source, sourceIndex: number) => {
            const filePreview = source.type?.includes('image') ? (
              <img className={classes.imgPreview} src={source.url} alt="image" />
            ) : (
              <div className={classes.filePreview}>
                <span> {source.filename}</span>
              </div>
            );
            return (
              <div className={classes.contentContainer} key={sourceIndex}>
                {filePreview}
                <div className={classes.viewButton}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={() => window.open(source.url, '_blank')}
                  >
                    {Translation('app.button.view')}
                  </Button>
                  <Button
                    disabled={disabled}
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={() => onRemove(sourceIndex)}
                  >
                    {Translation('app.button.remove')}
                  </Button>
                </div>
              </div>
            );
          })}
          {showUpload && (
            <div className={classes.contentContainer}>
              <div
                className={`${classes.uploadArea} ${error ? classes.uploadAreaError : undefined}`}
                onClick={() => document.getElementById(id)?.click()}
              >
                <input
                  id={id}
                  hidden
                  type="file"
                  accept={accept}
                  onClick={(e) => {
                    if (isUploading) {
                      e.preventDefault();
                    } else {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }
                  }}
                  onChange={handleFile}
                />
                <img className={classes.uploadIcon} src={uploadIconUrl} alt={'Upload attachment'} />
                <div>{uploadText}</div>
              </div>
            </div>
          )}
        </div>
        {error && <div className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</div>}
      </div>
    </>
  );
};
