import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  ROLE_CONTROL_TITLE,
  ROLE_CONTROL_BASE_PATH,
  AdminRolePermissionType,
  ROLE_PERMISSION_TITLE,
  ROLE_PERMISSION_NAMESPACE,
  ROLE_CONTROL_PERMISSION_PATH,
  ROLE_CONTROL_USER_MANAGEMENT_PATH,
  ROLE_USER_MANAGEMENT_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import PermissionRoutes from './pages/PermissionRoutes';
import RoleUserManagementRoutes from './pages/role-user-management/role-user-management-routes';

export const roleControlModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    disableRoleControl: true,
    title: Translation(ROLE_CONTROL_TITLE),
    path: ROLE_CONTROL_BASE_PATH,
    icon: '/media/svg/icons/Communication/Shield-user.svg',
    enableRead: isPermitted(
      [
        AdminRolePermissionType.ROLE_PERMISSION_READ,
        AdminRolePermissionType.ROLE_PERMISSION_CREATE,
        AdminRolePermissionType.ROLE_PERMISSION_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(ROLE_PERMISSION_TITLE),
        path: `${ROLE_CONTROL_BASE_PATH}${ROLE_CONTROL_PERMISSION_PATH}`,
        namespace: ROLE_PERMISSION_NAMESPACE,
        component: PermissionRoutes,
        enableRead: isPermitted(
          [
            AdminRolePermissionType.ROLE_PERMISSION_READ,
            AdminRolePermissionType.ROLE_PERMISSION_CREATE,
            AdminRolePermissionType.ROLE_PERMISSION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRolePermissionType.ROLE_PERMISSION_CREATE], permissions),
        enableUpdate: isPermitted([AdminRolePermissionType.ROLE_PERMISSION_UPDATE], permissions),
      },
      {
        title: Translation('roleControl.title.userManagement'),
        path: `${ROLE_CONTROL_BASE_PATH}${ROLE_CONTROL_USER_MANAGEMENT_PATH}`,
        namespace: ROLE_USER_MANAGEMENT_NAMESPACE,
        component: RoleUserManagementRoutes,
        enableRead: isPermitted(
          [
            AdminRolePermissionType.ROLE_PERMISSION_READ,
            AdminRolePermissionType.ROLE_PERMISSION_CREATE,
            AdminRolePermissionType.ROLE_PERMISSION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRolePermissionType.ROLE_PERMISSION_CREATE], permissions),
        enableUpdate: isPermitted([AdminRolePermissionType.ROLE_PERMISSION_UPDATE], permissions),
      },
    ],
  };
};
