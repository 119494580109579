import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { deleteCampaignFeedback } from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';
import { campaignFeedbackPath } from '../../CampaignFeedbackRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignFeedbackItem } from 'src/app/modules/PulseLeads/types/campaign-feedback-types';

type CampaignFeedbackListProps = {
  isLoading: boolean;
  campaignFeedbackList?: PaginateList<CampaignFeedbackItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const CampaignFeedbackList: FC<CampaignFeedbackListProps> = ({
  isLoading,
  campaignFeedbackList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteCampaignFeedback(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Feedback deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  const renderI18nColumn = (regionLocale: RegionLocale[]) =>
    regionLocale.map((locale, index) => ({
      keyIndex: `name-${locale}`,
      displayName: `${Translation('pulseleads.campaignFeedback.common.name')} (${Translation(
        `navBar.lang.${locale}`,
      )})`,
      renderData: (row: CampaignFeedbackItem) => {
        if (row.i18nData) {
          return row.i18nData[locale]?.name || '-';
        } else if (
          (regionLocale.includes(RegionLocale.ENGLISH) && locale === RegionLocale.ENGLISH) ||
          (!regionLocale.includes(RegionLocale.ENGLISH) && index === 0)
        ) {
          return row.name || '-';
        }
        return '-';
      },
    }));

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.campaignFeedback.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.campaignFeedback.newFeedback'),
            onClick: () => history.push(`${campaignFeedbackPath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Feedback',
            onClick: (row) => history.push(`${campaignFeedbackPath}/edit/${row._id}`),
          },
          // {
          //   title: Translation("section.common.operation.delete"),
          //   tooltipText: "Delete Feedback",
          //   onClick: (row) => setDialogState({ open: true, id: row._id })
          // }
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          ...renderI18nColumn(regionLocale),
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={campaignFeedbackList?.docs}
        totalPages={campaignFeedbackList?.totalPages}
        totalRecords={campaignFeedbackList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default CampaignFeedbackList;
