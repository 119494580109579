import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { I18nGenericData } from 'src/app/i18n';
import { PaginateList, AttachmentDef } from 'src/app/common/types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { TargetApplicantTypeEnum } from 'src/app/common/components/target-applicant-section-v2';
import {
  IncentiveTypeEnum,
  IncentiveDetail,
  IncentiveHighlight,
  IncentiveAward,
  IncentiveListItem,
} from '../types/incentive-types';

const incentiveEndpoint = 'v2/incentives';

export type IncentiveListParam = {
  type: IncentiveTypeEnum;
  code: string;
  name: string;
  status: string;
  createdDateFrom: Date | null;
  createdDateTo: Date | null;
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  isArchived: string;
  page: number;
  limit: number;
};

export type CreateUpdateIncentiveBody = {
  type: IncentiveTypeEnum;
  code: string;
  name: I18nGenericData<string>;
  startDate: Date | null;
  endDate: Date | null;
  detail: I18nGenericData<string>;
  objective?: I18nGenericData<string>;
  bulletin?: I18nGenericData<string>;
  award: I18nGenericData<string>;
  incentiveImage?: I18nGenericData<AttachmentDef>;
  bulletinFile?: I18nGenericData<AttachmentDef>;
  highlights?: IncentiveHighlight[];
  awards?: IncentiveAward[];
  stickOnTop: boolean;
  publishDate: Date | null;
  archiveDate: Date | null;
  targetApplicantType?: TargetApplicantTypeEnum;
  eligibleDesignations?: string[];
  eligibleSegments?: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];
  agentListFile?: AttachmentDef | null;
  // Incentive
  updateSet?: boolean;
  // Incentive Set
  subIncentiveIds?: string[];
  isParallelAwards?: boolean;
};

export type UploadResultBody = {
  blobId: string;
  filename: string;
  resultDate: string;
};

export const createNewIncentive = async (data: CreateUpdateIncentiveBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${incentiveEndpoint}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyIncentive = async (
  code: string,
  data: CreateUpdateIncentiveBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${incentiveEndpoint}/${code}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishIncentive = async (code: string, updateSet?: boolean, dispatch?: Dispatch<any>): Promise<void> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${incentiveEndpoint}/${code}/publish?`, { updateSet });
  return apiClient
    .patch<void>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishIncentive = async (code: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${incentiveEndpoint}/${code}/unpublish`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadIncentiveCsvResultByBlobId = async (
  submitData: UploadResultBody,
  code: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${incentiveEndpoint}/${code}/results`, submitData)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveItem = async (code: string, dispatch?: Dispatch<any>): Promise<IncentiveDetail> => {
  return apiClient
    .get<IncentiveDetail>(`${ADMIN_URL}/${incentiveEndpoint}/${code}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveList = async (
  params: IncentiveListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<IncentiveListItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${incentiveEndpoint}?`, params);
  return apiClient
    .get<PaginateList<IncentiveListItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveAgentTarget = async (code: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${incentiveEndpoint}/${code}/agents/targets`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveResultTemplate = async (code: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${incentiveEndpoint}/${code}/results/template`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
