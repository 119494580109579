import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PaginateList } from 'src/app/common/types/common-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { EligibilityRule } from 'src/app/modules/PulseLeads/types/eligibility-rule-types';
import { deleteEligibilityRule } from 'src/app/modules/PulseLeads/network/eligibilityRuleCrud';
import { eligibilityRulePath } from '../../EligibilityRuleRoutes';

type EligibilityRuleListProps = {
  isLoading: boolean;
  eligibilityRuleList?: PaginateList<EligibilityRule>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const EligibilityRuleList: FC<EligibilityRuleListProps> = ({
  isLoading,
  eligibilityRuleList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteEligibilityRule(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Eligibility Rule deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-eligibility-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.eligibilityRule.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.eligibilityRule.newRule'),
            onClick: () => history.push(`${eligibilityRulePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Eligibility Rule',
            onClick: (row) => history.push(`${eligibilityRulePath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Eligibility Rule',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('pulseleads.eligibilityRule.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'noOfAgents',
            align: 'center',
            displayName: Translation('pulseleads.eligibilityRule.common.noOfAgents'),
            renderData: (row) =>
              row.listOfAgents && row.listOfAgents.length > 0 ? String(row.listOfAgents.length) : '-',
          },
          {
            keyIndex: 'description',
            align: 'center',
            displayName: Translation('pulseleads.eligibilityRule.common.description'),
            renderData: (row) => row.description || '-',
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.eligibilityRule.common.updatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('pulseleads.eligibilityRule.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.eligibilityRule.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={eligibilityRuleList?.docs}
        totalPages={eligibilityRuleList?.totalPages}
        totalRecords={eligibilityRuleList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default EligibilityRuleList;
