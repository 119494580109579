import React, { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { RootState } from 'src/redux/store';
import { appendAlertItem, AlertType, AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { ProductCampaignItem, ProductCampaignOptions } from 'src/app/modules/PulseLeads/types/product-campaign-types';
import {
  CreateProductCampaignBody,
  UpdateProductCampaignBody,
  createProductCampaign,
  modifyProductCampaign,
} from 'src/app/modules/PulseLeads/network/productCampaignCrud';
import { productCampaignPath } from '../../ProductCampaignRoutes';

type ProductCampaignFormProps = {
  formMode: CampaignMappingFormMode;
  productCampaignDropdownList?: CampaignDropdownList;
  productCampaignOptions?: ProductCampaignOptions;
  productCampaignItem?: ProductCampaignItem;
  config?: any;
};

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type ProductCampaignFormState = {
  productCode?: string;
  campaign?: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
};

const initialState: ProductCampaignFormState = {
  productCode: undefined,
  campaign: undefined,
  product: undefined,
  interestedProductPlan: undefined,
  leadDetail: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ProductCampaignFormState;
    value: any;
  };
};

type ProductCampaignFormAction = ModifyFieldAction;

const formReducer = (state: ProductCampaignFormState, action: ProductCampaignFormAction): ProductCampaignFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (productCampaignItem?: ProductCampaignItem): ProductCampaignFormState => {
  return productCampaignItem
    ? {
        productCode: productCampaignItem.productCode,
        campaign: productCampaignItem.campaign,
        product: productCampaignItem.product,
        interestedProductPlan: productCampaignItem.interestedProductPlan,
        leadDetail: productCampaignItem.leadDetail,
      }
    : initialState;
};

const getFieldConfig = (config: any) => {
  return {
    enableProductCampaignOptions: config?.content?.Sales?.campaign?.enableProductCampaignOptions ? true : false,
  };
};

const ProductCampaignForm: FC<ProductCampaignFormProps> = ({
  formMode,
  productCampaignDropdownList,
  productCampaignOptions,
  productCampaignItem,
  config,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(productCampaignItem));
  const fieldConfig = getFieldConfig(config);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'productCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'campaign',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === CampaignMappingFormMode.CREATE) {
        const body: CreateProductCampaignBody = {
          productCode: formState.productCode || '',
          campaign: formState.campaign || '',
          product: formState.product,
          interestedProductPlan: formState.interestedProductPlan,
          leadDetail: formState.leadDetail,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createProductCampaign(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Product Campaign saved successfully',
              },
            ]),
          );
          history.push(productCampaignPath);
        } catch (err) {}
      } else if (productCampaignItem) {
        const body: UpdateProductCampaignBody = {
          productCode: formState.productCode || '',
          campaign: formState.campaign || '',
          product: formState.product,
          interestedProductPlan: formState.interestedProductPlan,
          leadDetail: formState.leadDetail,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyProductCampaign(productCampaignItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Product Campaign updated successfully - ${productCampaignItem._id}`,
              },
            ]),
          );
          history.push(productCampaignPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.straightThrough.productCampaign.form.title.${
                formMode === CampaignMappingFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(productCampaignPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.productCode')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.productCode}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.productCode && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.productCode}
              onChange={(e) => {
                onDismissErrorHandler('productCode', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'productCode', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.campaignName')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.campaign} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.campaign || ''}
              onChange={(e) => {
                onDismissErrorHandler('campaign', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'campaign', value: e.target.value } });
              }}
            >
              {productCampaignDropdownList &&
                productCampaignDropdownList[CampaignDropdownType.CAMPAIGN] &&
                productCampaignDropdownList[CampaignDropdownType.CAMPAIGN].map((dropdownItem) => (
                  <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                    {dropdownItem.value}
                  </MenuItem>
                ))}
            </Select>
            {errorState.mandatory.campaign && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>
        {fieldConfig.enableProductCampaignOptions && (
          <>
            {productCampaignOptions?.products && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.matchedProduct')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.product || ''}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'product', value: e.target.value } });
                    }}
                  >
                    {productCampaignOptions.products.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {productCampaignOptions?.interestedProductPlans && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.interestedProduct')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.interestedProductPlan || ''}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'interestedProductPlan', value: e.target.value },
                      });
                    }}
                  >
                    {productCampaignOptions.interestedProductPlans.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {productCampaignOptions?.leadDetails && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.otherLeadComments')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.leadDetail || ''}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'leadDetail', value: e.target.value } });
                    }}
                  >
                    {productCampaignOptions.leadDetails.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        )}
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default ProductCampaignForm;
