import de from './de.json';
import baseEn from './en.json';
import es from './es.json';
import fr from './fr.json';
import ja from './ja.json';
import zhHans from './zh-Hans.json';
import baseZhHant from './zh_HK.json';
import id from './id.json';
import vn from './vn.json';
import { learningEn, learningZh } from '@pruforce/learning-adminweb-sdk';
import { schedulerEn, schedulerZh } from '@pruforce/scheduler-adminweb-sdk';

const en = {...learningEn, ...schedulerEn, ...baseEn};
const zhHant = {...learningZh, ...schedulerZh, ...baseZhHant};

export { de, en, es, fr, ja, zhHans, zhHant, id, vn };
