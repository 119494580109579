import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: '#ffffff',
  },
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  dataContainer: {
    borderBottomWidth: 3,
    borderColor: '#F0F0F0',
    marginBottom: 25,
  },
  dataHeader: {
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#333333',
    color: '#FFFFFF',
    padding: 18,
  },
}));
