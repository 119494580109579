import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useRoleUserManagementListingPage } from './role-user-management-listing-page.hook';
import { RoleUserManagementList } from './components/role-user-management-list.component';

type RoleUserManagementListingPageProps = ParamsProps;

const UserStatusMap = {
  all: {
    displayName: 'component.status.all',
    value: '',
  },
  enable: {
    displayName: 'roleControl.userManagement.status.enable',
    value: 'ENABLED',
  },
  disable: {
    displayName: 'roleControl.userManagement.status.disable',
    value: 'DISABLED',
  },
};

export const RoleUserManagementListingPage: FC<RoleUserManagementListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { filterState, roleUserManagementList, isLoading, setFilterState, refreshData, onSort } =
    useRoleUserManagementListingPage({
      initialParams,
      onChangeQueryParams,
    });

  return (
    <>
      <PruFilter
        title={Translation('roleControl.title.userManagement.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'userName',
            initialValue: filterState.userName,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            choices: map(UserStatusMap, (value, key) => ({
              value: value.value,
              displayName: Translation(value.displayName),
            })),
            displayName: Translation('component.formLabel.status'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldTo: 'updatedTo',
            fieldFrom: 'updatedFrom',
            displayName: Translation('roleControl.userManagement.filter.lastUpdatedDate'),
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            ...data,
          });
        }}
        fetchData={refreshData}
      />
      <RoleUserManagementList
        isLoading={isLoading}
        roleUserManagementList={roleUserManagementList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
      />
    </>
  );
};
