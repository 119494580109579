import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types';
import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { getQueryPath } from 'src/app/common/utils';

export type RoleUserManagementListParam = {
  userName?: string;
  status?: string;
  updatedFrom?: string;
  updatedTo?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export type CreateRoleUserManagementItem = {
  userId?: string;
  userName: {
    [lang: string]: string;
  };
  status: string;
  password?: string;
};

export type RoleUserManagementItem = {
  id: string;
  status: string;
  userName: {
    [lang: string]: string;
  };
  userId: string;
};

export const fetchRoleUserManagementList = async (
  params: RoleUserManagementListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<RoleUserManagementItem>> => {
  let queryPath = getQueryPath(`${ADMIN_URL}/user-management/users?`, params);
  // let queryPath = `http://localhost:3008/user-management/users?`;
  return apiClient
    .get<PaginateList<RoleUserManagementItem>>(queryPath)
    .then((response: any) => {
      return {
        docs: response.data.data,
        ...response.data.meta,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getUserDetail = async (userId: string, dispatch?: Dispatch<any>): Promise<RoleUserManagementItem> => {
  let queryPath = `${ADMIN_URL}/user-management/users/${userId}`;
  // let queryPath = `http://localhost:3008/user-management/users/${userId}`;
  return apiClient
    .get<PaginateList<RoleUserManagementItem>>(queryPath)
    .then((response: any) => {
      return response.data?.data ?? {};
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateUserDetail = async (
  userId: string,
  body: CreateRoleUserManagementItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const queryPath = `${ADMIN_URL}/user-management/users/${userId}`;
  return apiClient
    .patch<void>(queryPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createUser = async (body: CreateRoleUserManagementItem, dispatch?: Dispatch<any>): Promise<void> => {
  const queryPath = `${ADMIN_URL}/user-management/users`;
  return apiClient
    .post<void>(queryPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
