import React, { FC, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import { ApplicationFormEditHistoryListParam, PaginateList } from '../../../types/edit-history-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler, ErrorFieldDef, getDayEnd, getDayStart } from 'src/app/common/utils';
import { FormControl, Select, FormHelperText, Button } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import PruFilter, {
  PruFilterAsyncDropdownDef,
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from 'src/app/common/components/PruTable/PruFilter';
import AsyncAutocomplete from 'src/app/common/components/AsyncAutocomplete';
import {
  fetchOnboardingTrainingQuiz,
  fetchOnboardingTrainingQuizReport,
} from '../../../network/onboarding-course-crud';
import { useLang } from 'src/app/i18n';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { handleDowloadFileError } from '../../../utils';

type QuizReportExportPageProps = ParamsProps;

type PruFilterState = {
  [id: string]: any;
};

const DATE_ERROR_TEXT = 'End date must be after Begin date';

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    value: Array<Date | null> | string | Date | null;
  };
};

const filterReducer = (state: PruFilterState, action: ModifyFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
  }
};

type registrationFormState = {
  type?: string;
  name?: string;
  startDate: Date | null;
  endDate: Date | null;
};

const initialState: registrationFormState = {
  type: undefined,
  name: undefined,
  startDate: null,
  endDate: null,
};

const errorFieldConvertor = (fields: PruFilterItemDef[]): ErrorFieldDef[] => {
  const errorFieldDef: ErrorFieldDef[] = [];
  fields.forEach((field) => {
    if (field) {
      errorFieldDef.push({
        name: field.displayName,
        fieldType: ErrorFieldType.MANDATORY,
      });
    }
  });
  return errorFieldDef;
};

type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef | PruFilterAsyncDropdownDef;

const QuizReportExportPage: FC<QuizReportExportPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const locale = useLang();
  const [formState, filterDispatch] = useReducer(filterReducer, initialState);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes: commonClasses } = useCommonStyles();
  const fieldsColumn: PruFilterItemDef[] = [
    {
      type: PruFilterItemType.ASYNC_DROPDOWN,
      field: 'quizData',
      initialValue: formState.quizData,
      displayName: Translation('recruitment.quiz.name'),
      fetchList: async () => {
        const res = await fetchOnboardingTrainingQuiz(dispatch);
        return res.map((item: any) => ({
          displayName: item.quizName?.[locale] || '-',
          value: item || '-',
        }));
      },
    },
    {
      type: PruFilterItemType.DATE_RANGE,
      fieldFrom: 'startDate',
      fieldTo: 'endDate',
      initialDateFrom: formState.startDate,
      initialDateTo: formState.endDate,
      displayName: Translation('recruitment.common.dateRange'),
    },
  ];

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formState,
    errorFieldConvertor(fieldsColumn),
  );

  const handleExport = async () => {
    const quizData = formState.quizData;
    const extraParams = {
      startDate: formState.startDate,
      endDate: formState.endDate,
      currLang: locale,
      lessonId: quizData?.lessonId,
    };
    try {
      await fetchOnboardingTrainingQuizReport(extraParams, quizData?.courseId, quizData?.id);
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  };

  return (
    <div className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-h-1/2 tw-w-full">
      <div className="tw-flex tw-items-center tw-p-1">
        <div className="tw-w-full tw-flex">
          <div className={commonClasses.header}>{Translation('recruitment.training.quizReportExport.title')}</div>
        </div>
      </div>

      {fieldsColumn.map((item, index) => {
        if (item.type === PruFilterItemType.ASYNC_DROPDOWN)
          return (
            <div key={`export-${item.type}-${item.field}`}>
              <div className="tw-flex tw-items-center tw-m-4">
                <span className="tw-text-base tw-mr-1">{item.displayName}</span>
                <div className="tw-ml-3">
                  <FormControl error={errorState.mandatory[item.field]} variant="outlined" margin="dense">
                    <AsyncAutocomplete
                      className="tw-min-w-full tw-w-52"
                      value={formState.quizData || ''}
                      onChange={(value) => {
                        onDismissErrorHandler(item.field, value);
                        filterDispatch({
                          type: 'CHANGE_FILTER',
                          payload: { field: item.field, value: value },
                        });
                      }}
                      getData={item.fetchList}
                    ></AsyncAutocomplete>
                    {errorState.mandatory.distributionChannel && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          );
        else if (item.type === PruFilterItemType.DATE_RANGE)
          return (
            <div key={`export-${item.type}-${index}-${item.fieldFrom}-${item.fieldTo}`}>
              <div className="tw-flex tw-items-center tw-m-4">
                <span className="tw-text-base tw-mr-1">{item.displayName}</span>
                <PruDatePicker
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={formState.startDate}
                  onChange={(date) =>
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: getDayStart(date!) },
                    })
                  }
                />
                <div className="tw-h-1 tw-w-5 tw-ml-3 tw-mr-3">
                  <div className="tw-h-1/5 tw-w-full tw-bg-gray-900" />
                </div>
                <PruDatePicker
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                      helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                      style: { marginRight: 20 },
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={formState[item.fieldTo as string]}
                  onChange={(date) => {
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(date!) },
                    });
                  }}
                />
              </div>
            </div>
          );
      })}

      <div className="tw-absolute tw-right-8 tw-top-1/2 tw-transform tw--translate-y-1/2">
        <Button variant="contained" color="secondary" onClick={handleExport}>
          {Translation('button_export')}
        </Button>
      </div>
    </div>
  );
};
export default QuizReportExportPage;
