import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { MTmode } from 'src/app/modules/MandatoryTraining/constants';
import { useStyles } from '../style';
import { createMicrosite, updateMicrosite } from 'src/app/modules/lead-microsite/network/api';
import { useHistory, useParams } from 'react-router-dom';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { LEAD_MICROSITE_BASE_PATH, MICROSITE_BASE_PATH } from 'src/app/modules/lead-microsite/constants';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { activeType } from 'src/app/modules/lead-microsite/types/microsite.type';
import SurveyCreator, { CREATE_MODE } from 'src/app/common/components/microsite-survey-form/survey-creator';

type PageTwoDetailProps = {
  formMode: MTmode;
  data: any;
  setData: (value: any) => void;
  originData: any;
  onPrevPage: () => void;
};

const PageTwoDetail: FC<PageTwoDetailProps> = ({ formMode, data, setData, originData, onPrevPage }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // loading not in use
  // const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  // const reminderChecked = localStorage.getItem('reminderChecked') ?? false;
  const setSurvey = (surveyJson: any) => {
    const temp = { ...data };
    temp.inputFieldsConfig = surveyJson;
    setData(temp);
  };
  // latest survey json, including not saved content
  const refSurveyJson = useRef<Record<string, unknown>>();

  const onUpdate = async (data: any) => {
    await updateMicrosite(id, data);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Successfully',
        },
      ]),
    );
    history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`);
  };

  const campaignIdHandle = async (allData: any) => {
    if (allData.campaignId === originData.campaignId) {
      await onUpdate(allData);
      return;
    }

    const description =
      data.status === activeType(intl).Active.value
        ? Translation('microsite_campaign_change_des1')
        : Translation('microsite_campaign_change_des2');

    const handleOption1 = async () => {
      if (data.status === activeType(intl).Active.value) {
        // keep old link
        const newValue = { ...allData, keepOriginActive: true };
        await onUpdate(newValue);
      }
      // click cancel to go back
      history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`);
    };

    const handleOption2 = async () => {
      // keep old link
      let newValue = { ...allData, keepOriginActive: true };
      if (data.status === activeType(intl).Active.value) {
        // drop old link
        newValue = { ...allData, keepOriginActive: false };
      }
      await onUpdate(newValue);
      // const handleActive = async () => {
      //   const newValue = { ...allValue, keepOriginActive: false };
      //   await onUpdate(newValue);
      // };
      // const handleInactive = async () => {
      //   const newValue = { ...allValue, keepOriginActive: true };
      //   await onUpdate(newValue);
      // };
      // data.status === activeType(intl).Active.value ? handleActive() : handleInactive();
    };

    const option1Text =
      data.status === activeType(intl).Active.value
        ? Translation('microsite_campaign_change_confirm')
        : Translation('global.text.cancel');

    const option2Text =
      data.status === activeType(intl).Active.value
        ? Translation('microsite_campaign_change_cancel')
        : Translation('global.text.confirm');

    await ModalService.options({
      title: Translation('microsite_campaign_change_title'),
      description,
      handleOption1,
      handleOption2,
      option1Text,
      option2Text,
    });
  };

  /** save form */
  const onSave = async () => {
    // data: page1 + page2
    let allData = data;
    if (refSurveyJson.current) {
      // survey modified content (may not saved)
      allData = { ...data, inputFieldsConfig: refSurveyJson.current };
    }
    // return console.log('allData=====:', allData)
    try {
      if (formMode === MTmode.EDIT) {
        await campaignIdHandle(allData);
      } else {
        await createMicrosite(allData);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Submit Successfully',
            },
          ]),
        );
        history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`);
      }
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
      return;
    }
  };

  return (
    <div className="tw-bg-white tw-w-full tw-h-full">
      <div className="tw-flex tw-pt-6">
        <div className={classes.subHeader}>{Translation('microsite_input_fields_setting')}</div>
      </div>

      <div className={classes.ml_24}>
        <span className={classes.notifyFont}>{Translation('agent_referral_link_inputField_recommend')}</span>
      </div>
      <div className="tw-h-5/6">
        <SurveyCreator
          content={data?.inputFieldsConfig}
          onSavedContent={(surveyJson: any) => {
            setSurvey(surveyJson);
          }}
          onModifiedContent={(surveyJson: any) => {
            refSurveyJson.current = surveyJson;
          }}
          mode={CREATE_MODE.CUSTOM}
        />
      </div>
      <div className="tw-flex tw-pb-6 tw-items-center tw-justify-center tw-pt-6 tw-overflow-y-auto">
        <Button variant="contained" color="secondary" onClick={() => onPrevPage()}>
          {Translation('app.button.previous')}
        </Button>

        <span className={classes.ml_24}>
          <Button variant="contained" color="secondary" id="btn-submit-form" onClick={onSave}>
            {Translation('app.button.submit')}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default PageTwoDetail;
