import React, { FC, useState } from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { ApplicationItem } from '../../../types/application-types';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import { APPLICATION_BASE_PATH } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { deleteApplication } from '../../../network/applicationCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';

type ApplicationListProps = {
  isLoading: boolean;
  applicationList?: ApplicationItem[];
  onRefresh: () => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const ApplicationList: FC<ApplicationListProps> = ({ isLoading, applicationList, onRefresh }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteApplication(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Application deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        disableBulkSelect
        disablePagination
        dataSource={applicationList}
        isLoading={isLoading}
        onRefresh={onRefresh}
        title={Translation('application.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('application.newApplication'),
            onClick: () => history.push(`${APPLICATION_BASE_PATH}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Application',
            onClick: (row) => history.push(`${APPLICATION_BASE_PATH}/edit/${row.id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Application',
            onClick: (row) => setDialogState({ open: true, id: row.id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'iconUrl',
            displayName: '',
            renderData: (row) => (
              <div style={{ width: 60, height: 60, overflow: 'hidden', borderRadius: 12 }}>
                <img style={{ width: '100%', height: '100%' }} src={row.iconUrl} alt="App Icon" />
              </div>
            ),
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: Translation('application.common.name'),
            renderData: (row) => row.name,
          },
          {
            keyIndex: 'targetVersion',
            align: 'center',
            displayName: Translation('application.common.version'),
            renderData: (row) => row.targetVersion,
          },
          {
            keyIndex: 'isPublic',
            align: 'center',
            displayName: Translation('application.common.accessLevel'),
            renderData: (row) => (row.isPublic ? 'PUBLIC' : 'PRIVATE'),
          },
          {
            keyIndex: 'role',
            align: 'center',
            displayName: Translation('application.common.role'),
            style: {
              maxWidth: 200,
            },
            renderData: (row) => (
              <>
                {row.role &&
                  row.role.map((role: string) => (
                    <Chip style={{ marginRight: 5, marginBottom: 5 }} key={role} label={role} />
                  ))}
              </>
            ),
          },
          {
            keyIndex: 'tags',
            align: 'center',
            displayName: Translation('application.common.tags'),
            style: {
              maxWidth: 200,
            },
            renderData: (row) => (
              <>
                {row.tags &&
                  row.tags.map((tag: string) => (
                    <Chip style={{ marginRight: 5, marginBottom: 5 }} key={tag} label={tag} />
                  ))}
              </>
            ),
          },
          {
            keyIndex: 'hardUpdateDate',
            align: 'center',
            displayName: Translation('application.common.forceUpdateDate'),
            renderData: (row) => getDefaultDisplayDate(row.hardUpdateDate),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('application.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('application.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
          },
        ]}
      />
    </>
  );
};

export default ApplicationList;
