import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PaginateList } from 'src/app/common/types'; // might differ based on the api response
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { RoleUserManagementItem } from '../../../../network/role-user-management-crud'; // might differ based on the api response
import { useLang } from 'src/app/i18n';
import { getDefaultDisplayDate, onDisplayDateFormat } from 'src/app/common/utils';
import { useHistory } from 'react-router-dom';
import { roleUserManagementPath } from '../../role-user-management-routes';

type RoleUserManagementListProps = {
  isLoading: boolean;
  roleUserManagementList?: PaginateList<RoleUserManagementItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

const STATUS_TRANSLATION_MAP: any = {
  ENABLED: 'roleControl.userManagement.status.enable',
  DISABLED: 'roleControl.userManagement.status.disable',
};

export const RoleUserManagementList: FC<RoleUserManagementListProps> = ({
  isLoading,
  roleUserManagementList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lang = useLang();
  const history = useHistory();

  const onEditClick = (row: RoleUserManagementItem) => {
    history.push(`${roleUserManagementPath}/edit/${row.userId}`);
  };

  const onAddClick = () => {
    history.push(`${roleUserManagementPath}/create/`);
  };

  return (
    <PruTable
      title={Translation('roleControl.title.userManagement.list')}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('roleControl.userManagement.list.add'),
          onClick: onAddClick,
          condition: () => {
            return true;
          }, // Add button display condition here
        },
      ]}
      operationDef={[
        // {
        //   title: Translation('section.common.operation.view'),
        //   tooltipText: 'View',
        //   onClick: (row) => {},
        // },
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit',
          onClick: (row) => {
            onEditClick(row);
          },
          condition: () => {
            return true;
          }, // Add button display condition here
        },
      ]}
      columnDef={[
        // Add columns here
        {
          isId: true,
          hidden: true,
          keyIndex: 'id',
          displayName: '',
          renderData: (row: any) => row.id,
          // sortable: true,
          // onSort: (sort) => {
          //   onSort({ key: '', value: sort[''] });
          // },
        },
        {
          keyIndex: 'id',
          displayName: Translation('roleControl.userManagement.list.userId'),
          renderData: (row: any) => row.userId,
        },
        {
          keyIndex: 'userName',
          displayName: Translation('roleControl.userManagement.list.name'),
          renderData: (row: any) => row.userName[lang] ?? '',
        },
        {
          keyIndex: 'createdAt',
          displayName: Translation('roleControl.userManagement.list.createdDate'),
          renderData: (row: any) => getDefaultDisplayDate(row.createdAt, 'datetime'),
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('roleControl.userManagement.list.lastUpdatedDate'),
          renderData: (row: any) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('roleControl.userManagement.list.updatedBy'),
          renderData: (row: any) => row.lastUpdatedBy,
        },
        {
          keyIndex: 'status',
          displayName: Translation('component.formLabel.status'),
          renderData: (row: any) => Translation(STATUS_TRANSLATION_MAP[row.status]),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={roleUserManagementList?.docs}
      totalPages={roleUserManagementList?.totalPages}
      totalRecords={roleUserManagementList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};
