import { map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { TokenInfo } from '@pruforce/common-adminweb-sdk';
import { regionReportParams } from 'src/app/modules/PruleadInsightsReporting/types/report-types';
import RegionReportDetailPage from './components/RegionReportDetailPage';
import { useJWT } from 'src/app/common/utils';
const ExportJsonExcel = require('js-export-excel');

type RegionReportListingPageProps = {} & ParamsProps;
type selectFilterType = {
  businessUnit: string[];
  region: string[];
  mda: string[];
  branch: string[];
  unit: string[];
  segment: string[];
  channel: string[];
};

const initialState: regionReportParams = {
  businessUnit: '',
  region: '',
  branch: '',
  unit: '',
  mda: '',
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
  segment: '',
  channel: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): regionReportParams => {
  return initialParams
    ? {
        businessUnit: '',
        region: '',
        branch: '',
        unit: '',
        mda: '',
        dateRange: {
          startDate: undefined,
          endDate: undefined,
        },
        segment: '',
        channel: '',
      }
    : initialState;
};

export const regionReportFilterKeys = [];

const RegionReportListingPage: FC<RegionReportListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const [filterState, setFilterState] = useState<regionReportParams>({
    ...paramsInitiator(initialParams),
    region,
    channel,
  });
  const [chartData, setChartData] = useState<{ fields: object; documents: object[] }>();

  const [selectFilter, setSelectFilter] = useState<selectFilterType>({
    businessUnit: [],
    mda: [],
    branch: [],
    region: [],
    unit: [],
    segment: [],
    channel: [],
  });

  const downloadExcel = async () => {
    let sheetHeader: any[] = [];
    let sheetData: any[] = [];

    if (chartData) {
      type fieldsType = keyof typeof chartData.fields;

      let fields = chartData.fields;
      let fieldsKey: string[] = [];

      for (let key in fields) {
        fieldsKey.push(key as fieldsType);
        sheetHeader.push(fields[key as fieldsType]);
      }

      //adjust the order of data : group -> group_series_(index) -> value
      chartData.documents.forEach((doc: any) => {
        let tmpDoc: Record<string, string> = {};
        fieldsKey.forEach((key) => {
          tmpDoc[key] = doc[key] ?? '';
        });
        sheetData.push(tmpDoc);
      });
      // over

      var option = {
        fileName: Translation('insights.dashboard.filter.aolRegionBranch'),
        datas: [
          {
            sheetData: sheetData,
            sheetName: 'sheet',
            sheetHeader: sheetHeader,
          },
        ],
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
  };

  return (
    <>
      <PruFilter
        title={Translation('insights.dashboard.filter.aolRegionBranch')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'businessUnit',
            displayName: 'Business Unit',
            initialValue: filterState.businessUnit,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.businessUnit, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            displayName: 'Region',
            initialValue: filterState.region,
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'branch',
            displayName: 'Branch',
            initialValue: filterState.branch,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.branch, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'unit',
            displayName: 'Unit',
            initialValue: filterState.unit,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.unit, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'mda',
            displayName: 'MDA',
            initialValue: filterState.mda,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.mda, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: 'Date',
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'segment',
            displayName: 'Segment',
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.segment, (option: string) => ({ displayName: option, value: option })),
            ],
            initialValue: filterState.segment,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
            initialValue: filterState.channel,
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            businessUnit: data.businessUnit,
            region: data.region,
            branch: data.branch,
            unit: data.unit,
            mda: data.mda,
            dateRange: {
              startDate: data.startDate,
              endDate: data.endDate,
            },
            segment: data.segment,
            channel: data.channel,
          });
        }}
        fetchData={() => {}}
        downloadExcel={downloadExcel}
      />
      <RegionReportDetailPage
        filter={filterState}
        selectFilter={setSelectFilter}
        updateData={(data) => setChartData(data)}
      />
    </>
  );
};

export default RegionReportListingPage;
