import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { useStyles } from '../../style';

import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { MTmode } from 'src/app/modules/lead-microsite/constants';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import SurveyCreator from 'src/app/common/components/microsite-survey-form/survey-creator';
import { initJson } from 'src/app/common/components/microsite-survey-form/survey-json';
import { updateARLConfig } from '../../network/api';

type PageTwoDetailProps = {
  configs: any[];
  setConfigs: (value: any[]) => void;
  index: number;
  onPrevPage: () => void;
};

const PageTwoDetail: FC<PageTwoDetailProps> = ({ configs, setConfigs, index, onPrevPage }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const formMode = configs[index].formMode ?? MTmode.VIEW;
  // const reminderChecked = localStorage.getItem('reminderChecked') ?? false;
  // latest survey json, including not saved content
  const refSurveyJson = useRef<Record<string, unknown>>();

  useEffect(() => {
    // set default value for inputFields
    if (!configs[index]?.inputFields) {
      const newData = [...configs];
      newData[index].inputFields = initJson;
      setConfigs(newData);
    }
  }, []);

  const onSave = async () => {
    let allValue = configs;
    if (refSurveyJson.current) {
      // survey modified content (may not saved)
      allValue[index].inputFields = refSurveyJson.current;
    }
    try {
      await updateARLConfig(configs[index]._id, allValue[index]);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Submit successfully',
          },
        ]),
      );
      const newConfigs = configs;
      newConfigs[index].formMode = MTmode.VIEW;
      setConfigs(newConfigs);
      onPrevPage();
      return true;
    } catch (error) {
      console.log(error);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
      return false;
    }
  };

  return (
    <div className="tw-bg-white tw-w-full tw-h-full">
      <div className="tw-flex tw-mt-6">
        <div className={classes.subHeader}>{Translation('agent_referral_link_input_field_setting')}</div>
      </div>

      <div className={classes.ml_24}>
        <span className={classes.notifyFont}>{Translation('agent_referral_link_inputField_recommend')}</span>
      </div>

      <div className="tw-h-5/6">
        <SurveyCreator
          content={configs[index]?.inputFields}
          onSavedContent={(surveyJson: any) => {
            const newConfigs = [...configs];
            newConfigs[index].inputFields = surveyJson;
            setConfigs(newConfigs);
          }}
          onModifiedContent={(surveyJson: any) => {
            refSurveyJson.current = surveyJson;
          }}
        />
      </div>

      <div className="tw-flex tw-pb-6 tw-pt-6 tw-items-center tw-justify-center tw-overflow-y-auto">
        <Button variant="contained" color="secondary" onClick={() => onPrevPage()}>
          {Translation('app.button.previous')}
        </Button>

        <span className={classes.ml_24}>
          <Button variant="contained" color="secondary" onClick={onSave}>
            {Translation('app.button.submit')}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default PageTwoDetail;
