import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useCommonStyles } from '../../../../common/styles/common-styles';
import { useIntl } from 'react-intl';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useErrorHandler, ErrorFieldType, fileSizeConvertorMb } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../constants';
import { createDefaultTarget, downloadDefaultTemplate } from '../../network/productionCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import AsyncCsvLink from '../../../../common/components/AsyncCsvLink';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadRowContainer: {
    width: '100%',
    display: 'flex',
  },
  fieldContainer: {
    width: 180,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type FormState = {
  file: File | null;
  year: Date | null;
};

type ErrorState = {
  mandatory: {
    file: boolean;
    year: boolean;
  };
  immediate: {};
};

const DefaultTargetPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, setFormState] = useState<FormState>({
    file: null,
    year: null,
  });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler<ErrorState>(formState, [
    {
      name: 'file',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'year',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      onDismissErrorHandler('file', file);
      setFormState({
        ...formState,
        file,
      });
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const year = String(formState.year?.getFullYear());
      try {
        await createDefaultTarget(formState.file!, year, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Default Target set successfully`,
            },
          ]),
        );
      } catch (err) {}
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={commonClasses.header}>{Translation('title.productionDefaultTarget')}</div>
      </div>
      <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('productionDefaultTarget.common.year')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <PruDatePicker
          slotProps={{
            textField: {
              error: errorState.mandatory.year,
              helperText: errorState.mandatory.year && MANDATORY_FIELD_ERROR_TEXT,
            },
          }}
          views={['year']}
          disableFuture
          format="YYYY"
          value={formState.year}
          onChange={(e) => {
            onDismissErrorHandler('year', e);
            setFormState({
              ...formState,
              year: e,
            });
          }}
        />
      </div>
      <div style={{ marginBottom: 10 }} className={classes.uploadRowContainer}>
        <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('productionDefaultTarget.common.uploadDefaultTarget')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <input id="upload-default-target-csv" hidden type="file" accept=".csv" onChange={handleFile} />
          <div className={classes.rowContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-default-target-csv')!.click()}
            >
              {Translation('app.button.chooseFile')}
            </Button>
            <div style={{ marginLeft: 10 }}>
              {formState.file
                ? `${formState.file.name} - ${fileSizeConvertorMb(formState.file.size)}`
                : 'No file chosen'}
            </div>
            {errorState.mandatory.file && (
              <span style={{ marginLeft: 10 }} className={classes.errorText}>
                {MANDATORY_FIELD_ERROR_TEXT}
              </span>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
        <AsyncCsvLink
          isDisabled={false}
          filename={`default-target-template.csv`}
          dataParser={(str) => str}
          asyncCall={() => downloadDefaultTemplate(dispatch)}
        >
          <Button variant="contained" color="secondary">
            Download Template
          </Button>
        </AsyncCsvLink>
      </div>
      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.save')}
        </Button>
      </div>
    </div>
  );
};

export default DefaultTargetPage;
