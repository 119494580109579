import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  designationRow: {
    display: 'flex',
  },
  fieldContainer: {
    width: 130,
    boxSizing: 'border-box',
  },
  textInput: {
    width: 400,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
}));
