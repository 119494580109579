import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CampaignListParam,
  CampaignPaginateList,
  CampaignTypeItem,
  CampaignTypeListParam,
} from '../../../types/staff-campaign-types';
import { WorkflowRoleTypeEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { RootState } from '../../../../../../redux/store';
import { AuthenticationState, TokenInfo } from '@pruforce/common-adminweb-sdk';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { toAbsoluteUrl, useDataProvider } from 'src/app/common/utils';
import { fetchCampaignList, fetchCampaignTypeList } from '../../../network/staffCampaignCrud';
import { fetchUserRole } from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import CampaignList from './CampaignList';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle, TextField } from '@mui/material';
import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import CampaignTypeSelectForm from './components/CampaignTypeSelectForm';
import { RouteComponentProps } from 'react-router';
import {
  AgencyCampaignTypeStatusEnum,
  AGENCY_CAMPAIGN_BASE_PATH,
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH,
} from '../../../constants';
import FilterSelectForm from './components/FilterSelectForm';
import { useIntl } from 'react-intl';

type CampaignListingPageProps = {} & ParamsProps & RouteComponentProps;

const initialState: CampaignListParam = {
  limit: 20,
  page: 1,
  name: '',
  createdBy: '',
};

const paramsInitiator = (initialParams?: Record<string, string>, user?: TokenInfo): CampaignListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
        name: initialParams.name ?? '',
        createdBy: user?.username ?? '',
      }
    : {
        ...initialState,
        createdBy: user?.username ?? '',
      };
};

const useStyles = makeStyles()(() => ({
  searchInput: {
    height: 46,
    padding: 0,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
}));

const AgencyCampaignListPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH}`;

const CampaignListPage: FC<CampaignListingPageProps> = ({ initialParams, onChangeQueryParams, history }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const [filterState, setFilterState] = useState<CampaignListParam>(paramsInitiator(initialParams, user));
  const [campaignTypeFilter, setCampaignTypeFilter] = useState<CampaignTypeListParam>({
    roleType: WorkflowRoleTypeEnum.STAFF,
    roles: [''],
  });
  const [campaignListData, setCampaignListData] = useState<CampaignPaginateList>();
  const [campaignTypeList, setCampaignTypeList] = useState<CampaignTypeItem[]>();
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [filterModalVisible, handleFilterModalVisiable] = useState<boolean>(false);
  const [filterCampaignType, setFilterCampaignType] = useState<string[]>([]);
  const [filterCampaignStatus, setFilterCampaignStatus] = useState<AgencyCampaignTypeStatusEnum[]>([]);
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const { isLoading, refreshData } = useDataProvider<CampaignPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCampaignList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setCampaignListData,
    false,
  );

  const [isLoadingUserRole, setIsLoadingUserRole] = useState<boolean>(true);
  const [userRoleList, setUserRoleList] = useState<string[]>([]);
  const loadUserRoleList = async () => {
    const result = await fetchUserRole({ type: 'STAFF', user: user?.sub || '' });
    setUserRoleList(result);
    setCampaignTypeFilter({ ...campaignTypeFilter, roles: result });
    setIsLoadingUserRole(false);
    campaignTypeProvider.refreshData();
  };

  const onSorter = () => {
    let updateList = campaignTypeList;
    if (updateList !== undefined && updateList?.length > 0) {
      if (direction === 'asc') {
        updateList = updateList.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
        });
        setCampaignTypeList(updateList);
        setDirection('desc');
      } else {
        updateList = updateList.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
        setCampaignTypeList(updateList);
        setDirection('asc');
      }
    }
  };

  useEffect(() => {
    loadUserRoleList();
  }, []);

  const campaignTypeProvider = useDataProvider<CampaignTypeItem[]>(
    async () => {
      if (!isLoadingUserRole) {
        try {
          return (await fetchCampaignTypeList(campaignTypeFilter, dispatch)).sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
          });
        } catch (err) {}
      }
    },
    setCampaignTypeList,
    false,
  );

  const onTypeSelected = (data: any) => {
    handleModalVisible(false);
    history.push(`${AgencyCampaignListPath}/create/${data}`);
  };

  const onFilterSelected = (data: {
    filterCampaignType: string[];
    filterCampaignStatus: AgencyCampaignTypeStatusEnum[];
  }) => {
    setFilterCampaignType(data.filterCampaignType);
    setFilterCampaignStatus(data.filterCampaignStatus);
    setFilterState({
      ...filterState,
      status: data.filterCampaignStatus.length > 0 ? data.filterCampaignStatus : undefined,
      campaignType: data.filterCampaignType.length > 0 ? data.filterCampaignType : undefined,
    });
    handleFilterModalVisiable(false);
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  useEffect(() => {
    refreshData();
  }, [filterState, sortKey]);

  return (
    <div style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ display: 'flex', flex: 2, flexDirection: 'row', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: 8 }}>
          <TextField
            style={{
              background: '#F0F0F0',
              borderRadius: 50,
              minWidth: 200,
              paddingLeft: 10,
              height: 40,
              marginBottom: 0,
              marginTop: 0,
              fontFamily: 'Poppins, Helvetica, "sans-serif"',
            }}
            margin="dense"
            fullWidth
            placeholder="Search here"
            InputProps={{
              startAdornment: (
                <>
                  <SearchOutlined></SearchOutlined>
                </>
              ),
              classes: { input: classes.searchInput },
              disableUnderline: true,
            }}
            value={filterState.name}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                name: e.target.value,
              });
            }}
          ></TextField>
          <>
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 15 }}
              onClick={() => {
                handleFilterModalVisiable(true);
              }}
            >
              <img
                style={{ height: '20px', width: '20px' }}
                alt="logo"
                src={toAbsoluteUrl('/media/icon/filter2.png')}
              />
            </div>
          </>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', padding: 8 }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ width: 200 }}
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            {Translation('agencyCampaign.common.addNewCampaign')}
          </Button>
        </div>
      </div>
      <CampaignList
        isLoading={isLoading}
        campaignList={campaignListData}
        campaignTypeList={campaignTypeList}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page: page,
            limit: rowsPerPage,
          });
        }}
      />

      <Dialog
        open={createModalVisible}
        onClose={() => {
          handleModalVisible(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.common.newCampaign')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              handleModalVisible(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        {campaignTypeList ? (
          <CampaignTypeSelectForm
            onSave={onTypeSelected}
            onCancel={() => {
              handleModalVisible(false);
            }}
            confirmLoading={true}
            initialValues={campaignTypeList}
          />
        ) : null}
      </Dialog>
      <Dialog
        open={filterModalVisible}
        onClose={() => {
          handleFilterModalVisiable(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.dialog.filter')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              handleFilterModalVisiable(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        {campaignTypeList ? (
          <FilterSelectForm
            onSave={onFilterSelected}
            onCancel={() => {
              handleFilterModalVisiable(false);
            }}
            confirmLoading={true}
            initialValues={{ filterCampaignType: filterCampaignType, filterCampaignStatus: filterCampaignStatus }}
            campaignTypeList={campaignTypeList}
            onSort={onSorter}
            isSort={true}
            direction={direction}
          />
        ) : null}
      </Dialog>
    </div>
  );
};

export default CampaignListPage;
