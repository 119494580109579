import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { appendAlertItem, AlertType, TokenInfo } from '@pruforce/common-adminweb-sdk';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { approvalPath } from '../../ApprovalRoutes';
import {
  ApprovalCampaignItem,
  AgencyCampaignStatusEnum,
  WorkflowApprovalStatusEnum,
} from 'src/app/modules/AgencyCampaign/types/approval-types';
import { modifyApproval, UpdateApprovalBody } from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import ApprovalDialog from '../../components/ApprovalDialog';

type ApprovalListProps = {
  isLoading: boolean;
  filterStatus: string;
  approvalList?: PaginateList<ApprovalCampaignItem>;
  user?: TokenInfo | undefined;
  userRoleList: string[];
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

type ApprovalDialogState = {
  open: boolean;
  _id: string | undefined;
  campaignId: string | undefined;
  newStatus: WorkflowApprovalStatusEnum | undefined;
};

const initialDialogState: ApprovalDialogState = {
  open: false,
  _id: undefined,
  campaignId: undefined,
  newStatus: undefined,
};

const ApprovalList: FC<ApprovalListProps> = ({
  isLoading,
  filterStatus,
  approvalList,
  user,
  userRoleList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<ApprovalDialogState>(initialDialogState);
  const onCancel = () => {
    setDialogState(initialDialogState);
  };
  const onConfirm = async (comment: string) => {
    if (dialogState._id && dialogState.newStatus) {
      const body: UpdateApprovalBody = {
        assignee: user?.sub || '',
        assigneeName: user?.username || '',
        role: userRoleList,
        agencyCampaignId: dialogState._id,
        status: dialogState.newStatus,
        comment: comment,
      };
      await modifyApproval(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign Approval status updated successfully`,
          },
        ]),
      );
      onRefresh();
      onCancel();
    }
  };

  return (
    <>
      {dialogState._id !== undefined && dialogState.campaignId !== undefined && dialogState.newStatus !== undefined && (
        <ApprovalDialog
          open={dialogState.open}
          campaignId={dialogState.campaignId}
          newStatus={dialogState.newStatus}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
      <PruTable
        title={Translation('agencyCampaign.approval.list')}
        headerBtnDef={[]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Campaign',
            onClick: (row) => history.push(`${approvalPath}/view/${row._id}`),
          },
          ...(filterStatus === AgencyCampaignStatusEnum.PENDING
            ? [
                {
                  title: Translation('section.common.operation.approve'),
                  tooltipText: 'Approve Campaign',
                  onClick: (row: ApprovalCampaignItem) =>
                    setDialogState({
                      open: true,
                      _id: row._id,
                      campaignId: row.campaignId,
                      newStatus: WorkflowApprovalStatusEnum.APPROVED,
                    }),
                },
                {
                  title: Translation('section.common.operation.reject'),
                  tooltipText: 'Reject Campaign',
                  onClick: (row: ApprovalCampaignItem) =>
                    setDialogState({
                      open: true,
                      _id: row._id,
                      campaignId: row.campaignId,
                      newStatus: WorkflowApprovalStatusEnum.REJECTED,
                    }),
                },
              ]
            : []),
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('agencyCampaign.common.campaignName'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'campaignId',
            displayName: Translation('agencyCampaign.common.campaignId'),
            renderData: (row) => row.campaignId || '-',
          },
          {
            keyIndex: 'campaignType',
            displayName: Translation('agencyCampaign.common.campaignType'),
            renderData: (row) => row.agencyCampaign.typeId?.name || '-',
          },
          {
            keyIndex: 'applicant',
            align: 'center',
            displayName: Translation('agencyCampaign.common.applicant'),
            renderData: (row) => row.agencyCampaign.applicant.applicantName || '-',
          },
          {
            keyIndex: 'submitTime',
            align: 'center',
            displayName: Translation('agencyCampaign.common.submitTime'),
            renderData: (row) => getDefaultDisplayDate(row.agencyCampaign.submitDate, 'datetime'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'submitTime', value: sort['submitTime'] });
            },
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={approvalList?.docs}
        totalPages={approvalList?.totalPages}
        totalRecords={approvalList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default ApprovalList;
