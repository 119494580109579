import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { FormMode } from 'src/app/common/types';
import {
  formatBytes,
  getDefaultDisplayDate,
  getLocalDateFormat,
  useJWT,
  fileUpload,
  getConfigurations,
  getEnabledCustomWeight,
} from 'src/app/common/utils';
import { SALESKIT_BASE_PATH, COMPANYPICK_PATH, COMPANYPICK_RESOURCE } from 'src/app/modules/sales-kit/constants';
import { styles, useStyles } from '../../style/styles';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';
import Form from 'src/app/common/components/Form';
import { createBlob, getBlob } from 'src/app/common/network';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import CloseIcon from '@mui/icons-material/Close';
import DateTimeRangePicker from 'src/app/common/components/DateTimeRangePicker';
import moment from 'moment';
import { PublishStatus } from 'src/app/modules/sales-kit/components/Library/constants';
import MaterialArea from './components/MaterialArea';
import { ModuleTypeList, ModuleTypeRoute } from './components/MaterialArea/types';
import { CompanyPickDetail, PublishedMode } from '../../type/types';
import {
  createCompanyPick,
  fetchCompanyPickDetail,
  fetchCompanyPickWeights,
  getCustomerSurveyById,
  getMaterialById,
  updateCompanyPick,
} from '../../network/api';
import { regionLocale } from 'src/app/i18n';
import { useLang } from 'src/app/i18n';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';
import { TranslationWithParams } from 'src/app/i18n';
import { get } from 'lodash';

const DetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const enableRecruitLead = !!getConfigurations()?.Sales?.lead?.enableRecruitLead;
  const formMode = id ? FormMode.EDIT : FormMode.CREATE;
  const Translation = (id: string) => intl.formatMessage({ id });
  const [form] = Form.useForm();
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [bannerData, setBannerData] = useState<any>();
  const [publishItems, SetPublishItems] = useState<any[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
  const [weights, setWeights] = useState<number[]>([]);
  const data = useRef<any>();
  const enabledCustomWeight = getEnabledCustomWeight();
  const jwt = useJWT();
  const regionLocales: any[] = regionLocale;
  const currentLocale = useLang();
  useEffect(() => {
    const fetchWeights = async () => {
      const result = await fetchCompanyPickWeights();
      setWeights(result);
    };
    fetchWeights();
    SetPublishItems([PublishStatus.Draft, PublishStatus.Published]);
  }, []);
  useEffect(() => {
    const fetchDetail = async () => {
      const res = await fetchCompanyPickDetail(id);
      data.current = res;
      if (!res.uploadFile) {
        res.uploadFile = res.coverpage;
      }
      form.setFieldsValue(res);
      setBannerData(res.coverpage);
      switch (res.type) {
        case ModuleTypeList.Sales:
        case ModuleTypeList.Promotion:
        case ModuleTypeList.Recruit:
          const material = await getMaterialById(res.resourceId);
          setSelectedMaterial({
            name: material.name,
            module: res.type,
            resourceId: res.resourceId,
            effectiveDate: material.effectiveDate,
            expiredDate: material.expiredDate,
          });
          break;

        case ModuleTypeList.CustomerSurvey:
          const survey = await getCustomerSurveyById(res.resourceId);
          setSelectedMaterial({
            name: survey.body.title,
            module: res.type,
            resourceId: res.resourceId,
            effectiveDate: survey.startDate,
            expiredDate: survey.endDate,
          });
          break;

        default:
          setSelectedMaterial({
            module: res.type,
          });
          break;
      }
      if (data.current?.status === PublishStatus.Draft.key) {
        SetPublishItems([PublishStatus.Draft, PublishStatus.Published]);
      } else {
        SetPublishItems([PublishStatus.Published, PublishStatus.Unpublished]);
      }
    };
    if (id) {
      fetchDetail();
    }
  }, [id]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const TranslationWithVariable = (key: string) => intl.formatMessage({ id: key });
      const errMsg = TranslationWithVariable('global.max.file.size.allow');
      const size = formatBytes(file.size);

      const overSize = (size.type === 1 && size.value > 5120) || (size.type === 2 && size.value > 5);
      if (overSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }

      try {
        const createBlobRes = await createBlob(
          {
            mimeType: file.type,
            accessLevel: 'public',
            module: COMPANYPICK_RESOURCE,
            originalFilename: file.name,
          },
          dispatch,
        );

        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

        const result = blobDetail[0];

        if (result) {
          const coverpage = {
            ...bannerData,
            [locale]: {
              id: result.blobId,
              fileName: result.blobName,
              url: result.url,
            },
          };
          const newContent = {
            ...form.getFieldsValue(true),
            coverpage,
          };
          setBannerData(coverpage);
          form.setFieldsValue(newContent);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };
  const DATE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (!value?.[0] || !value?.[1]) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }
        if (
          (selectedMaterial?.effectiveDate && moment(value[0]).isBefore(moment(selectedMaterial.effectiveDate))) ||
          (selectedMaterial?.expiredDate && moment(value[1]).isAfter(moment(selectedMaterial.expiredDate)))
        ) {
          return Promise.reject(
            new Error(
              TranslationWithParams(`saleskit.companyPick.dateRangeValidation`, {
                startTime: getDefaultDisplayDate(new Date(selectedMaterial.effectiveDate)),
                endTime: getDefaultDisplayDate(new Date(selectedMaterial.expiredDate)),
              }),
            ),
          );
        }
        if (value && value[0] && value[1]) {
          if (moment(value[0]).isAfter(moment(value[1]))) {
            return Promise.reject(new Error(Translation('component.form-date-compare')));
          }
        }
        return Promise.resolve();
      },
    },
  ];

  const WEIGHT_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (!value) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }
        const numberRegex = /^[+]?(\d*[1-9]\d*|\d+\.\d*[1-9])$/;
        if (!numberRegex.test(value)) {
          return Promise.reject(new Error(Translation('value.need.greater.zero.msg')));
        }
        if (weights.includes(Number(value)) && Number(value) !== data.current?.weight) {
          return Promise.reject(new Error(Translation('saleskit.companyPick.weight.isUsed')));
        }
        return Promise.resolve();
      },
    },
  ];

  const onDeleteMatieral = () => {
    setSelectedMaterial(null);
  };

  const onSelectMaterial = (data: any) => {
    setSelectedMaterial(data);

    const dateRange: any = {};
    if (data.effectiveDate) {
      dateRange.effectiveRange = [
        moment(data.effectiveDate || data.startDate),
        data.expiredDate ? moment(data.expiredDate || data.endDate) : null,
      ];
    } else if (data.expiredDate) {
      dateRange.effectiveRange = [null, moment(data.expiredDate || data.endDate)];
    }
    if (Object.keys(dateRange).length !== 0) {
      form.setFieldsValue({ effectiveRange: dateRange.effectiveRange });
    }

    form.setFieldsValue({
      resourceType: data.module,
      resourceId: (data._id || data.id || '').toString() ?? data.module,
      uniqueId: data?.uuid ?? data?._id,
    });
  };
  const convertFormValue = () => {
    const allValue = form.getFieldsValue(true);

    let newItem: Partial<CompanyPickDetail>;
    const materials: any = [];
    regionLocales.forEach((locale) => {
      newItem = {};
      newItem.coverpage = allValue.coverpage[locale];
      newItem.status = allValue.status;
      newItem.weight = allValue.weight;
      newItem.effectiveDate = allValue.effectiveRange?.[0];
      newItem.expiredDate = allValue.effectiveRange?.[1];
      newItem.linkTo = ModuleTypeRoute[allValue.resourceType];
      newItem.linkType = allValue?.linkType ?? 'app';
      newItem.linkParam = allValue?.linkParam ?? { id: allValue?.resourceId };
      newItem.resourceId = allValue?.resourceId ?? '';
      newItem.updatedAt = new Date();
      newItem.lastUpdatedBy = jwt?.username ?? '';
      newItem.type = allValue.resourceType;
      newItem.name = allValue.name[locale];
      newItem.locale = locale;
      newItem.uniqueId = allValue.uniqueId ?? '';
      materials.push(newItem);
    });

    return materials;
  };

  const onSubmit = async () => {
    const currentWeights = await fetchCompanyPickWeights();
    setWeights(currentWeights);
    await form.validateFields();
    if (formMode === FormMode.EDIT) {
      await onEdit();
    } else {
      await onCreate();
    }
  };
  const onEdit = async () => {
    const materials = convertFormValue();
    try {
      const currentMaterial = materials.filter((item: any) => item.locale === currentLocale)[0];
      const responseLang1 = await updateCompanyPick(id, currentMaterial);
      if (data.current?.localizations?.id) {
        const responseLang2 = await updateCompanyPick(
          data.current.localizations.id,
          materials.filter((item: any) => item.locale === data.current.localizations?.locale)[0],
        );
      }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Submit successfully',
          },
        ]),
      );
      toList();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'ERROR',
            content: 'Submit failed',
          },
        ]),
      );
    }
  };

  const onCreate = async () => {
    const materials = convertFormValue();
    try {
      const response = await createCompanyPick(materials[0], regionLocales[0]);
      for (let i = 1; i < regionLocales.length; i++) {
        await createCompanyPick(materials[i], regionLocales[i], response.id);
      }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Submit successfully',
          },
        ]),
      );
      toList();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'ERROR',
            content: 'Submit failed',
          },
        ]),
      );
    }
  };

  const toList = () => {
    history.goBack();
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`saleskit.companyPick.${formMode === FormMode.CREATE ? FormMode.CREATE : FormMode.EDIT}`)}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => history.push(`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/list`)}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      <Form form={form} initialValues={{ status: PublishedMode.DRAFT }}>
        <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-pl-12">
          <div className="tw-text-xl tw-font-bold"> {'Material'}</div>

          <div className="tw-mt-6 tw-mb-6 tw-w-full">
            {regionLocales.map((locale, index) => {
              return (
                <Form.Item
                  name={['name', `${locale}`]}
                  label={`${Translation('saleskit.companyPick.list.name')} (${locale.toUpperCase()})`}
                  rules={[{ required: true, message: Translation('saleskit.companyPick.name.validation') }]}
                  key={locale}
                >
                  <TextField margin="dense" variant="outlined" style={styles.textWidth} />
                </Form.Item>
              );
            })}
          </div>

          <div className="tw-mt-6 tw-mb-6">
            {regionLocales.map((locale, index) => {
              return (
                <div key={locale} className="tw-mb-6">
                  <Form.Item
                    name={['uploadFile', locale]}
                    label={`${Translation('saleskit.companyPick.image')} (${locale.toUpperCase()})`}
                    rules={[{ required: true, message: Translation('saleskit.companyPick.image.validation') }]}
                  >
                    <div className="tw-flex tw-flex-row ">
                      <div>
                        {bannerData?.[locale]?.url && (
                          <div className="tw-flex tw-flex-row">
                            <div className={classes.imageContainer}>
                              <img
                                className={classes.imageDisplayArea}
                                src={bannerData?.[locale]?.url}
                                alt="App Icon"
                              />
                            </div>
                            <span>
                              <CloseIcon
                                className="tw-ml-8"
                                onClick={() => {
                                  const coverpage = { ...bannerData, [locale]: { id: '', fileName: '', url: '' } };
                                  setBannerData(coverpage);
                                  const newData = {
                                    ...form.getFieldsValue(true),
                                    coverpage,
                                  };
                                  form.setFieldsValue(newData);
                                }}
                              />
                            </span>
                          </div>
                        )}
                        <div className={classes.rowContainer}>
                          <div className="tw-relative tw-w-full">
                            <div className="tw-flex tw-items-center">
                              <>
                                {!bannerData?.[locale]?.url && (
                                  <div>
                                    <input
                                      id={`upload-companyPick-image-${locale}`}
                                      hidden
                                      type="file"
                                      accept="image/jpeg, image/png, image/jpg"
                                      onClick={(e) => {
                                        const element = e.target as HTMLInputElement;
                                        element.value = '';
                                      }}
                                      onChange={(e) => handleFile(e, locale)}
                                    />
                                    <Button
                                      key={locale}
                                      variant="contained"
                                      color="secondary"
                                      onClick={() =>
                                        document.getElementById(`upload-companyPick-image-${locale}`)!.click()
                                      }
                                    >
                                      {Translation('section.common.operation.upload')}
                                    </Button>
                                  </div>
                                )}
                                {!bannerData?.[locale]?.url && (
                                  <div className="tw-ml-3">{Translation('saleskit.companyPick.coverpage.tips')}</div>
                                )}

                                {!!imageUploadProgress && (
                                  <LinearProgress
                                    className={classes.mt10}
                                    variant="determinate"
                                    color="secondary"
                                    value={imageUploadProgress}
                                  />
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              );
            })}
          </div>

          <div>
            <Form.Item
              name="resourceType"
              label={Translation('saleskit.companyPick.redirection')}
              rules={[{ required: true, message: Translation('app.input.placeholder.please-select') }]}
            >
              <MaterialArea
                form={form}
                onDeleteMatieral={onDeleteMatieral}
                onSelectMaterial={onSelectMaterial}
                selectedMaterial={selectedMaterial}
              />
            </Form.Item>
          </div>
        </div>

        <div className="tw-bg-white tw-mt-6 tw-pt-6 tw-pb-6 tw-pl-12">
          <div className="tw-text-xl tw-font-bold tw-mb-6">{Translation('saleskit.companyPick.publishSetting')}</div>
          <div className="tw-text-sm tw-text-gray-500">
            {Translation('saleskit.companyPick.publishSetting.subtitle')}
          </div>

          <div className="tw-mt-6 tw-mb-6">
            <Form.Item
              required={true}
              name="effectiveRange"
              label={Translation('component.formLabel.effective-date')}
              rules={DATE_VIDATOR}
            >
              <DateTimeRangePicker />
            </Form.Item>
          </div>

          {enabledCustomWeight && (
            <div className="tw-mt-6 tw-mb-6">
              <Form.Item name="weight" label={Translation('saleskit.companyPick.list.weight')} rules={WEIGHT_VIDATOR}>
                <TextField margin="dense" variant="outlined" type="number" />
              </Form.Item>
            </div>
          )}

          <div className="tw-mt-6 tw-mb-6">
            <Form.Item name="status" label={Translation('survey.common.status')} rules={[DEFAULT_REQUIRED_RULES]}>
              <RadioGroup
                aria-label="publishStatus"
                name="status"
                style={{ ...getConvertedClasses('flex flex-row items-center'), flexDirection: 'row' }}
              >
                {publishItems.map((item: any) => {
                  return (
                    <FormControlLabel
                      key={item.key}
                      value={item.key}
                      control={<Radio />}
                      label={Translation(item.label)}
                    />
                  );
                })}
              </RadioGroup>
            </Form.Item>
          </div>
        </div>

        <div className="tw-bg-white tw-flex tw-justify-center tw-pb-6">
          <span className="tw-mr-6">
            <Button color="inherit" variant="contained" onClick={toList}>
              {Translation('app.button.cancel')}
            </Button>
          </span>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default DetailPage;
