import React, { FC, useReducer, useState, useEffect, useMemo } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import {
  ApplicationFormMode,
  ApplicationDetail,
  ApplicationRole,
  ApplicationTag,
  LanguageText,
} from '../../../types/application-types';
import { useCommonStyles } from '../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  Select,
  Chip,
  MenuItem,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { APPLICATION_BASE_PATH, MANDATORY_FIELD_ERROR_TEXT } from '../../../constants';
import { map, forEach } from 'lodash';
import { fileSizeConvertorMb, getDayStart, useErrorHandler, fileUpload, ErrorFieldType } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { ApplicationBody, createApplication, updateApplication } from '../../../network/applicationCrud';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { regionLocale, regionLocaleMap, getAvailableServerLocale, convertServerLocale } from 'src/app/i18n';
import { useIntl } from 'react-intl';

type ApplicationDetailFormProps = {
  formMode: ApplicationFormMode;
  applicationDetail?: ApplicationDetail;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type ApplicationFormState = {
  iconId?: string;
  iconUrl?: string;
  name?: string;
  bundleIdentifier?: string;
  targetVersion?: string;
  hardUpdateDate: Date | null;
  isPublic: boolean;
  role: string[];
  tags: string[];
  downloadLink?: string;
  description?: LanguageText;
  releaseNote?: LanguageText;
  iosFileId?: string;
  iosFileName?: string;
  iosFileSize?: number;
  androidFileId?: string;
  androidFileName?: string;
  androidFileSize?: number;
};

const initialState: ApplicationFormState = {
  iconId: undefined,
  iconUrl: undefined,
  name: undefined,
  bundleIdentifier: undefined,
  targetVersion: undefined,
  hardUpdateDate: null,
  isPublic: true,
  role: [],
  tags: [],
  downloadLink: undefined,
  description: undefined,
  releaseNote: undefined,
  iosFileId: undefined,
  iosFileName: undefined,
  iosFileSize: undefined,
  androidFileId: undefined,
  androidFileName: undefined,
  androidFileSize: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ApplicationFormState;
    value: any;
  };
};

type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<ApplicationFormState, 'description' | 'releaseNote'>;
    locale: string;
    value: any;
  };
};

type UploadIconAction = {
  type: 'UPLOAD_ICON';
  payload: {
    id: string;
    fileUrl: string;
  };
};

type UploadFileAction = {
  type: 'UPLOAD_APPLICATION';
  payload: {
    mode: 'ios' | 'android';
    id: string;
    fileName: string;
    fileSize: number;
  };
};

type ApplicationFormAction = ModifyFieldAction | ModifyLocaleFieldAction | UploadFileAction | UploadIconAction;

const formReducer = (state: ApplicationFormState, action: ApplicationFormAction): ApplicationFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };
    case 'UPLOAD_APPLICATION':
      if (action.payload.mode === 'ios') {
        return {
          ...state,
          iosFileId: action.payload.id,
          iosFileName: action.payload.fileName,
          iosFileSize: action.payload.fileSize,
        };
      } else {
        return {
          ...state,
          androidFileId: action.payload.id,
          androidFileName: action.payload.fileName,
          androidFileSize: action.payload.fileSize,
        };
      }
    case 'UPLOAD_ICON':
      return {
        ...state,
        iconId: action.payload.id,
        iconUrl: action.payload.fileUrl,
      };
  }
};

const detailToStateConvertor = (detail: ApplicationDetail): ApplicationFormState => {
  return {
    iconId: detail.iconId,
    iconUrl: detail.iconUrl,
    name: detail.name,
    bundleIdentifier: detail.bundleIdentifier,
    targetVersion: detail.targetVersion,
    hardUpdateDate: detail.hardUpdateDate,
    downloadLink: detail.downloadLink,
    isPublic: detail.isPublic,
    role: detail.role,
    tags: detail.tags,
    description: detail.description,
    releaseNote: detail.releaseNote,
    iosFileId: detail.ios.fileId,
    iosFileName: detail.ios.fileName,
    iosFileSize: detail.ios.fileSize,
    androidFileId: detail.android.fileId,
    androidFileName: detail.android.fileName,
    androidFileSize: detail.android.fileSize,
  };
};

const ApplicationDetailForm: FC<ApplicationDetailFormProps> = ({ formMode, applicationDetail }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const { regionLocales, availableLocales } = useMemo(() => {
    return {
      regionLocales: regionLocale,
      availableLocales: getAvailableServerLocale(),
    };
  }, []);

  const [expanded, setExpanded] = useState<Record<string, boolean>>(() => {
    let openState: Record<string, boolean> = {};
    availableLocales.forEach((locale) => {
      openState[locale] = false;
    });
    return openState;
  });

  const [iconUploadProgress, setIconUploadProgress] = useState<number>();
  const [iosUploadProgress, setIosUplaodProgress] = useState<number>();
  const [androidUploadProgress, setAndroidUploadProgress] = useState<number>();

  const [formState, formDispatch] = useReducer(
    formReducer,
    applicationDetail ? detailToStateConvertor(applicationDetail) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'icon',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.iconId && formState.iconUrl);
      },
    },
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'bundleIdentifier',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'targetVersion',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'hardUpdateDate',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'downloadLink',
      fieldType: ErrorFieldType.MANDATORY,
    },
    ...availableLocales.map((locale) => ({
      name: `description${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.description || !formState.description[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    ...availableLocales.map((locale) => ({
      name: `releaseNote${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.releaseNote || !formState.releaseNote[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    {
      name: 'iosFile',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.iosFileId && formState.iosFileName && formState.iosFileSize);
      },
    },
    {
      name: 'androidFile',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.androidFileId && formState.androidFileName && formState.androidFileSize);
      },
    },
  ]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>, mode: 'icon' | 'ios' | 'android') => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        if (mode === 'icon') {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', module: 'application' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, setIconUploadProgress);
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            formDispatch({
              type: 'UPLOAD_ICON',
              payload: {
                id: result.blobId,
                fileUrl: result.url,
              },
            });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Upload icon successfully - ${file.name}`,
                },
              ]),
            );
            onDismissErrorHandler('icon', file);
          }
        } else if (mode === 'ios') {
          const createBlobRes = await createBlob(
            { mimeType: 'application/ios', accessLevel: 'public', module: 'application' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, setIosUplaodProgress);
          formDispatch({
            type: 'UPLOAD_APPLICATION',
            payload: {
              mode: 'ios',
              id: createBlobRes.blobId,
              fileName: file.name,
              fileSize: file.size,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          onDismissErrorHandler('iosFile', file);
        } else if (mode === 'android') {
          const createBlobRes = await createBlob(
            { mimeType: 'application/vnd.android.package-archive', accessLevel: 'public', module: 'application' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, setAndroidUploadProgress);
          formDispatch({
            type: 'UPLOAD_APPLICATION',
            payload: {
              mode: 'android',
              id: createBlobRes.blobId,
              fileName: file.name,
              fileSize: file.size,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          onDismissErrorHandler('androidFile', file);
        }
      } finally {
        if (mode === 'icon') {
          setIconUploadProgress(undefined);
        } else if (mode === 'ios') {
          setIosUplaodProgress(undefined);
        } else if (mode === 'android') {
          setAndroidUploadProgress(undefined);
        }
      }
    }
  };

  const onSubmit = async () => {
    let { hasError, currentErrorState } = onSubmitErrorValidator();
    let expandedState: Record<string, boolean> = { ...expanded };
    forEach(availableLocales, (locale) => {
      if (currentErrorState.mandatory[`description${locale}`] || currentErrorState.mandatory[`releaseNote${locale}`]) {
        expandedState[locale] = true;
      }
    });
    setExpanded(expandedState);

    if (!hasError) {
      const body: ApplicationBody = {
        name: formState.name || '',
        targetVersion: formState.targetVersion || '',
        bundleIdentifier: formState.bundleIdentifier || '',
        iconId: formState.iconId || '',
        isPublic: formState.isPublic,
        role: formState.role,
        tags: formState.tags,
        hardUpdateDate: formState.hardUpdateDate!,
        downloadLink: formState.downloadLink || '',
        description: formState.description || {},
        releaseNote: formState.releaseNote || {},
        ios: {
          fileId: formState.iosFileId || '',
          fileName: formState.iosFileName || '',
          fileSize: formState.iosFileSize!,
        },
        android: {
          fileId: formState.androidFileId || '',
          fileName: formState.androidFileName || '',
          fileSize: formState.androidFileSize!,
        },
      };
      try {
        if (formMode === ApplicationFormMode.CREATE) {
          await createApplication(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Application created successfully`,
              },
            ]),
          );
        } else if (formMode === ApplicationFormMode.EDIT) {
          await updateApplication(applicationDetail!.id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Application updated successfully - ${applicationDetail!.id}`,
              },
            ]),
          );
        }
        history.push(APPLICATION_BASE_PATH);
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (formState.isPublic) {
      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'role', value: [] } });
    }
  }, [formState.isPublic]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`application.${formMode}`)}
            {/* {formMode === ApplicationFormMode.CREATE ? 'Add' : 'Edit'} Application */}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(APPLICATION_BASE_PATH)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      {/* Basic Information */}
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>{Translation('application.create.basicInfo')}</span>
        </div>
        <div style={{ marginBottom: 5 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer} />
          {formState.iconUrl && (
            <div className={classes.iconContainer}>
              <img style={{ width: '100%', height: '100%' }} src={formState.iconUrl} alt="App Icon" />
            </div>
          )}
        </div>
        <div style={{ marginBottom: 10 }} className={classes.textAreaRowContainer}>
          <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.applicationIcon')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-application-icon"
              hidden
              type="file"
              accept="image/*"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => handleFile(e, 'icon')}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-application-icon')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              {errorState.mandatory.icon && (
                <div style={{ marginLeft: 10 }} className={classes.errorText}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </div>
              )}
            </div>
            {!!iconUploadProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                variant="determinate"
                color="secondary"
                value={iconUploadProgress}
              />
            )}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.bundleIdentifier')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              disabled={formMode === ApplicationFormMode.EDIT}
              error={errorState.mandatory.bundleIdentifier}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.bundleIdentifier && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.bundleIdentifier}
              onChange={(e) => {
                onDismissErrorHandler('bundleIdentifier', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bundleIdentifier', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.version')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              error={errorState.mandatory.targetVersion}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.targetVersion && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.targetVersion}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                onDismissErrorHandler('targetVersion', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'targetVersion', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('application.common.accessLevel')} :</span>
          </div>
          <FormControlLabel
            style={{ marginBottom: 0 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={!!formState.isPublic}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isPublic', value: true } });
                  }
                }}
              />
            }
            label="Public"
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ marginBottom: 0, marginRight: 30 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={!!!formState.isPublic}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isPublic', value: false } });
                  }
                }}
              />
            }
            label="Private"
            labelPlacement="end"
          />
          <FormControl margin="dense" variant="outlined">
            <Select
              disabled={formState.isPublic}
              multiple
              style={{ minWidth: 160 }}
              value={formState.role}
              onChange={(e) =>
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'role', value: (e.target.value || []) as ApplicationRole[] },
                })
              }
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {(selected as ApplicationRole[]).map(
                    (value) => value && <Chip key={value} label={value} style={{ margin: 2 }} />,
                  )}
                </div>
              )}
            >
              {map(ApplicationRole, (option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('application.common.tags')} :</span>
          </div>
          <FormControl margin="dense" variant="outlined">
            <Select
              multiple
              style={{ minWidth: 160 }}
              value={formState.tags}
              onChange={(e) =>
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'tags', value: (e.target.value || []) as ApplicationTag[] },
                })
              }
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {(selected as ApplicationTag[]).map(
                    (value) => value && <Chip key={value} label={value} style={{ margin: 2 }} />,
                  )}
                </div>
              )}
            >
              {map(ApplicationTag, (option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.forceUpdateDate')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <PruDatePicker
            slotProps={{
              textField: {
                error: errorState.mandatory.hardUpdateDate,
              },
            }}
            format="DD/MM/YYYY"
            value={formState.hardUpdateDate}
            onChange={(date) => {
              onDismissErrorHandler('hardUpdateDate', date);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'hardUpdateDate', value: date ? getDayStart(date) : null },
              });
            }}
          />
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.downloadLink')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: '50%' }}
              error={errorState.mandatory.downloadLink}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.downloadLink && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.downloadLink}
              onChange={(e) => {
                onDismissErrorHandler('downloadLink', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'downloadLink', value: e.target.value } });
              }}
            />
          </div>
        </div>
      </div>

      {/* Application Details */}
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>{Translation('application.create.applicationDetails')}</span>
        </div>
        <div>
          {regionLocales.map((locale) => {
            const serverLocale = convertServerLocale(locale);
            return (
              <Accordion
                expanded={expanded[serverLocale]}
                onChange={(_, isExpanded) => setExpanded({ ...expanded, [serverLocale]: isExpanded })}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={`${expanded[serverLocale] ? commonClasses.header : ''} ${classes.subHeader}`}>
                    {Translation(regionLocaleMap[locale].name)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>
                        {Translation('application.common.description')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        error={errorState.mandatory[`description${serverLocale}`]}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        helperText={errorState.mandatory[`description${serverLocale}`] && MANDATORY_FIELD_ERROR_TEXT}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={formState.description ? formState.description[serverLocale] : ''}
                        onChange={(e) => {
                          onDismissErrorHandler(`description${serverLocale}`, e.target.value);
                          formDispatch({
                            type: 'MODIFY_LOCALE_FIELD',
                            payload: { field: 'description', locale: serverLocale, value: e.target.value },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>
                        {Translation('application.common.releaseNote')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        error={errorState.mandatory[`releaseNote${serverLocale}`]}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        helperText={errorState.mandatory[`releaseNote${serverLocale}`] && MANDATORY_FIELD_ERROR_TEXT}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={formState.releaseNote ? formState.releaseNote[serverLocale] : ''}
                        onChange={(e) => {
                          onDismissErrorHandler(`releaseNote${serverLocale}`, e.target.value);
                          formDispatch({
                            type: 'MODIFY_LOCALE_FIELD',
                            payload: { field: 'releaseNote', locale: serverLocale, value: e.target.value },
                          });
                        }}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>

      {/* Application Upload */}
      <div className={classes.sectionMargin}>
        <div style={{ marginBottom: 20 }} className={classes.divideMargin}>
          <span className={classes.subHeader}>{Translation('application.create.applicationUpload')}</span>
        </div>
        <div style={{ marginBottom: 10 }} className={classes.textAreaRowContainer}>
          <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.uploadIos')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-iOS-application"
              hidden
              type="file"
              accept=".ipa"
              onChange={(e) => handleFile(e, 'ios')}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-iOS-application')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              <div style={{ marginLeft: 10 }}>
                {formState.iosFileName && formState.iosFileSize
                  ? `${formState.iosFileName} - ${fileSizeConvertorMb(formState.iosFileSize)}`
                  : Translation('app.file.noFileChosen')}
              </div>
              {errorState.mandatory.iosFile && (
                <div style={{ marginLeft: 10 }} className={classes.errorText}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </div>
              )}
            </div>
            {!!iosUploadProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                color="secondary"
                variant="determinate"
                value={iosUploadProgress}
              />
            )}
          </div>
        </div>
        <div style={{ marginBottom: 10 }} className={classes.textAreaRowContainer}>
          <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('application.common.uploadAndroid')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-android-application"
              hidden
              type="file"
              accept=".apk"
              onChange={(e) => handleFile(e, 'android')}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-android-application')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              <div style={{ marginLeft: 10 }}>
                {formState.androidFileName && formState.androidFileSize
                  ? `${formState.androidFileName} - ${fileSizeConvertorMb(formState.androidFileSize)}`
                  : Translation('app.file.noFileChosen')}
              </div>
              {errorState.mandatory.androidFile && (
                <span style={{ marginLeft: 10 }} className={classes.errorText}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </span>
              )}
            </div>
            {!!androidUploadProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                color="secondary"
                variant="determinate"
                value={androidUploadProgress}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ApplicationDetailForm;
