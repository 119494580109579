import React, { FC, useState, useReducer, useEffect } from 'react';
import { isArray, map } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, FormControl, FormHelperText, Select, MenuItem } from '@mui/material';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { CAMPAIGN_TYPE_CODE_ERROR_TEXT } from 'src/app/modules/AgencyCampaign/constants';
import {
  CampaignTypeFormMode,
  CampaignTypeItem,
  CampaignTypeDropdownEnum,
  CampaignTypeDropdownList,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import {
  CampaignTypeDropdownListParam,
  CreateCampaignTypeBody,
  fetchCampaignTypeDropdownList,
} from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import PageFooter from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/components/PageFooter';
import {
  CampaignTypeFormPageProps,
  useStyles,
} from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';

type CampaignTypeFormPageOneProps = {
  campaignTypeDropdownList?: CampaignTypeDropdownList;
} & CampaignTypeFormPageProps;

type CampaignTypeFormPageOneFormState = {
  name?: string;
  description?: string;
  code?: string;
  agentLeadSource?: string;
  agentLeadSubSource?: string[];
};

const initialState: CampaignTypeFormPageOneFormState = {
  name: undefined,
  description: undefined,
  code: undefined,
  agentLeadSource: undefined,
  agentLeadSubSource: undefined,
};

type ActionPayload = {
  field: keyof CampaignTypeFormPageOneFormState;
  value: any;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: ActionPayload | ActionPayload[];
};

type CampaignTypeFormPageOneFormAction = ModifyFieldAction;

const formReducer = (
  state: CampaignTypeFormPageOneFormState,
  action: CampaignTypeFormPageOneFormAction,
): CampaignTypeFormPageOneFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const data = isArray(action.payload) ? action.payload : [action.payload];
      let patchData = {};
      for (let index = 0; index < data.length; index++) {
        const element: ActionPayload = data[index];
        patchData = {
          ...patchData,
          [element.field]: element.value,
        };
      }
      return {
        ...state,
        ...patchData,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (campaignTypeItem?: CampaignTypeItem): CampaignTypeFormPageOneFormState => {
  return campaignTypeItem
    ? {
        name: campaignTypeItem.name,
        description: campaignTypeItem.description,
        code: campaignTypeItem.code,
        agentLeadSource: campaignTypeItem.agentLeadSource,
        agentLeadSubSource: campaignTypeItem.agentLeadSubSource,
      }
    : initialState;
};

const CampaignTypeFormPageOne: FC<CampaignTypeFormPageOneProps> = ({
  formMode,
  campaignTypeItem,
  campaignTypeDropdownList,
  renderProgressBar,
  onNextPage = () => {},
  onCancel,
  onShowCancelDialog,
  onSubmit,
}) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(campaignTypeItem));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'description',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'code',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'agentLeadSource',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'agentLeadSubSource',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'twoLettersCode',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.code) {
            return !/^[A-Z]{2}$/.test(formState.code);
          } else {
            return false;
          }
        },
      },
      {
        name: 'emptySubSourceList',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.agentLeadSubSource) {
            return formState.agentLeadSubSource.length === 0;
          } else {
            return false;
          }
        },
      },
    ],
  );

  const [subSourceDropdownList, setSubSourceDropdownList] = useState<CampaignTypeDropdownList>();
  const reloadSubSourceDropdown = async () => {
    const params: CampaignTypeDropdownListParam = {
      type: map(CampaignTypeDropdownEnum),
      parent: formState.agentLeadSource,
    };
    if (formState.agentLeadSource) {
      const dropdownListRes = await fetchCampaignTypeDropdownList(params, dispatch);
      setSubSourceDropdownList(dropdownListRes);
    }
  };

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.code, formState.agentLeadSubSource]);

  useEffect(() => {
    reloadSubSourceDropdown();
  }, [formState.agentLeadSource]);

  const onSave = async (toNextPage: boolean = false) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateCampaignTypeBody = {
        name: formState.name || '',
        description: formState.description || '',
        code: formState.code || '',
        agentLeadSource: formState.agentLeadSource || '',
        agentLeadSubSource: formState.agentLeadSubSource || [],
        createdBy: user?.username || 'Default',
        updatedBy: user?.username || 'Default',
      };
      await onSubmit(body);
      if (toNextPage) {
        onNextPage();
      } else {
        onCancel();
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `agencyCampaign.campaignType.form.title.${formMode === CampaignTypeFormMode.CREATE ? 'create' : 'edit'}`,
            )}
          </div>
        </div>
        {renderProgressBar()}
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agencyCampaign.campaignType.name')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 250 }}
                error={errorState.mandatory.name}
                margin="dense"
                helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.name}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  onDismissErrorHandler('name', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
                }}
              />
            </div>
          </div>

          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agencyCampaign.campaignType.description')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 250 }}
                error={errorState.mandatory.description}
                margin="dense"
                helperText={errorState.mandatory.description && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.description}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  onDismissErrorHandler('description', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'description', value: e.target.value } });
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agencyCampaign.campaignType.code')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 250 }}
                error={errorState.immediate.twoLettersCode || errorState.mandatory.code}
                margin="dense"
                helperText={
                  errorState.immediate.twoLettersCode
                    ? CAMPAIGN_TYPE_CODE_ERROR_TEXT
                    : errorState.mandatory.code && MANDATORY_FIELD_ERROR_TEXT
                }
                value={formState.code}
                inputProps={{ maxLength: 2 }}
                onChange={(e) => {
                  onDismissErrorHandler('code', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'code', value: e.target.value.toUpperCase() },
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agencyCampaign.common.leadSource')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl error={errorState.mandatory.agentLeadSource} margin="dense">
                <Select
                  variant="standard"
                  style={{ minWidth: 250 }}
                  value={formState.agentLeadSource || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('agentLeadSource', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: [
                        { field: 'agentLeadSource', value: e.target.value },
                        { field: 'agentLeadSubSource', value: [] },
                      ],
                    });
                  }}
                >
                  {campaignTypeDropdownList &&
                    campaignTypeDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE] &&
                    campaignTypeDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {errorState.mandatory.agentLeadSource && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          </div>

          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agencyCampaign.common.leadSubSource')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl
                error={errorState.immediate.emptySubSourceList || errorState.mandatory.agentLeadSubSource}
                margin="dense"
              >
                <Select
                  variant="standard"
                  style={{ minWidth: 250 }}
                  multiple
                  value={formState.agentLeadSubSource || []}
                  onChange={(e) => {
                    onDismissErrorHandler('agentLeadSubSource', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'agentLeadSubSource', value: e.target.value || [] },
                    });
                  }}
                >
                  {formState.agentLeadSource &&
                    subSourceDropdownList &&
                    subSourceDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE] &&
                    subSourceDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {(errorState.immediate.emptySubSourceList || errorState.mandatory.agentLeadSubSource) && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      <PageFooter disablePrevious={true} onCancel={onShowCancelDialog} onSave={onSave} />
    </div>
  );
};

export default CampaignTypeFormPageOne;
