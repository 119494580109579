import { makeStyles } from 'tss-react/mui';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FC, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { isArray } from 'lodash';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { updateMarketingCampaignLeadLimitRule } from '../../../network/ruleConfigCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { MarketingLeadLimitFormState, ModifyFieldAction, ActionPayload } from './type';
import { useStyles } from './styles';

const formReducer = (state: MarketingLeadLimitFormState, action: ModifyFieldAction): MarketingLeadLimitFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const data = isArray(action.payload) ? action.payload : [action.payload];
      let patchData = {};
      for (let index = 0; index < data.length; index++) {
        const element: ActionPayload = data[index];
        patchData = {
          ...patchData,
          [element.field]: element.value,
        };
      }
      return {
        ...state,
        ...patchData,
      };
    case 'SUBMIT': {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

const convertToState = (data?: any): MarketingLeadLimitFormState => {
  const state: MarketingLeadLimitFormState = {
    enableForAllCampaign: data?.content?.allCampaignLeadLimit.enabled || false,
    enableForDaily: data?.content?.dailyLeadLimit.enabled || false,
    enableForMissedAccept: data?.content?.numberOfMissedAcceptSLA?.enabled || false,
    allCampaignLeadLimit: data?.content?.allCampaignLeadLimit?.limit,
    DailyLeadLimit: data?.content?.dailyLeadLimit?.limit,
    NumberOfMissedAcceptSLA: data?.content?.numberOfMissedAcceptSLA?.limit,
  };
  return state;
};

const MarketingLeadLimitForm: FC<any> = ({ data }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const initeState = convertToState(data) ?? {
    enableForAllCampaign: false,
    enableForDaily: false,
    enableForMissedAccept: false,
  };
  const [formState, formDispatch] = useReducer(formReducer, initeState);
  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'allCampaignLeadLimit',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.enableForAllCampaign) {
            const numberRegex = new RegExp(/^\d+$/);
            return !numberRegex.test(String(formState?.allCampaignLeadLimit));
          } else {
            return false;
          }
        },
      },
      {
        name: 'DailyLeadLimit',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.enableForDaily) {
            const numberRegex = new RegExp(/^\d+$/);
            return !numberRegex.test(String(formState?.DailyLeadLimit));
          } else {
            return false;
          }
        },
      },
      {
        name: 'NumberOfMissedAcceptSLA',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.enableForMissedAccept) {
            const numberRegex = new RegExp(/^\d+$/);
            return !numberRegex.test(String(formState?.NumberOfMissedAcceptSLA));
          } else {
            return false;
          }
        },
      },
    ],
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [formState]);

  const onSave = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const data = {
        allCampaignLeadLimit: {
          enabled: formState.enableForAllCampaign ?? false,
          limit: formState?.allCampaignLeadLimit,
        },
        dailyLeadLimit: { enabled: formState.enableForDaily ?? false, limit: formState?.DailyLeadLimit },
        numberOfMissedAcceptSLA: {
          enabled: formState.enableForMissedAccept ?? false,
          limit: formState?.NumberOfMissedAcceptSLA,
        },
      };
      try {
        await updateMarketingCampaignLeadLimitRule(data);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Rule updated successfully`,
            },
          ]),
        );
      } catch (err) {}
    }
  };

  return (
    <div className="tw-flex tw-flex-1 tw-bg-white tw-flex-col ">
      <div className="tw-m-6">
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation('title.pulseleadsRule.marketingLeadLimit')}</div>
          </div>{' '}
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center ">
          <div className={classes.checkboxFieldContainer}>
            <div>{Translation('pulseleads.marketingLeadLimitRule.allCampaign')} :</div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center ">
            <div className="tw-flex tw-mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.enableForAllCampaign === true}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'enableForAllCampaign',
                            value: true,
                          },
                        });
                      }
                    }}
                  />
                }
                label={Translation('pulseleads.marketingLeadLimitRule.enable')}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={!formState.enableForAllCampaign}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'enableForAllCampaign',
                            value: false,
                          },
                        });
                      }
                    }}
                  />
                }
                label={Translation('pulseleads.marketingLeadLimitRule.disable')}
                labelPlacement="end"
              />
            </div>
            {formState.enableForAllCampaign === true && (
              <span className="tw-flex tw-flex-row tw-items-center">
                <TextField
                  error={errorState.immediate.allCampaignLeadLimit}
                  margin="dense"
                  variant="outlined"
                  helperText={
                    errorState.immediate.allCampaignLeadLimit &&
                    Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                  }
                  value={formState.allCampaignLeadLimit ?? null}
                  type="number"
                  onChange={(e) => {
                    onDismissErrorHandler('autoDropLeadDays', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'allCampaignLeadLimit',
                        value: Number(e.target.value),
                      },
                    });
                  }}
                />
                <span className="tw-ml-4">{Translation('pulseleads.marketingLeadLimitRule.maxLeads')}</span>
              </span>
            )}
          </div>
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center tw-mt-4 ">
          <div className={classes.checkboxFieldContainer}>
            <div>{Translation('pulseleads.marketingLeadLimitRule.partCampaign')} :</div>
          </div>

          <div>{Translation('pulseleads.marketingLeadLimitRule.dailyLimit.desc')}</div>
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center tw-mt-4">
          <div className={classes.checkboxFieldContainer}>
            <div>{Translation('pulseleads.marketingLeadLimitRule.dailyLimit')} :</div>
          </div>

          <div className="tw-flex tw-mt-3">
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.enableForDaily === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableForDaily',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.marketingLeadLimitRule.enable')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={!formState.enableForDaily}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableForDaily',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.marketingLeadLimitRule.disable')}
              labelPlacement="end"
            />
          </div>
          {formState.enableForDaily === true && (
            <span className="tw-flex tw-items-center">
              <TextField
                error={errorState.immediate.DailyLeadLimit}
                margin="dense"
                variant="outlined"
                helperText={
                  errorState.immediate.DailyLeadLimit && Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                }
                value={formState.DailyLeadLimit ?? null}
                type="number"
                onChange={(e) => {
                  onDismissErrorHandler('DailyLeadLimit', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'DailyLeadLimit',
                      value: Number(e.target.value),
                    },
                  });
                }}
              />
              <span className="tw-ml-4">{Translation('pulseleads.marketingLeadLimitRule.maxLeads')}</span>
            </span>
          )}
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center">
          <div className={classes.checkboxFieldContainer}>
            <div>{Translation('pulseleads.marketingLeadLimitRule.missedAcceptLimit')} :</div>
          </div>

          <div className="tw-flex tw-mt-3">
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.enableForMissedAccept === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableForMissedAccept',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.marketingLeadLimitRule.enable')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={!formState.enableForMissedAccept}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableForMissedAccept',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.marketingLeadLimitRule.disable')}
              labelPlacement="end"
            />
          </div>
          {formState.enableForMissedAccept === true && (
            <span className="tw-flex tw-items-center">
              <TextField
                error={errorState.immediate.NumberOfMissedAcceptSLA}
                margin="dense"
                variant="outlined"
                helperText={
                  errorState.immediate.NumberOfMissedAcceptSLA &&
                  Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                }
                value={formState.NumberOfMissedAcceptSLA ?? null}
                type="number"
                onChange={(e) => {
                  onDismissErrorHandler('NumberOfMissedAcceptSLA', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'NumberOfMissedAcceptSLA',
                      value: Number(e.target.value),
                    },
                  });
                }}
              />
              <span className="tw-ml-4">{Translation('pulseleads.marketingLeadLimitRule.maxLeads')}</span>
            </span>
          )}
        </div>

        <div className="tw-flex tw-items-end tw-justify-end">
          <Button variant="contained" color="secondary" size="large" onClick={onSave}>
            {Translation('app.button.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MarketingLeadLimitForm;
