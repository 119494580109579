import React, { FC, useState } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignItem, CampaignStatusEnum } from 'src/app/modules/PulseLeads/types/campaign-types';
import { deleteCampaign, updateCampaignStatus } from 'src/app/modules/PulseLeads/network/campaignCrud';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType, AuthenticationState } from '@pruforce/common-adminweb-sdk';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { useHistory } from 'react-router-dom';
import { campaignPath } from '../../CampaignRoutes';
import { RootState } from 'src/redux/store';

type CampaignListProps = {
  isLoading: boolean;
  campaignList?: PaginateList<CampaignItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const CampaignList: FC<CampaignListProps> = ({ isLoading, campaignList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteCampaign(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  const onUpdateStatus = async (id: string, status: CampaignStatusEnum) => {
    try {
      await updateCampaignStatus(id, {
        status,
        updatedBy: user?.username || 'Default',
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign status updated successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-campaign-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('pulseleads.campaign.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.campaign.newCampaign'),
            onClick: () => history.push(`${campaignPath}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Campaign',
            condition: (row) => row.status !== CampaignStatusEnum.CANCELED,
            onClick: (row) => history.push(`${campaignPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Campaign',
            condition: (row) => row.status === CampaignStatusEnum.CANCELED,
            onClick: (row) => history.push(`${campaignPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.activate'),
            tooltipText: 'Activate Campaign',
            condition: (row) => row.status === CampaignStatusEnum.DRAFT || row.status === CampaignStatusEnum.PAUSED,
            onClick: (row) => onUpdateStatus(row._id, CampaignStatusEnum.ACTIVE),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Campaign',
            condition: (row) => row.status === CampaignStatusEnum.DRAFT,
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
          {
            title: Translation('section.common.operation.pause'),
            tooltipText: 'Pause Campaign',
            condition: (row) => row.status === CampaignStatusEnum.ACTIVE,
            onClick: (row) => onUpdateStatus(row._id, CampaignStatusEnum.PAUSED),
          },
          {
            title: Translation('section.common.operation.cancel'),
            tooltipText: 'Cancel Campaign',
            condition: (row) => row.status === CampaignStatusEnum.PAUSED,
            onClick: (row) => onUpdateStatus(row._id, CampaignStatusEnum.CANCELED),
          },
          {
            title: Translation('pulseleads.campaign.common.leadList'),
            tooltipText: 'View Lead List',
            onClick: (row) => history.push(`${campaignPath}/${row._id}/lead/list`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'campaignId',
            displayName: Translation('pulseleads.campaign.common.campaignId'),
            renderData: (row) => row.campaignId || '-',
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.startDate'),
            renderData: (row) => getDefaultDisplayDate(row.startDate) || '-',
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.endDate'),
            renderData: (row) => getDefaultDisplayDate(row.endDate) || '-',
          },
          {
            keyIndex: 'marketingOwnerName',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.marketingOwnerName'),
            renderData: (row) => row.marketingOwnerName || '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.status'),
            renderData: (row) => (row.status ? Translation(`pulseleads.campaign.status.option.${row.status}`) : '-'),
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.updatedBy'),
            renderData: (row) => row.updatedBy,
          },
        ]}
        dataSource={campaignList?.docs}
        totalPages={campaignList?.totalPages}
        totalRecords={campaignList?.totalDocs}
      />
    </>
  );
};

export default CampaignList;
