import React, { useEffect } from 'react';
import { TextField, Button, RadioGroup, Radio, FormControlLabel, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload, formatBytes } from 'src/app/common/utils';

import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { MANDATORY_FIELD_ERROR_TEXT, DATE_ERROR_TEXT } from 'src/app/modules/Survey/constants';
import { PublishStatus } from 'src/app/modules/PulseLeads/pages/Engagement/EngagementTopic/Detail/utils';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

import Flyer from './Flyer';
import TargetAgent from './TargetAgent';
import { useStyles } from './style';
import { SURVEY_CUSTOMER_RESOURCE } from 'src/app/modules/sales-kit/constants';
import { CustomerSurveyFormState, CustomerSurveyErrorState } from '../../redux/CustomerRedux';
import { getImageSizeFromUrl } from 'src/app/modules/News/utils/news';

type SurveyConfigSectionProps = {
  formState: CustomerSurveyFormState;
  errorState: CustomerSurveyErrorState;
  onChangeStartDate: (startDate: Date | null) => void;
  onChangeEndDate: (endDate: Date | null) => void;

  onNextPage: () => void;
  onDismissErrorHandler: (field: keyof CustomerSurveyErrorState['mandatory'], value: any) => void;
  immediateErrorValidator: () => void;
  onChangeCustomerConsent: (consent: string[]) => void;
  onChangeThankyouPageTitle: (title: string) => void;
  onChangeThankyouPageContent: (content: string) => void;
  addTnc: (numberOfTnc: number) => void;
  onChangeImage: (image: string) => void;
  onChangeStatus: (status: string) => void;
  apiData: boolean;
  onChangeFlyer: (flyer: string) => void;
  onChangeEnableAgentType: (enableTargetAgents: boolean) => void;
  onChangeTargetAgents: (targetAgents: any[]) => void;
  onStatusChange: (status: string) => void;
  onChangeImageId: (bannerId: string) => void;
};

const targetAgentType = {
  allAgent: 'allAgent',
  targetAgent: 'targetAgent',
};

function CustomerSurveyConfigSection({
  formState,
  errorState,
  apiData,
  onChangeStartDate,
  onChangeEndDate,
  onChangeThankyouPageTitle,
  onChangeThankyouPageContent,
  onNextPage,
  onDismissErrorHandler,
  immediateErrorValidator,
  onChangeCustomerConsent,
  onChangeImage,
  onChangeImageId,
  onStatusChange,
  onChangeFlyer,
  onChangeEnableAgentType,
  onChangeTargetAgents,
  addTnc,
}: SurveyConfigSectionProps) {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [formStatus, setFormStatus] = React.useState<string>('');
  const dispatch = useDispatch();
  const [publishItems, setPublishItems] = React.useState<any[]>([]);
  const getStatus = () => {
    let displayStatus: object[] = [];

    switch (formState.status) {
      case 'draft':
        displayStatus = [PublishStatus.Draft, PublishStatus.Publish];
        break;
      case 'published':
        displayStatus = [PublishStatus.Publish, PublishStatus.UnPublish];
        break;
      case 'unpublished':
        displayStatus = [PublishStatus.Publish, PublishStatus.UnPublish];
        break;
    }
    setPublishItems(displayStatus);
  };
  useEffect(() => {
    setFormStatus(formState.status);
  }, []);

  useEffect(() => {
    onStatusChange(formStatus);
    getStatus();
  }, [formStatus]);

  const [imageUploadProgress, setImageUploadProgress] = React.useState<number>();

  const TncForm = (index: number) => {
    return (
      <div style={{ marginBottom: 20 }} className={classes.rowContainer} key={index}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('survey.form.tnc')}
            {index + 1} :{' '}
          </span>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              value={formState.consents[index] || ''}
              onChange={(e) => {
                const tnc = e.target.value;
                formState.consents[index] = tnc;
                onChangeCustomerConsent(formState.consents);
              }}
            />

            <div style={{ flex: 1 }}>
              <CloseIcon
                className={classes.formCloseIcon}
                onClick={() => {
                  formState.consents.splice(index, 1);
                  addTnc(formState.numberofTnc - 1);
                  onChangeCustomerConsent(formState.consents);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const TranslationWithVariable = (key: string) => intl.formatMessage({ id: key });
      const errMsg = TranslationWithVariable('global.max.file.size.allow');
      const size = formatBytes(file.size);

      const overSize = size.type !== 1 || size.value > 300;
      if (overSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }
      try {
        /**
         * check image dimensions, must greater than 200*200,
         * whatsapp preview did NOT work for small dimensions image
         */
        const dimensions = await getImageSizeFromUrl(URL.createObjectURL(file));
        if (!dimensions || dimensions.height < 200 || dimensions.width < 200) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: '',
                content: Translation('global.max.file.resolution.allow'),
              },
            ]),
          );
          return;
        }
      } catch {}

      try {
        const createBlobRes = await createBlob(
          {
            mimeType: file.type,
            accessLevel: 'public',
            module: SURVEY_CUSTOMER_RESOURCE,
            originalFilename: file.name,
          },
          dispatch,
        );

        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        // console.log('blobDetail', blobDetail);

        const result = blobDetail[0];

        if (result) {
          onChangeImage(result.url);
          onChangeImageId(result.blobId);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  function AllTncForm(num: number) {
    let rows = [];
    for (let i = 0; i < num; i++) {
      let row = TncForm(i);
      rows.push(row);
    }
    return rows;
  }
  useEffect(() => {
    immediateErrorValidator();
  }, [formState.startDate, formState.endDate, formState.flyerMessage]);

  const TncReminder = () => {
    return (
      <>
        <div className={classes.TNC}>
          Please input [ ] to define file link name and ( ) to define file URL.
          <br />
          Example: We use cookies to provide necessary website functionality, improve your experience and analyze our
          traffic.
          <br />
          By using our website, you agree to our [privacy policy] (http://www.google.com.hk) and our cookies usage.
        </div>
      </>
    );
  };

  return (
    <div className={classes.sectionMargin}>
      <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('survey.form.duration')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <PruDatePicker
            slotProps={{
              textField: {
                error: errorState.mandatory.startDate || errorState.immediate.endDateBeforeStartDate,
                helperText: errorState.mandatory.startDate && MANDATORY_FIELD_ERROR_TEXT,
              },
            }}
            format="DD/MM/YYYY"
            value={formState.startDate}
            onChange={(date) => {
              onDismissErrorHandler('startDate', date);
              onChangeStartDate(date);
            }}
          />
          <div className={classes.dateDivider}>-</div>
          <PruDatePicker
            slotProps={{
              textField: {
                error: errorState.mandatory.endDate || errorState.immediate.endDateBeforeStartDate,
                helperText: errorState.mandatory.endDate
                  ? MANDATORY_FIELD_ERROR_TEXT
                  : errorState.immediate.endDateBeforeStartDate && DATE_ERROR_TEXT,
              },
            }}
            format="DD/MM/YYYY"
            value={formState.endDate}
            onChange={(date) => {
              onDismissErrorHandler('endDate', date);
              onChangeEndDate(date);
            }}
          />
        </div>
      </div>

      <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
        <div className="tw-flex">
          <div className={classes.subHeader}>{Translation('survey.form.basicConfiguration')}</div>
        </div>

        <Flyer onChangeFlyer={onChangeFlyer} formState={formState} errorState={errorState} />

        {/*banner*/}
        <div className="tw-flex tw-flex-row tw-mb-6">
          <div className={classes.titleTextContainer}>
            <label className={classes.field}>{Translation('survey.form.banner')}</label>
          </div>
          <div>
            {formState.surveyImage && (
              <div className="tw-flex tw-flex-row">
                <div className={classes.imageContainer}>
                  <img className={classes.imageDisplayArea} src={formState.surveyImage} alt="App Icon" />
                </div>
                <span>
                  <CloseIcon
                    className="tw-ml-8"
                    onClick={() => {
                      onChangeImage('');
                      onChangeImageId('');
                    }}
                  />
                </span>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className="tw-relative tw-w-full">
                <div className="tw-flex tw-items-center">
                  <>
                    {!formState.surveyImage && (
                      <div>
                        <input
                          id="upload-campaign-image"
                          hidden
                          type="file"
                          accept="image/*"
                          onClick={(e) => {
                            const element = e.target as HTMLInputElement;
                            element.value = '';
                          }}
                          onChange={handleFile}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          size="small"
                          onClick={() => document.getElementById('upload-campaign-image')!.click()}
                        >
                          {Translation('section.common.operation.upload')}
                        </Button>
                      </div>
                    )}
                    {!formState.surveyImage && (
                      <div className="tw-ml-3">{Translation('survey.resource.create.banner')}</div>
                    )}

                    {!!imageUploadProgress && (
                      <LinearProgress
                        className={classes.mt10}
                        variant="determinate"
                        color="secondary"
                        value={imageUploadProgress}
                      />
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*consent*/}
        <div className="tw-mb-2.5">
          <div className="tw-flex tw-items-center">
            <div className={classes.titleTextContainer}>
              <label className={classes.field}>{Translation('survey.form.consent')}</label>
            </div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={() => {
                addTnc(formState.numberofTnc + 1);
              }}
            >
              {Translation('app.button.addNew')}
            </Button>
          </div>
        </div>

        {/*Tncs*/}
        <div className="tw-pl-64 tw-mb-6">
          <TncReminder />
        </div>

        <div>{AllTncForm(formState.numberofTnc)}</div>
      </div>

      <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
        <div className="tw-flex">
          <div className={classes.subHeader}>{Translation('survey.form.completed')}</div>
        </div>

        <div className={classes.titleTextMargin}>
          <label>{Translation('survey.form.completed.helperText')}</label>
        </div>

        <div className="tw-flex tw-items-center tw-mb-6">
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('survey.common.title')} : </span>
          </div>

          <div className={classes.textfieldContainer}>
            <TextField
              variant="outlined"
              fullWidth
              value={formState.completeMessage.title}
              placeholder={Translation('survey.form.thankyou.title')}
              onChange={(e) => {
                const thankyouMsgTitle = e.target.value;
                onChangeThankyouPageTitle(thankyouMsgTitle);
              }}
            />
            <div className={classes.cancelContainer} />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-mb-6">
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('survey.common.content')} : </span>
          </div>
          <div className={classes.textfieldContainer}>
            <TextField
              variant="outlined"
              fullWidth
              value={formState.completeMessage.content}
              placeholder={Translation('survey.form.thankyou.content')}
              onChange={(e) => {
                const thankyouMsgContent = e.target.value;
                onChangeThankyouPageContent(thankyouMsgContent);
              }}
            />
            <div className={classes.cancelContainer} />
          </div>
        </div>
      </div>

      <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
        <div className="tw-flex">
          <div className={classes.subHeader}>{Translation('survey.form.publishSetting')}</div>
        </div>

        <div className="tw-flex tw-items-center tw-mb-3">
          <div className={classes.fieldContainer}>
            <div className={classes.field}>{Translation('survey.form.publishSetting.targetAgent')}</div>
          </div>

          <div>
            <RadioGroup
              className={classes.radioGroup}
              defaultValue={formState.enableTargetAgents ? targetAgentType.targetAgent : targetAgentType.allAgent}
            >
              {Object.entries(targetAgentType).map(([key, value]) => {
                return (
                  <FormControlLabel
                    className={classes.commonRadio}
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={Translation(`survey.common.targetAgentType.${key}`)}
                    onChange={() => {
                      key === targetAgentType.allAgent ? onChangeEnableAgentType(false) : onChangeEnableAgentType(true);
                    }}
                  />
                );
              })}
            </RadioGroup>
          </div>
        </div>
        {formState.enableTargetAgents && (
          <div className={classes.agentTableContainer}>
            <div>
              <TargetAgent setTargetAgents={onChangeTargetAgents} data={formState.targetAgents} />
            </div>

            {formState.targetAgents?.length === 0 && (
              <div className="tw-mt-3">{Translation('survey.common.targetAgentType.noAgent')}</div>
            )}
          </div>
        )}

        <div className="tw-flex tw-items-center tw-mb-6">
          <div className={classes.fieldContainer}>
            <span className={classes.titleTextMargin}>{Translation('survey.common.status')} :</span>
          </div>
          <div>
            <RadioGroup className={classes.radioGroup}>
              {publishItems &&
                publishItems.map((item: any) => {
                  return (
                    item && (
                      <FormControlLabel
                        className={classes.commonRadio}
                        key={item.key}
                        value={item.key}
                        checked={item.key === formStatus}
                        onChange={() => {
                          setFormStatus(item.key);
                        }}
                        control={<Radio />}
                        label={Translation(item.label)}
                      />
                    )
                  );
                })}
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onNextPage}>
          {Translation('app.button.next')}
        </Button>
      </div>
    </div>
  );
}

export default CustomerSurveyConfigSection;
