import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { RootState } from 'src/redux/store';
import { AuthenticationState, appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { activeType } from './constants';
import RuleCriteriaDropdown from 'src/app/modules/PulseLeads/common/RuleCriteriaDropdown';
import { renderOptions } from './utils';
import { getRule, updateRule } from './api';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    paddingBottom: 50,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
}));

const ProspectMasterMatchingPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const [rules, setRules] = useState<any>([]);
  const [isSelectRules, setIsSelectRules] = useState<boolean>(true);
  const [enableEmptyValidation, setEnableEmptyValidation] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<any>({
    masterRuleId: 'ruleone',
    isActive: 'true',
  });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const MASTER_RULE_ID = 'ruleone';

  const RULES_VALIDATE = [
    {
      validator(_: any, value: string) {
        if (rules.length === 0 && enableEmptyValidation) {
          setIsSelectRules(false);
          return Promise.reject(new Error(Translation('component.form-required-text')));
        } else {
          setIsSelectRules(true);
          return Promise.resolve();
        }
      },
    },
  ];

  useEffect(() => {
    const fetchAPI = async () => {
      const rule = await getRule(MASTER_RULE_ID, dispatch);
      setInitialValues(rule.data);
      setRules(rule.data.rules);
      rule.data.isActive = rule.data.isActive.toString();
      form.setFieldsValue(rule.data);
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values: any) => {
    const allValues = form.getFieldsValue(true);
    const saveData = {
      ...allValues,
      ...{
        isActive: JSON.parse(allValues.isActive),
      },
      createdBy: user?.username || 'Default',
      updatedBy: user?.username || 'Default',
    };

    await updateRule(MASTER_RULE_ID, saveData, dispatch);

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Prospect Master Matching Rule saved successfully',
        },
      ]),
    );
  };

  const onChangeCriteria = (ruleItems: any) => {
    setRules(ruleItems);
    setIsSelectRules(ruleItems.length !== 0);
    form.setFieldsValue({ rules: ruleItems });
  };

  return (
    <Form
      form={form}
      className={`${classes.container} add-form-wraper form-wraper`}
      onFinish={onFinish}
      initialValues={{ ...initialValues }}
    >
      <Form.Item name="isActive" label={Translation('form.prospect.master.is.active')} rules={DEFAULT_REQUIRED_RULES}>
        <RadioGroup aria-label="isActive？" name="isActive" style={{ display: 'flex', flexDirection: 'row' }}>
          {Object.keys(activeType).map((key: string) => (
            <FormControlLabel
              key={activeType[key].text}
              value={activeType[key].value}
              control={
                <Radio
                  onChange={(e: any) => {
                    form.setFieldsValue({ isActive: e.target.value.toString() });
                    setEnableEmptyValidation(Boolean(JSON.parse(e.target.value)));
                  }}
                />
              }
              label={activeType[key].text}
            />
          ))}
        </RadioGroup>
      </Form.Item>
      <Form.Item
        name="name"
        label={Translation('component.formLabel.name')}
        rules={DEFAULT_REQUIRED_RULES}
        required={true}
        shouldUpdate={true}
      >
        <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={false} />
      </Form.Item>
      <Form.Item name="description" label={Translation('component.formLabel.description')} required={false}>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={placeEnter}
          disabled={false}
          multiline
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>

      <Form.Item noStyle rules={RULES_VALIDATE} name="rules">
        <>
          <RuleCriteriaDropdown
            style={{ marginTop: 20 }}
            title={Translation('form.prospect.master.criteria.title')}
            onChangeCriteria={onChangeCriteria}
            ruleCriteria={{
              maxLayer: 5,
              mandatoryLayer: 1,
              rules,
              dropdown: renderOptions(intl),
            }}
          />

          {!isSelectRules && enableEmptyValidation && (
            <FormHelperText style={{ marginLeft: 160, color: 'red', fontSize: 13, fontWeight: 400 }}>
              {Translation('component.form-required-text')}
            </FormHelperText>
          )}
        </>
      </Form.Item>

      <Form.Item noStyle>
        <Button type="submit" style={{ float: 'right' }} variant="contained" color="secondary" onClick={() => {}}>
          {Translation('app.button.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProspectMasterMatchingPage;
