import React from 'react';
import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
// import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { ROLE_CONTROL_BASE_PATH, ROLE_CONTROL_USER_MANAGEMENT_PATH } from '../../constants';
import { roleUserManagementFilterKeys } from './list/role-user-management-listing-page.hook';
import { RoleUserManagementListingPage } from './list/role-user-management-listing-page.screen';
import { CreateUserPage } from './create-user/create-user.screen';

export const roleUserManagementPath = `${ROLE_CONTROL_BASE_PATH}${ROLE_CONTROL_USER_MANAGEMENT_PATH}`;

const RoleUserManagementRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={roleUserManagementPath} to={`${roleUserManagementPath}/list`} />
      <PruRoute exact path={`${roleUserManagementPath}/list`} component={RoleUserManagementListingPage} />
      <PruRoute exact path={`${roleUserManagementPath}/:mode/:id`} component={CreateUserPage} />
      <PruRoute exact path={`${roleUserManagementPath}/:mode`} component={CreateUserPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RoleUserManagementRoutes;
