import { FC, Fragment, useReducer, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  IconButton,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Tooltip,
  Dialog,
} from '@mui/material';
import { Edit, Delete, Error, Add, AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useLang } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import AntSwitch from 'src/app/common/components/AntSwitch';
import { CommonOperatorEnum, CategoryOperatorEnum } from '../../../../types/incentive-types';
import { KpiItem } from '../../../../types/kpi-types';
import { AwardFormState, awardFormReducer } from '../reducers';
import { IncentiveTableCell, IncentiveLeftStickyTableCell, IncentiveRightStickyTableCell } from './IncentiveTableCell';

type AwardSectionProps = {
  isDisabled: boolean;
  enableKpiShowHide: boolean;
  nonRankKpiList: KpiItem[];
  categoryKpiList: KpiItem[];
  initialFormState: AwardFormState;
  hasError: boolean;
  onChange: (newAwards: AwardFormState['awards']) => void;
  onDismissErrorHandler: (field: string, value: any) => void;
};

type DialogState = {
  open: boolean;
  awardIndex?: number;
};

const initialDialogState = { open: false, awardIndex: undefined };

const TIER_LIMIT = 4;
const CRITERIA_LIMIT = 3;
const CONDITION_LIMIT = 10;

const CRITERIA_COLUMN_WIDTH = 160;

const useStyles = makeStyles()(() => ({
  criteriaCell: {
    width: CRITERIA_COLUMN_WIDTH,
    minWidth: CRITERIA_COLUMN_WIDTH,
  },
  iconButton: {
    color: 'black',
    padding: 5,
  },
  noMargin: {
    margin: 0,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  tier: {
    minWidth: 60,
  },
  tierTextField: {
    width: 150,
    marginRight: 10,
  },
  select: {
    minWidth: 120,
  },
  checkbox: {
    paddingLeft: 0,
  },
  criteriaTextField: {
    width: 120,
  },
  orText: {
    color: '#919EAB',
    fontWeight: 400,
    marginRight: 10,
  },
  andText: {
    color: '#919EAB',
    fontWeight: 400,
    margin: '0 12px 0 12px',
  },
  criteriaOperationCell: {
    width: 87,
    minWidth: 87,
  },
  criteriaOperationIconButton: {
    color: '#58647A',
    padding: 0,
  },
  criteriaOperationIcon: {
    width: 25,
    height: 25,
  },
  switch: {
    marginLeft: 6,
  },
  smallSpacing: {
    marginRight: 5,
  },
  spacing: {
    marginRight: 10,
  },
  warningIcon: {
    color: '#FF7D00',
    marginRight: 5,
  },
  errorText: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#F018A6',
    marginTop: 4,
  },
  dialogContent: {
    padding: 16,
  },
  dialogFooter: {
    justifyContent: 'flex-end',
  },
}));

const AwardSection: FC<AwardSectionProps> = ({
  isDisabled,
  enableKpiShowHide,
  nonRankKpiList,
  categoryKpiList,
  initialFormState,
  hasError,
  onChange,
  onDismissErrorHandler,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();

  const [formState, formDispatch] = useReducer(awardFormReducer, initialFormState);
  const [deleteDialog, setDeleteDialog] = useState<DialogState>(initialDialogState);
  const { awards, editableTiers } = formState;

  useEffect(() => {
    onChange(awards);
  }, [awards]);

  const getCategoryKpiIndex = (keyId: string) => categoryKpiList.findIndex((kpiItem) => kpiItem._id === keyId);

  return (
    <>
      <div className={classes.rowSpacing}>
        <Button
          disabled={isDisabled || awards.length === TIER_LIMIT}
          variant="contained"
          color="secondary"
          onClick={() => formDispatch({ type: 'ADD_TIER' })}
        >
          {Translation('incentive.common.add_tier')}
        </Button>
        {hasError && <div className={classes.errorText}>All fields in Condition are mandatory</div>}
      </div>
      {awards.map((award, awardIndex) => {
        const disableAddCriteria = isDisabled || award.requirements.length === CRITERIA_LIMIT;
        const editableTier = editableTiers[awardIndex];
        return (
          <div key={`${award.name}-${awardIndex}`}>
            <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
              <div className={classes.rowContainer}>
                {editableTier.editable ? (
                  <>
                    <TextField
                      className={`${classes.tierTextField} ${classes.noMargin}`}
                      inputProps={{ maxLength: 15 }}
                      margin="dense"
                      variant="outlined"
                      error={editableTier.error && !editableTier.tierCode}
                      helperText={editableTier.error && !editableTier.tierCode && MANDATORY_FIELD_ERROR_TEXT}
                      value={editableTier.tierCode}
                      onChange={(e) =>
                        formDispatch({
                          type: 'SET_EDITABLE_TIER',
                          payload: {
                            awardIndex,
                            value: { ...editableTier, tierCode: e.target.value, error: false },
                          },
                        })
                      }
                    />
                    <TextField
                      className={`${classes.tierTextField} ${classes.noMargin}`}
                      inputProps={{ maxLength: 60 }}
                      margin="dense"
                      variant="outlined"
                      error={editableTier.error && !editableTier.name}
                      helperText={editableTier.error && !editableTier.name && MANDATORY_FIELD_ERROR_TEXT}
                      value={editableTier.name}
                      onChange={(e) =>
                        formDispatch({
                          type: 'SET_EDITABLE_TIER',
                          payload: {
                            awardIndex,
                            value: { ...editableTier, name: e.target.value, error: false },
                          },
                        })
                      }
                    />
                    <Button
                      className={classes.spacing}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (!!!editableTier.tierCode || !!!editableTier.name) {
                          formDispatch({
                            type: 'SET_EDITABLE_TIER',
                            payload: { awardIndex, value: { ...editableTier, error: true } },
                          });
                        } else {
                          formDispatch({
                            type: 'MODIFY_TIER',
                            payload: {
                              awardIndex,
                              value: {
                                ...award,
                                tierCode: editableTier.tierCode,
                                name: editableTier.name,
                              },
                            },
                          });
                          formDispatch({
                            type: 'SET_EDITABLE_TIER',
                            payload: { awardIndex, value: { ...editableTier, editable: false } },
                          });
                        }
                      }}
                    >
                      {Translation('app.button.save')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() =>
                        formDispatch({
                          type: 'SET_EDITABLE_TIER',
                          payload: {
                            awardIndex,
                            value: { editable: false, tierCode: award.tierCode, name: award.name, error: false },
                          },
                        })
                      }
                    >
                      {Translation('app.button.cancel')}
                    </Button>
                  </>
                ) : (
                  <>
                    <span className={`${classes.tier} ${classes.spacing}`}>{`${award.tierCode} - ${award.name}`}</span>
                    <IconButton
                      disabled={isDisabled}
                      className={classes.iconButton}
                      onClick={() =>
                        formDispatch({
                          type: 'SET_EDITABLE_TIER',
                          payload: { awardIndex, value: { ...editableTier, editable: true } },
                        })
                      }
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      disabled={isDisabled}
                      className={classes.iconButton}
                      onClick={() => setDeleteDialog({ open: true, awardIndex })}
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <IncentiveLeftStickyTableCell className={classes.criteriaCell}>
                      <div className={classes.rowContainer}>
                        <div className={classes.rowContainer}>
                          <span className={classes.smallSpacing}>{Translation('incentive.common.criteria')}</span>
                        </div>
                        <div style={{ flexGrow: 1, textAlign: 'right' }}>
                          <IconButton
                            disabled={disableAddCriteria}
                            className={classes.iconButton}
                            style={{
                              color: 'white',
                              visibility: disableAddCriteria ? 'hidden' : 'visible',
                            }}
                            onClick={() => formDispatch({ type: 'ADD_CRITERIA', payload: { awardIndex } })}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      </div>
                    </IncentiveLeftStickyTableCell>
                    <IncentiveTableCell>{Translation('incentive.common.condition')}</IncentiveTableCell>
                    <IncentiveRightStickyTableCell className={classes.criteriaOperationCell} />
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <TableContainer className={classes.rowSpacing}>
              <Table>
                <TableBody>
                  {award.requirements.map((requirement, requirementIndex) => (
                    <TableRow key={`${requirement.name}-${requirementIndex}`}>
                      <IncentiveLeftStickyTableCell className={classes.criteriaCell}>
                        <div className={classes.rowContainer}>
                          <span
                            className={classes.orText}
                            style={{ visibility: requirementIndex === 0 ? 'hidden' : 'visible' }}
                          >
                            OR
                          </span>
                          <IconButton
                            disabled={isDisabled}
                            className={classes.iconButton}
                            onClick={() => {
                              formDispatch({ type: 'REMOVE_CRITERIA', payload: { awardIndex, requirementIndex } });
                              if (hasError) {
                                onDismissErrorHandler('awards', true);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                          <span>{`${Translation('incentive.common.criteria')} ${requirementIndex + 1}`}</span>
                        </div>
                      </IncentiveLeftStickyTableCell>
                      <IncentiveTableCell>
                        <div className={classes.rowContainer}>
                          {requirement.items.map((item, itemIndex) => {
                            const itemsLength = requirement.items.length;
                            const categoryKpiIndex = getCategoryKpiIndex(item.keyId);
                            const isCategory = categoryKpiIndex !== -1;
                            const categoryValues = isCategory ? categoryKpiList[categoryKpiIndex].values : undefined;
                            return (
                              <Fragment key={`item-${itemIndex}`}>
                                <div className={classes.rowContainer}>
                                  <FormControl
                                    disabled={isDisabled}
                                    className={`${classes.select} ${classes.noMargin}`}
                                    margin="dense"
                                    variant="outlined"
                                  >
                                    <Select
                                      value={item.keyId}
                                      onChange={(e) => {
                                        const newItem = { ...item, keyId: e.target.value as string };
                                        if (getCategoryKpiIndex(newItem.keyId) !== -1) {
                                          newItem.operator = CategoryOperatorEnum.IN;
                                          newItem.value = [];
                                          newItem.isShown = false;
                                        } else if (isCategory) {
                                          newItem.operator = CommonOperatorEnum.GREATER_THAN_EQUAL;
                                          newItem.value = '';
                                        }
                                        formDispatch({
                                          type: 'MODIFY_ITEM',
                                          payload: {
                                            awardIndex,
                                            requirementIndex,
                                            itemIndex,
                                            value: newItem,
                                          },
                                        });
                                        if (hasError) {
                                          onDismissErrorHandler('awards', e.target.value);
                                        }
                                      }}
                                    >
                                      {nonRankKpiList.map((kpiItem) => (
                                        <MenuItem key={kpiItem._id} value={kpiItem._id}>
                                          {kpiItem.name[locale]}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    disabled={isDisabled}
                                    className={classes.noMargin}
                                    margin="dense"
                                    variant="outlined"
                                  >
                                    <Select
                                      value={item.operator}
                                      onChange={(e) =>
                                        formDispatch({
                                          type: 'MODIFY_ITEM',
                                          payload: {
                                            awardIndex,
                                            requirementIndex,
                                            itemIndex,
                                            value: { ...item, operator: e.target.value as CommonOperatorEnum },
                                          },
                                        })
                                      }
                                    >
                                      {!isCategory
                                        ? Object.values(CommonOperatorEnum).map((operator) => (
                                            <MenuItem key={operator} value={operator}>
                                              {operator}
                                            </MenuItem>
                                          ))
                                        : Object.values(CategoryOperatorEnum).map((operator) => (
                                            <MenuItem key={operator} value={operator}>
                                              {operator}
                                            </MenuItem>
                                          ))}
                                    </Select>
                                  </FormControl>
                                  {!isCategory || !categoryValues || categoryValues.length === 0 ? (
                                    <Tooltip
                                      arrow
                                      title={isDisabled && Array.isArray(item.value) ? item.value.join(', ') : ''}
                                    >
                                      <TextField
                                        disabled={isDisabled}
                                        className={`${classes.criteriaTextField} ${classes.noMargin}`}
                                        margin="dense"
                                        variant="outlined"
                                        placeholder={Translation('incentive.common.input_placeholder')}
                                        value={item.value}
                                        onChange={(e) => {
                                          formDispatch({
                                            type: 'MODIFY_ITEM',
                                            payload: {
                                              awardIndex,
                                              requirementIndex,
                                              itemIndex,
                                              value: { ...item, value: e.target.value },
                                            },
                                          });
                                          if (hasError) {
                                            onDismissErrorHandler('awards', e.target.value);
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      arrow
                                      title={isDisabled && Array.isArray(item.value) ? item.value.join(', ') : ''}
                                    >
                                      <FormControl
                                        disabled={isDisabled}
                                        className={`${classes.criteriaTextField} ${classes.noMargin}`}
                                        margin="dense"
                                        variant="outlined"
                                      >
                                        <Select
                                          multiple
                                          value={item.value}
                                          renderValue={(selected: any) =>
                                            selected.length > 1 ? selected.join(', ') : selected[0]
                                          }
                                          onChange={(e) => {
                                            formDispatch({
                                              type: 'MODIFY_ITEM',
                                              payload: {
                                                awardIndex,
                                                requirementIndex,
                                                itemIndex,
                                                value: { ...item, value: e.target.value as string[] },
                                              },
                                            });
                                            if (hasError) {
                                              onDismissErrorHandler('awards', e.target.value);
                                            }
                                          }}
                                        >
                                          {categoryValues.map((option) => (
                                            <MenuItem key={option} value={option}>
                                              <Checkbox
                                                className={classes.checkbox}
                                                checked={item.value.indexOf(option) !== -1}
                                              />
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Tooltip>
                                  )}
                                  {enableKpiShowHide && (
                                    <AntSwitch
                                      color="default"
                                      disabled={isDisabled || isCategory}
                                      className={classes.switch}
                                      checked={item.isShown}
                                      onChange={(e) =>
                                        formDispatch({
                                          type: 'MODIFY_ITEM',
                                          payload: {
                                            awardIndex,
                                            requirementIndex,
                                            itemIndex,
                                            value: { ...item, isShown: e.target.checked },
                                          },
                                        })
                                      }
                                    />
                                  )}
                                </div>
                                {itemsLength > 1 && itemIndex !== itemsLength - 1 && (
                                  <span className={classes.andText}>AND</span>
                                )}
                              </Fragment>
                            );
                          })}
                        </div>
                      </IncentiveTableCell>
                      <IncentiveRightStickyTableCell className={classes.criteriaOperationCell}>
                        <div className={classes.rowContainer}>
                          <IconButton
                            disabled={isDisabled || requirement.items.length === 1}
                            className={`${classes.criteriaOperationIconButton} ${classes.smallSpacing}`}
                            onClick={() => {
                              formDispatch({
                                type: 'REMOVE_ITEM',
                                payload: { awardIndex, requirementIndex, itemIndex: requirement.items.length - 1 },
                              });
                              if (hasError) {
                                onDismissErrorHandler('awards', true);
                              }
                            }}
                          >
                            <RemoveCircleOutline className={classes.criteriaOperationIcon} />
                          </IconButton>
                          <IconButton
                            disabled={isDisabled || requirement.items.length === CONDITION_LIMIT}
                            className={classes.criteriaOperationIconButton}
                            onClick={() =>
                              formDispatch({ type: 'ADD_ITEM', payload: { awardIndex, requirementIndex } })
                            }
                          >
                            <AddCircleOutline className={classes.criteriaOperationIcon} />
                          </IconButton>
                        </div>
                      </IncentiveRightStickyTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
      {deleteDialog.open && deleteDialog.awardIndex !== undefined && (
        <Dialog open={deleteDialog.open}>
          <div className={classes.dialogContent}>
            <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
              <Error className={classes.warningIcon} />
              {Translation('incentive.common.delete_tier_warning')}
            </div>
            <div className={`${classes.rowContainer} ${classes.dialogFooter}`}>
              <Button
                className={classes.spacing}
                variant="contained"
                onClick={() => setDeleteDialog(initialDialogState)}
              >
                {Translation('app.button.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  formDispatch({ type: 'REMOVE_TIER', payload: { awardIndex: deleteDialog.awardIndex as number } });
                  setDeleteDialog(initialDialogState);
                  if (hasError) {
                    onDismissErrorHandler('awards', true);
                  }
                }}
              >
                {Translation('app.button.delete')}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default AwardSection;
