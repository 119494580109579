import React, { FC, useState } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { ProductionTarget } from '../../../types/production-target-types';
import { useCommonStyles } from '../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { IconButton, Button, TextField, CircularProgress, Tooltip, Checkbox } from '@mui/material';
import {
  toAbsoluteUrl,
  numberSeparator,
  removeSeparator,
  isValueExist,
  getDefaultDisplayDate,
} from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { updateProductionTarget, UpdateProductionTargetBody } from '../../../network/productionCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

type ProductionTargetItemProps = {
  target: ProductionTarget;
  onRefresh: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    padding: '10px 20px 0 20px',
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
    borderBottom: '1px solid #EEEEEE',
  },
  contentContainer: {
    padding: '8px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainer: {
    padding: '8px 10px 0px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  footerContainer: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #EEEEEE',
  },
  sectionSize: {
    width: 160,
  },
  contentHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.grey[300],
    opacity: 0.35,
    borderRadius: 8,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type EditState = {
  netAfyp: string;
  netAfypHnp: string;
  netCase: string;
  defaultNetAfyp: string;
  defaultNetAfypHnp: string;
  defaultNetCase: string;
  enableEdit: boolean;
};

const initEditState = (target: ProductionTarget): EditState => {
  return {
    netAfyp: target.netAfyp ? numberSeparator(String(target.netAfyp)) : '',
    netAfypHnp: target.netAfypHnp ? numberSeparator(String(target.netAfypHnp)) : '',
    netCase: target.netCase ? numberSeparator(String(target.netCase)) : '',
    defaultNetAfyp: target.defaults && target.defaults.netAfyp ? numberSeparator(String(target.defaults.netAfyp)) : '',
    defaultNetAfypHnp:
      target.defaults && target.defaults.netAfypHnp ? numberSeparator(String(target.defaults.netAfypHnp)) : '',
    defaultNetCase: target.defaults && target.defaults.netCase ? numberSeparator(String(target.defaults.netCase)) : '',
    enableEdit: target.enableEdit,
  };
};

const ProductionTargetItem: FC<ProductionTargetItemProps> = ({ target, onRefresh }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editState, setEditState] = useState<EditState | undefined>();

  const onSave = () => {
    if (editState !== undefined) {
      const body: UpdateProductionTargetBody = {
        target: target.target,
        targetRef: target.targetRef,
        year: String(target.year),
        month: target.month ? String(target.month) : undefined,
        netAfyp: Number(removeSeparator(editState.netAfyp)),
        netAfypHnp: Number(removeSeparator(editState.netAfypHnp)),
        netCase: Number(removeSeparator(editState.netCase)),
        defaults: {
          netAfyp: Number(removeSeparator(editState.defaultNetAfyp)),
          netAfypHnp: Number(removeSeparator(editState.defaultNetAfypHnp)),
          netCase: Number(removeSeparator(editState.defaultNetCase)),
        },
        enableEdit: editState.enableEdit,
      };
      setIsLoading(true);
      updateProductionTarget(body, dispatch)
        .then(() => {
          setIsLoading(false);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Production Target modified successfully`,
              },
            ]),
          );
          onRefresh();
        })
        .finally(() => {
          setIsLoading(false);
          setEditState(undefined);
        });
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && (
        <>
          <div className={classes.modal}>
            <CircularProgress color="secondary" size={45} />
          </div>
        </>
      )}
      <div className={classes.headerContainer}>
        <div className={commonClasses.header}>
          {target.year &&
            !target.month &&
            intl.formatMessage(
              { id: 'productionTarget.title.yearTarget' },
              {
                year: target.year,
              },
            )}
          {target.year && target.month && getDefaultDisplayDate(new Date(target.year, target.month - 1), 'month')}
        </div>
        <div>
          <Tooltip title={Translation('productionTarget.btn.editTarget')}>
            <IconButton style={{ padding: 7 }} onClick={() => setEditState(initEditState(target))}>
              <img style={{ width: 20, height: 20 }} src={toAbsoluteUrl('/media/custom/edit.png')} alt="Edit Icon" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.contentHeader}>{Translation('productionTarget.common.enableEdit')}</div>
        <Checkbox
          style={{ marginLeft: 10, padding: 3 }}
          disabled={editState === undefined}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          checked={editState ? editState.enableEdit : target.enableEdit}
          onChange={(e) => {
            if (editState) {
              setEditState({
                ...editState,
                enableEdit: e.target.checked,
              });
            }
          }}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.netAfyp')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: 150 }}
              margin="dense"
              variant="outlined"
              value={editState.netAfyp}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  netAfyp: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#ED1B2E' }} className={classes.content}>
              {isValueExist(target.netAfyp) ? `$${numberSeparator(String(target.netAfyp))}` : '--'}
            </div>
          )}
        </div>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.netHnpAfyp')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: 150 }}
              margin="dense"
              variant="outlined"
              value={editState.netAfypHnp}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  netAfypHnp: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#3994F2' }} className={classes.content}>
              {isValueExist(target.netAfypHnp) ? `$${numberSeparator(String(target.netAfypHnp))}` : '--'}
            </div>
          )}
        </div>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.netCase')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: '100%' }}
              margin="dense"
              variant="outlined"
              value={editState.netCase}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  netCase: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#44CF7C' }} className={classes.content}>
              {isValueExist(target.netCase) ? numberSeparator(String(target.netCase)) : '--'}
            </div>
          )}
        </div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.defaultNetAfyp')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: 150 }}
              margin="dense"
              variant="outlined"
              value={editState.defaultNetAfyp}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  defaultNetAfyp: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#ED1B2E' }} className={classes.content}>
              {target.defaults && isValueExist(target.defaults.netAfyp)
                ? `$${numberSeparator(String(target.defaults.netAfyp))}`
                : '--'}
            </div>
          )}
        </div>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.defaultNetHnpAfyp')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: 150 }}
              margin="dense"
              variant="outlined"
              value={editState.defaultNetAfypHnp}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  defaultNetAfypHnp: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#3994F2' }} className={classes.content}>
              {target.defaults && isValueExist(target.defaults.netAfypHnp)
                ? `$${numberSeparator(String(target.defaults.netAfypHnp))}`
                : '--'}
            </div>
          )}
        </div>
        <div className={classes.sectionSize}>
          <div className={classes.contentHeader}>{Translation('productionTarget.common.defaultNetCase')}</div>
          {editState !== undefined ? (
            <TextField
              style={{ width: '100%' }}
              margin="dense"
              variant="outlined"
              value={editState.defaultNetCase}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setEditState({
                  ...editState,
                  defaultNetCase: numberSeparator(onlyNums),
                });
              }}
            />
          ) : (
            <div style={{ color: '#44CF7C' }} className={classes.content}>
              {target.defaults && isValueExist(target.defaults.netCase)
                ? numberSeparator(String(target.defaults.netCase))
                : '--'}
            </div>
          )}
        </div>
      </div>
      {editState !== undefined && (
        <div className={classes.footerContainer}>
          <Button style={{ marginRight: 10 }} size="small" onClick={() => setEditState(undefined)}>
            {Translation('global.text.cancel')}
          </Button>
          <Button size="small" color="secondary" onClick={() => onSave()}>
            {Translation('global.text.confirm')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductionTargetItem;
