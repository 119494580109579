import React, { FC, useState } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignItem } from 'src/app/modules/PruleadInsightsReporting/types/insights-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { campaignPath } from '../../CampaignRoutes';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';

type CampaignListProps = {
  isLoading: boolean;
  campaignList?: PaginateList<CampaignItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const CampaignList: FC<CampaignListProps> = ({ isLoading, campaignList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  return (
    <>
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('pulseleads.campaign.list')}
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Report',
            //condition: (row) => row.status === CampaignStatusEnum.CANCELED,
            onClick: (row) => history.push(`${campaignPath}/view/` + row.campaignId + '/' + row._id),
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'campaignId',
            displayName: Translation('pulseleads.campaign.common.campaignId'),
            renderData: (row) => row.campaignId || '-',
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.startDate'),
            renderData: (row) => getDefaultDisplayDate(row.startDate) || '-',
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.endDate'),
            renderData: (row) => getDefaultDisplayDate(row.endDate) || '-',
          },
          {
            keyIndex: 'marketingOwnerName',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.marketingOwnerName'),
            renderData: (row) => row.marketingOwnerName || '-',
          },
          {
            keyIndex: 'relativeSourceName',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.source'),
            renderData: (row) => row.relativeSourceName || '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.status'),
            renderData: (row) => (row.status ? Translation(`pulseleads.campaign.status.option.${row.status}`) : '-'),
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.campaign.common.updatedBy'),
            renderData: (row) => row.updatedBy,
          },
        ]}
        dataSource={campaignList?.docs}
        totalPages={campaignList?.totalPages}
        totalRecords={campaignList?.totalDocs}
      />
    </>
  );
};

export default CampaignList;
