import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import {
  RoleUserManagementListParam,
  fetchRoleUserManagementList,
  RoleUserManagementItem,
} from '../../../network/role-user-management-crud'; // create role-user-management-crud.ts in types folder

const initialState: RoleUserManagementListParam = {
  page: 1,
  limit: 5,
};

export const roleUserManagementFilterKeys = [];

const paramsInitiator = (initialParams?: Record<string, string>): RoleUserManagementListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

export const useRoleUserManagementListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<RoleUserManagementListParam>(paramsInitiator(initialParams));
  const [roleUserManagementList, setRoleUserManagementList] = useState<PaginateList<RoleUserManagementItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<RoleUserManagementItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchRoleUserManagementList(filterState, dispatch);
      } catch (err) {}
    },
    setRoleUserManagementList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  return {
    filterState,
    roleUserManagementList,
    isLoading,
    setFilterState,
    refreshData,
    onSort,
  };
};
