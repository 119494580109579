import React, { useState, useEffect, ChangeEvent, CSSProperties, useImperativeHandle, memo } from 'react';
// import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import Preview from '../Preview';
import * as imageConversion from 'image-conversion';
import { createBlob, getBlob } from 'src/app/common/network';
import { get } from 'lodash';
import { getFileSize } from '../../utils/file-utils';

const compressFileSize = 150;

export interface FileObj extends Record<string, any> {
  url?: string;
  importId: string;
  fileName: string;
}

export interface FileUploaderProps {
  showAllowText?: any;
  // btnType: ButtonType;
  btnTxt?: string;
  disabled?: boolean;
  onChange?: (value?: FileObj | null) => void;
  value?: FileObj;
  // upload: (params: { file: Blob, fileName: string, fileType: string }) => Promise<{ id: string, fileName?: string, url?: string }>
  upload: any;
  color?: any;
  maxFileSize?: number; // in MB
  allowedFileTypes?: string[];
  download?: (fileName: string, url: string) => void;
  enabledDownload?: boolean; // click filename to download
  showPreview?: boolean;
  needCompressImage?: boolean;
  linkStyle?: CSSProperties;
  allowDuplicateFile?: boolean;
  maxFileNumberErrorText?: string;
  maxFileNumber?: number;
  showFileSize?: boolean;
}
const closeIcon = {
  verticalAlign: 'middle',
  cursor: 'pointer',
  color: '#666',
};
const uploadBtn = { marginRight: '1rem' };

const FileUploader = (props: FileUploaderProps, ref: any) => {
  const [loadPercentage, setLoadPercentage] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [openPreview, handleOpenPreview] = useState<boolean>(false);
  const [previewContent, handlePreviewContent] = useState<any>();
  const [value, setValue] = useState<any>(undefined);

  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  let flag: any = null;
  const {
    showAllowText,
    // btnType,
    btnTxt = Translation('app.button.upload'),
    disabled,
    value: valueProp,
    onChange,
    upload,
    color = 'primary',
    maxFileSize,
    allowedFileTypes,
    download,
    enabledDownload = true,
    showPreview,
    needCompressImage,
    linkStyle,
    allowDuplicateFile,
    maxFileNumber,
    maxFileNumberErrorText,
    showFileSize,
  } = props;
  const inputRef = React.createRef<HTMLInputElement>();

  useImperativeHandle(ref, () => ({
    clear() {
      setValue('');
    },
  }));

  const onSelect = () => {
    inputRef.current!.click();
  };

  const triggerChange = (changedValue: FileObj | null) => {
    if (onChange) {
      if (changedValue) {
        onChange({
          ...value,
          ...changedValue,
        });
      } else {
        onChange(null);
      }
    }
  };

  const simulatorPercentage = () => {
    flag = setInterval(() => {
      setLoadPercentage((v) => {
        if (v < 90) {
          return v + 10;
        }
        clearInterval(flag);
        return v;
      });
    }, 1000);
  };

  const updateUploadFlag = (type = 'add') => {
    const curr = sessionStorage.getItem('isUploadFile') || 0;
    sessionStorage.setItem('isUploadFile', String(Number(curr) + (type === 'add' ? 1 : -1)));
  };

  const compressFile = async (file: any, fileType: any) => {
    const fileSize = file.size / 1024;
    const needCompress = needCompressImage && fileSize > compressFileSize;

    if (needCompress) {
      const blob = await imageConversion.compressAccurately(file, compressFileSize);
      return blob;
    } else {
      return new Blob([file], { type: fileType });
    }
  };

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    inputRef.current!.value = '';
    const { name: fileName, type: fileType } = file || {};
    simulatorPercentage();
    setIsUploading(true);
    updateUploadFlag('add');
    const newFileBlob = await compressFile(file, fileType);

    upload({
      file: newFileBlob,
      fileName,
      fileType,
      setLoadPercentage,
    })
      .then((res: any) => {
        // .then(({ id, url } : { id: string, url?: string }) => {
        const url = res.url;
        const httpUrl = url?.includes('http') ? url : `https:${url}`;
        triggerChange({
          ...res,
          url: httpUrl,
          importId: res.id,
          fileName,
        });
        setLoadPercentage(100);
        setIsUploading(false);
        clearInterval(flag);
        updateUploadFlag('minus');
      })
      .catch((err: any) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: err?.message || err?.responseMessage || Translation('global.submit.fail'),
            },
          ]),
        );
        triggerChange(null);
        setIsUploading(false);
        setLoadPercentage(0);
        clearInterval(flag);
        updateUploadFlag('minus');
      });
  };

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    // check if set max file numbers
    if (maxFileNumber && !checkMaxFileNumbers(e.target.files, maxFileNumber, maxFileNumberErrorText)) {
      if (allowDuplicateFile) {
        e.target.value = '';
      }
      return;
    }
    const file = (e.target.files || [])[0];
    if (!checkFile(file, maxFileSize, allowedFileTypes)) {
      // reset input value for next change event triggered
      if (allowDuplicateFile) {
        e.target.value = '';
      }
      return;
    }
    onUploadFile(e);
  };

  const deleteFile = () => {
    triggerChange(null);
    setLoadPercentage(0);
  };

  const checkMaxFileNumbers = (files: any, maxFileNumber: number, maxFileErrorText: any) => {
    if (files?.length && files.length > maxFileNumber) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: maxFileErrorText,
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file?.name?.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: TranslationWithVariable('global.max.file.type.allow', fileTypes.join('/')),
          },
        ]),
      );
      return false;
    }

    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: TranslationWithVariable('global.max.file.size.allow', maxSize),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const onPreview = () => {
    if (!value) return;
    handlePreviewContent(`<img src="${value.url}" width="100%" />`);
    handleOpenPreview(true);
  };

  useEffect(() => {
    return () => {
      clearInterval(flag);
      sessionStorage.setItem('isUploadFile', '0');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fileSrouce = valueProp ? { ...valueProp } : null;
    if (!fileSrouce) {
      return setValue('');
    }
    const loadResouce = async () => {
      if ((!fileSrouce.size && showFileSize) || (!fileSrouce.url && enabledDownload)) {
        const blobDetail = await getBlob(
          { resourceIds: fileSrouce.id || fileSrouce.blobId, random: Math.random() },
          dispatch,
        );
        const result = blobDetail?.[0];
        if (result) {
          fileSrouce.url = result.url;
          if (showFileSize) {
            const contentLength: number | undefined = get(result, 'meta.contentLength');
            if (contentLength) {
              // file size in KB
              fileSrouce.size = Math.round(contentLength / 1024);
            } else {
              const fileSize = await getFileSize(result.url).catch(() => 0);
              fileSrouce.size = fileSize;
            }
          }
        }
      }
      setValue(fileSrouce);
    };

    loadResouce();

    // setValue(fileSrouce);

    return;
    // eslint-disable-next-line
  }, [valueProp]);

  return (
    <>
      {!isUploading && !value && (
        <>
          <Button
            /*type={btnType}*/ disabled={disabled}
            onClick={onSelect}
            variant="contained"
            color={color}
            component="span"
            style={uploadBtn}
          >
            <AddIcon />
            {btnTxt}
          </Button>
          {showAllowText && <span className="ml10">{showAllowText}</span>}
        </>
      )}
      {/* {isUploading && <Progress percent={loadPercentage} />} */}

      <div className={isUploading ? 'tw-w-full' : 'tw-hidden'}>
        <LinearProgress variant="determinate" value={loadPercentage} />
      </div>

      {/* <LinearProgress variant="determinate" value={50} /> */}
      {value && !isUploading && (
        <div>
          {/* eslint-disable-next-line */}
          <a
            href={value.url}
            target="_blank"
            onClick={(download && download(value.fileName, value.url)) || undefined}
            style={linkStyle}
            className={enabledDownload ? '' : 'tw-pointer-events-none'}
          >
            {value.fileName}
          </a>
          <span title="file size">{value.size ? ` (${value.size} KB)` : ''} </span>
          {!disabled && <Delete style={closeIcon} onClick={deleteFile} />}
          {showPreview ? (
            <Button onClick={onPreview} component="span" style={{ marginLeft: 50 }}>
              {Translation('global.text.preview')}
            </Button>
          ) : null}
        </div>
      )}
      <input style={{ display: 'none' }} type="file" id="input" ref={inputRef} multiple onChange={change} />
      <Preview html={previewContent} open={openPreview} onClose={() => handleOpenPreview(false)} />
    </>
  );
};

export default memo(React.forwardRef(FileUploader));
