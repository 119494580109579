export const ROLE_CONTROL_BASE_PATH = '/permission';

export const ROLE_CONTROL_PERMISSION_PATH = '/role';

export const ROLE_CONTROL_USER_MANAGEMENT_PATH = '/user';

//  These constants map to aside menu, header menu to display title
export const ROLE_CONTROL_TITLE = 'title.roleControl';
export const ROLE_PERMISSION_TITLE = 'title.roleControl.permissionMgmt';

//  These constants map to permission control, identical to permission enum defined below
export const ROLE_PERMISSION_NAMESPACE = 'ROLE-PERMISSION';
export const ROLE_USER_MANAGEMENT_NAMESPACE = 'ROLE-USER-MANAGEMENT';

export enum AdminRolePermissionType {
  ROLE_PERMISSION_READ = 'READ_ANY_ROLE-PERMISSION',
  ROLE_PERMISSION_CREATE = 'CREATE_ANY_ROLE-PERMISSION',
  ROLE_PERMISSION_UPDATE = 'UPDATE_ANY_ROLE-PERMISSION',
}
