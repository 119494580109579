import React, { FC, useEffect, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import {
  createNewBanner,
  fetchBannerItem,
  fetchBannerList,
  modifyBanner,
  publishBanner,
  unpublishBanner,
} from '../../../network/bannerCrud';
import { useDataProvider, useJWT } from 'src/app/common/utils';
import {
  BannerEditItem,
  BannerFormState,
  BannerItem,
  BannerPaginateList,
  BannerPublishedMode,
  BannerValidMode,
  CanBeShared,
  IsPublish,
  OpenByType,
  BannerListParam,
  RedirectType,
} from '../../../types/operation-type';
import { useDispatch } from 'react-redux';
import BannerList from './components/BannerList';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle } from '@mui/material';
import AddForm from './components/Create';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { forEach } from 'lodash';
import { MAX_BANNER_PUBLISHED_LIMIT } from '../../../constants';
import PruDialog from '../../../../../common/components/PruDialog';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
type BannerListingPageProps = {} & ParamsProps & ModulePermissionProps;

const initialState: BannerListParam = {
  pageSize: 20,
  page: 1,
  name: '',
  isPublish: '',
  isValid: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): BannerListParam => {
  return initialParams
    ? {
        page: 1,
        pageSize: 20,
        name: initialParams.name || '',
        isPublish: initialParams.isPublish || '',
        isValid: initialParams.isValid || '',
      }
    : initialState;
};

export const bannerFilterKeys = ['name', 'isPublish', 'isValid'];

const DEDAULT_FORM_DATA: BannerFormState = {
  linkType: '',
  linkTo: '',
  openBy: '',
  canShared: '',
  weight: '',
  effectiveRange: ['', ''],
  status: '',
  isPublish: '',
  lastUpdatedBy: '',
};

const BannerListingPage: FC<BannerListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  enableUpdate,
  enableCreate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const jwt = useJWT();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [isDiasbledForm, handleDisabledForm] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<BannerFormState>(DEDAULT_FORM_DATA);
  const [allWeights, setWeights] = useState<{ [key: string]: number }>({});
  const [totalPublished, setTotalPublished] = useState<number>(0);

  const [filterState, setFilterState] = useState<BannerListParam>(paramsInitiator(initialParams));
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [submitLocalizationsArray, setsubmitLocalizationsArray] = useState<string[]>([]);

  const [bannerList, setBannerList] = useState<BannerPaginateList>();
  const [openWarning, handleOpenWarning] = useState<boolean>(false);
  const regionLocales = regionLocale;

  const filterOptions = {
    publishStatus: [
      { displayName: Translation('component.status.all'), value: BannerPublishedMode.ALL },
      { displayName: Translation('component.status.published'), value: BannerPublishedMode.PUBLISHED },
      { displayName: Translation('component.status.unpublished'), value: BannerPublishedMode.UNPUBLISHED },
    ],
    validStatus: [
      { displayName: Translation('component.status.all'), value: BannerValidMode.ALL },
      { displayName: Translation('component.status.valid'), value: BannerValidMode.VALID },
      { displayName: Translation('component.status.invalid'), value: BannerValidMode.INVALID },
    ],
  };

  const { isLoading, refreshData } = useDataProvider<BannerPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        getTotalPublishList();
        return await fetchBannerList(locale, filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setBannerList,
    false,
  );

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  const getTotalPublishList = async () => {
    const publishList = await fetchBannerList(locale, { isPublish: BannerPublishedMode.PUBLISHED }, [], dispatch);
    setTotalPublished(publishList.total);

    const weightObj: { [key: string]: number } = {};
    forEach(publishList.bannerList, (bannerItem, key) => {
      if (bannerItem.weight) {
        weightObj[bannerItem.id] = parseFloat(bannerItem.weight);
      }
    });
    setWeights(weightObj);
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const convertEditParams = async (rowData: BannerItem, lang: string) => {
    let result: any = {};
    const effectiveRange: [string, string] = [
      rowData.effectiveDate ? new Date(rowData.effectiveDate).toISOString() : '',
      rowData.expiredDate ? new Date(rowData.expiredDate).toISOString() : '',
    ];
    result = {
      ...DEDAULT_FORM_DATA,
      linkType: rowData.linkType || '',
      linkTo: rowData.linkTo || '',
      openBy: rowData.openBy || OpenByType['pruforce-webview'],
      canShared: rowData.canShare ? CanBeShared['yes'] : CanBeShared['no'],
      weight: rowData.weight,
      effectiveRange: effectiveRange,
      linkParams: rowData.linkParams,
    };

    result[rowData.locale] = { ...rowData.i18nData, name: rowData.name };
    if (rowData.localizations && rowData.localizations.length >= 1) {
      for (var index = 0; index < rowData.localizations?.length; index++) {
        const itemResult = await fetchBannerItem(rowData.localizations[index].id, dispatch);
        result[itemResult.locale] = { ...itemResult.i18nData, name: itemResult.name };
        if (index === rowData.localizations?.length - 1) {
          return result;
        }
      }
    } else {
      return result;
    }
  };

  const editBanner = async (rowData: BannerItem, mode: string, lang: string = 'en') => {
    setSelectedId(rowData.id);
    const editParam = await convertEditParams(rowData, lang);
    const isPublish = rowData.publishedAt ? 'Y' : 'N';
    setInitialValues({ ...editParam, isPublish });
    if (mode === 'view') {
      handleDisabledForm(true);
    }
    handleModalVisible(true);
  };

  const onCancel = () => {
    handleDisabledForm(false);
    handleModalVisible(false);
    setSelectedId('');
    setInitialValues({ ...DEDAULT_FORM_DATA });
  };

  const submitForm = async (formData: BannerFormState) => {
    console.log(formData);
    if (
      initialValues.isPublish !== IsPublish['save-publish'] &&
      formData.isPublish === IsPublish['save-publish'] &&
      totalPublished >= MAX_BANNER_PUBLISHED_LIMIT
    ) {
      handleOpenWarning(true);
      return;
    }

    // @ts-ignore
    const localeData = formData[locale];

    const currentLocaleSubmitData: BannerEditItem = {
      openBy: formData.openBy,
      canShare: formData.canShared === 'Y' ? true : false,
      effectiveDate: formData.effectiveRange[0] ? new Date(formData.effectiveRange[0]).toISOString() : null,
      expiredDate: formData.effectiveRange[1] ? new Date(formData.effectiveRange[1]).toISOString() : null,
      i18nData: { banner: localeData.banner },
      linkTo: formData.linkType === RedirectType.no ? '' : formData.linkTo,
      linkType: formData.linkType,
      name: localeData.name || '',
      weight: formData.weight ? parseFloat(formData.weight.toString()) : null,
      linkParams: formData.linkParams,
    };

    if (selectedId) {
      //Edit
      await callSubmitEditAction(selectedId, currentLocaleSubmitData, formData, true);
    } else {
      // Create
      await callSubmitCreateAction(currentLocaleSubmitData, formData, locale, true);
    }
  };

  const callSubmitEditAction = async (
    selectedId: string,
    sumbitData: BannerEditItem,
    formData: BannerFormState,
    isOriginalLocale: boolean = false,
  ) => {
    try {
      const resultData = await modifyBanner(selectedId, sumbitData, dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      console.log(resultData);
      if (formData.isPublish === 'N' && resultData.publishedAt) {
        //If existing is in Publish mode, and user want to save as draft
        await unpublishBanner(resultData.id);
      }
      if (formData.isPublish === 'Y' && !resultData.publishedAt) {
        //If existing is in draft mode, and user want to save as publish
        await publishBanner(resultData.id);
      }
      if (isOriginalLocale) {
        //Call edit action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const regionLocaleItem = regionLocales[i];
          if (regionLocaleItem !== resultData.locale) {
            const localeItem = resultData.localizations?.filter((item) => regionLocaleItem === item.locale)[0];
            // @ts-ignore
            const localeData = formData[regionLocaleItem];
            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              localizations: localizationArray,
              i18nData: { banner: localeData.banner },
            };
            if (localeItem) {
              await callSubmitEditAction(localeItem.id, newLocalSubmitData, formData, false);
            } else {
              await callSubmitCreateAction(newLocalSubmitData, formData, regionLocaleItem, false, resultData);
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      submitFail();
    }
  };

  const callSubmitCreateAction = async (
    sumbitData: BannerEditItem,
    formData: BannerFormState,
    locale: string,
    isOriginalLocale: boolean = false,
    prevResultData?: BannerItem,
  ) => {
    try {
      const resultData = await createNewBanner(sumbitData, locale, prevResultData ? prevResultData.id : '', dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      if (formData.isPublish === 'Y') {
        // call publish again
        await publishBanner(resultData.id, dispatch);
      }
      if (isOriginalLocale) {
        //Call create action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const localItem = regionLocales[i];
          if (localItem !== locale) {
            // @ts-ignore
            const localeData = formData[localItem];
            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: { banner: localeData.banner },
              localizations: localizationArray,
            };
            await callSubmitCreateAction(newLocalSubmitData, formData, localItem, false, resultData);
          }
        }
        submitSuccess();
      }
    } catch (err) {
      submitFail();
    }
  };

  const submitSuccess = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );
    handleModalVisible(false);
    refreshData();
    setFormLoading(false);
    setInitialValues({ ...DEDAULT_FORM_DATA });
    setSelectedId('');
    setsubmitLocalizationsArray([]);
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setFormLoading(false);
  };

  const onPublishBannerClicked = async (rowItem: BannerItem) => {
    if (totalPublished >= MAX_BANNER_PUBLISHED_LIMIT) {
      handleOpenWarning(true);
      return;
    }
    try {
      await publishBanner(rowItem.id, dispatch);
      forEach(rowItem.localizations, async (localeItem, key) => {
        await publishBanner(localeItem.id, dispatch);
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Publish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const onUnpublishBannerClicked = async (rowItem: BannerItem) => {
    try {
      await unpublishBanner(rowItem.id, dispatch);
      forEach(rowItem.localizations, async (localeItem, key) => {
        await unpublishBanner(localeItem.id, dispatch);
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const closeWaringDialog = () => {
    handleOpenWarning(false);
  };

  return (
    <>
      <PruFilter
        title={Translation('component.filterTitle.operation-banner')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isPublish',
            initialValue: filterState.isPublish,
            displayName: Translation('component.formLabel.publish-status'),
            choices: filterOptions.publishStatus,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'publishDateStart',
            fieldTo: 'publishDateEnd',
            initialDateFrom: filterState.publishDateStart,
            initialDateTo: filterState.publishDateEnd,
            displayName: Translation('component.formLabel.published-time'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isValid',
            initialValue: filterState.isValid,
            displayName: Translation('component.formLabel.valid-status'),
            choices: filterOptions.validStatus,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            isPublish: data.isPublish,
            publishDateStart: data.publishDateStart,
            publishDateEnd: data.publishDateEnd,
            isValid: data.isValid,
          })
        }
        fetchData={refreshData}
      />
      <BannerList
        isLoading={isLoading}
        bannerList={bannerList}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        onAddBanner={() => handleModalVisible(true)}
        onEditBanner={(rowData, mode) => editBanner(rowData, mode, locale)}
        onPublishBannerClicked={(rowData) => onPublishBannerClicked(rowData)}
        onUnpublishBannerClicked={(rowData) => onUnpublishBannerClicked(rowData)}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
      />

      <Dialog open={createModalVisible} onClose={isDiasbledForm ? onCancel : () => {}} fullWidth maxWidth="md">
        <DialogTitle>
          {isDiasbledForm
            ? Translation('global.view.text')
            : selectedId
            ? Translation('global.edit.text')
            : Translation('global.add.text')}
        </DialogTitle>
        {createModalVisible ? (
          <AddForm
            onSave={submitForm}
            onCancel={onCancel}
            confirmLoading={formLoading}
            initialValues={initialValues}
            allWeights={allWeights}
            disabled={isDiasbledForm}
            id={selectedId}
          />
        ) : null}
      </Dialog>

      <PruDialog
        open={openWarning}
        canCloseDialog={true}
        hideCancelBtn={true}
        onCancel={closeWaringDialog}
        onOk={closeWaringDialog}
      >
        {TranslationWithVariable('component.banner.reachlimit', MAX_BANNER_PUBLISHED_LIMIT)}
      </PruDialog>
    </>
  );
};

export default BannerListingPage;
