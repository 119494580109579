import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TokenInfo } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { ApiErrorResponse } from '../network/apiErrorHandler';

export const useUpdateEffect = (callback: () => any, deps: any) => {
  const ref = useRef<boolean | null>(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else {
      callback();
    }
    // eslint-disable-next-line
  }, deps);
};

/**
 * DO NOT use this method except for <PruFilter />, it will cause multiple-rendering.
 * use useRequest instead. // import { useRequest } from 'ahooks';
 * refreshData: () => setRefresh(true): to trigger page rerender to collect filter params changes
 * @param callback api request method
 * @param setData api request done callback func
 * @param clear is clear data on fetch
 * @param clearValue value for clear data on fetch
 * @param manualTrigger is manual trigger refetch
 * @returns
 */
export const useDataProvider = <T = any>(
  callback: () => Promise<T | void | undefined>,
  setData: (data: T | undefined) => void,
  clear?: boolean,
  clearValue?: T,
  manualTrigger?: boolean,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiErrorResponse | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);

  const loadData = async () => {
    setError(undefined);
    if (clear) {
      setData(clearValue);
    }
    setIsLoading(true);
    try {
      const response = await callback();
      if (response) {
        setData(response);
      }
    } catch (e: any) {
      setError(e);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (!manualTrigger) {
      loadData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh) {
      loadData();
    }
    // eslint-disable-next-line
  }, [refresh]);

  return {
    isLoading,
    error,
    /**
     * important: need state "refresh" to trigger page rerender to collect params.
     * e.g. get filterState in PruFilter
     */
    refreshData: () => setRefresh(true),
  };
};

export const useParamReader = <T extends Record<string, unknown>>(names: string[]): Partial<T> => {
  const obj: Record<string, unknown> = {};
  const search = window.location.search;
  const params = new URLSearchParams(search);

  names.forEach((name) => {
    const val = params.get(name);
    if (!obj[name] && val !== undefined) {
      obj[name] = val;
    }
  });
  return obj as Partial<T>;
};

export const useJWT = (): TokenInfo => {
  return useSelector<RootState, TokenInfo>((state) => state.auth.user as TokenInfo);
};
