import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import Preview from '../Preview';

import * as imageConversion from 'image-conversion';
import { createBlob, getBlob } from 'src/app/common/network';

const compressFileSize = 150;

export interface FileObj {
  url?: string;
  importId: string;
  fileName: string;
}

export interface MultiFileUploaderProps {
  showAllowText?: any;
  // btnType: ButtonType;
  btnTxt?: string;
  disabled?: boolean;
  onChange?: (values?: FileObj[] | null) => void;
  values?: FileObj[];
  // upload: (params: { file: Blob, fileName: string, fileType: string }) => Promise<{ id: string, fileName?: string, url?: string }>
  upload: any;
  color?: any;
  maxFileSize?: number; // in MB
  maxFileCount?: number;
  allowedFileTypes?: string[];
  download?: any;
  showPreview?: boolean;
  needCompressImage?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
}));

const MultiFileUploader: React.FC<MultiFileUploaderProps> = (props) => {
  const [values, setValues] = useState<Record<string, any> | undefined>(undefined);
  const [loadPercentages, setLoadPercentages] = useState<number[]>([]);
  const [isUploading, setIsUploading] = useState<boolean[]>([]);
  const [openPreview, handleOpenPreview] = useState<boolean>(false);
  const [previewContent, handlePreviewContent] = useState<any>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { classes } = useStyles();
  const This = useRef<any>({});

  let flag: any = {};
  const {
    showAllowText,
    // btnType,
    btnTxt = Translation('app.button.upload'),
    disabled,
    values: valuesProp = [],
    onChange,
    upload,
    color = 'primary',
    maxFileSize,
    maxFileCount = 1,
    allowedFileTypes,
    download,
    showPreview,
    needCompressImage,
  } = props;
  const inputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    This.current.values = valuesProp || [];
    if (This.current.loadPercentages?.length !== This.current.values.length) {
      This.current.loadPercentages = This.current.values.map(() => 100);
      setLoadPercentages(This.current.loadPercentages);
    }
    if (This.current.isUploading?.length !== This.current.values.length) {
      This.current.isUploading = This.current.values.map(() => false);
      setIsUploading(This.current.isUploading);
    }

    const fileSrouces = valuesProp ? [...valuesProp] : [];

    const loadResouce = async () => {
      if (valuesProp.length > 0) {
        const methods = getResourceById(fileSrouces);

        const filesData = await Promise.all(methods.map((item: any) => item()));

        setValues(filesData);
      }
    };

    loadResouce();
  }, [valuesProp]);

  const getResourceById = (fileSrouces: any[]) => {
    const methods: any = [];

    const getBlobData = async (config: any) => {
      if (config.id) {
        const blobDetail = await getBlob({ resourceIds: config.id }, dispatch);

        const result = blobDetail?.[0];
        if (result) {
          config.url = result.url;
        }
      }

      return { ...config };
    };

    fileSrouces.map((config) => {
      if (config) {
        const p = () =>
          new Promise(async (resolve, reject) => {
            const data = await getBlobData(config);
            resolve(data);
          });

        methods.push(p);
      }
    });

    return methods;
  };

  const onSelect = () => {
    inputRef.current!.value = '';
    inputRef.current!.click();
  };

  const triggerFileChange = (changedValue: FileObj | null, index: number) => {
    This.current.values[index] = changedValue;
    triggerChange([...This.current.values]);
  };
  const updateUploading = (v: boolean, index: number) => {
    This.current.isUploading[index] = v;
    setIsUploading([...This.current.isUploading]);
  };

  const triggerChange = (changedValue: FileObj[] | null) => {
    if (onChange) {
      if (changedValue) {
        This.current.values = changedValue;
        onChange(changedValue);
      } else {
        onChange([]);
        This.current.values = [];
      }
    }
  };

  const setLoadPercentage = (n: number, index: number) => {
    This.current.loadPercentages[index] = n;
    setLoadPercentages([...This.current.loadPercentages]);
  };

  // eslint-disable-next-line
  const simulatorPercentage = (index: number) => {
    let p = 0;
    flag[index] = setInterval(() => {
      if (p < 90) {
        setLoadPercentage(p, index);
        p += 10;
      } else {
        setLoadPercentage(p, index);
        clearInterval(flag[index]);
      }
    }, 1000);
  };

  const updateUploadFlag = (type = 'add') => {
    const curr = sessionStorage.getItem('isUploadFile') || 0;
    sessionStorage.setItem('isUploadFile', String(Number(curr) + (type === 'add' ? 1 : -1)));
  };

  const compressFile = async (file: any, fileType: any) => {
    const fileSize = file.size / 1024;
    const needCompress = needCompressImage && fileSize > compressFileSize;

    if (needCompress) {
      const blob = await imageConversion.compressAccurately(file, compressFileSize);
      return blob;
    } else {
      return new Blob([file], { type: fileType });
    }
  };

  const onUploadFile = async (file: File, index: number) => {
    const { name: fileName, type: fileType } = file || {};
    // simulatorPercentage(index);
    updateUploading(true, index);
    setLoadPercentage(0, index);
    updateUploadFlag('add');
    const newFileBlob = await compressFile(file, fileType);

    upload({ file: newFileBlob, fileName, fileType, setLoadPercentage: (n: number) => setLoadPercentage(n, index) })
      .then((res: any) => {
        let url = res.url;
        const httpUrl = url?.includes('http') ? url : `https:${url}`;
        triggerFileChange(
          {
            ...res,
            url: httpUrl,
            importId: res.id,
            fileName,
          },
          index,
        );
        setLoadPercentage(100, index);
        updateUploading(false, index);
        clearInterval(flag[index]);
        updateUploadFlag('minus');
      })
      .catch(() => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: Translation('global.submit.fail'),
            },
          ]),
        );
        triggerFileChange(null, index);
        updateUploading(false, index);
        setLoadPercentage(0, index);
        clearInterval(flag[index]);
        updateUploadFlag('minus');
      });
  };

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const currentCount = values ? values.length : 0;
    let leftCount = maxFileCount - currentCount;

    ([...(e.target.files || [])] as Array<File>).forEach((file: any, index: number) => {
      if (!checkFile(file, maxFileSize, allowedFileTypes)) {
        console.debug('check failed');
        return;
      }
      if (leftCount-- <= 0) {
        console.debug('check count');
        return;
      }
      onUploadFile(file, index + currentCount);
    });
  };

  const deleteFile = (index: number) => {
    This.current.values.splice(index, 1);
    This.current.loadPercentages.splice(index, 1);
    This.current.isUploading.splice(index, 1);
    setIsUploading([...This.current.isUploading]);
    triggerChange([...This.current.values]);
    setLoadPercentages([...This.current.loadPercentages]);
  };

  const checkFile = (file: Blob, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file.type.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: TranslationWithVariable('global.max.file.type.allow', fileTypes.join('/')),
          },
        ]),
      );
      return false;
    }

    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: TranslationWithVariable('global.max.file.size.allow', maxSize),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const onPreview = (value: any) => {
    if (!value) {
      return;
    }
    const img: string = `<img src="${value.url}" width="100%" />`;
    handlePreviewContent(img);
    handleOpenPreview(true);
  };

  useEffect(() => {
    return () => {
      for (const index in flag) {
        clearInterval(flag[index]);
      }
      sessionStorage.setItem('isUploadFile', '0');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {(!values || values?.length < maxFileCount) && (
        <>
          <Button
            /*type={btnType}*/ disabled={disabled}
            onClick={onSelect}
            variant="contained"
            color={color}
            component="span"
            style={{ marginRight: 20 }}
          >
            <AddIcon />
            {btnTxt}
          </Button>
          {showAllowText && <span className="ml10">{showAllowText}</span>}
        </>
      )}
      {/* {isUploading && <Progress percent={loadPercentage} />} */}
      {(isUploading || []).map((uploading, index) => {
        return (
          <>
            {uploading && (
              <div style={{ width: '100% ', marginTop: 1 }}>
                <LinearProgress variant="determinate" value={loadPercentages[index]} />
              </div>
            )}
            {values && values[index] && !uploading && (
              <div>
                {/* eslint-disable-next-line */}
                <a
                  href={values[index].url}
                  target="_blank"
                  onClick={(download && download(values[index].fileName, values[index].url)) || (() => {})}
                >
                  {values[index].fileName}
                </a>
                {!disabled && <CloseIcon className={classes.closeIcon} onClick={() => deleteFile(index)} />}
                {showPreview ? (
                  <Button onClick={() => onPreview(values[index])} component="span" style={{ marginLeft: 50 }}>
                    {Translation('global.text.preview')}
                  </Button>
                ) : null}
              </div>
            )}
          </>
        );
      })}
      <input style={{ display: 'none' }} type="file" id="input" ref={inputRef} multiple onChange={change} />
      <Preview html={previewContent} open={openPreview} onClose={() => handleOpenPreview(false)} />
    </>
  );
};

export default MultiFileUploader;
