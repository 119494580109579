import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';

import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './create-user.style';
import { STATUS_ENUM, useCreateUser } from './create-user.hook';
import Form from 'src/app/common/components/Form';
import { useLang, regionLocale, RegionLocale } from 'src/app/i18n';
import { get } from 'lodash';
import { updateUserDetail, createUser } from '../../../network/role-user-management-crud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getConfigurations } from 'src/app/common/utils';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const CreateUserPage: React.FC<PageProps> = (props: PageProps) => {
  const { id, mode } = useParams<{ id: string; mode: string }>();
  const lang = useLang();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('roleControl.userManagement.form.nameRequired'),
  };
  const PASSWORD_REQUIRED_RULES = {
    required: true,
    message: Translation('roleControl.userManagement.form.passwordRequired'),
  };
  const configs = getConfigurations();

  const NAME_VIDATOR = {
    validator(_: any, value: string) {
      const regex = /^(?!\s*$)[A-Za-z\s]+$/;
      if (regex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(Translation('roleControl.userManagement.form.invalidName')));
    },
  };

  const PASSWORD_VIDATOR = {
    validator(_: any, value: string) {
      const regex = new RegExp(
        get(
          configs,
          'Global.validation.password.regex',
          '^(?!.*[\\s\\u4e00-\\u9fff])((?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\\d.*|.*[\\W_].*)|(?=.*[A-Z].*)(?=.*\\d.*)(?=.*[\\W_].*)|(?=.*[a-z].*)(?=.*\\d.*)(?=.*[\\W_].*)).{3,}$',
        ),
      );

      const { minLength, maxLength } = get(configs, 'Global.validation.password', { minLength: 8, maxLength: 16 });
      if (regex.test(value) && value.length >= minLength && /^(?!.*[\s\u4e00-\u9fff])/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(Translation('roleControl.userManagement.form.invalidPassword')));
    },
  };

  const defaultRegion = RegionLocale.ENGLISH;

  const sortedRegion = regionLocale.sort((X, Y) => {
    if (X === defaultRegion && Y !== defaultRegion) {
      return -1;
    } else if (X !== defaultRegion && Y === defaultRegion) {
      return 1;
    } else return 0;
  });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { initialValues, form, loading } = useCreateUser({ id: id, mode: mode });

  const onSave = () => {
    const data = form.getFieldsValue();
    if (mode === 'create') {
      createUser(data)
        .then((res) => {
          props.history.goBack();
        })
        .catch((err) => {
          dispatch(
            appendAlertItem([
              { severity: AlertType.ERROR, title: Translation('global.submit.fail'), content: err.message ?? '' },
            ]),
          );
        });
      return;
    }

    updateUserDetail(id, data).then((res) => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('roleControl.userManagement.form.success.updatedUser'),
          },
        ]),
      );
      props.history.goBack();
    });
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        onSave();
      })
      .catch((err) => {
        return;
      });

    form.submit();
    return false;
  };

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>
            {Translation(
              mode === 'edit' ? 'roleControl.userManagement.form.title.edit' : 'roleControl.userManagement.form.title',
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {!loading && (
        <>
          <Form form={form} onFinish={onSave} initialValues={{ ...initialValues }}>
            <Form.Item label={Translation('roleControl.userManagement.form.userId')} name="userId">
              <TextField
                defaultValue={form.getFieldValue('userId')}
                className={classes.textInput}
                margin="dense"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: mode === 'edit',
                }}
                sx={
                  mode === 'edit'
                    ? {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                        },
                      }
                    : {}
                }
              ></TextField>
            </Form.Item>
            {mode === 'create' && (
              <Form.Item
                label={Translation('roleControl.userManagement.form.password')}
                name="password"
                rules={[PASSWORD_REQUIRED_RULES, PASSWORD_VIDATOR]}
              >
                <TextField className={classes.textInput} margin="dense" variant="outlined" size="small"></TextField>
              </Form.Item>
            )}
            {sortedRegion.map((region) => (
              <Form.Item
                required={region === defaultRegion}
                label={`${Translation('roleControl.userManagement.form.name')} ${
                  region !== defaultRegion ? '(local)' : ''
                }`}
                name={['userName', region]}
                rules={region === defaultRegion ? [DEFAULT_REQUIRED_RULES, NAME_VIDATOR] : [NAME_VIDATOR]}
              >
                <TextField
                  defaultValue={get(form.getFieldValue('userName'), region, '')}
                  className={classes.textInput}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 30,
                    itemType: 'text',
                  }}
                ></TextField>
              </Form.Item>
            ))}
            <Form.Item name="status" label={Translation('component.formLabel.status')} rules={[DEFAULT_REQUIRED_RULES]}>
              <RadioGroup aria-label="IsActive" name="status" className={classes.flexRow}>
                <FormControlLabel
                  key={'enable'}
                  value={STATUS_ENUM.ENABLE}
                  control={<Radio />}
                  label={Translation('roleControl.userManagement.status.enable')}
                />
                <FormControlLabel
                  key={'disable'}
                  value={STATUS_ENUM.DISABLE}
                  control={<Radio />}
                  label={Translation('roleControl.userManagement.status.disable')}
                />
              </RadioGroup>
            </Form.Item>
          </Form>

          <div className={classes.bottomButtonContainer}>
            <Button
              variant="contained"
              color="inherit"
              disabled={loading}
              style={{ marginRight: 16 }}
              onClick={props.history.goBack}
            >
              {Translation('app.button.cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              color="secondary"
              onClick={() => {
                onSubmit();
              }}
            >
              {Translation('app.button.submit')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
