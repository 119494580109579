import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { deleteLeadSource } from 'src/app/modules/PulseLeads/network/sourceCrud';
import { leadSourcePath } from '../../LeadSourceRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { LeadSource } from 'src/app/modules/PulseLeads/types/source-types';

type LeadSoucreListProps = {
  isLoading: boolean;
  leadSourceList?: PaginateList<LeadSource>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const LeadSourceList: FC<LeadSoucreListProps> = ({ isLoading, leadSourceList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteLeadSource(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Lead Source deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  const renderI18nColumn = (regionLocale: RegionLocale[]) =>
    regionLocale.map((locale, index) => ({
      keyIndex: `name-${locale}`,
      displayName: `${Translation('pulseleads.leadSource.common.name')} (${Translation(`navBar.lang.${locale}`)})`,
      renderData: (row: LeadSource) => {
        if (row.i18nData) {
          return row.i18nData[locale]?.name || '-';
        } else if (
          (regionLocale.includes(RegionLocale.ENGLISH) && locale === RegionLocale.ENGLISH) ||
          (!regionLocale.includes(RegionLocale.ENGLISH) && index === 0)
        ) {
          return row.sourceName || '-';
        }
        return '-';
      },
    }));

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.leadSource.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.leadSource.newSource'),
            onClick: () => history.push(`${leadSourcePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Lead Source',
            onClick: (row) => history.push(`${leadSourcePath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Lead Source',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'image',
            displayName: '',
            renderData: (row) => (
              <div style={{ width: 60, height: 60, overflow: 'hidden', borderRadius: 12 }}>
                <img style={{ width: '100%', height: '100%' }} src={row.image?.url} alt="App Icon" />
              </div>
            ),
          },
          {
            keyIndex: 'parent',
            displayName: Translation('pulseleads.leadSource.common.parentName'),
            renderData: (row) => (row.parent ? (row.parent as LeadSource).sourceName : '-'),
          },
          ...renderI18nColumn(regionLocale),
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.leadSource.common.updatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('pulseleads.leadSource.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.leadSource.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={leadSourceList?.docs}
        totalPages={leadSourceList?.totalPages}
        totalRecords={leadSourceList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default LeadSourceList;
