import React, { FC, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import ProTable, { ActionType } from 'src/app/common/components/ProTable';
import columns from './columns';
import {
  getList,
  exportCSV,
  batchUpdate,
  redistributeLead,
  RedistributeLeadBody,
  RedistributeAllLeadBody,
  redistributeAllLead,
} from '../api';
import ManuallyAssignForm from './ManuallyAssignForm';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { defaultDateRange, dontDistributeStatus } from '../util';

const useStyles = makeStyles()((theme) => ({}));

const LeadNotDistributionPage: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const { classes } = useStyles();
  const actionRef = useRef<ActionType>();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    limit: 20,
  });

  const initialFilter = {
    status: '0',
    dateRange: defaultDateRange,
    distributionStatus: [],
  };

  const [filterData, setFilterData] = useState<{ [key: string]: any }>(initialFilter);

  const [filterSaveData, setFilterSaveData] = useState<{ [key: string]: any }>({});

  const [currentStatus, setCurrentStatus] = useState<string>('0');
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);
  const [deleteConfirmPopupVisible, setDeleteConfirmPopupVisible] = useState<boolean>(false);
  const [assignFormPopupVisible, setAssignFormPopupVisible] = useState<boolean>(false);
  const [enableExport, setEnableExport] = useState<boolean>(true);

  const canOperate = filterSaveData.status !== '2';

  const fetchData = async (newFilterState?: any, sorter?: any) => {
    let sortBy;
    let isAsec;
    sorter &&
      Object.entries(sorter).forEach(([item, order]) => {
        sortBy = item;
        isAsec = order === 'asc';
      });

    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...newFilter,
      ...{
        limit: newFilter.pageSize || pageData.limit,
        page: newFilter.page + 1,
        fromDate:
          (newFilter.dateRange && moment(newFilter.dateRange[0]).format('YYYY-MM-DD')) || filterData.dateRange[0],
        toDate: (newFilter.dateRange && moment(newFilter.dateRange[1]).format('YYYY-MM-DD')) || filterData.dateRange[1],
        distributionStatus:
          filterData.distributionStatus.length === 0
            ? Object.keys(dontDistributeStatus[currentStatus])
            : filterData.distributionStatus,
        sortBy,
        isAsec,
      },
    };

    delete requestParams.pageSize;
    delete requestParams.dateRange;
    // delete requestParams.status;

    setFilterSaveData(requestParams);

    const result = await getList(requestParams);
    const res = {
      data: result.data.docs || [],
      success: true,
      total: result.data.totalDocs,
    };

    return res;
  };

  const onDelete = async () => {
    console.log(selectedRowsState);

    const ids = selectedRowsState && selectedRowsState.map((item) => item._id);

    const data = {
      ids,
      isDeleted: true,
    };

    try {
      await batchUpdate(data, dispatch);

      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Deleted successfully',
          },
        ]),
      );

      console.log(filterSaveData);

      await onRefresh();
    } catch (error) {
      console.log(error);
    }

    setDeleteConfirmPopupVisible(false);
  };

  const onRefresh = async () => {
    actionRef.current?.reload();
  };

  const onRedistribute = async () => {
    const ids = selectedRowsState && selectedRowsState.map((item) => item._id);
    const body: RedistributeLeadBody = {
      leadRawIds: ids,
    };
    try {
      await redistributeLead(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Redistribution triggers successfully',
          },
        ]),
      );
      await onRefresh();
    } catch (error) {}
  };

  const onRedistributeAll = async () => {
    const body: RedistributeAllLeadBody = {
      status: filterData.status,
      campaignId: filterData.campaignId,
      fromDate: moment(filterData.dateRange[0]).format('YYYY-MM-DD') || filterData.dateRange[0],
      toDate: moment(filterData.dateRange[1]).format('YYYY-MM-DD') || filterData.dateRange[1],
      distributionStatus:
        filterData.distributionStatus.length === 0
          ? Object.keys(dontDistributeStatus[currentStatus])
          : filterData.distributionStatus,
      isCustomerDedupApiConnectionFail: filterData.isCustomerDedupApiConnectionFail,
    };
    try {
      await redistributeAllLead(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Redistribution triggers successfully',
          },
        ]),
      );
      await onRefresh();
    } catch (error) {}
  };

  return (
    <>
      <ProTable
        rowKey="key"
        filterTitle={'Filter Leads Not Distributed'}
        headerTitle={''}
        actionRef={actionRef}
        columns={columns(Translation, currentStatus, setCurrentStatus, filterData, setFilterData)}
        request={(params, sorter, filter) => {
          console.log(sorter);
          return fetchData(params, sorter);
        }}
        filterNumberPerRow={3}
        toolBarRender={() => [
          canOperate ? (
            <Button
              color="secondary"
              variant="contained"
              disabled={selectedRowsState.length === 0}
              onClick={() => onRedistribute()}
            >
              {Translation('pulseleads.not.distributed.redistribute')}
            </Button>
          ) : null,
          canOperate ? (
            <Button
              key="reassign"
              color="secondary"
              variant="contained"
              disabled={selectedRowsState.length === 0}
              onClick={() => {
                setAssignFormPopupVisible(true);
              }}
            >
              {Translation('pulseleads.not.distributed.reassign.btn.text')}
            </Button>
          ) : null,
          canOperate ? (
            <Button
              color="secondary"
              variant="contained"
              disabled={selectedRowsState.length === 0}
              onClick={() => {
                setDeleteConfirmPopupVisible(true);
              }}
              key="addSet"
            >
              {Translation('app.button.delete')}
            </Button>
          ) : null,
          canOperate ? (
            <Button color="secondary" variant="contained" onClick={() => onRedistributeAll()}>
              {Translation('pulseleads.not.distributed.redistributeAll')}
            </Button>
          ) : null,
          <AsyncCsvLink
            style={{ display: 'inline' }}
            isDisabled={!enableExport}
            filename={`Leads Not Distributed.csv`}
            dataParser={(str) => str}
            asyncCall={() => exportCSV(filterSaveData).finally(() => setEnableExport(true))}
          >
            <Button
              color="secondary"
              variant="contained"
              // onClick={onExport}
              key="export"
              disabled={!enableExport}
              onClick={() => setEnableExport(false)}
            >
              {Translation('export.list.text')}
            </Button>
          </AsyncCsvLink>,
        ]}
        rowSelection={
          canOperate
            ? {
                onChange: (_, selectedRows) => {
                  setSelectedRows(selectedRows);
                },
                getCheckboxProps: (record) => ({
                  // eslint-disable-next-line
                  // disabled: currentStatus === '2'
                }),
              }
            : undefined
        }
        pageSize={pageData.limit}
      />

      <Dialog open={deleteConfirmPopupVisible} fullWidth>
        <DialogTitle style={{ textAlign: 'center' }}>Warning</DialogTitle>
        <div style={{ padding: 20, textAlign: 'center' }}>
          Are you sure you want to delete this item ? This action cannot be reverted.
        </div>
        <div className={commonClasses.footerContainer}>
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setDeleteConfirmPopupVisible(false);
              }}
            >
              {Translation('app.button.cancel')}
            </Button>
            <Button style={{ marginLeft: 20 }} variant="contained" color="secondary" onClick={onDelete}>
              {Translation('global.text.confirm')}
            </Button>
          </>
        </div>
      </Dialog>

      <Dialog open={assignFormPopupVisible} fullWidth maxWidth="md">
        <DialogTitle style={{ textAlign: 'center' }}>Reassign Leads</DialogTitle>
        <ManuallyAssignForm
          selectRows={selectedRowsState}
          setAssignFormPopupVisible={setAssignFormPopupVisible}
          onRefresh={onRefresh}
        />
      </Dialog>
    </>
  );
};

export default LeadNotDistributionPage;
