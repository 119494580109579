import React, { FC, useState } from 'react';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';

import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import List from './list';
import {
  ApplicationTrainingCourseListParam,
  CourseListQueryParam,
  OptionType,
  PaginateList,
  PublishStatusEnum,
} from '../../../types/courses-types';
import {
  fetchOnboardingTrainingCourseList,
  fetchOnboardingTrainingOptions,
} from '../../../network/onboarding-course-crud';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { map } from 'lodash';
type CourseListPageProps = ParamsProps;

const initialState: CourseListQueryParam = {
  lastUpdatedBy: undefined,
  lastUpdatedAt: undefined,
  limit: 5,
  page: 1,
};

export const FilterKeys = ['courseCode', 'courseType', 'courseName', 'lastUpdatedAt', 'lastUpdatedBy', 'status'];

interface ResWithMessage extends PaginateList<ApplicationTrainingCourseListParam> {
  message: string;
  status: string;
}

const paramsInitiator = (initialParams?: Record<string, any>): CourseListQueryParam => {
  return initialParams
    ? {
        lastUpdatedAt: initialParams.lastUpdatedAt,
        lastUpdatedBy: initialParams.lastUpdatedBy || '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

const CourseListPage: FC<CourseListPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialParams));
  const [courseList, setCourseList] = useState<any>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState as any);
        }
        const reqParams = {
          ...filterState,
          lang: locale,
          currLang: locale,
        };
        if (filterState.lastUpdatedAt) {
          reqParams.lastUpdatedAt = moment(filterState.lastUpdatedAt).format('YYYY-MM-DD');
        }
        const res = await fetchOnboardingTrainingCourseList(reqParams, sortKey, dispatch);
        if (res?.status) {
          const resWithMessage = res as ResWithMessage;
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: resWithMessage.status,
                content: resWithMessage.message,
              },
            ]),
          );
        } else {
          return res;
        }
      } catch (err) {}
    },
    setCourseList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('golbal.filters.search')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'courseCode',
            initialValue: filterState.courseCode,
            displayName: Translation('recruitment.course.code'),
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            field: 'courseType',
            style: { width: 230 },
            initialValue: filterState.courseType,
            displayName: Translation('recruitment.course.type'),
            fetchList: async () => {
              const res = await fetchOnboardingTrainingOptions(dispatch);
              let typeList: any = [];
              res?.forEach((data) => {
                if (data?.category === OptionType.CourseType) {
                  typeList = data?.options ?? [];
                }
              });
              return typeList?.map((item: any) => ({
                displayName: item?.key || '-',
                value: item?.value || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'courseName',
            initialValue: filterState.courseName || '',
            displayName: Translation('recruitment.course.name'),
          },
          {
            type: PruFilterItemType.DATE,
            field: 'lastUpdatedAt',
            initialDate: filterState.lastUpdatedAt,
            displayName: Translation('recruitment.common.lastUpdatedAt'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'lastUpdatedBy',
            initialValue: filterState.lastUpdatedBy,
            displayName: Translation('recruitment.common.lastUpdatedBy'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('recruitment.common.status'),
            choices: [
              { displayName: '', value: '' },
              ...map(PublishStatusEnum, (option: string) => ({ displayName: option.toUpperCase(), value: option })),
            ],
          },
        ]}
        onChangeFilter={(filter) => {
          setFilterState({
            ...filterState,
            lastUpdatedBy: filter.lastUpdatedBy,
            lastUpdatedAt: filter.lastUpdatedAt,
            courseName: filter.courseName,
            courseType: filter.courseType,
            courseCode: filter.courseCode,
            status: filter.status,
          });
        }}
        fetchData={refreshData}
      />
      <List
        isLoading={isLoading}
        courseList={courseList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
      />
    </>
  );
};
export default CourseListPage;
