import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import {
  BDMdashboardDataCoeDev,
  DashboardLibraryCoeDev,
  TopDashboardsCoeDev,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-coedev';
import {
  BDMdashboardDataProd,
  DashboardLibraryProd,
  TopDashboardsProd,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-prod';
import {
  BDMdashboardDataUat,
  DashboardLibraryUat,
  TopDashboardsUat,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-uat';
import { RootState } from 'src/redux/store';
import { getMongoChartToken } from '../../../../../Auth/_redux/authCrud';
import { TokenInfo } from '@pruforce/common-adminweb-sdk';
import { BDMdashboardFilter, BDMdashboardGroupMap, TopDashboardDetail } from '../../../../types/dashboard-types';
// const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
var libarary = DashboardLibraryCoeDev;
var topDashboards = TopDashboardsCoeDev;
var dataChartId = BDMdashboardDataCoeDev;

type ChartListProps = {
  filter: any;
  onRefresh?: boolean;
  refreshdata: () => void;
  setExcelData: (data: any) => void;
  getDataList: (list: any) => void;
};

const CommonFilters = ['agentViewInfo.region', 'agentViewInfo.channel'];

const DashboardDetailPage: FC<ChartListProps> = ({ filter, onRefresh, refreshdata, setExcelData, getDataList }) => {
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'uat') {
      libarary = DashboardLibraryUat;
      topDashboards = TopDashboardsUat;
      dataChartId = BDMdashboardDataUat;
    } else {
      if (env === 'prod') {
        libarary = DashboardLibraryProd;
        topDashboards = TopDashboardsProd;
        dataChartId = BDMdashboardDataProd;
      }
    }
  }, []);

  const baseUrl: string = getConfigurations()?.mongoChart?.baseUrl;

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      const response = await getMongoChartToken();
      return response.accessToken;
    },
  });
  var chartID = '';

  for (var i = 0; i < libarary.report.length; i++) {
    var object = libarary.report[i];
    if (object.chartName == filter.chartName) {
      chartID = object.chartID;
      if (object.chartType == filter.chartType) {
        chartID = object.chartID;
        break;
      }
    }
    if (chartID == '') {
      chartID = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277';
    }
  }

  const [myFilter, setMyFilter] = useState<any>({
    type: '',
    assignedDate: [],
    leadSource: '',
    'agentViewInfo.region': '',
    'agentViewInfo.channel': '',
  });

  const dataChartDiv = useRef<HTMLDivElement>(null);
  const chartDiv = useRef<HTMLDivElement>(null);
  const chartDivLeft = useRef<HTMLDivElement>(null);
  const chartDivRight = useRef<HTMLDivElement>(null);
  const chartDivLeftMiddle = useRef<HTMLDivElement>(null);
  const chartDivRightMiddle = useRef<HTMLDivElement>(null);
  const chartDivLeftBottom = useRef<HTMLDivElement>(null);
  const chartDivRightBottom = useRef<HTMLDivElement>(null);

  const [rendered, setRendered] = useState(false);
  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: chartID,
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [dataChart, reDataChart] = useState(
    sdk.createChart({
      chartId: dataChartId,
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [moreIdList, setMoreIdList] = useState<Array<string>>([]);

  const [chartLeft, setChartLeft] = useState(
    sdk.createChart({
      chartId: topDashboards.get(filter.chartName)?.dashboardLeft ?? '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [chartRight, setChartRight] = useState(
    sdk.createChart({
      chartId: topDashboards.get(filter.chartName)?.dashboardRight ?? '6267bd98-d717-4757-8bff-7d34608e2384',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [chartLeftMiddle, setChartLeftMiddle] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [chartRightMiddle, setChartRightMiddle] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [chartLeftBottom, setChartLeftBottom] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [chartRightBottom, setChartRightBottom] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
    }),
  );

  const [isShowChart, setIsShowChart] = useState<Boolean>(false);

  const getTopCharts = (topChartsName: TopDashboardDetail) => {
    let isChart3 = topDashboards.get(filter.chartName)?.type == 'Unique' ? true : false;
    let isChart2 = topDashboards.get(filter.chartName)?.type == 'Among' ? true : false;

    if (chartDivLeft.current) {
      let left = sdk.createChart({
        chartId: topChartsName.dashboardLeft,
        height: isChart3 ? 400 : 200,
        width: isChart3 ? 800 : 200,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartLeft(left);
      left
        .render(chartDivLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRight.current && topChartsName.dashboardRight) {
      let right = sdk.createChart({
        chartId: topChartsName.dashboardRight,
        height: isChart2 ? 400 : 200,
        width: isChart2 ? 800 : 200,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartRight(right);
      right
        .render(chartDivRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
  };

  const getMoreCharts = (chartList: Array<string>) => {
    let idList = [];
    for (var i = 0; i < libarary.report.length; i++) {
      if (chartList.indexOf(libarary.report[i].chartName) != -1 && libarary.report[i].chartType == 'Chart') {
        idList.push(libarary.report[i].chartID);
      }
    }

    setMoreIdList(idList);

    let width = (window.innerWidth - 40) / 2;

    if (chartDivLeft.current) {
      const leftTop = sdk.createChart({
        chartId: idList[0],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartLeft(leftTop);
      leftTop
        .render(chartDivLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRight.current) {
      const rightTop = sdk.createChart({
        chartId: idList[1],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartRight(rightTop);
      rightTop
        .render(chartDivRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivLeftMiddle.current) {
      const leftMiddle = sdk.createChart({
        chartId: idList[2],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartLeftMiddle(leftMiddle);
      leftMiddle
        .render(chartDivLeftMiddle.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRightMiddle.current) {
      const rightMiddle = sdk.createChart({
        chartId: idList[3],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartRightMiddle(rightMiddle);
      rightMiddle
        .render(chartDivRightMiddle.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
  };

  useEffect(() => {
    let width = (window.innerWidth - 40) / 2;

    if (chartDivLeftBottom.current && moreIdList[4]) {
      const leftBottom = sdk.createChart({
        chartId: moreIdList[4],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartLeftBottom(leftBottom);
      leftBottom
        .render(chartDivLeftBottom.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRightBottom.current && moreIdList[5]) {
      const rightBottom = sdk.createChart({
        chartId: moreIdList[5],
        height: 400,
        width: width,
        theme: 'light',
        filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
      });
      setChartRightBottom(rightBottom);
      rightBottom
        .render(chartDivRightBottom.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
  }, [moreIdList]);

  useEffect(() => {
    if (chartDiv.current && dataChartDiv.current) {
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log(res));
        })
        .catch((err) => console.log('Error during Charts rendering.', err));

      dataChart
        .render(dataChartDiv.current)
        .then(() => {
          dataChart.getData().then((result) => {
            type resultType = keyof typeof result;
            type fieldsType = keyof typeof fields;
            type dataType = keyof (typeof data)[0];

            const fields = result['fields' as resultType] as object;
            const data = result['documents' as resultType] as Array<{ group: string }>;

            let nameObj = {
              leadSource: '',
              region: '',
              channel: '',
            };

            type nameType = keyof typeof nameObj;

            // fields[fieldName as fieldsType] -> chart's field name
            for (const fieldName in fields) {
              nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
            }

            let dataList: Record<string, any[]> = {
              leadSource: [],
              region: [],
              channel: [],
            };

            // filter duplicate choices
            data.forEach((item) => {
              for (const key in dataList) {
                if (
                  !!item[nameObj[key as nameType] as dataType] &&
                  !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                ) {
                  dataList[key].push(item[nameObj[key as nameType] as dataType]);
                }
              }
            });
            getDataList(dataList);
          });
        })
        .catch((err) => console.log('Error during data Chart rendering.', err));
    } else {
      console.log('Error');
    }
  }, [chartDiv, chart, rendered]);

  useEffect(() => {
    setMyFilter({
      type: !!filter['type'] && filter['type'] != 'all' ? filter['type'] : undefined,
      assignedDate: filter['assignedDate'],
      leadSource: !!filter['leadSource'] && filter['leadSource'] != 'all' ? filter['leadSource'] : undefined,
      'agentViewInfo.region': !!filter['region'] ? filter['region'] : region,
      'agentViewInfo.channel': !!filter['channel'] ? filter['channel'] : channel,
    });

    console.log(myFilter);
  }, [chart, filter, rendered]);

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));

    let chartFilter = BDMdashboardFilter.get(filter.chartName);

    // delete the no filterName
    for (const key in obj) {
      if (!chartFilter[key] && !CommonFilters.includes(key)) {
        delete obj[key];
      }
    }

    if (obj?.type) {
      myFilter.type != 'All'
        ? (obj.type =
            myFilter.type == 'Yes'
              ? 'existingCustomer'
              : myFilter.type == 'No'
              ? { $ne: 'existingCustomer' }
              : myFilter.type)
        : delete obj.type;
    }

    if (obj?.assignedDate) {
      // pls put the date type to be filtered here
      if (!!myFilter?.assignedDate[0] && !!myFilter?.assignedDate[1]) {
        obj.$and = [
          { assignedDate: { $gte: new Date(myFilter.assignedDate[0]) } },
          { assignedDate: { $lte: new Date(myFilter.assignedDate[1]) } },
        ];
      }
      delete obj.assignedDate;
    }

    chart.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartLeft.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartRight.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartLeftMiddle.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartRightMiddle.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartLeftBottom.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chartRightBottom.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
  }, [chart, filter, rendered, myFilter]);

  useEffect(() => {
    if (BDMdashboardGroupMap.get(filter.chartName)) {
      getMoreCharts(BDMdashboardGroupMap.get(filter.chartName) ?? []);
    } else if (topDashboards.get(filter.chartName) && filter.chartType == 'Table') {
      reRender(
        sdk.createChart({
          chartId: chartID,
          height: 800,
          theme: 'light',
          filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
        }),
      );
      setIsShowChart(true);
      getTopCharts(topDashboards.get(filter.chartName));
    } else {
      reRender(
        sdk.createChart({
          chartId: chartID,
          height: 800,
          theme: 'light',
          filter: { $and: [{ 'agentViewInfo.region': region }, { 'agentViewInfo.channel': channel }] },
        }),
      );
      setIsShowChart(false);
    }
  }, [filter.chartName, filter.chartType, isShowChart]);

  useEffect(() => {
    if (chartDiv.current) {
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log(res));
        })
        .catch((err) => console.log('Error during get data.', err));
    } else {
      console.log('Error');
    }
  }, [filter.chartName, filter.chartType, rendered]);

  return (
    <>
      {BDMdashboardGroupMap.get(filter.chartName) ? (
        <>
          <div className="top" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
            <div className="chart" ref={chartDivLeft} />
            <div className="chart" ref={chartDivRight} style={{ marginLeft: 20 }} />
          </div>
          <div className="middle" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', height: 400 }}>
            <div className="chart" ref={chartDivLeftMiddle} />
            <div className="chart" ref={chartDivRightMiddle} style={{ marginLeft: 20 }} />
          </div>
          {moreIdList[4] && (
            <div className="bottom" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
              <div className="chart" ref={chartDivLeftBottom} />
              {moreIdList[5] && <div className="chart" ref={chartDivRightBottom} style={{ marginLeft: 20 }} />}
            </div>
          )}
        </>
      ) : isShowChart ? (
        <>
          <div
            className="top"
            style={
              topDashboards.get(filter.chartName)?.type == 'Among'
                ? { marginBottom: 20 }
                : { marginBottom: 20, display: 'flex', flexDirection: 'row' }
            }
          >
            <div className="chart" ref={chartDivLeft} />
            {topDashboards.get(filter.chartName)?.dashboardRight && (
              <div
                className="chart"
                ref={chartDivRight}
                style={topDashboards.get(filter.chartName)?.type == 'Among' ? { marginTop: 20 } : { marginLeft: 20 }}
              />
            )}
          </div>
          <div className="chart" ref={chartDiv} />
        </>
      ) : (
        <div className="chart" ref={chartDiv} />
      )}
      <div className="data-chart" ref={dataChartDiv} hidden />
    </>
  );
};

export default DashboardDetailPage;
