import React, { FC, useReducer, useContext, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { rolePermissionPath } from '../PermissionRoutes';
import { RouteComponentProps } from 'react-router-dom';
import ModulePermissionTree from '../Permission/components/ModulePermissionTree';
import { useErrorHandler, ErrorFieldType, useUpdateEffect } from 'src/app/common/utils';
import { PermissionItem } from '../../types/permission-types';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import { createRolePermissions, CreateRolePermissionsBody } from '../../network/permissionCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { RoleType, STAFF_ROLE_PREFIX } from '../../types/role-types';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  roleInformation: {
    backgroundColor: theme.palette.grey[100],
    padding: '10px 10px 10px 15px',
    borderRadius: 8,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    fontSize: '1.15rem',
    fontWeight: 400,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    padding: '0 0 0 15px',
    display: 'flex',
  },
  fieldContainer: {
    width: 150,
    paddingTop: 16,
    boxSizing: 'border-box',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  inputBox: {
    backgroundColor: 'white',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type RolePermissionState = {
  groupId: string;
  groupEmail?: string;
  type: string;
  name: string;
  permissions: PermissionItem[];
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof Pick<RolePermissionState, 'groupId' | 'name' | 'groupEmail'>;
    value: string;
  };
};

type ModifyByResourceAction = {
  type: 'MODIFY_PERMISSIONS_BY_RESOURCE';
  payload: {
    resource: string;
    permissions: PermissionItem[];
  };
};

type RolePermissionAction = ModifyFieldAction | ModifyByResourceAction;

const formReducer = (state: RolePermissionState, action: RolePermissionAction): RolePermissionState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_PERMISSIONS_BY_RESOURCE':
      const originalItems = state.permissions.filter(
        (item) => item.resource.toLowerCase() !== action.payload.resource.toLowerCase(),
      );
      return {
        ...state,
        permissions: [...originalItems, ...action.payload.permissions],
      };
  }
};

const CreateRolePage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { moduleMap } = useContext(ModuleContext);

  const filteredModuleMap = useMemo(() => moduleMap.filter((item) => !item.disableRoleControl), [moduleMap]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [formState, formDispatch] = useReducer(formReducer, {
    groupId: '',
    groupEmail: undefined,
    type: RoleType.STAFF,
    name: '',
    permissions: [],
  });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'groupId',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'namePrefix',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.name.includes(STAFF_ROLE_PREFIX)) {
            return false;
          } else {
            return true;
          }
        },
      },
    ],
  );

  useUpdateEffect(() => {
    immediateErrorValidator();
  }, [formState.name]);

  const onUpdate = (resource: string, permissions: PermissionItem[]) => {
    formDispatch({
      type: 'MODIFY_PERMISSIONS_BY_RESOURCE',
      payload: {
        resource,
        permissions,
      },
    });
  };

  const onSubmit = async (body: CreateRolePermissionsBody) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      setIsSubmitting(true);
      try {
        await createRolePermissions(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Record updated for staff - ${body.name}`,
            },
          ]),
        );
        history.push(rolePermissionPath);
      } catch (err) {
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRow}>
          <div className={commonClasses.header}>{Translation('roleControl.role.create')}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(`${rolePermissionPath}/list`)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.root}>
        <div className={classes.roleInformation} style={{ marginBottom: 10 }}>
          <div className={classes.header}>{Translation('roleControl.role.basicInformation')}</div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('roleControl.common.name')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 350 }}
                error={errorState.mandatory.name || errorState.immediate.namePrefix}
                margin="dense"
                variant="outlined"
                InputProps={{
                  className: classes.inputBox,
                }}
                helperText={
                  errorState.mandatory.name
                    ? MANDATORY_FIELD_ERROR_TEXT
                    : errorState.immediate.namePrefix
                    ? Translation('roleControl.name.restrictions')
                    : Translation('roleControl.name.restrictions')
                }
                value={formState.name}
                onChange={(e) => {
                  onDismissErrorHandler('name', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
                }}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('roleControl.common.groupId')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 350 }}
                error={errorState.mandatory.groupId}
                margin="dense"
                variant="outlined"
                InputProps={{
                  className: classes.inputBox,
                }}
                helperText={
                  errorState.mandatory.groupId ? MANDATORY_FIELD_ERROR_TEXT : Translation('roleControl.groupId.warning')
                }
                value={formState.groupId}
                onChange={(e) => {
                  onDismissErrorHandler('groupId', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'groupId', value: e.target.value } });
                }}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('roleControl.common.groupEmail')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 350 }}
                margin="dense"
                variant="outlined"
                InputProps={{
                  className: classes.inputBox,
                }}
                value={formState.groupEmail}
                onChange={(e) => {
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'groupEmail', value: e.target.value } });
                }}
              />
            </div>
          </div>
        </div>
        <ModulePermissionTree
          style={{ width: '100%' }}
          isRoot
          onUpdate={onUpdate}
          permissions={formState.permissions}
          treeNode={filteredModuleMap}
        />
      </div>

      <div className={classes.footerContainer}>
        <Button disabled={isSubmitting} variant="contained" color="secondary" onClick={() => onSubmit(formState)}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default CreateRolePage;
