import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PaginateList } from 'src/app/common/types'; // might differ based on the api response
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useLang } from 'src/app/i18n';
import { UserManagementLogItem } from '../../../../types/user-management-log-types';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { useHistory } from 'react-router-dom';
import { userManagementLogPath } from '../../user-management-log-routes';

type UserManagementLogListProps = {
  isLoading: boolean;
  userManagementLogList?: PaginateList<UserManagementLogItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

export const UserManagementLogList: FC<UserManagementLogListProps> = ({
  isLoading,
  userManagementLogList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lang = useLang();
  const history = useHistory();

  return (
    <PruTable
      title={Translation('auditLog.userManagementLog.list.title')}
      disableBulkSelect
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View',
          onClick: (row) => {
            history.push(`${userManagementLogPath}/detail`, { detail: row });
          },
        },
      ]}
      columnDef={[
        // Add columns here
        {
          isId: true,
          hidden: true,
          keyIndex: 'id',
          displayName: '',
          renderData: () => '',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: '', value: sort[''] });
          },
        },
        {
          keyIndex: 'key',
          displayName: Translation('auditLog.userManagementLog.list.key'),
          renderData: (row: any) => row.key,
        },
        {
          keyIndex: 'createdAt',
          displayName: Translation('auditLog.userManagementLog.list.createdDate'),
          renderData: (row: any) => getDefaultDisplayDate(row.createdAt, 'datetime'),
        },
        {
          keyIndex: 'lastUpdatedDate',
          displayName: Translation('auditLog.userManagementLog.list.lastUpdatedDate'),
          renderData: (row: any) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('auditLog.userManagementLog.list.lastUpdatedBy'),
          renderData: (row: any) => row.lastUpdatedBy,
        },
        {
          keyIndex: 'activity',
          displayName: Translation('auditLog.userManagementLog.list.activity'),
          renderData: (row: any) => row.activity,
        },
        {
          keyIndex: 'description',
          displayName: Translation('auditLog.userManagementLog.list.description'),
          renderData: (row: any) => row.description,
        },
        {
          keyIndex: 'activityStatus',
          displayName: Translation('auditLog.userManagementLog.list.status'),
          renderData: (row: any) => row.activityStatus,
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={userManagementLogList?.docs}
      totalPages={userManagementLogList?.totalPages}
      totalRecords={userManagementLogList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};
