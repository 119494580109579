import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import PruDialog from '../../../../../../common/components/PruDialog';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { LibraryItem, LibraryListParam, LibraryPaginateList } from '../../types/library-types';
import { useDataProvider, useJWT } from 'src/app/common/utils';
import {
  editUnpublishLibrary,
  fetchAllCategoryList,
  fetchAllColumnList,
  fetchLibraryList,
  modifyLibrary,
  unpublishLibrary,
} from '../../network/libraryCrud';
import { CategoryPaginateList } from '../../../Category/types/category-types';
import { ColumnPaginateList } from '../../../Column/types/column-types';
import LibraryList from './components/LibraryList';
import { forEach, get } from 'lodash';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useLang } from 'src/app/i18n';
import { LibraryPublishStatus } from '../../constants';
import { RootState } from 'src/redux/store';
import { setFilterState, setSortKey } from '../../../../redux/saleskit.slice';

type ResourceProps = {
  filterName: string;
  blockName: string;
  blockType: string;
  createPagePath: string;
  editPageBasePath: string;
  viewPageBasePath: string;
  moduleType: string;
  performancePagePath?: string;
} & RouteComponentProps &
  ModulePermissionProps;

const paramsInitiator = (moduleType: string): LibraryListParam => {
  return {
    page: 1,
    pageSize: 20,
    name: '',
    module: moduleType,
  };
};

const MaterialLibraryListingPage: FC<ResourceProps> = ({
  history,
  filterName,
  blockType,
  moduleType,
  blockName,
  createPagePath,
  editPageBasePath,
  viewPageBasePath,
  performancePagePath,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const defFilter: LibraryListParam = useMemo(() => paramsInitiator(moduleType), [moduleType]);
  const filterState = useSelector<RootState>((state) => {
    return state.saleskit.materialFilter || defFilter;
  }) as LibraryListParam;

  const sortKey = useSelector<RootState>((state) => {
    return state.saleskit.sortKey || [];
  }) as { key: string; value?: string | undefined }[];

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  // const [filterState, setFilterState] = useState<LibraryListParam>(paramsInitiator(moduleType));
  const [libraryList, setLibraryList] = useState<LibraryPaginateList>();
  const [categoryOptionList, setCategoryOptionList] = useState<{ displayName: string; value: string }[]>();
  const [columnOptionList, setColumnOptionList] = useState<{ displayName: string; value: string }[]>();
  // use redux instead
  // const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [selectedUnpublishRow, setSelectedUnpublishRow] = useState<LibraryItem>();
  const jwt = useJWT();

  const filterOptions = {
    rType: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: 'File', value: 'file' },
      { displayName: 'Link', value: 'link' },
      { displayName: 'Content', value: 'content' },
      { displayName: 'Material Set', value: 'materialSet' },
      { displayName: 'Text', value: 'text' },
      { displayName: 'Picture', value: 'picture' },
    ],
    status: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: Translation('component.status.published'), value: 'Published' },
      { displayName: Translation('component.status.unpublished'), value: 'Unpublished' },
      { displayName: Translation('component.status.draft'), value: 'Draft' },
      { displayName: Translation('component.status.publishedToSetOnly'), value: 'PublishedToSetOnly' },
    ],
  };

  const { isLoading, refreshData } = useDataProvider<LibraryPaginateList>(
    async () => {
      try {
        return await fetchLibraryList(locale, filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setLibraryList,
    false,
    undefined,
    true,
  );

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  useDataProvider<CategoryPaginateList>(
    async () => {
      try {
        const allCategoryList = await fetchAllCategoryList(moduleType, dispatch);
        let optionList: { displayName: string; value: string }[] = [
          { displayName: Translation('component.status.all'), value: '' },
        ];
        allCategoryList.categoryList.map((item) => {
          return optionList.push({ displayName: item.name, value: item.name });
        });
        setCategoryOptionList(optionList);
        return allCategoryList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  useDataProvider<ColumnPaginateList>(
    async () => {
      try {
        const allColumnList = await fetchAllColumnList(moduleType, dispatch);
        let optionList: { displayName: string; value: string }[] = [
          { displayName: Translation('component.status.all'), value: '' },
        ];
        allColumnList.columnList.map((item) => {
          //@ts-ignore
          return optionList.push({ displayName: item.name[locale] ? item.name[locale] : '', value: item.id });
        });
        setColumnOptionList(optionList);
        return allColumnList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    dispatch(setSortKey(sortingKeyArray));
  };

  const closeWaringDialog = () => {
    setOpenDialog(false);
  };

  const onUnpublish = () => {
    closeWaringDialog();
    if (selectedUnpublishRow) {
      handleUnpublishLibrary(selectedUnpublishRow);
    }
  };

  const onUnpublishButtonClick = (rowData: LibraryItem) => {
    if (
      rowData.parentItems &&
      (rowData.parentItems as { count: number }).count &&
      (rowData.parentItems as { count: number }).count > 0
    ) {
      setOpenDialog(true);
      setSelectedUnpublishRow(rowData);
    } else {
      handleUnpublishLibrary(rowData);
    }
  };

  const openAddPage = (url: string, type?: string, selectedRows?: any[]) => {
    history.push({
      pathname: url,
      state: {
        type,
        selectedRows,
      },
    });
  };

  const openEditPage = (rowData: LibraryItem, mode: string) => {
    if (mode === 'view') {
      history.push({
        pathname: `${viewPageBasePath}/${rowData.id}`,
        state: {},
      });
    } else {
      history.push({
        pathname: `${editPageBasePath}/${rowData.id}`,
        state: {},
      });
    }
  };

  const openPerformancePage = (rowData: LibraryItem) => {
    if (performancePagePath)
      history.push({
        pathname: `${performancePagePath}/${rowData.uuid}`,
        state: { name: rowData.name },
      });
  };

  const handleUnpublishLibrary = async (rowData: LibraryItem) => {
    const isInMaterialSet =
      rowData.parentItems &&
      (rowData.parentItems as { count: number }).count &&
      (rowData.parentItems as { count: number }).count > 0;

    try {
      let submitData: any = {
        status: LibraryPublishStatus.Unpublished,
      };

      if (isInMaterialSet) {
        submitData.parentItems = [];
      }
      console.log(rowData);
      await modifyLibrary(rowData.id, submitData);
      // await editUnpublishLibrary(rowData.id, submitData);
      forEach(rowData.localizations, async (localeItem, key) => {
        await modifyLibrary(localeItem.id, submitData);
        // await editUnpublishLibrary(localeItem.id, submitData)
      });
    } catch (err) {}

    try {
      // await unpublishLibrary(rowData.id, dispatch);
      // forEach(rowData.localizations, async (localeItem, key) => {
      //   await unpublishLibrary(localeItem.id, dispatch);
      // })
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };
  // <PruTable/> page-number start from 0
  const defaultPageNumber = get(filterState, 'page', 1) - 1;

  return (
    <>
      <PruFilter
        title={Translation(filterName)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 250 },
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('component.formLabel.category'),
            choices: categoryOptionList
              ? categoryOptionList
              : [{ displayName: Translation('component.status.all'), value: '' }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.status,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'type',
            initialValue: filterState.type,
            displayName: Translation('component.formLabel.type'),
            choices: filterOptions.rType,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 350 },
            field: 'column',
            initialValue: filterState.column,
            displayName: Translation('component.formLabel.published-to-module'),
            choices: columnOptionList ? columnOptionList : [{ displayName: 'All', value: '' }],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createTimeStart',
            fieldTo: 'createTimeEnd',
            initialDateFrom: filterState.createTimeStart,
            initialDateTo: filterState.createTimeEnd,
            displayName: Translation('component.formLabel.created-time'),
          },
        ]}
        onChangeFilter={(data) => {
          dispatch(
            setFilterState({
              ...filterState,
              name: data.name,
              category: data.category,
              status: data.status,
              type: data.type,
              column: data.column,
              createTimeStart: data.createTimeStart,
              createTimeEnd: data.createTimeEnd,
            }),
          );
        }}
        fetchData={refreshData}
      />
      <LibraryList
        defaultPageNumber={defaultPageNumber}
        enablePerformance={!!performancePagePath}
        isLoading={isLoading}
        libraryList={libraryList}
        libraryTableTitle={blockName}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          dispatch(
            setFilterState({
              ...filterState,
              page,
              pageSize: rowsPerPage,
            }),
          );
          refreshData();
        }}
        onAddLibrary={(type, rowData) => {
          openAddPage(createPagePath, type, rowData);
        }}
        onEditLibrary={(rowData, mode) => {
          openEditPage(rowData, mode);
        }}
        onPerformance={(rowData) => {
          openPerformancePage(rowData);
        }}
        onUnpublishLibraryClicked={(rowData) => {
          onUnpublishButtonClick(rowData);
        }}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
        currentLanguage={locale}
      />
      <PruDialog
        open={openDialog}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={onUnpublish}
        confirmBtnText={Translation('global.text.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {Translation('warning.message.unpublish.material')}
      </PruDialog>
    </>
  );
};

export default MaterialLibraryListingPage;
