import { get } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import { getBlob } from 'src/app/common/network';
import { downloadFileByBlodId, useDataProvider } from 'src/app/common/utils';
import { Translation } from 'src/app/i18n/utils/i18n-utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { fetchPDFTemplateList } from '../../../../network/pdf-template-crud';
import { PDFTemplateItem, PDFTemplateListParam, PaginateList } from '../../../../types/pdf-template-type';

const initialState: PDFTemplateListParam = {
  page: 1,
  limit: 5,
};

export const PDFTemplateFilterKeys = [];

const paramsInitiator = (initialParams?: Record<string, string>): PDFTemplateListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const usePDFTemplateListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<PDFTemplateListParam>(paramsInitiator(initialParams));
  const [templateList, setTemplateList] = useState<PaginateList<PDFTemplateItem>>();
  // const onboardingTemplate = useSelector((state: any) => state.config.data.onboardingTemplate) || [];
  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);
  const pdfTypeOptions: string[] = Array.from(
    new Set(onboardingTemplate.flatMap((template: { pdfTemplate: { types: any } }) => template.pdfTemplate.types)),
  );

  const { isLoading, refreshData } = useDataProvider<PaginateList<PDFTemplateItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchPDFTemplateList(filterState, dispatch);
      } catch (err) {}
    },
    setTemplateList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      const sortBy = value.toUpperCase();
      setFilterState({ ...filterState, sortBy: key, orderBy: sortBy });
    } else {
      setFilterState({ ...filterState, sortBy: undefined, orderBy: undefined });
    }
    refreshData();
  };

  const fetchPDFFile = async (blobId: string) => {
    try {
      const blobDetail = await getBlob({ resourceIds: blobId }, dispatch);
      const result = blobDetail[0];
      if (result?.blobId) {
        downloadFileByBlodId(result.blobId || '').then(() =>
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.download.success'),
                content: Translation('global.view.pdf'),
              },
            ]),
          ),
        );
      }
    } catch (err) {}
  };

  return {
    filterState,
    templateList,
    isLoading,
    formTypeOptions,
    pdfTypeOptions,
    setFilterState,
    refreshData,
    onSort,
    fetchPDFFile,
  };
};
