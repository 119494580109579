import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { fetchIncentiveResultTemplate, uploadIncentiveCsvResultByBlobId } from '../../../../network/incentiveCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';
import { MANDATORY_FIELD_ERROR_TEXT, DATE_FORMAT_ERROR_TEXT } from 'src/app/common/constants';
import { useUpdateEffect, fileUpload, isDateValid } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { AttachmentDef } from 'src/app/common/types';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';

type UploadResultDialogProps = {
  code: string;
  resultFileName: string;
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
};

type FormState = {
  file: AttachmentDef | null;
  resultDate: Date | null;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 15,
  },
  fieldContainer: {
    paddingTop: 5,
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  rowContainer: {
    display: 'flex',
  },
  templateButton: {
    color: 'blue',
    cursor: 'pointer',
    marginBottom: 10,
  },
}));

type ErrorState = {
  fileMissing: boolean;
  dateFormat: boolean;
  dateMissing: boolean;
};

const UploadResultDialog: FC<UploadResultDialogProps> = ({ code, resultFileName, open, onClose, onRefresh }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>({ file: null, resultDate: null });
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();
  const [errorState, setErrorState] = useState<ErrorState>({
    fileMissing: false,
    dateFormat: false,
    dateMissing: false,
  });

  const errorValidator = () => {
    let internal: ErrorState = {
      fileMissing: false,
      dateFormat: false,
      dateMissing: false,
    };
    if (!formState.file) internal.fileMissing = true;
    if (!formState.resultDate) internal.dateMissing = true;
    if (formState.resultDate && !isDateValid(formState.resultDate)) internal.dateFormat = true;
    setErrorState(internal);
    return internal;
  };

  const onSubmit = () => {
    const result = errorValidator();
    if (formState.file && formState.resultDate && find(result, (status) => status === true) === undefined) {
      setIsUploading(true);
      const submitData = {
        blobId: formState.file.blobId,
        filename: formState.file.filename,
        resultDate: formState.resultDate.toISOString(),
      };
      uploadIncentiveCsvResultByBlobId(submitData, code, dispatch)
        .then(() => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload result successfully - ${code}`,
              },
            ]),
          );
          onRefresh();
          onClose();
        })
        .catch(() => {})
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'incentive' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          setFormState({ ...formState, file: { blobId: result.blobId, filename: file.name } });
        }
      } catch (err) {
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };

  useUpdateEffect(() => {
    let currentState = { ...errorState };
    if (formState.resultDate) currentState.dateMissing = false;
    if (formState.file) currentState.fileMissing = false;
    if (formState.resultDate && isDateValid(formState.resultDate)) currentState.dateFormat = false;
    setErrorState(currentState);
  }, [formState]);

  return (
    <Dialog onClose={() => !isUploading && onClose()} open={open}>
      <div className={classes.container}>
        <div className={classes.header}>Upload Result</div>
        {resultFileName && (
          <div style={{ marginBottom: 10, alignItems: 'center' }} className={classes.rowContainer}>
            <div className={classes.noPaddingFieldContainer}>
              <span>Last File Name :</span>
            </div>
            <div>{resultFileName}</div>
          </div>
        )}
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>Result :</span>
          </div>
          <div>
            <input
              id="upload-csv"
              type="file"
              accept=".csv"
              disabled={!!uploadFileProgress}
              onChange={handleUploadFile}
            />
            {errorState.fileMissing && <div className={commonClasses.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</div>}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer} />
          <AsyncCsvLink
            isDisabled={false}
            filename={`${code}_template.csv`}
            dataParser={(str) => str}
            asyncCall={() => fetchIncentiveResultTemplate(code, dispatch)}
          >
            <div className={classes.templateButton}>{Translation('download_template_button')}</div>
          </AsyncCsvLink>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>Result Date :</span>
          </div>
          <PruDatePicker
            disableFuture
            slotProps={{
              textField: {
                error: errorState.dateMissing || errorState.dateFormat,
                helperText: errorState.dateMissing
                  ? MANDATORY_FIELD_ERROR_TEXT
                  : errorState.dateFormat && DATE_FORMAT_ERROR_TEXT,
                style: { margin: 0 },
              },
            }}
            format="DD/MM/YYYY"
            value={formState.resultDate}
            onChange={(date) => setFormState({ ...formState, resultDate: date })}
          />
        </div>
        <div className={classes.footer}>
          <Button disabled={isUploading} variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isUploading}
            style={{ marginLeft: 15 }}
            variant="contained"
            color="secondary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UploadResultDialog;
