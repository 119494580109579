import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';

import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

import { columns, headerBtn, operation } from './columns';
import { ConfigAboutMePaginateList } from 'src/app/modules/Profile/types/agent-profile-types';
import { deleteAboutmeTemplate } from 'src/app/modules/Profile/network/agentProfileCrud';

import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type aboutMeListProps = {
  isLoading: boolean;
  list?: ConfigAboutMePaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AboutMeList: FC<aboutMeListProps> = ({ isLoading, list, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAboutmeTemplate(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Aboutme deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
        content={Translation('agentProfile.ad_delete_message')}
      />
      <PruTable
        title={Translation('agentProfile.ad_template_list_header_title')}
        headerBtnDef={headerBtn(Translation, history)}
        disableBulkSelect
        operationDef={operation(Translation, setDialogState, history)}
        columnDef={columns(Translation, getDefaultDisplayDate)}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={list?.docs}
        totalPages={list?.totalPages}
        totalRecords={list?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AboutMeList;
