import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'src/app/i18n/I18nConfig';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { deleteAgentProfile, deleteAwardType } from 'src/app/modules/Profile/network/agentProfileCrud';

import { PaginateList } from 'src/app/common/types/common-types';
import { AwardListItem, AwardTypeListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { awardTypeListPath } from '../AwardTypeListRoute';
import { toTitleCase } from 'src/app/common/utils';
// import { awardListPath } from '../../AwardListRoutes';

type AwardTypeListProps = {
  isLoading: boolean;
  awardTypeList?: PaginateList<AwardTypeListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AwardTypeList: FC<AwardTypeListProps> = ({ isLoading, awardTypeList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAwardType(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Award deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
        content={Translation('agentProfile.ad_delete_message')}
      />
      <PruTable
        title={Translation('agentProfile.ad_agents_awards_header_title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('agentProfile.ad_add_award_label'),

            onClick: () => history.push(`${awardTypeListPath}/addAward`),
          },
        ]}
        secondBtnDef={[
          {
            color: 'primary',
            title: Translation('agentProfile.ad_add_award_list_header_title'),

            onClick: () => history.push(`${awardTypeListPath}/addList`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('agentProfile.ad_edit_label'),
            tooltipText: 'Edit Award',
            onClick: (row) => history.push(`${awardTypeListPath}/edit/${row.typeId}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'delete award type',
            onClick: (row) => setDialogState({ open: true, id: row.typeId }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },

          {
            keyIndex: 'awardNameLocal',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_local_label'),
            renderData: (row) =>
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ height: 50, width: 50, overflow: 'hidden' }}>
                    {row.image && row.image !== null && <img src={row.image}></img>}
                  </div>
                  <span style={{ paddingLeft: 8 }}>{row.name['zhHk']}</span>
                </div>
              ) || '-',
          },
          {
            keyIndex: 'awardNameEn',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_en_label'),
            renderData: (row) => row.name['enUs'] || '-',
          },
          {
            keyIndex: 'awardCode',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_code_label'),
            renderData: (row) => row.awardCode || '-',
          },
          {
            keyIndex: 'type',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_type_label'),
            renderData: (row) => toTitleCase(row.type) || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={awardTypeList?.docs}
        totalPages={awardTypeList?.totalPages}
        totalRecords={awardTypeList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AwardTypeList;
