import React, { FC, useEffect, useState } from 'react';
import { FormControl, Select, TextField, Button } from '@mui/material';
import moment from 'moment';
import { map } from 'lodash';
import useFilterStyles from '../../../../../../common/styles/filter-styles';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { useIntl } from 'react-intl';
import { NotificationFilterReceiverType, ReceiverType } from '../../../../constants';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { getCurrentConfig } from 'src/app/common/utils';

type IncentiveFilterProps = {
  onChangeFilter: (filterState: IncentiveFilterState) => void;
  filterState: IncentiveFilterState;
  onSearch: () => void;
};

export interface IncentiveFilterState {
  id?: string;
  msgTitle?: string;
  modifier?: string;
  inboxStatus?: string;
  category?: string;
  sendingTimeStart?: Date | string | null;
  sendingTimeEnd?: Date | string | null;
  agentCode?: string;
  receiverType?: NotificationFilterReceiverType;
}
const initState = {
  id: '',
  msgTitle: '',
  modifier: '',
  inboxStatus: '',
  category: '',
  sendingTimeStart: null,
  sendingTimeEnd: null,
  agentCode: '',
  receiverType: NotificationFilterReceiverType['specific-receivers'],
};

const NotificationFilter: FC<IncentiveFilterProps> = ({ onChangeFilter, filterState, onSearch }) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const currentConfig = getCurrentConfig(user);
  const CategoryType = currentConfig?.notificationCategoryType as { [key: string]: string };

  // useUpdateEffect(() => {
  //   onChangeFilter(filterState);
  // }, [filterState]);

  const onChange = (data: IncentiveFilterState) => {
    onChangeFilter({
      ...filterState,
      ...data,
    });
  };
  const onReset = () => {
    onChangeFilter(initState);
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  // eslint-disable-next-line
  const [currentReceiverType, setCurrentReceiverType] = useState(NotificationFilterReceiverType['specific-receivers']);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterState]);

  return (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      {/* Header Row */}
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={commonClasses.header}>{Translation('component.notificationFilter.filterTitle')}</div>
        <div className="PruFilter-row">
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        </div>
      </div>
      {/* First Row */}
      <div className="PruFilter-row">
        <span className="PruFilter-criteria">{Translation('component.formLabel.notification-title')} :</span>
        <TextField
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={filterState.msgTitle || ''}
          onChange={(e) => onChange({ msgTitle: e.target.value })}
        />
        <span className="PruFilter-criteria">{Translation('component.formLabel.last-updated-by')} :</span>
        <TextField
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={filterState.modifier || ''}
          onChange={(e) => onChange({ modifier: e.target.value })}
        />

        <span className="PruFilter-criteria">{Translation('component.formLabel.category')} :</span>
        <FormControl margin="dense" variant="outlined" style={{ marginRight: 20 }}>
          <Select
            native
            value={filterState.category || ''}
            onChange={(e) => onChange({ category: e.target.value as string })}
            inputProps={{
              name: 'category',
              id: 'filled-age-native-simple',
            }}
          >
            <option value="">{Translation('component.tagSelect.all')}</option>
            {map(CategoryType, (key: string, value: string) => (
              <option key={key} value={key}>
                {Translation(`notification.category.type.${value}`)}
              </option>
            ))}
            {/* only add secretary for auto notification */}
            <option key={'inbox_secretary'} value={'inbox_secretary'}>
              {Translation(`notification.category.type.Secretary`)}
            </option>
          </Select>
        </FormControl>

        <span className="PruFilter-criteria">{Translation('component.formLabel.sent-time')}:</span>
        <PruDateTimePicker
          ampm={false}
          format="DD/MM/YYYY HH:mm"
          value={filterState.sendingTimeStart ? moment(filterState.sendingTimeStart).toDate() : null}
          onChange={(date) => onChange({ sendingTimeStart: date })}
        />
        <div className="PruFilter-date-divider" />
        <PruDateTimePicker
          slotProps={{
            textField: {
              style: { marginRight: 20 },
            },
          }}
          ampm={false}
          format="DD/MM/YYYY HH:mm"
          value={filterState.sendingTimeEnd ? moment(filterState.sendingTimeEnd).toDate() : null}
          onChange={(date) => onChange({ sendingTimeEnd: date })}
        />
        {/* </FormControl>    */}
      </div>
      <div className="PruFilter-row">
        <span className="PruFilter-criteria">{Translation('component.formLabel.receiver')} :</span>
        <FormControl margin="dense" variant="outlined" style={{ marginRight: 20 }}>
          <Select
            native
            value={filterState.receiverType || ''}
            onChange={(e) => {
              onChange({ receiverType: e.target.value as NotificationFilterReceiverType });
              setCurrentReceiverType(e.target.value as NotificationFilterReceiverType);
            }}
            inputProps={{
              name: 'receiverType',
              id: 'filled-age-native-simple',
            }}
          >
            {map(NotificationFilterReceiverType, (key: string, value: string) => (
              <option key={key} value={key}>
                {Translation(`component.formSelectItem.${value}`)}
              </option>
            ))}
          </Select>
        </FormControl>
        {filterState.receiverType === NotificationFilterReceiverType['all-pruforce-users'] ? null : (
          <>
            <span className="PruFilter-criteria">{Translation('feedbackResult.common.agentCode')} :</span>
            <TextField
              style={{ marginRight: 20 }}
              margin="dense"
              variant="outlined"
              value={filterState.agentCode || ''}
              onChange={(e) => onChange({ agentCode: e.target.value })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationFilter;
