import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    lineHeight: 'normal',
    width: '100%',
    '& .tox-statusbar__help-text': {
      display: 'none',
    },
    '& .tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-start': {
      justifyContent: 'space-between',
    },
  },
  btnContainer: {
    position: 'absolute',
    right: 1,
    top: 2,
    zIndex: 999,
  },
}));
