import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './user-management-log-detail.style';
import { getDefaultDisplayDate } from 'src/app/common/utils';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

const DisplayItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <Grid item xs={2} sm={4} md={4} key={value}>
      <div>
        {label}: {value ?? ''}
      </div>
    </Grid>
  );
};

const DataItem = ({ label, value }: { label: string; value: any }) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <div className={styles.dataContainer}>
      <Typography fontWeight={600} fontSize={12} lineHeight={'18px'} marginBottom={'25px'}>
        {`${label}:`}
      </Typography>
      <div className={styles.dataHeader}>
        <Typography>{'</>JSON'}</Typography>
      </div>

      <Box sx={{ flexGrow: 1, padding: '25px' }}>
        <pre>{JSON.stringify(value ?? '', null, 2)}</pre>
      </Box>
    </div>
  );
};

export const UserManagementLogDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { detail } = props.history.location.state as any;

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <>
      <div className={styles.container}>
        <div className={commonStyles.headerContainer}>
          <div className={commonStyles.rowContainer}>
            <div className={commonStyles.header}>
              {Translation('auditLog.userManagementLog.detail.basicInformation')}
            </div>
          </div>
          <Button variant="contained" color="inherit" onClick={props.history.goBack}>
            {Translation('app.button.back')}
          </Button>
        </div>

        <div className={styles.wrap}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.key')} value={detail.key} />
              <DisplayItem
                label={Translation('auditLog.userManagementLog.detail.lastUpdatedBy')}
                value={detail.lastUpdatedBy}
              />
              <DisplayItem
                label={Translation('auditLog.userManagementLog.detail.createdDate')}
                value={getDefaultDisplayDate(detail.createdAt, 'datetime')}
              />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.service')} value={detail.service} />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.module')} value={detail.module} />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.activity')} value={detail.activity} />
              <DisplayItem
                label={Translation('auditLog.userManagementLog.detail.description')}
                value={detail.description}
              />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.ip')} value={detail.ip} />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.hostName')} value={detail.hostName} />
              <DisplayItem
                label={Translation('auditLog.userManagementLog.detail.userAgent')}
                value={detail.userAgent}
              />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.region')} value={detail.region} />
              <DisplayItem label={Translation('auditLog.userManagementLog.detail.channel')} value={detail.channel} />
              <DisplayItem
                label={Translation('auditLog.userManagementLog.detail.status')}
                value={detail.activityStatus}
              />
            </Grid>
          </Box>
        </div>
      </div>
      <div className={styles.container}>
        <div className={commonStyles.headerContainer}>
          <div className={commonStyles.rowContainer}>
            <div className={commonStyles.header}>{Translation('auditLog.userManagementLog.detail.data')}</div>
          </div>
        </div>

        <div className={styles.wrap}>
          {detail.oldValue && <DataItem label="Old Value" value={detail.oldValue ?? ''} />}
          {detail.newValue && <DataItem label="New Value" value={detail.newValue ?? ''} />}
        </div>
      </div>
    </>
  );
};
