import React, { FC, useReducer, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  Dialog,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
  RadioGroup,
  Radio,
} from '@mui/material';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { NotificationItem } from '../../types/notification-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { SetOnTop, MANDATORY_FIELD_ERROR_TEXT } from '../../constants';
import { setOnTop } from '../../redux/notificationCrud';
import { appendAlertItem, AlertType, AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface OnTopProp {
  row?: NotificationItem;
  open: boolean;
  openDialog: (status: boolean) => void;
  refreshData: () => void;
}

type OnTopFormState = {
  top?: string;
  topStart?: Date;
  topEnd?: Date;
};

const initialState: OnTopFormState = {
  top: '',
  topStart: undefined,
  topEnd: undefined,
};

type InitialFieldAction = {
  type: 'INITIAL_FIELDS';
  payload: OnTopFormState;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof OnTopFormState;
    value: any;
  };
};

type IncentiveFormAction = InitialFieldAction | ModifyFieldAction;

const incentiveFormReducer = (state: OnTopFormState, action: IncentiveFormAction): OnTopFormState => {
  switch (action.type) {
    case 'INITIAL_FIELDS':
      return {
        top: action.payload.top,
        topStart: action.payload.topStart,
        topEnd: action.payload.topEnd,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
  }
};

type ErrorState = {
  mandatory: {
    top: boolean;
    topStart: boolean;
    topEnd: boolean;
  };
  immediate: {};
  isStartDateIsGreater: boolean;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    minWidth: 100,
    boxSizing: 'border-box',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxRowContainer: {
    display: 'flex',
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
}));

const OnTop: FC<OnTopProp> = ({ row, open, openDialog, refreshData }) => {
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);
  const [formState, formDispatch] = useReducer(incentiveFormReducer, initialState);
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const isDisabled = false;
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const intialErrorState = {
    mandatory: {
      top: false,
      topStart: false,
      topEnd: false,
    },
    immediate: {},
    isStartDateIsGreater: false,
  };

  const [errorState, setErrorState] = useState<ErrorState>(intialErrorState);

  const onSubmitErrorValidator = () => {
    const needSetOnTop = formState.top === SetOnTop.yes;

    let currentErrorState: ErrorState = {
      ...errorState,
      mandatory: {
        top: formState.top ? false : true,
        topStart: false,
        topEnd: false,
      },
      isStartDateIsGreater: false,
    };

    if (needSetOnTop) {
      currentErrorState.mandatory.topStart = !!formState.topStart ? false : true;
      currentErrorState.mandatory.topEnd = !!formState.topEnd ? false : true;
    }

    if (!!formState.topStart && !!formState.topEnd) {
      const startDateIsGreater = moment(new Date(formState.topStart)).isAfter(moment(new Date(formState.topEnd)));
      if (startDateIsGreater) {
        currentErrorState.mandatory.topStart = true;
        currentErrorState.isStartDateIsGreater = true;
      }
    }

    setErrorState(currentErrorState);
    return currentErrorState;
  };

  const onDismissErrorHandler = (field: keyof ErrorState['mandatory'], value: any) => {
    if (value) {
      setErrorState({
        ...errorState,
        mandatory: {
          ...errorState.mandatory,
          [field]: false,
        },
      });
    }
  };

  const onSubmit = () => {
    let currentState = onSubmitErrorValidator();
    if (
      find(currentState.mandatory, (status) => status === true) === undefined &&
      find(currentState.immediate, (status) => status === true) === undefined
    ) {
      setIsSubmiting(true);
      // todo: need to update modifier
      setOnTop(
        { ...formState, top: formState.top === SetOnTop.yes, modifier: (user && user.username) || '' },
        row!.id,
        dispatch,
      )
        .then(() => {
          refreshData();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: Translation('component.ontop.successdec'),
              },
            ]),
          );
          setIsSubmiting(false);
        })
        .catch((err) => {
          setIsSubmiting(false);
          throw err;
        });
      openDialog(false);
    }
  };

  const onCancel = () => {
    openDialog(false);
    setErrorState(intialErrorState);
  };

  useEffect(() => {
    const { top, topStart, topEnd } = row || {};
    formDispatch({ type: 'INITIAL_FIELDS', payload: { top: top ? SetOnTop.yes : SetOnTop.no, topStart, topEnd } });
  }, [row]);

  const getStartDateErrMsg = () => {
    if (errorState.isStartDateIsGreater) {
      return Translation('component.form-date-compare');
    }

    if (errorState.mandatory.topStart) {
      return Translation('component.hint.empty-value-not-allowed');
    }
  };

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('component.ontop.modalTitle')}</span>
          </div>
          <div className={classes.checkboxRowContainer}>
            <div className={classes.checkboxFieldContainer}>
              <span className={classes.field}>
                {Translation('component.formLabel.setOnTop')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <FormControl error disabled={isDisabled}>
              <RadioGroup
                aria-label="onTop"
                name="onTop"
                value={formState.top}
                onChange={(e) => {
                  onDismissErrorHandler('top', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'top', value: e.target.value } });
                }}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {Object.entries(SetOnTop).map(([label, value]) => (
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={Translation(`app.select.${label}`)}
                  />
                ))}
              </RadioGroup>
              <FormHelperText style={{ marginLeft: 14 }}>
                {errorState.mandatory.top && MANDATORY_FIELD_ERROR_TEXT}
              </FormHelperText>
            </FormControl>
          </div>
          {formState.top === SetOnTop.yes ? (
            <>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('component.ontop.startTime')} :</span>
                </div>
                <PruDateTimePicker
                  minutesStep={5}
                  slotProps={{
                    textField: {
                      error: errorState.mandatory.topStart,
                      helperText: getStartDateErrMsg(),
                    },
                  }}
                  ampm={false}
                  disabled={isDisabled}
                  format="DD/MM/YYYY HH:mm"
                  value={formState.topStart || null}
                  onChange={(date) =>
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'topStart', value: date } })
                  }
                />
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('component.ontop.endTime')} :</span>
                </div>
                <PruDateTimePicker
                  minutesStep={5}
                  slotProps={{
                    textField: {
                      error: errorState.mandatory.topEnd,
                      helperText: errorState.mandatory.topEnd && Translation('component.hint.empty-value-not-allowed'),
                    },
                  }}
                  ampm={false}
                  disabled={isDisabled}
                  format="DD/MM/YYYY HH:mm"
                  value={formState.topEnd || null}
                  onChange={(date) => formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'topEnd', value: date } })}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className={classes.btnContainer}>
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onCancel}>
            {Translation('app.button.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
            {isSubmitting && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default OnTop;
