import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import {
  LeadGoalListParam,
  fetchLeadGoalList,
  createLeadGoal,
  updateLeadGoal,
} from '../../../../../network/lead-goal-crud';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';

const initialState: LeadGoalListParam = {
  page: 1,
  limit: 5,
};

export const leadGoalFilterKeys = [];

export type LeadGoalItem = {
  _id: string;
  name: string;
  targetPoint: number;
  timeRange: {
    days: number;
  };
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
};

const paramsInitiator = (initialParams?: Record<string, string>): LeadGoalListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

export const useLeadGoalListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<LeadGoalListParam>(paramsInitiator(initialParams));
  const [leadGoalList, setLeadGoalList] = useState<PaginateList<LeadGoalItem>>();
  const [showDialog, setShowDialog] = useState(false);
  const [pointValue, setPointValue] = useState<number | null>(null);
  const [daysValue, setDaysValue] = useState<number | null>(null);
  const [editTarget, setEditTarget] = useState<LeadGoalItem | null>(null);

  const onPointInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setPointValue(value);
  };

  const onDaysInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setDaysValue(value);
  };

  const onAddClick = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setPointValue(null);
    setDaysValue(null);
    setShowDialog(false);
    setEditTarget(null);
  };

  const onEditClick = (target: LeadGoalItem) => {
    setPointValue(target.targetPoint);
    setDaysValue(target.timeRange.days);
    setEditTarget(target);
    setShowDialog(true);
  };

  const onConfirm = () => {
    const leadGoalName = `Reach ${pointValue} points in ${daysValue} days`;
    const bodyData: any = {
      name: leadGoalName,
      targetPoint: pointValue as number,
      timeRange: {
        days: daysValue as number,
      },
    };

    if (editTarget) {
      updateLeadGoal(editTarget._id, bodyData).then(() => {
        refreshData();
      });
    } else {
      createLeadGoal(bodyData).then(() => {
        refreshData();
      });
    }
    closeDialog();
  };

  const isPointValueValid = useMemo(() => {
    if (pointValue === null) {
      return true;
    }
    return validatePositiveInteger(pointValue);
  }, [pointValue]);

  const isDaysValueValid = useMemo(() => {
    if (daysValue === null) {
      return true;
    }
    return validatePositiveInteger(daysValue);
  }, [daysValue]);

  const isValueValid = useMemo(() => {
    return pointValue && daysValue && isPointValueValid && isDaysValueValid;
  }, [pointValue, daysValue, isPointValueValid, isDaysValueValid]);

  function validatePositiveInteger(input: number) {
    const trimmedInput = String(input).trim();
    const regex = /^[1-9]\d*$/;
    const isValid = regex.test(trimmedInput);

    return isValid;
  }

  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchLeadGoalList({}, dispatch);
      } catch (err) {}
    },
    setLeadGoalList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    refreshData();
  };

  return {
    filterState,
    leadGoalList,
    isLoading,
    showDialog,
    isPointValueValid,
    isDaysValueValid,
    isValueValid,
    editTarget,
    setFilterState,
    refreshData,
    onSort,
    onAddClick,
    onEditClick,
    closeDialog,
    onPointInputChange,
    onDaysInputChange,
    onConfirm,
  };
};
