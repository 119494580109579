import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, getEnabledCustomWeight, useJWT } from 'src/app/common/utils';
import { SALESKIT_BASE_PATH, COMPANYPICK_PATH } from 'src/app/modules/sales-kit/constants';
import { CompanyPickPaginateList } from '../../../type/types';
import { TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { fetchCompanyPickWeights, updateCompanyPick } from '../../../network/api';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

type CompanyPickListProps = {
  isLoading: boolean;
  list?: CompanyPickPaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const RenderWeight: FC<any> = ({ weight, id }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState<string | null>('');
  const jwt = useJWT();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    setValue(weight);
  }, [weight]);

  const handlerBlur = async () => {
    if (weight === Number(value)) {
      setEditable(false);
      return;
    }
    const numberRegex = /^[+]?(\d*[1-9]\d*|\d+\.\d*[1-9])$/;
    if (!numberRegex.test(value)) {
      setError(Translation('value.need.greater.zero.msg'));
      return;
    }
    const currentWeights = await fetchCompanyPickWeights();
    if (currentWeights?.includes(Number(value))) {
      setError(Translation('saleskit.companyPick.weight.isUsed'));
      return;
    }
    setError(null);
    await updateCompanyPick(id, { weight: value, updatedAt: new Date(), lastUpdatedBy: jwt?.username })
      .then(() => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Update Weight successfully',
            },
          ]),
        );
        setEditable(false);
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Network Error',
              content: 'Update Weight Failed',
            },
          ]),
        );
      });
  };
  if (editable) {
    return (
      <div className="tw-flex-1">
        <TextField
          variant="outlined"
          margin="dense"
          onChange={handleChange}
          onBlur={handlerBlur}
          defaultValue={value}
        />
        {error && <div className="tw-text-red-600">{error}</div>}
      </div>
    );
  } else {
    return (
      <div className="tw-flex tw-justify-between">
        <span>{value}</span>
        <EditIcon
          className="tw-cursor-pointer"
          onClick={() => {
            setEditable(true);
          }}
        />
      </div>
    );
  }
};

const CompanyPickList: FC<CompanyPickListProps> = ({ isLoading, onRefresh, onChangePage, list, onSort }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const enabledCustomWeight = getEnabledCustomWeight();
  return (
    <div>
      <PruTable
        disableBulkSelect
        dataSource={list?.docs}
        isLoading={isLoading}
        onRefresh={onRefresh}
        operationSticky={true}
        title={Translation('saleskit.companyPick.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('saleskit.companyPick.list.new'),
            onClick: () => history.push(`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit',
            onClick: (row) => history.push(`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/edit/${row.id}`),
          },
        ]}
        columnDef={[
          {
            keyIndex: 'name',
            displayName: Translation('saleskit.companyPick.list.name'),
            renderData: (row) => row?.name,
          },
          {
            keyIndex: 'weight',
            displayName: Translation('saleskit.companyPick.list.weight'),
            sortable: true,
            onSort: (sort) => {
              onSort({
                key: 'weight',
                value: sort['weight'],
              });
            },
            hidden: !enabledCustomWeight,
            renderData: (row) => <RenderWeight id={row.id} weight={row?.weight} />,
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('microsite_status'),
            renderData: (row) => String(row?.status),
          },
          {
            keyIndex: 'effectiveDate',
            align: 'center',
            displayName: Translation('saleskit.companyPick.effectiveDate'),
            sortable: true,
            onSort: (sort) => {
              onSort({
                key: 'effectiveDate',
                value: sort['effectiveDate'],
              });
            },
            renderData: (row) =>
              row?.effectiveDate ? getDefaultDisplayDate(new Date(row?.effectiveDate), 'datetime') : '',
          },
          {
            keyIndex: 'expiredDate',
            align: 'center',
            displayName: Translation('saleskit.companyPick.expiredDate'),
            sortable: true,
            onSort: (sort) => {
              onSort({
                key: 'expiredDate',
                value: sort['expiredDate'],
              });
            },
            renderData: (row) =>
              row?.expiredDate ? getDefaultDisplayDate(new Date(row?.expiredDate), 'datetime') : '',
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('list.common.lastUpdatedBy'),
            renderData: (row) => row?.lastUpdatedBy,
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('component.formLabel.last-updated-time'),
            sortable: true,
            onSort: (sort) => {
              onSort({
                key: 'updatedAt',
                value: sort['updatedAt'],
              });
            },
            renderData: (row) => getDefaultDisplayDate(new Date(row?.updatedAt), 'datetime'),
          },
        ]}
        totalPages={list?.totalPages}
        totalRecords={list?.totalDocs}
        onChangePage={onChangePage}
        defaultRowsPerPage={20}
      />
    </div>
  );
};

export default CompanyPickList;
