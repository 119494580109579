import React, { FC, useReducer } from 'react';
import moment from 'moment';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler, useUpdateEffect, getDayStart, getDayEnd } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { TIME_ERROR_TEXT } from 'src/app/modules/PulseLeads/constants';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType, AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { SLAConfigItem, SLAFormMode, SLAType } from 'src/app/modules/PulseLeads/types/sla-types';
import { getSLARootPath } from '../../SLARoutes';
import {
  CreateSLAConfigItemBody,
  createSLAConfigItem,
  UpdateSLAConfigItemBody,
  modifySLAConfigItem,
} from 'src/app/modules/PulseLeads/network/slaCrud';

type SLAFormProps = {
  slaType: SLAType;
  formMode: SLAFormMode;
  slaConfig?: SLAConfigItem;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 300,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: 292,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type SLAFormState = {
  name?: string;
  durationInMinutes?: string;
  durationInDays?: string;
  startTime: Date | null;
  endTime: Date | null;
  isRunAllDay: boolean;
  isWeekendIncluded: boolean;
  isPullBackLeadEnabled: boolean;
  // waitInMinutesToReassignLead?: string;
};

const initialState: SLAFormState = {
  name: undefined,
  durationInMinutes: undefined,
  startTime: getDayStart(new Date()),
  endTime: getDayEnd(new Date()),
  isRunAllDay: false,
  isWeekendIncluded: false,
  isPullBackLeadEnabled: false,
  // waitInMinutesToReassignLead: undefined
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof SLAFormState;
    value: any;
  };
};

type SLAFormAction = ModifyFieldAction;

const formReducer = (state: SLAFormState, action: SLAFormAction): SLAFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      let newState = { ...state };
      // if (action.payload.field === "isPullBackLeadEnabled") {
      //   newState.waitInMinutesToReassignLead = undefined;
      // }
      return {
        ...newState,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const getTime = (timeStr?: string): Date | null => {
  if (!timeStr) {
    return null;
  }
  const hourMin = timeStr.split(':');
  if (hourMin.length !== 2) {
    return null;
  }
  const date = new Date();
  date.setHours(Number(hourMin[0]));
  date.setMinutes(Number(hourMin[1]));
  return date;
};

const detailToStateConvertor = (slaConfig: SLAConfigItem): SLAFormState => {
  return {
    name: slaConfig.name,
    durationInMinutes: String(slaConfig.durationInMinutes),
    durationInDays: String(slaConfig.durationInDays),
    startTime: getTime(slaConfig.startTime),
    endTime: slaConfig.endTime === '24:00' ? initialState.endTime : getTime(slaConfig.endTime),
    isRunAllDay: slaConfig.endTime === '24:00',
    isWeekendIncluded: slaConfig.isWeekendIncluded,
    isPullBackLeadEnabled: slaConfig.isPullBackLeadEnabled,
    // waitInMinutesToReassignLead: slaConfig.waitInMinutesToReassignLead ? String(slaConfig.waitInMinutesToReassignLead) : undefined
  };
};

const SLAForm: FC<SLAFormProps> = ({ formMode, slaType, slaConfig }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const afterContact = slaType === 'meet' || slaType === 'proposal' || slaType === 'application';

  const [formState, formDispatch] = useReducer(
    formReducer,
    slaConfig ? detailToStateConvertor(slaConfig) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: afterContact ? 'durationInDays' : 'durationInMinutes',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'startTime',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endTime',
        fieldType: ErrorFieldType.MANDATORY,
      },
      // {
      //   name: "waitInMinutesToReassignLead",
      //   fieldType: ErrorFieldType.MANDATORY,
      //   condition: () => {
      //     if (formState.isPullBackLeadEnabled && !formState.waitInMinutesToReassignLead) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // },
      {
        name: 'endTimeBeforeStartTime',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startTime && formState.endTime) {
            return !!moment(new Date(formState.startTime)).isAfter(moment(new Date(formState.endTime)));
          } else {
            return false;
          }
        },
      },
    ],
  );

  useUpdateEffect(() => {
    immediateErrorValidator();
    // eslint-disable-next-line
  }, [formState.startTime, formState.endTime]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const startTime = formState.startTime ? moment(formState.startTime).format('HH:mm') : '00:00';
      const endTime = formState.endTime ? moment(formState.endTime).format('HH:mm') : '23:59';
      if (formMode === SLAFormMode.CREATE) {
        const body: CreateSLAConfigItemBody = {
          type: slaType,
          name: formState.name || '',
          durationInMinutes: formState.durationInMinutes ? Number(formState.durationInMinutes) : 0,
          durationInDays: formState.durationInDays ? Number(formState.durationInDays) : 0,
          startTime: formState.isRunAllDay ? '00:00' : startTime,
          endTime: formState.isRunAllDay ? '24:00' : endTime,
          isWeekendIncluded: formState.isWeekendIncluded,
          isPullBackLeadEnabled: afterContact ? false : formState.isPullBackLeadEnabled,
          // waitInMinutesToReassignLead: formState.waitInMinutesToReassignLead ? Number(formState.waitInMinutesToReassignLead) : undefined,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createSLAConfigItem(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'SLA Config saved successfully',
              },
            ]),
          );
          history.push(getSLARootPath(location.pathname));
        } catch (err) {}
      } else if (slaConfig) {
        const body: UpdateSLAConfigItemBody = {
          name: formState.name || '',
          durationInMinutes: formState.durationInMinutes ? Number(formState.durationInMinutes) : 0,
          durationInDays: formState.durationInDays ? Number(formState.durationInDays) : 0,
          startTime: formState.isRunAllDay ? '00:00' : startTime,
          endTime: formState.isRunAllDay ? '24:00' : endTime,
          isWeekendIncluded: formState.isWeekendIncluded,
          isPullBackLeadEnabled: afterContact ? false : formState.isPullBackLeadEnabled,
          // waitInMinutesToReassignLead: formState.waitInMinutesToReassignLead ? Number(formState.waitInMinutesToReassignLead) : undefined,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifySLAConfigItem(slaConfig._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `SLA Config updated successfully - ${slaConfig._id}`,
              },
            ]),
          );
          history.push(getSLARootPath(location.pathname));
        } catch (err) {}
      }
    }
  };

  console.log(afterContact);
  console.log(formState);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`pulseleadsSLA.${slaType}.form.title.${formMode === SLAFormMode.CREATE ? 'create' : 'edit'}`)}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(getSLARootPath(location.pathname))}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.sla.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation(`pulseleads.sla.form.duration.${slaType}`)}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={afterContact ? errorState.mandatory.durationInDays : errorState.mandatory.durationInMinutes}
              margin="dense"
              variant="outlined"
              helperText={
                (afterContact ? errorState.mandatory.durationInDays : errorState.mandatory.durationInMinutes) &&
                MANDATORY_FIELD_ERROR_TEXT
              }
              value={afterContact ? formState.durationInDays : formState.durationInMinutes}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                onDismissErrorHandler(afterContact ? 'durationInDays' : 'durationInMinutes', onlyNums);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: afterContact ? 'durationInDays' : 'durationInMinutes', value: onlyNums },
                });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.sla.form.isRunAllDay')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '5px 30px 5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.isRunAllDay === true}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isRunAllDay', value: true } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.yes')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: '5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.isRunAllDay === false}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isRunAllDay', value: false } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.no')}
            labelPlacement="end"
          />
        </div>

        {!formState.isRunAllDay && (
          <>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation(`pulseleads.sla.form.startTime.${slaType}`)}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <PruTimePicker
                  ampm={false}
                  slotProps={{
                    textField: {
                      error: errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime,
                      helperText: errorState.mandatory.startTime && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  value={formState.startTime}
                  onChange={(e) => {
                    onDismissErrorHandler('startTime', e);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: e } });
                  }}
                />
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation(`pulseleads.sla.form.endTime.${slaType}`)}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <PruTimePicker
                  ampm={false}
                  slotProps={{
                    textField: {
                      error: errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime,
                      helperText: errorState.immediate.endTimeBeforeStartTime
                        ? TIME_ERROR_TEXT
                        : errorState.mandatory.endTime && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  value={formState.endTime}
                  onChange={(e) => {
                    onDismissErrorHandler('endTime', e);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: e } });
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className={classes.rowContainer}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.sla.form.isWeekendIncluded')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '5px 30px 5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.isWeekendIncluded === true}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isWeekendIncluded', value: true } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.yes')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: '5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.isWeekendIncluded === false}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isWeekendIncluded', value: false } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.no')}
            labelPlacement="end"
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.sla.form.isPullBackLeadEnabled')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '5px 30px 5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                disabled={afterContact}
                checked={formState.isPullBackLeadEnabled === true}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isPullBackLeadEnabled', value: true } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.yes')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: '5px 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                disabled={afterContact}
                checked={formState.isPullBackLeadEnabled === false}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isPullBackLeadEnabled', value: false } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.no')}
            labelPlacement="end"
          />
        </div>

        {/* {formState.isPullBackLeadEnabled && (
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation("pulseleads.sla.form.waitInMinutesToReassignLead")}<span className={classes.mandatory}>*</span> :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 250 }}
                error={errorState.mandatory.waitInMinutesToReassignLead}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.waitInMinutesToReassignLead && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.waitInMinutesToReassignLead}
                onChange={e => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                  onDismissErrorHandler("waitInMinutesToReassignLead", onlyNums);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: "waitInMinutesToReassignLead", value: onlyNums } });
                }}
              />
            </div>
          </div>
        )} */}
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default SLAForm;
