import { FC, useEffect, useReducer, useState } from 'react';
import { AgentSegmentItem } from 'src/app/common/types';
import { AgentSegmentFormMode, SegmentNameItem, AgentSegmentEditItem } from '../../../../types/agent-segment-type';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, FormControlLabel, Dialog, RadioGroup, Radio } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CONFIG_BASE_PATH, CONFIG_AGENT_SEGMENT_PATH, AdminAgentSegmentPermissionType } from '../../../../constants';
import { useIntl } from 'react-intl';
import { getAvailableServerLocale } from 'src/app/i18n';
import {
  ErrorFieldType,
  useErrorHandler,
  useJWT,
  preFillZeroList,
  toAbsoluteUrl,
  isPermitted,
} from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import {
  createNewAgentSegment,
  modifyAgentSegment,
  fetchCheckNewsAttached,
} from '../../../../network/agentSegmentCrud';
import { map } from 'lodash';
import TargetApplicationSection from './TargetApplicantSection';
import TargetApplicationSection_MO_BROKER from './TargetApplicantSection_MO_BROKER';
import { EnrolmentType } from 'src/app/modules/News/types/news-type';
import { MuiColorInput } from 'mui-color-input';

type AgentSegmentFormProps = {
  code?: string;
  formMode: AgentSegmentFormMode;
  agentSegment?: AgentSegmentItem;
  lastFileName: Pick<AgentSegmentItem, 'agentListFileName'>;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  newsText: {
    lineHeight: 1.8,
  },
  imgRow: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  imgItem: {
    margin: 10,
    maxWidth: 500,
  },
  tag: {
    padding: '8px 10px',
    fontWeight: 600,
    fontSize: 12,
  },
  inputMargin: {
    marginRight: 20,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  settingTable: {
    width: '100%',
  },
  checkboxNoArchiveContainer: {
    margin: 0,
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  dialogContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTitle: {
    fontSize: 20,
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButton: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
  },
  thumbnails: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailContainer: {
    backgroundColor: 'red',
    padding: '10px',
    marginLeft: 10,
    marginRight: 20,
    marginBottom: 10,
    borderRadius: 5,
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailImage: {
    width: 'auto',
    height: 'auto',
  },
  checkBoxContainer: {
    padding: '10px',
    marginLeft: 5,
    marginRight: 20,
    marginBottom: 10,
    borderRadius: 5,
    height: 50,
    width: 50,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export type AgentSegmentFormState = {
  SegmentId: string;
  name: SegmentNameItem;
  icon: string;
  iconBgColor: string;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  eligibleDesignations?: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  region: string;
  channel: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  enableInPruNews: boolean;
  enableInIncentives: boolean;
};

const initialState: AgentSegmentFormState = {
  SegmentId: '',
  name: {},
  icon: '',
  iconBgColor: '#ff0000',
  enrolmentType: EnrolmentType.BY_SEGMENT,
  eligibleSegments: {
    designation: [],
  },
  eligibleAgents: [],
  excludedAgents: [],
  agentListFileName: '',
  region: '',
  channel: '',
  createdBy: '',
  updatedBy: '',
  createdAt: null,
  updatedAt: null,
  eligibleDesignations: undefined,
  enableInPruNews: true,
  enableInIncentives: true,
};

const detailToStateConvertor = (detail: AgentSegmentItem): AgentSegmentFormState => {
  const eligibleDesignations =
    detail.eligibleSegments && detail.eligibleSegments.designation ? detail.eligibleSegments.designation : [];
  return {
    SegmentId: detail._id,
    name: detail.name || {},
    icon: detail.icon,
    iconBgColor: detail.iconBgColor || '#ff0000',
    enrolmentType: detail.enrolmentType,
    eligibleSegments: detail.eligibleSegments || { designation: [] },
    eligibleAgents: detail.eligibleAgents || [],
    excludedAgents: detail.excludedAgents || [],
    agentListFileName: detail.agentListFileName,
    region: detail.region,
    channel: detail.channel,
    createdBy: detail.createdBy,
    updatedBy: detail.updatedBy,
    createdAt: detail.createdAt || null,
    updatedAt: detail.updatedAt || null,
    enableInPruNews: detail.enableInPruNews || false,
    enableInIncentives: detail.enableInIncentives || false,
    eligibleDesignations,
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AgentSegmentFormState;
    value: any;
  };
};

type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<AgentSegmentFormState, 'name'>;
    locale: string;
    value: any;
  };
};

type ToggleDesignationAction = {
  type: 'TOGGLE_DESIGNATION';
  payload: {
    value: string;
  };
};

type AgentSegmentFormAction = ModifyFieldAction | ToggleDesignationAction | ModifyLocaleFieldAction;

const agentSegmentFormReducer = (
  state: AgentSegmentFormState,
  action: AgentSegmentFormAction,
): AgentSegmentFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };

    case 'TOGGLE_DESIGNATION':
      const newDesignation = [...(state.eligibleDesignations || [])];
      const itemIndex = newDesignation.findIndex((designation) => designation === action.payload.value);
      newDesignation[itemIndex] ? newDesignation.splice(itemIndex, 1) : newDesignation.push(action.payload.value);
      return {
        ...state,
        eligibleDesignations: newDesignation,
      };
  }
};

const AgentSegmentForm: FC<AgentSegmentFormProps> = ({ agentSegment, code, formMode, onRouteTo }) => {
  const availableLocales = getAvailableServerLocale();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [formState, formDispatch] = useReducer(
    agentSegmentFormReducer,
    agentSegment ? detailToStateConvertor(agentSegment) : initialState,
  );
  const [attachedDialog, setAttachedDialog] = useState<boolean>(false);

  const isDisabled = false;
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  let approverPermission = false;
  let archivePermission = false;
  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    approverPermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_APPROVE], jwtPermission);
    archivePermission = isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_ARCHIVE], jwtPermission);
  }

  const modifyTargetApplicant = (
    option: keyof Pick<
      AgentSegmentFormState,
      'enrolmentType' | 'eligibleAgents' | 'eligibleDesignations' | 'excludedAgents' | 'agentListFileName'
    >,
    value: any,
  ) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: option, value } });
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    ...availableLocales.map((locale) => ({
      name: `name${locale}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.name || !formState.name[locale]) {
          flag = true;
        }
        return flag;
      },
    })),
    {
      name: 'icon',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.icon || formState.icon == '') {
          flag = true;
        }
        return flag;
      },
    },
    {
      name: 'iconColor',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = false;
        if (!formState.iconBgColor || formState.iconBgColor == '') {
          flag = true;
        }
        return flag;
      },
    },
    {
      name: 'designation',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (
          (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
          (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
          (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
          (!formState.agentListFileName || formState.agentListFileName == '')
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
  ]);

  useEffect(() => {
    onDismissErrorHandler(
      'designation',
      !(
        (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
        (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
        (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
        (!formState.agentListFileName || formState.agentListFileName == '')
      ),
    );
    // eslint-disable-next-line
  }, [formState.eligibleDesignations, formState.eligibleAgents, formState.excludedAgents, formState.agentListFileName]);

  const onSubmitForm = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      const details: AgentSegmentEditItem = {
        name: formState.name,
        icon: formState.icon,
        iconBgColor: formState.iconBgColor,
        enrolmentType: formState.enrolmentType,
        eligibleSegments: {
          designation: formState.eligibleDesignations || [],
        },
        eligibleAgents: formState.eligibleAgents ? preFillZeroList(formState.eligibleAgents) : [],
        excludedAgents: formState.excludedAgents ? preFillZeroList(formState.excludedAgents) : [],
        agentListFileName: formState.agentListFileName || '',
        enableInPruNews: formState.enableInPruNews,
        enableInIncentives: formState.enableInIncentives,
      };

      try {
        if (formMode === AgentSegmentFormMode.CREATE) {
          await createNewAgentSegment(details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('configAgentSegment.success.saved'),
              },
            ]),
          );
        } else if (code && formMode === AgentSegmentFormMode.EDIT) {
          await modifyAgentSegment(code, details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('configAgentSegment.success.updated', { code }),
              },
            ]),
          );
        }
        onRouteTo(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`);
      } catch (err) {}
    }
  };

  ///png handle
  const pngFiles = ['health.png', 'medical.png', 'reward.png', 'runner.png', 'team.png', 'tie.png', 'trophy.png'];
  const pngRadioButtons = Object.entries(pngFiles).map(([label, value]) => {
    const imageUrl = toAbsoluteUrl(`/media/icon/segments_icon/${value}`);
    const imageAlt = value;
    const labelContent = (
      <div className={classes.thumbnailContainer}>
        <img src={imageUrl} alt={imageAlt} className={classes.thumbnailImage} />
      </div>
    );

    return (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio disabled={false} />}
        label={labelContent}
        checked={formState.icon === value}
        onChange={() => {
          onDismissErrorHandler('icon', value);
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'icon', value: value } });
        }}
      />
    );
  });

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {formMode === AgentSegmentFormMode.CREATE
              ? Translation('configAgentSegment.common.newAgentSegment')
              : Translation('configAgentSegment.edit')}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`);
          }}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}></div>
        {availableLocales.map((locale) => (
          <div key={locale} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`configAgentSegment.common.name.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                error={errorState.mandatory[`name${locale}`]}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory[`name${locale}`] && Translation('field_mandatory_helper_label')}
                value={formState.name[locale] || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_LOCALE_FIELD',
                    payload: { field: 'name', locale, value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        ))}

        {/* working */}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.icon')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>

          <RadioGroup aria-label="icon" name="icon" style={{ display: 'flex', flexDirection: 'row' }}>
            {pngRadioButtons}
          </RadioGroup>
          {errorState.mandatory.icon && (
            <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
          )}
        </div>
        {/* working */}

        <div className={classes.rowContainer} style={{ marginBottom: 15 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.color')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <MuiColorInput
            isAlphaHidden
            name="color"
            margin="dense"
            variant="outlined"
            format="hex"
            error={errorState.mandatory.color}
            helperText={errorState.mandatory.color && Translation('field_mandatory_helper_label')}
            value={formState.iconBgColor}
            onChange={(color) => {
              onDismissErrorHandler('color', color);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'iconBgColor', value: color } });
            }}
          />
          {errorState.mandatory.iconColor && (
            <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
          )}
        </div>
        {/* working */}

        <div className={classes.rowContainer} style={{ marginTop: '20px' }}>
          <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
            <span className={classes.field}>
              {Translation('configAgentSegment.common.targetApplicants')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ width: '75%' }}>
            {channel == 'BROKER' && region == 'MO' ? (
              <TargetApplicationSection_MO_BROKER
                isDisabled={isDisabled}
                enrolmentType={formState.enrolmentType}
                eligibleDesignations={formState.eligibleDesignations || []}
                eligibleAgents={formState.eligibleAgents || []}
                excludedAgents={formState.excludedAgents || []}
                agentListFileName={formState.agentListFileName}
                onModifyTarget={modifyTargetApplicant}
                onToggleDesignation={(value) => {
                  formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                }}
              />
            ) : (
              <TargetApplicationSection
                isDisabled={isDisabled}
                enrolmentType={formState.enrolmentType}
                eligibleDesignations={formState.eligibleDesignations || []}
                eligibleAgents={formState.eligibleAgents || []}
                excludedAgents={formState.excludedAgents || []}
                agentListFileName={formState.agentListFileName}
                onModifyTarget={modifyTargetApplicant}
                onToggleDesignation={(value) => {
                  formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                }}
              />
            )}

            {errorState.mandatory.designation && (
              <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
            )}
          </div>
        </div>
      </div>

      {/* enable segment button in prunews */}
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgentSegment.common.isPRUNews')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ width: '75%' }}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              value="Enable"
              control={<Radio disabled={false} />}
              label={Translation('configAgentSegment.common.enable')}
              checked={formState.enableInPruNews === true}
              onChange={() => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: true } });
              }}
            />
            <FormControlLabel
              value="Disable"
              control={<Radio disabled={false} />}
              checked={formState.enableInPruNews === false}
              label={Translation('configAgentSegment.common.disable')}
              onChange={async () => {
                if (formMode === AgentSegmentFormMode.EDIT) {
                  const result = await fetchCheckNewsAttached(formState.SegmentId);
                  if (result) {
                    setAttachedDialog(true);
                    return;
                  }
                }
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: false } });
              }}
            />
          </RadioGroup>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgentSegment.common.incentives')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ width: '75%' }}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              value="Enable"
              control={<Radio disabled={false} />}
              label={Translation('configAgentSegment.common.enable')}
              checked={formState.enableInIncentives === true}
              onChange={() => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInIncentives', value: true } });
              }}
            />
            <FormControlLabel
              value="Disable"
              control={<Radio disabled={false} />}
              checked={formState.enableInIncentives === false}
              label={Translation('configAgentSegment.common.disable')}
              onChange={async () => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInIncentives', value: false } });
              }}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.footerContainer}>
        {
          <>
            {/* <Button
              style={{ marginLeft: 25 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                console.log(formState)
              }}
            >
              {"test btn"}
            </Button> */}
            <Button
              style={{ marginLeft: 25 }}
              variant="contained"
              color="secondary"
              onClick={() => onSubmitForm()}
              // onClick={() => {
              //   newsArticle?.status == NewsArticleStatus.Published
              //     ? setSaveToDraftWarningDialog(NewsArticleStatus.Pending)
              //     : onSubmitForm(NewsArticleStatus.Pending);
              // }}
            >
              {Translation('app.button.submit')}
            </Button>
          </>
        }
      </div>

      <Dialog maxWidth="xs" open={!!attachedDialog} onClose={() => setAttachedDialog(false)}>
        <div className={classes.dialogContainer}>
          <label className={classes.dialogTitle}>{Translation('reminder_title')}</label>
          <label>{Translation('configAgentSegment.dialog.newsAttached.title')}</label>
        </div>
        <div className={classes.btnContainer} style={{ flexDirection: 'column' }}>
          <Button
            fullWidth
            style={{ marginBottom: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => (
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableInPruNews', value: false } }),
              setAttachedDialog(false)
            )}
          >
            {Translation('global.text.confirm')}
          </Button>
          <Button fullWidth variant="contained" color="inherit" onClick={() => setAttachedDialog(false)}>
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AgentSegmentForm;
