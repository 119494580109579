import { useEffect, useReducer, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { regionLocale, initI18nGenericData, RegionLocale } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';
import { useErrorHandler, dismissTinymceError, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { StepStatusEnum, ComponentProps, useUpdateChecker } from 'src/app/common/components/pru-stepped-form';
import { EventFormCommonProps, EventItem } from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';
import { DetailsFormState, detailsFormReducer } from '../../../reducers';

type HookProps = ComponentProps<EventFormCommonProps>;

const detailToStateConvertor = (regionLocale: RegionLocale[], eventItem?: EventItem): DetailsFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  const initI18nFileData = initI18nGenericData<AttachmentDef>(regionLocale);
  const initI18nFileListData = initI18nGenericData<AttachmentDef[]>(regionLocale, []);
  return eventItem
    ? {
        eventImage: eventItem.eventImage || initI18nFileData,
        description: eventItem.description || initI18nStringData,
        attachments: eventItem.attachments || initI18nFileListData,
      }
    : {
        eventImage: initI18nFileData,
        description: initI18nStringData,
        attachments: initI18nFileListData,
      };
};

export const useDetailsForm = ({
  formCommonProps,
  onStepChange,
  componentUpdateStepStatus,
  setDiscardChanges,
}: HookProps) => {
  const dispatch = useDispatch();
  const { eventItem, isReset, setChangesUnsaved, setIsReset, reloadData } = formCommonProps;
  const initialState = useMemo(() => detailToStateConvertor(regionLocale, eventItem), [eventItem]);
  const [formState, formDispatch] = useReducer(detailsFormReducer, initialState);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, resetErrorState } = useErrorHandler(formState, [
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `eventImage-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.eventImage[locale],
          },
          {
            name: `description-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.description[locale],
          },
        ];
      });
      return errorFieldDef;
    })(),
  ]);

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    resetErrorState();
    componentUpdateStepStatus({
      newStatus: eventItem?.eventImage ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
      forceUpdateStatus: true,
    });
    setIsReset(true);
  };

  useEffect(() => {
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, []);

  useEffect(() => {
    dismissTinymceError(formState, 'description', onDismissErrorHandler, regionLocale);
  }, [formState.description, regionLocale]);

  useUpdateChecker({ formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateUpdateEventBody = {
        eventImage: formState.eventImage,
        description: formState.description,
        attachments: formState.attachments,
      };
      try {
        if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          await reloadData();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Data saved successfully',
              },
            ]),
          );
          onStepChange({
            newActiveStep: { stepIndex: 0, child: { stepIndex: 2 } },
            currentStepStatus: StepStatusEnum.FINISHED,
            forceNavigation: true,
            saveChanges: true,
          });
        }
      } catch (err) {}
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  return {
    formState,
    errorState,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};
