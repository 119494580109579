import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControlLabel, Link, MenuItem, Select, TextField } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked, RemoveCircleOutline } from '@mui/icons-material';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { RemarkItem, ContentEnum, SendTypesEnum } from 'src/app/modules/AgencyCampaign/types/approval-types';
import { generateLouTemplate } from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import { useForm } from 'src/app/common/components/Form';
import Form from 'src/app/common/components/Form';
import AcmRadio from 'src/app/modules/AgencyCampaign/common/AcmRadio';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    width: 450,
    height: 550,
    maxWidth: 450,
    padding: 16,
    background: 'white',
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  closeBtn: {
    float: 'right',
    cursor: 'pointer',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  rowContainer: {
    marginBottom: 10,
  },
  uploadSectionContainer: {
    marginLeft: 9,
  },
  templateSectionContainer: {
    marginLeft: 9,
    padding: 9,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  removeBtnContainer: {
    paddingRight: 10,
  },
  removeBtn: {
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  bottomSpacing: {
    marginBottom: 25,
  },
  actionBtn: {
    width: 100,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: 20,
  },
  mandatory: {
    color: 'red',
  },
  border: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.28)',
    borderRadius: 10,
    padding: '20px 20px 10px',
    maxWidth: 700,
  },
}));

const initialRemarkItem: RemarkItem = {
  type: ContentEnum.FIXED,
  resourceIds: [],
  templateId: '',
  sendTypes: [SendTypesEnum.APPLICANT],
  createdBy: '',
  updatedBy: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

const operatorDropdownList = [
  { key: 'gte', value: 'Before' },
  { key: 'lte', value: 'After' },
];

const DependsOnDropdownList = [
  { key: 'campaignStartDate', value: 'Campaign start date' },
  { key: 'campaignEndDate', value: 'Campaign end date' },
];

const SendDocumentsSetting: FC<any> = ({ resources, louTemplateList, approverName, onLoaded, sendDocumentSetting }) => {
  const [visible, setVisible] = useState<boolean>();
  const { classes: workflowClasses } = useWorkflowStyles();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [form] = useForm();
  const { id } = useParams<{ id: string }>();
  const [initialValue] = useState<any>(
    sendDocumentSetting
      ? {
          ...sendDocumentSetting,
        }
      : {
          enableSendDocument: 'true',
          needACMApproved: 'true',
        },
  );
  const [remarkItem, setRemarkItem] = useState<RemarkItem>(
    sendDocumentSetting?.remarkItem
      ? { ...sendDocumentSetting.remarkItem }
      : {
          ...initialRemarkItem,
          createdBy: approverName,
          updatedBy: approverName,
        },
  );
  const [filenameList, setFilenameList] = useState<Record<string, string>>({});
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };

  const LEADSETTING_ENDDATESETTING_VALUE_VALIDATE = [
    DEFAULT_REQUIRED_RULES,
    {
      message: Translation('must_be_an_integer_bigger_than_0'),
      validator(_: any, value: string) {
        if (!value?.match?.(/^\d+$/)) {
          return Promise.reject(new Error(Translation('must_be_an_integer_bigger_than_0')));
        }
        return Promise.resolve();
      },
    },
  ];

  useEffect(() => {
    onLoaded({ form });
    if (sendDocumentSetting) {
      setVisible(sendDocumentSetting.enableSendDocument === 'true');
      const convertData = {
        ...sendDocumentSetting,
        enableSendDocument: sendDocumentSetting?.enableSendDocument?.toString(),
      };
      form.setFieldsValue(convertData);
    } else {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    const newFieldsValue = form.getFieldsValue(true);
    const allValue = { ...newFieldsValue, remarkItem };
    form.setFieldsValue(allValue);
  }, [remarkItem]);

  const removeFile = (index: number) => {
    const newRemarkItem = { ...remarkItem, resourceIds: [...remarkItem?.resourceIds] };
    newRemarkItem.resourceIds.splice(index, 1);
    newRemarkItem.updatedAt = new Date();
    newRemarkItem.updatedBy = approverName;
    setRemarkItem(newRemarkItem);
  };

  const addFile = (blobId: string) => {
    const newRemarkItem = { ...remarkItem, resourceIds: [...remarkItem?.resourceIds] };
    newRemarkItem.resourceIds.push(blobId);
    newRemarkItem.updatedAt = new Date();
    newRemarkItem.updatedBy = approverName;
    setRemarkItem(newRemarkItem);
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const createBlobRes = await createBlob(
        { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agencyCampaign' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file);
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        addFile(result.blobId);
        setFilenameList({
          ...filenameList,
          [result.blobId]: file.name,
        });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload file successfully - ${file.name}`,
            },
          ]),
        );
      }
    }
  };

  const handleSelectSendType = async (value: SendTypesEnum) => {
    const newRemarkItem = { ...remarkItem, sendTypes: [...remarkItem?.sendTypes] };
    const newSendTypes = newRemarkItem.sendTypes;
    if (newSendTypes.includes(value)) {
      newSendTypes.splice(newSendTypes.indexOf(value), 1);
    } else {
      newSendTypes.push(value);
    }
    setRemarkItem(newRemarkItem);
  };

  const getLouTemplateContent = async (templateId: string) => {
    const body: any = { id: id, templateId: templateId, campaignTypeId: id };
    const templateContent = await generateLouTemplate(body, dispatch);
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.document.body.innerHTML = templateContent;
    }
  };
  return (
    <Form form={form} initialValues={initialValue}>
      <div className={workflowClasses.bottomSpacing}>
        <div className={workflowClasses.rowContainer}>
          <div className="col-3">
            <span className={workflowClasses.settingTitle}>
              <span>{Translation(`send_document`)}</span>
            </span>
          </div>
        </div>
        <div className={workflowClasses.rowContainer}>
          <div className="col-3" />
          <div className="col-9">
            <Form.Item name="enableSendDocument" rules={[DEFAULT_REQUIRED_RULES]}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -30 }}>
                <span className="tw-whitespace-nowrap tw-w-44">{Translation('enable_send_document')}</span>
                <span className="tw-flex tw-flex-row">
                  <FormControlLabel
                    style={{ margin: '2px 0' }}
                    control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                    onClick={(e) => {
                      form.setFieldsValue({ enableSendDocument: 'true' });
                      setVisible(true);
                    }}
                    checked={form.getFieldValue('enableSendDocument')?.toString() === 'true'}
                    label={'Yes'}
                    value={'true'}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    style={{ margin: '2px 0' }}
                    control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                    onClick={(e) => {
                      form.setFieldsValue({ enableSendDocument: 'false' });
                      setVisible(false);
                    }}
                    checked={form.getFieldValue('enableSendDocument')?.toString() === 'false'}
                    label={'No'}
                    value={'false'}
                    labelPlacement="end"
                  />
                </span>
              </div>
            </Form.Item>

            {visible && (
              <div className={classes.border}>
                <div className={classes.subTitle}>{Translation('agencyCampaign.approval.remark.contentType')}</div>
                <div className={classes.rowContainer}>
                  <FormControlLabel
                    style={{ margin: '2px 12px 2px 0' }}
                    control={
                      <AcmCheckbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={remarkItem?.type === ContentEnum.FIXED}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newRemarkItem = { ...remarkItem };
                            newRemarkItem.type = ContentEnum.FIXED;
                            setRemarkItem(newRemarkItem);
                          }
                        }}
                      />
                    }
                    label={Translation('agencyCampaign.approval.remark.fixContent')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    style={{ margin: '2px 0' }}
                    control={
                      <AcmCheckbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={remarkItem?.type === ContentEnum.VARIABLE}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newRemarkItem = { ...remarkItem };
                            newRemarkItem.type = ContentEnum.VARIABLE;
                            newRemarkItem.sendTypes = [SendTypesEnum.APPLICANT];
                            setRemarkItem(newRemarkItem);
                          }
                        }}
                      />
                    }
                    label={Translation('agencyCampaign.approval.remark.variableContent')}
                    labelPlacement="end"
                  />
                </div>

                {remarkItem?.type === ContentEnum.FIXED && (
                  <div className={`${classes.uploadSectionContainer} ${classes.bottomSpacing}`}>
                    <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
                      {Translation('agencyCampaign.approval.remark.uploadFiles')}
                    </div>
                    <div className={classes.rowContainer}>
                      {remarkItem?.resourceIds.map((resourceId, index) => (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                          <div className={classes.removeBtnContainer}>
                            <RemoveCircleOutline className={classes.removeBtn} onClick={() => removeFile(index)} />
                          </div>
                          <Link
                            color="secondary"
                            target="_blank"
                            href={resources?.find((item: any) => item.blobId === resourceId)?.url}
                          >
                            {resources?.find((item: any) => item.blobId === resourceId)?.originalFilename ||
                              filenameList[resourceId]}
                          </Link>
                        </div>
                      ))}
                    </div>
                    <div>
                      <input
                        id="upload-file"
                        hidden
                        type="file"
                        accept=".pdf"
                        onClick={(e) => {
                          const element = e.target as HTMLInputElement;
                          element.value = '';
                        }}
                        onChange={(e) => handleFile(e)}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => document.getElementById('upload-file')!.click()}
                      >
                        {Translation('app.button.upload')}
                      </Button>
                    </div>
                  </div>
                )}

                {remarkItem?.type === ContentEnum.VARIABLE && (
                  <div className={`${classes.templateSectionContainer} ${classes.bottomSpacing}`}>
                    <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
                      {Translation('agencyCampaign.approval.remark.selectTemplate')}
                    </div>
                    {louTemplateList.map((louTemplate: { templateId: string }) => (
                      <div>
                        <AcmCheckbox
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<CheckCircle />}
                          checked={remarkItem?.templateId === louTemplate.templateId}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newRemarkItem = { ...remarkItem };
                              newRemarkItem.templateId = louTemplate.templateId;
                              setRemarkItem(newRemarkItem);
                            }
                          }}
                        />
                        <Link
                          color="secondary"
                          target="_blank"
                          onClick={(event: React.MouseEvent<any>) => {
                            event.preventDefault();
                            getLouTemplateContent(louTemplate.templateId);
                          }}
                        >
                          {Translation(`agencyCampaign.approval.remark.louTemplate.${louTemplate.templateId}`)}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}

                <div className={classes.bottomSpacing}>
                  <div className={classes.subTitle}>{Translation('component.formLabel.receiver')}</div>
                  {Object.values(SendTypesEnum).map((value) => (
                    <span>
                      <FormControlLabel
                        style={{ margin: '2px 0' }}
                        disabled={remarkItem?.type === ContentEnum.VARIABLE}
                        control={
                          <AcmCheckbox
                            checked={remarkItem?.sendTypes.includes(value)}
                            onClick={() => handleSelectSendType(value)}
                          />
                        }
                        label={Translation(`agencyCampaign.approval.remark.${value}`)}
                        labelPlacement="end"
                      />
                    </span>
                  ))}
                </div>

                <div>
                  <div className={classes.subTitle}>{Translation('agencyCampaign.common.publishTime')}</div>
                  <span className="tw-flex tw-items-center">
                    <span className="tw-mr-3">
                      <Form.Item
                        name={['publishTimeSetting', 'value']}
                        rules={LEADSETTING_ENDDATESETTING_VALUE_VALIDATE}
                      >
                        <TextField
                          margin="dense"
                          variant="outlined"
                          id="en"
                          size="small"
                          placeholder={Translation('app.input.placeholder.please-enter')}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </span>

                    <span className="tw-mr-3">{'Days'}</span>

                    <span className="tw-mr-3">
                      <Form.Item name={['publishTimeSetting', 'operator']} rules={[DEFAULT_REQUIRED_RULES]}>
                        <Select style={{ minWidth: 100 }} margin="dense" variant="outlined">
                          {operatorDropdownList.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Form.Item>
                    </span>

                    <span>
                      <Form.Item name={['publishTimeSetting', 'dependsOn']} rules={[DEFAULT_REQUIRED_RULES]}>
                        <Select style={{ minWidth: 200 }} margin="dense" variant="outlined">
                          {DependsOnDropdownList.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Form.Item>
                    </span>

                    <span style={{ margin: '0 10px 0 10px' }}>{'on 9.00AM'}</span>
                  </span>
                </div>

                <div className="tw-mt-5">
                  <div className={classes.subTitle}>{Translation('approval_status_required')}</div>
                  <Form.Item name="needACMApproved">
                    <FormControlLabel
                      style={{ margin: '2px 12px 2px 0' }}
                      control={
                        <AcmCheckbox
                          onChange={(e) => form.setFieldsValue({ needACMApproved: e.target.checked.toString() })}
                          defaultChecked={form.getFieldValue('needACMApproved') === 'true' ? true : false}
                        />
                      }
                      label={Translation('only_when_campaign_is_approved')}
                      labelPlacement="end"
                    />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SendDocumentsSetting;
