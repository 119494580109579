import React, { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler, ErrorFieldDef } from 'src/app/common/utils';
import { regionLocale, initI18nData, RegionLocale, I18nDataItem } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import {
  CampaignFeedbackFormMode,
  CampaignFeedbackItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';
import { campaignFeedbackPath } from '../../CampaignFeedbackRoutes';
import {
  createCampaignFeedback,
  modifyCampaignFeedback,
  GenericCampaignFeedbackBody,
} from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';

type CampaignFeedbackFormProps = {
  formMode: CampaignFeedbackFormMode;
  campaignFeedbackItem?: CampaignFeedbackItem;
};

const FIELD_CONTAINER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type CampaignFeedbackFormState = {
  name?: string;
  i18nData: I18nDataItem;
};

const initialState: CampaignFeedbackFormState = {
  name: undefined,
  i18nData: { en: { name: undefined } },
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof CampaignFeedbackFormState;
    value: any;
  };
};

type CampaignFeedbackFormAction = ModifyFieldAction;

const formReducer = (
  state: CampaignFeedbackFormState,
  action: CampaignFeedbackFormAction,
): CampaignFeedbackFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  campaignFeedbackItem?: CampaignFeedbackItem,
): CampaignFeedbackFormState => {
  const initialI18nData = initI18nData(regionLocale, campaignFeedbackItem, campaignFeedbackItem?.name);
  return campaignFeedbackItem
    ? {
        name: campaignFeedbackItem.name,
        i18nData: initialI18nData,
      }
    : {
        ...initialState,
        i18nData: initialI18nData,
      };
};

const CampaignFeedbackForm: FC<CampaignFeedbackFormProps> = ({ formMode, campaignFeedbackItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(regionLocale, campaignFeedbackItem));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    ...(() => {
      const errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef.push({
          name: `i18nData-${locale}`,
          fieldType: ErrorFieldType.MANDATORY,
          condition: () => {
            return !!!formState.i18nData[locale]?.name;
          },
        });
      });
      return errorFieldDef;
    })(),
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === CampaignFeedbackFormMode.CREATE) {
        const body: GenericCampaignFeedbackBody = {
          name: formState.name || '',
          i18nData: formState.i18nData,
        };
        try {
          await createCampaignFeedback(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Feedback saved successfully',
              },
            ]),
          );
          history.push(campaignFeedbackPath);
        } catch (err) {}
      } else if (campaignFeedbackItem) {
        const body: GenericCampaignFeedbackBody = {
          name: formState.name || '',
          i18nData: formState.i18nData,
        };
        try {
          await modifyCampaignFeedback(campaignFeedbackItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Feedback updated successfully`,
              },
            ]),
          );
          history.push(campaignFeedbackPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.campaignFeedback.form.title.${
                formMode === CampaignFeedbackFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(campaignFeedbackPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      {regionLocale.map((locale, index) => (
        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaignFeedback.common.name')} ({Translation(`navBar.lang.${locale}`)})
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
              <TextField
                style={{ width: 250, marginRight: 10 }}
                error={errorState.mandatory[`i18nData-${locale}`]}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory[`i18nData-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.i18nData[locale]?.name}
                onChange={(e) => {
                  onDismissErrorHandler(`i18nData-${locale}`, e.target.value);
                  if (
                    (regionLocale.includes(RegionLocale.ENGLISH) && locale === RegionLocale.ENGLISH) ||
                    (!regionLocale.includes(RegionLocale.ENGLISH) && index === 0)
                  ) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
                  }
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'i18nData',
                      value: {
                        ...formState.i18nData,
                        [locale]: {
                          name: e.target.value,
                        },
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      ))}

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default CampaignFeedbackForm;
