import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CreateRoleUserManagementItem,
  getUserDetail,
  RoleUserManagementItem,
} from '../../../network/role-user-management-crud';
import Form from 'src/app/common/components/Form';

interface HookProps {
  id: string;
  mode: string; //'create' | 'edit';
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export enum STATUS_ENUM {
  ENABLE = 'ENABLED',
  DISABLE = 'DISABLED',
}

export const useCreateUser = ({ id, mode }: HookProps) => {
  const [initialValues, setInitialValues] = useState<CreateRoleUserManagementItem>({
    userName: {},
    status: STATUS_ENUM.ENABLE,
    userId: '',
  });
  const [loading, setIsLoading] = useState(true);

  const [form] = Form.useForm();

  // // redux
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && mode === 'edit') {
      setIsLoading(true);
      getUserDetail(id, dispatch)
        .then((res: RoleUserManagementItem) => {
          setInitialValues({
            userName: res.userName,
            status: res.status,
            userId: res.userId,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    setIsLoading(false);
  }, [id]);

  return {
    loading,
    initialValues,
    form,
  };
};
