import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import * as XLSX from 'xlsx';
import { templateFileData } from './results-template';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { ExamResultsFileData } from '../../../types/license-exam-types';
import { TranslationWithParams, regionLocale, useLang } from 'src/app/i18n';
import { csvReader, dateFormatToServer } from '../../../utils';
import { ExamResultsEnum } from '../../../enum/license-exam.enum';
import { createLicenseExamResults, fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { combineDateTime } from '../schedule/util/api-helper.util';
import { createResultConfigs } from '../util/form-configs.util';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { apiErrorHandler } from 'src/app/common/network';

export interface setResultsFileDataParams {
  name: string;
  data: any[];
}

const initalResultsFileData: setResultsFileDataParams = {
  name: '',
  data: [],
};

const ExamResultsUploadPage: FC<RouteComponentProps & ModulePermissionProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [validatorErrors, setValidatorErrors] = useState<{ row: number; msg: string }[]>([]);

  const [resultsFileData, setResultsFileData] = useState(initalResultsFileData);

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const locale = useLang();

  const {
    data: examTypeValues,
    loading,
    error,
  } = useRequest(
    async () => {
      return fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
    },
    { manual: false },
  );

  async function xlsxHandler(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // get the first sheet as default work sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // transform the work sheet data to json
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

        resolve(jsonData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  // useEffect(() => {
  //   setIsLoading(false);
  // }, []);

  const getResultsData = async (file: File) => {
    let result: ExamResultsFileData[] = [];
    try {
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
      let data;
      if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx') {
        data = await xlsxHandler(file);
      } else {
        const errMsg = Translation('recruitment.exam.incorrect_file_type');
        throw new Error(errMsg);
      }
      const res = [];
      // map row header to row data
      const keys = data[0];

      const errorMsgs: any[] = [];

      // validate column
      const invalidColumns = keys.filter((i: string) => !templateFileData[0].includes(i));

      if (invalidColumns.length > 0) {
        errorMsgs.push({
          row: 0,
          msg: TranslationWithParams('recruitment.exam.invalid_column_validator', { text: invalidColumns.join(', ') }),
        });
      } else {
        const configs = createResultConfigs({ emptyMsgKey: 'recruitment.exam.missing_field_validator' });

        for (let i = 1; i < data.length; i++) {
          const exam: any = {};
          const rowData = data[i];
          const isEmpty = rowData.every((element: any) => {
            return element === undefined || element === null || element === '';
          });
          if (isEmpty) {
            continue;
          }
          for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const transformedKey = ExamResultsEnum[key as keyof typeof ExamResultsEnum] || key;
            exam[transformedKey] = rowData[j];
          }
          res.push(exam);

          // format date
          // will report error if time format is not correct, so we need to catch the error here, below code will validate the error
          ['examDate', 'startTime', 'endTime'].forEach((key) => {
            try {
              const val = exam[key];
              const examDate = exam['examDate'];
              const isExamDateValid = moment(examDate, 'DD/MM/YYYY').isValid();
              if (val) {
                switch (key) {
                  case 'examDate':
                    exam[key] = isExamDateValid ? moment(val, 'DD/MM/YYYY').toISOString() : 'INVALID_DATE';
                    break;
                  case 'startTime':
                    exam[key] = combineDateTime(isExamDateValid ? examDate : new Date(), val).toISOString();
                    break;
                  case 'endTime':
                    exam[key] = combineDateTime(isExamDateValid ? examDate : new Date(), val).toISOString();
                    break;
                }
              }
            } catch (err) {
              console.log('format time error: ', err);
            }
          });

          const form = {
            getFieldValue: (key: string) => exam[key],
          } as any;

          const examType = exam['examType'];

          if (examType && examTypeValues?.indexOf(examType) === -1) {
            errorMsgs.push({
              row: i,
              msg: TranslationWithParams('recruitment.exam.examType_value_validator', {
                text: examTypeValues.join('/'),
              }),
            });
          }

          configs.forEach((config) => {
            const { name, rules, label } = config;
            const value = exam[name];

            rules.forEach((r) => {
              let rule = r;
              if (typeof r === 'function') {
                rule = r(form);
              }
              const { required, validator } = rule as any;

              if (validator) {
                validator({ required }, value, (msg: string) => {
                  if (msg) {
                    errorMsgs.push({
                      row: i,
                      msg,
                      // value,
                      // name,
                    });
                  }
                });
              } else {
                if (required && !value) {
                  errorMsgs.push({
                    row: i,
                    msg: TranslationWithParams('recruitment.exam.missing_field_validator', { field: label }),
                    // value,
                    // name,
                  });
                }
              }
            });
          });
        }
      }

      if (errorMsgs?.length > 0) {
        // only show 20 errors at max
        setValidatorErrors(errorMsgs.slice(0, 20));
        setResultsFileData({
          name: file.name,
          data: [],
        });
      } else {
        //map result key to request param
        result = transformArray(res);
        setResultsFileData({
          name: file.name,
          data: result,
        });
      }
    } catch (e: any) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: e.message,
          },
        ]),
      );
    }
    return result;
  };

  const downLoadTemplate = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(templateFileData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'template.xlsx');
  };

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file?.name?.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('recruitment.exam.incorrect_file_type'),
          },
        ]),
      );
      return false;
    }
    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('file_size_warn_label'),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const transformArray = (originalArray: Record<string, any>[]): Record<string, any>[] => {
    return originalArray.map((obj) => {
      const { examDate, startTime, endTime, examNameLocal, examNameEnglish, result, ...rest } = obj;
      const examName = { en: examNameEnglish } as any;
      if (regionLocale?.length > 1) {
        regionLocale.map((locale) => {
          if (locale !== 'en') {
            examName[locale] = examNameLocal;
          }
        });
      }
      return {
        ...rest,
        examName,
        examDate: dayjs(examDate).format('YYYY-MM-DD'),
        startTime: dayjs(startTime).format('HH:mm'),
        endTime: dayjs(endTime).format('HH:mm'),
        result: (result as string).toUpperCase(),
      };
    });
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e?.target?.files?.[0];
      // set max size 10MB
      const maxSize = 10;
      if (!checkFile(file, maxSize, ['csv', 'xlsx', 'xls'])) {
        return;
      }
      const result = await getResultsData(file);
      if (result && !isEmpty(result)) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: ``,
            },
          ]),
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await createLicenseExamResults({ payload: resultsFileData.data });
      history.goBack();
    } catch (err: any) {
      if (err.statusCode == 400) {
        setValidatorErrors(
          (err.errors?.data ?? [err.message]).map((msg: string) => ({
            row: -1, // will from BE, will show the error message directly
            msg,
          })),
        );
        setResultsFileData((d) => ({
          ...d,
          data: [],
        }));
      } else {
        apiErrorHandler(err, dispatch);
      }
      console.warn('results upload result: ', err);
    }
  };

  const handleRemove = () => {
    setValidatorErrors([]);
    setResultsFileData(initalResultsFileData);
  };

  return (
    <>
      {loading ? (
        <LayoutSplashScreen />
      ) : error ? null : (
        <>
          <div
            className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-h-full tw-w-full"
            style={{ height: 'auto', minHeight: '100%', overflow: 'scroll' }}
          >
            <div className="tw-my-[21px]">
              <div className="tw-flex tw-w-full tw-items-center tw-mb-[21px]">
                <div className={commonClasses.header}>
                  {Translation('recruitment.exam.registration.uploadExamResult')}
                </div>
              </div>
            </div>
            <div className="tw-my-[21px]">
              <div className="tw-flex tw-w-full tw-items-center tw-mb-[21px]">
                <span className="tw-text-base tw-font-bold tw-mr-24">
                  {Translation('recruitment.exam.results.upload')}
                  <Typography component={'span'} color="#E8192C" fontSize={'12px'} fontWeight={400}>
                    *
                  </Typography>
                  :
                </span>
                {!resultsFileData?.name ? (
                  <>
                    <div>
                      <input
                        id="upload-file"
                        hidden
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onClick={(e) => {
                          const element = e.target as HTMLInputElement;
                          element.value = '';
                        }}
                        onChange={(e) => handleFile(e)}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => document.getElementById('upload-file')!.click()}
                      >
                        {Translation('app.button.upload')}
                      </Button>
                    </div>
                    <div className="tw-flex tw-flex-col tw-items-start tw-ml-5">
                      <span className="tw-text-base tw-ml-2">
                        {Translation('recruitment.exam.registration.upload.file_limit')}
                      </span>
                      <Button onClick={downLoadTemplate} color="secondary">
                        <span className="tw-underline tw-font-bold">{Translation('download_template_button')}</span>
                      </Button>
                      <div className="tw-ml-2">{resultsFileData.name || Translation('app.file.noFileChosen')}</div>
                    </div>
                  </>
                ) : (
                  <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                    <Typography color="#000000" fontSize={'12px'} fontWeight={400} height={'20px'} marginRight={'20px'}>
                      {resultsFileData?.name}
                    </Typography>
                    <Button variant="text" size="large" color="secondary" onClick={handleRemove}>
                      {Translation('app.button.remove')}
                    </Button>
                  </Box>
                )}
              </div>
              {validatorErrors.length > 0 ? (
                <div>
                  <Typography color="#E8192C" fontSize={'12px'} fontWeight={400} marginBottom={'14px'}>
                    *{Translation('recruitment.exam.schedule.file_upload_fail_validator')}
                  </Typography>
                  {validatorErrors.map((item, index) => (
                    <Typography key={index} color="#212B36" fontSize={'12px'} fontWeight={400} marginBottom={'12px'}>
                      {item.row === -1
                        ? item.msg
                        : TranslationWithParams('recruitment.exam.schedule.file_upload_fail_detail_validator', {
                            ...item,
                            row: item.row + 1,
                          })}
                    </Typography>
                  ))}
                </div>
              ) : (
                <Box marginTop={'24px'}>
                  <Typography color="#888888" fontSize={'12px'} fontWeight={600}>
                    {Translation('recruitment.exam.registration.upload.mandatory_check')}
                  </Typography>
                  <List disablePadding sx={{ listStyleType: 'disc', pl: 2 }}>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                      <ListItemText primary={Translation('recruitment.exam.registration.upload.mandatory_field')} />
                    </ListItem>
                  </List>
                  <Typography color="#888888" fontSize={'12px'} fontWeight={600}>
                    {Translation('recruitment.exam.registration.upload.matching_rule')}
                  </Typography>
                  <List disablePadding sx={{ listStyleType: 'disc', pl: 2 }}>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                      <ListItemText primary={Translation('recruitment.exam.registration.upload.rule1')} />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                      <ListItemText primary={Translation('recruitment.exam.registration.upload.rule2')} />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                      <ListItemText primary={Translation('recruitment.exam.registration.upload.rule3')} />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                      <ListItemText primary={Translation('recruitment.exam.registration.upload.rule4')} />
                    </ListItem>
                  </List>
                </Box>
              )}
            </div>

            <div className="tw-absolute tw-bottom-10 tw-right-10">
              <div className="tw-space-x-4">
                <Button variant="contained" size="large" color="inherit" onClick={() => history.goBack()}>
                  {Translation('app.button.back')}
                </Button>
                {validatorErrors?.length <= 0 ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    disabled={!resultsFileData?.name}
                    onClick={handleSubmit}
                  >
                    {Translation('app.button.confirm')}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExamResultsUploadPage;
