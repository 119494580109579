export const AUDIT_LOG_BASE_PATH = '/auditlog';
export const USER_MANAGEMENT_LOG_PATH = '/user-management-log';

export const AUDIT_LOG_TITLE = 'auditLog.title';
export const USER_MANAGEMENT_LOG_TITLE = 'auditLog.userManagementLog.title';

export const USER_MANAGEMENT_LOG_NAMESPACE = 'USER-MANAGEMENT-LOG';

export enum AdminAuditLogPermissionType {
  USER_MANAGEMENT_LOG_READ = 'READ_ANY_USER-MANAGEMENT-LOG',
  USER_MANAGEMENT_LOG_CREATE = 'CREATE_ANY_USER-MANAGEMENT-LOG',
  USER_MANAGEMENT_LOG_UPDATE = 'UPDATE_ANY_USER-MANAGEMENT-LOG',
}
