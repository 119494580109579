import axios, { AxiosRequestConfig } from 'axios';
declare module 'axios' {
  interface AxiosRequestConfig {
    requestKey?: string; // add custom key
  }
}
// temp save pending requests
let pendingRequests = new Map();
const OEM_APIM_SUBSCRIPTION_KEY = window.envConfig['REACT_APP_OEM_APIM_SUBSCRIPTION_KEY'] || '';
const isDev = window.envConfig['REACT_APP_ENV'] === 'local';

const apiClient = axios.create({
  timeout: 60000,
  // local/dev must use: withCredentials: false, otherwise api call will have cors error
  withCredentials: isDev ? false : true,
});

//  Pre-Config before request
apiClient.interceptors.request.use(
  (config) => {
    // The method, URL, and parameters of the request are combined into a string as the unique key to identify the request
    const requestKey = `${config.method}:${config.url}/${JSON.stringify(config.params)}/${JSON.stringify(config.data)}`;
    if (pendingRequests.has(requestKey)) {
      config.cancelToken = new axios.CancelToken((cancel) => {
        /**
         * Cancel duplicate request
         * The parameters of the cancel function will be captured as errors in the promise
         */
        cancel(`Duplicate request intercepted: ${requestKey}`);
      });
    } else {
      pendingRequests.set(requestKey, config);
      config.requestKey = requestKey;
    }
    // CAAEB-22458: pentest fixing, remove subscription-key from url
    // const { url } = config;
    // config.url =
    //   url && url?.indexOf('?') > 0
    //     ? `${url}&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`
    //     : `${url}?subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`;
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('jwt')}`;
    config.headers['Ocp-Apim-Subscription-Key'] = OEM_APIM_SUBSCRIPTION_KEY;
    return config;
  },
  (error) => {
    pendingRequests.clear(); // clear pendingRequests on error
    return Promise.reject(error);
  },
);

//  Handle response from every api call
apiClient.interceptors.response.use(
  (data) => {
    const requestKey = data.config.requestKey;
    pendingRequests.delete(requestKey);
    return data;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.error(error);
      /**
       * By returning a new Promise that does not resolve or reject, you effectively prevent the canceled request from affecting the overall promise chain. This way, the canceled request will not trigger any further .then() or .catch() handlers.
       */
      return new Promise(() => {});
      // return Promise.reject(error); // this will be capture by Promise.catch()
    }
    pendingRequests.clear();
    if (!error.response) {
      throw error;
    }
    if (error.response.data) {
      throw error.response.data;
    } else {
      throw error.response;
    }
  },
);

export default apiClient;
